import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { FieldType, FieldValue, FilterField, Filters, option } from '../../../../../features/ui'
import { CheckStatus, CheckType, useAdministrationHooks, useAdministrationState } from '../../..'
import { Labels } from '../../../../../features/data-types'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const CheckFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { checks } = useAdministrationState()

    const [searchText, setSearchText] = useState<string>()
    const [type, setType] = useState<CheckType>()
    const [status, setStatus] = useState<CheckStatus>()
    const [paymentDateFrom, setPaymentDateFrom] = useState<Date>()
    const [paymentDateTo, setPaymentDateTo] = useState<Date>()

    const { searchChecks } = useAdministrationHooks()

    useEffect(() => search(searchText, type, status, paymentDateFrom, paymentDateTo), [checks])

    const search = (
        searchTextParam?: string,
        typeParam?: CheckType,
        statusParam?: CheckStatus,
        paymentDateFromParam?: Date,
        paymentDateToParam?: Date
    ) => {
        onSearchStart()
        let checkList = checks.filter(check => !typeParam || check.type === typeParam)
        checkList = statusParam ? checkList.filter(check => check.status === statusParam) : checkList
        checkList = paymentDateFromParam ? checkList.filter(check => new Date(check.paymentDate) >= paymentDateFromParam) : checkList
        checkList = paymentDateToParam ? checkList.filter(check => new Date(check.paymentDate) <= paymentDateToParam) : checkList
        checkList = searchChecks(checkList, searchTextParam)
        setTimeout(() => onSearchDone(checkList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(newSearchText, type, status, paymentDateFrom, paymentDateTo)
    }

    const onChangeType = (value?: FieldValue) => {
        const newType = value as CheckType | undefined
        if (newType !== type) {
            setType(newType)
            search(searchText, newType, status, paymentDateFrom, paymentDateTo)
        }
    }

    const onChangeStatus = (value?: FieldValue) => {
        const newStatus = value as CheckStatus | undefined
        if (newStatus !== status) {
            setStatus(newStatus)
            search(searchText, type, newStatus, paymentDateFrom, paymentDateTo)
        }
    }

    const onChangePaymentDateFrom = (value?: FieldValue) => {
        const newPaymentDateFrom = value as Date | undefined
        newPaymentDateFrom?.setHours(11, 59, 59)
        if (newPaymentDateFrom !== paymentDateFrom) {
            setPaymentDateFrom(newPaymentDateFrom)
            search(searchText, type, status, newPaymentDateFrom, paymentDateTo)
        }
    }
    
    const onChangePaymentDateTo = (value?: FieldValue) => {
        const newPaymentDateTo = value as Date | undefined
        newPaymentDateTo?.setHours(11, 59, 59)
        if (newPaymentDateTo !== paymentDateTo) {
            setPaymentDateTo(newPaymentDateTo)
            search(searchText, type, status, paymentDateFrom, newPaymentDateTo)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por nombre, razón social o CUIT/CUIL',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'type',
            type: FieldType.SELECT,
            options: Object.values(CheckType).map(type => option(type, Labels.checkTypeLabel(type))),
            label: 'Tipo',
            space: 3,
            onChange: onChangeType
        },
        {
            name: 'status',
            type: FieldType.SELECT,
            options: Object.values(CheckStatus).map(status => option(status, Labels.checkStatusLabel(status))),
            label: 'Estado',
            space: 3,
            onChange: onChangeStatus
        },
        {
            name: 'paymentDateFrom',
            type: FieldType.DATE,
            label: 'Fecha de Pago: Desde',
            space: 3,
            onChange: onChangePaymentDateFrom
        },
        {
            name: 'paymentDateTo',
            type: FieldType.DATE,
            label: 'Fecha de Pago: Hasta',
            space: 3,
            onChange: onChangePaymentDateTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
