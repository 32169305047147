import { Button } from "../button/button"
import { Action } from '../types'

type Props = {
    action: Action
    className?: string
    onClick?: () => void
}

export const ActionButton = (props: Props) => {
    const { action, className, onClick } = props

    return (
        <Button
            kind={action.kind || 'primary'}
            label={action.label}
            icon={action.icon}
            tooltip={action.tooltip}
            narrowTooltip={action.narrowTooltip}
            disabled={action.disabled}
            className={className}
            onClick={onClick || action.onClick}
        />
    )
}
