import { DataType, TablesData } from "../../../tables";
import { TableName } from "../../../tables/types/types";
import { usePermissionsActions } from "./permissionsActions";
import { define } from "../../../utils/typeUtils";
import { firstItem } from "../../../utils/listUtils";
import { Standards, newId } from "../../../features/data-types";
import { tableAction, tableArn } from "../../../utils/policiesUtils";
import { AttributeMap, useDynamoActions } from "../../../services/dynamo"
import { useChangedIdMigration } from "../../../guides/migrations/changedId";
import { useRemovedPropMigration } from "../../../guides/migrations/removedProp";
import { StatementType } from "../../../services/iam";
import { useAddedRequiredPropMigration } from "../../../guides/migrations/addedRequiredProp";
import { useRenamedPropMigration } from "../../../guides/migrations/renamedProp";
import { useChangedEnumValueMigration } from "../../../guides/migrations/changedEnumValue";
import { useRemovedTableTenantKeyMigration } from "../../../guides/migrations/removedTableTenantKey";
import { Company, IdentifierType, Request, RequestStatus, systemPermissions, SystemPolicyName, User, UserRole, useSystemActions } from "../../system";
import { FieldValue, showErrorStrToast } from "../../../features/ui";
import { administrationPermissions, PaymentCategory, TaxType } from "../../administration";
import { purchasesPermissions } from "../../purchases";
import { salesPermissions } from "../../sales";
import { construfyAdminPermissions, ConstrufyAdminPolicyName, ConstrufyAdminRoleName } from "..";
import { managerPermissions, useManagerActions } from "../../manager";
import { warehousePermissions } from "../../warehouse";
import { TableActions, policyName, roleName, toIamPermissions, toPermissionList } from "../../../features/roles";
import { DynamoUserApi } from "../../../services";
import { useImportSuppliers, useImportCustomers, useImportProducts } from "../../../features/importer";
import { useUpdatedValueMigration } from "../../../guides/migrations/updatedValue";
import { PaymentStatus } from "../../operations";
import { awsS3Bucket, awsS3PublicBucket } from "../../../services/constants";
import { toListResponse } from "../../../services/utils";

export const useConstrufyAdminActions = () => {
    const systemActions = useSystemActions()
    const managerActions = useManagerActions()
    const permissionsActions = usePermissionsActions()
    const dynamoActions = useDynamoActions()
    const changedId = useChangedIdMigration()
    const removedProp = useRemovedPropMigration()
    const renamedProp = useRenamedPropMigration()
    const addedRequiredProp = useAddedRequiredPropMigration()
    const updatedValue = useUpdatedValueMigration()
    const changedEnumValue = useChangedEnumValueMigration()
    const removedTableTenantKey = useRemovedTableTenantKeyMigration()
    const importSuppliers = useImportSuppliers()
    const importCustomers = useImportCustomers()
    const importProducts = useImportProducts()
    
    const createCompany = async (
        code: string,
        name: string,
        legalName: string,
        websiteUrl: string,
        email: string,
        address: string
    ) => {
        const { fetchCompanyByCode, saveCompany } = systemActions()
        const company = await fetchCompanyByCode(code)
        if (company) {
            showErrorStrToast('La compañía ya existe.')
        } else {
            const newCompany: Company = {
                id: newId(),
                code,
                name,
                legalName,
                websiteUrl,
                email,
                address
            }
            saveCompany(newCompany)
        }
    }

    const resolveRequest = async (
        companyCode: string,
        userEmail: string,
        resolution: RequestStatus
    ) => {
        const company = define(await systemActions().fetchCompanyByCode(companyCode))
        const user = define(await systemActions().fetchUserByEmail(userEmail)) as User
        const request = define(await systemActions(company.id).fetchRequest(company.id, user.id)) as Request
        managerActions(company.id).resolveRequest(request, company, resolution, false)
    }

    const saveSystemPolicy = () => {
        const tablesStatements: StatementType[] = []
        toIamPermissions(toPermissionList(systemPermissions())).forEach(permission => {
            tablesStatements.push({
                Effect: "Allow",
                Action: permission.actions.map(action => tableAction(action)),
                Resource: permission.tables.map(table => tableArn(table))
            })
        })

        const policy = {
            "Version": "2012-10-17",
            "Statement": tablesStatements.concat(
                {
                    "Effect": "Allow",
                    "Action": [
                        "iam:AddUserToGroup",
                        "iam:CreateAccessKey",
                        "iam:CreateUser",
                        "iam:DeleteUser",
                        "iam:GetUser",
                        "iam:ListGroupsForUser",
                        "iam:RemoveUserFromGroup",
                        "sts:AssumeRole"
                    ],
                    "Resource": [
                        "*"
                    ]
                },
                {
                    "Effect": "Allow",
                    "Action": ["s3:*"],
                    "Resource": [
                        `arn:aws:s3:::${awsS3Bucket}/*`,
                        `arn:aws:s3:::${awsS3PublicBucket}/*`

                    ]
                }
            )
        }

        permissionsActions.savePolicyFromJson(SystemPolicyName, policy)
    }

    const saveConstrufyAdminPolicy = () => {
        const tablesStatements: StatementType[] = []
        toIamPermissions(toPermissionList(construfyAdminPermissions())).forEach(permission => {
            tablesStatements.push({
                Effect: "Allow",
                Action: permission.actions.map(action => tableAction(action)),
                Resource: permission.tables.map(table => tableArn(table))
            })
        })

        const policy = {
            "Version": "2012-10-17",
            "Statement": tablesStatements.concat({
                "Effect": "Allow",
                "Action": [
                    "dynamodb:CreateTable",
                    "dynamodb:DeleteTable",
                    "iam:AddUserToGroup",
                    "iam:AttachGroupPolicy",
                    "iam:AttachRolePolicy",
                    "iam:CreateGroup",
                    "iam:CreatePolicy",
                    "iam:CreateRole",
                    "iam:CreatePolicyVersion",
                    "iam:CreateUser",
                    "iam:DeletePolicyVersion",
                    "iam:GetUser",
                    "iam:ListPolicyVersions"
                ],
                "Resource": "*"
            })
        }

        permissionsActions.savePolicyFromJson(ConstrufyAdminPolicyName, policy)
        // permissionsActions.createRole(ConstrufyAdminRoleName, ConstrufyAdminPolicyName)
    }

    const createCompanyPolicies = async(companyId: string, companyCode: string) => {
        const createPolicy = async (userRole: UserRole, permissions: TableActions[]) => {
            const policy = policyName(companyCode, userRole)
            await permissionsActions.savePolicy(policy, toPermissionList(permissions), companyId, true)
            const role = roleName(companyCode, userRole)
            await permissionsActions.createRole(role, policy)
        }

        await createPolicy(UserRole.MANAGER, managerPermissions())
        await createPolicy(UserRole.ADMINISTRATION, administrationPermissions())
        await createPolicy(UserRole.PURCHASES, purchasesPermissions())
        await createPolicy(UserRole.SALES, salesPermissions())
        await createPolicy(UserRole.WAREHOUSE, warehousePermissions())
    }

    const saveCompanyPolicies = async () => { 
        const companies = await systemActions().fetchAllCompanies()
        companies.forEach(company => {
            const managerPolicy = policyName(company.code, UserRole.MANAGER)
            const adminstrationPolicy = policyName(company.code, UserRole.ADMINISTRATION)
            const purchasesPolicy = policyName(company.code, UserRole.PURCHASES)
            const salesPolicy = policyName(company.code, UserRole.SALES)
            const warehousePolicy = policyName(company.code, UserRole.WAREHOUSE)

            permissionsActions.savePolicy(managerPolicy, toPermissionList(managerPermissions()), company.id)
            permissionsActions.savePolicy(adminstrationPolicy, toPermissionList(administrationPermissions()), company.id)
            permissionsActions.savePolicy(purchasesPolicy, toPermissionList(purchasesPermissions()), company.id)
            permissionsActions.savePolicy(salesPolicy, toPermissionList(salesPermissions()), company.id)
            permissionsActions.savePolicy(warehousePolicy, toPermissionList(warehousePermissions()), company.id)
        })
    }

    const createTables = () => {
        const tableParams = (tableName: TableName, hasTKey: boolean) => ({
            name: define(TablesData.get(tableName)).dbTableName,
            hasTKey
        })
        const tables = [
            // tableParams(TableName.BRANCHES, true),
            // tableParams(TableName.BUDGETS, true),
            // tableParams(TableName.BUDGET_ITEMS, true),
            // tableParams(TableName.CASH_FUNDS, true),
            // tableParams(TableName.CATEGORIES, true),
            // tableParams(TableName.CHECKS, true),
            // tableParams(TableName.COMPANIES, false),
            // tableParams(TableName.CREDIT_CARDS, true),
            // tableParams(TableName.CUSTOMER_TYPES, true),
            // tableParams(TableName.CUSTOMERS, true),
            // tableParams(TableName.EMPLOYEES, true),
            // tableParams(TableName.EXPENSE_TYPES, true),
            // tableParams(TableName.EXPENSES, true),
            // tableParams(TableName.HOARD_ITEMS, true),
            // tableParams(TableName.HOARD_ORDERS, true),
            // tableParams(TableName.LOTS, true),
            // tableParams(TableName.MEASURES, true),
            // tableParams(TableName.ORDER_DELIVERIES, true),
            tableParams(TableName.PAYMENT_ORDERS, true),
            // tableParams(TableName.PAYMENT_TYPES, true),
            // tableParams(TableName.PRODUCT_PRICES, true),
            // tableParams(TableName.PRODUCTS, true),
            // tableParams(TableName.PURCHASE_ITEMS, true),
            // tableParams(TableName.PURCHASE_ORDERS, true),
            // tableParams(TableName.PURCHASE_TICKETS, true),
            // tableParams(TableName.REQUESTS, true),
            // tableParams(TableName.SALE_ITEMS, true),
            // tableParams(TableName.SALE_ORDERS, true),
            // tableParams(TableName.SETTINGS, true),
            // tableParams(TableName.STOCK, true),
            // tableParams(TableName.SUPPLIERS, true),
            // tableParams(TableName.TAXES, true),
            // tableParams(TableName.TRANSACTIONS, true),
            // tableParams(TableName.UNIT_TYPES, false),
            // tableParams(TableName.USERS, false)
        ]
        DynamoUserApi.createTables(tables)
    }

    const createUnitTypes = async () => {
        const defaultUnitTypesDT = Standards.unitTypes() as DataType[]
        const UnitTypesData = define(TablesData.get(TableName.UNIT_TYPES))
        await DynamoUserApi.createOrUpdate(UnitTypesData.dbTableName, defaultUnitTypesDT as AttributeMap[])
    }

    const createDefaultData = async (companyIdParam?: string) => {
        const createData = async (companyId: string) => {
            const { createOrUpdate } = dynamoActions(DynamoUserApi, companyId)

            const defaultTaxes = Standards.taxes(companyId)
            const defaultBranches = Standards.branches(companyId)
            const defaultCategories = Standards.categories(companyId)
            const defaultCustomerTypes = Standards.customerTypes(companyId)
            const defaultPaymentTypes = Standards.paymentTypes(companyId)
            const defaultExpenseTypes = Standards.expenseTypes(companyId)
            
            const defaultTaxesDT = defaultTaxes as DataType[]
            const TaxesData = define(TablesData.get(TableName.TAXES))
            await createOrUpdate(TaxesData.dbTableName, defaultTaxesDT as AttributeMap[])
            
            const defaultBranchesDT = defaultBranches as DataType[]
            const BranchesData = define(TablesData.get(TableName.BRANCHES))
            // await createOrUpdate(BranchesData.dbTableName, defaultBranchesDT as AttributeMap[])
            
            const defaultCategoriesDT = defaultCategories as DataType[]
            const CategoriesData = define(TablesData.get(TableName.CATEGORIES))
            // await createOrUpdate(CategoriesData.dbTableName, defaultCategoriesDT as AttributeMap[])
            
            const defaultCustomerTypesDT = defaultCustomerTypes as DataType[]
            const CustomerTypesData = define(TablesData.get(TableName.CUSTOMER_TYPES))
            // await createOrUpdate(CustomerTypesData.dbTableName, defaultCustomerTypesDT as AttributeMap[])
            
            const defaultPaymentTypesDT = defaultPaymentTypes as DataType[]
            const PaymentTypesData = define(TablesData.get(TableName.PAYMENT_TYPES))
            // await createOrUpdate(PaymentTypesData.dbTableName, defaultPaymentTypesDT as AttributeMap[])
            
            const defaultExpenseTypesDT = defaultExpenseTypes as DataType[]
            const ExpenseTypesData = define(TablesData.get(TableName.EXPENSE_TYPES))
            // await createOrUpdate(ExpenseTypesData.dbTableName, defaultExpenseTypesDT as AttributeMap[])
        }

        if (companyIdParam) {
            createData(companyIdParam)
        } else {
            const companies = await systemActions().fetchAllCompanies()
            companies.forEach(({ id }) => createData(id))
        }
    }

    const clearTable = async (companyId: string, tableName: TableName) => {
        const { getAll, remove } = dynamoActions(DynamoUserApi, companyId)
        const { dbTableName } = define(TablesData.get(tableName))
        const listResponse = await getAll(dbTableName)
        const { dataTypes } = (toListResponse(listResponse))
        const ids = dataTypes.map(dataType => dataType.id)
        await remove(dbTableName, ids)
    }

    const migrate = async () => {        
        const companies = await systemActions().fetchAllCompanies()
        const companyIds = companies.map(({ id }) => id)

        // addedRequiredProp(companyIds, TableName.CASH_FUNDS, 'reservedAmount', 0)

        // renamedProp(companyIds, TableName.HOARD_ORDERS, 'status', 'deliveryStatus')

        updatedValue(companyIds, TableName.TAXES, 'type', (value: FieldValue) => {
            const type = value as string
            return type === 'Tax' ? TaxType.SALE_TAX : type
        })

        // removedProp(companyIds, TableName.TAXES, 'saleEnabled')
    }

    const removeUser = async (userEmail: string) => {
        const users = await systemActions().fetchUsersByEmail(userEmail) as User[]
        const user = define(firstItem(users)) as User
        const userId = user.id
        
        await systemActions().removeUser(userId)
        
        const requests = await systemActions().fetchRequests(userId) as Request[]
        requests.forEach(async request => {
            await systemActions(request.companyId).removeRequest(request.id)
        })
    }

    return {
        createCompany,
        resolveRequest,
        saveConstrufyAdminPolicy,
        saveSystemPolicy,
        createCompanyPolicies,
        saveCompanyPolicies,
        createTables,
        createUnitTypes,
        createDefaultData,
        clearTable,
        migrate,
        removeUser,
        importSuppliers,
        importCustomers,
        importProducts
    }
}
