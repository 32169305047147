import { Form, FormField, Data } from '../../../ui'
import { define } from '../../../../utils/typeUtils'
import { useSystemStateHooks } from '../../../../modules/system'
import { DataType } from '../../../../tables'
import { TenantDataType } from '../../../../tables/types/types'
import { newId, toFieldTypeValue } from '../../utils/dataTypesUtils'
import { useRoutesHooks } from '../../../routes'

type Props = {
    formId: string
    fields: FormField[]
    createMode?: boolean
    createLabel?: string
    disableSubmit?: boolean
    onSubmit: (data: Data) => void
    onCancel: () => void
}

export const DataTypeForm = (props: Props) => {
    const { formId, fields, createMode = true, createLabel, disableSubmit, onSubmit, onCancel } = props
    const urlData = useRoutesHooks().parseUrl()
    const dataType = urlData.secondDataType || urlData.dataType
    const { companyId } = useSystemStateHooks()

    const dataTypeFields: FormField[] = fields.map(field => {
        const value = toFieldTypeValue(field.type, dataType?.[field.name as keyof DataType])
        const defaultValue = field.defaultValue
        return {
            ...field,
            defaultValue: createMode ? defaultValue : value || defaultValue
        }
    })

    const handleSubmit = (data: Data) => {
        if (createMode) {
            data.companyId = define(companyId)
            data.id = newId()
        } else {
            const tenantDataType = define(dataType) as TenantDataType
            data.companyId = tenantDataType.companyId
            data.id = tenantDataType.id
        }
        
        onSubmit(data)
    }

    return (
        <Form
            formId={formId}
            fields={dataTypeFields}
            createMode={createMode}
            createLabel={createLabel}
            disableSubmit={disableSubmit}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    )
}
