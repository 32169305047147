import { Dispatch } from 'redux'
import { ActionType, StockAction } from './actionTypes'
import { Stock, UnitType } from './types'

export const updateStock = (stock: Stock[]) => {
    return (dispatch: Dispatch<StockAction>) => {
        dispatch({
            type: ActionType.UPDATE_STOCK,
            payload: stock
        })
    }
}

export const removeStock = (ids: string[]) => {
    return (dispatch: Dispatch<StockAction>) => {
        dispatch({
            type: ActionType.REMOVE_STOCK,
            payload: ids
        })
    }
}

export const updateUnitTypes = (unitTypes: UnitType[]) => {
    return (dispatch: Dispatch<StockAction>) => {
        dispatch({
            type: ActionType.UPDATE_UNIT_TYPES,
            payload: unitTypes
        })
    }
}
