import { TableName } from "../../../../../tables"
import { FieldType, FormField } from "../../../../../features/ui"
import { WizardStep } from "../../../../../features/wizard"
import { HoardItem } from "../../../state/types"
import { LotInfo, ProductName } from "../../../../products"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"
import { useDescriptorHooks } from "../../../../../features/data-types"

type Props = {
    wizardKey: string
    stepIndex: number
    hoardItem: HoardItem
    pendingQuantity: number
}

export const HoardItemStep = (props: Props) => {
    const { wizardKey, stepIndex, hoardItem, pendingQuantity } = props
    
    const descriptorHooks = useDescriptorHooks()

    const { findProduct, findLot } = useActions()

    const product = findProduct(hoardItem.productId)

    const fields: FormField[] = [
        {
            name: 'product',
            type: FieldType.TEXT,
            label: 'Producto',
            render: () => <ProductName product={product} />
        },
        {
            name: 'pendingQuantity',
            type: FieldType.TEXT,
            label: 'Unidades Pendientes de Entrega',
            value: descriptorHooks.product.unitsDetail(pendingQuantity, product),
            disabled: true
        },
        {
            name: 'deliveredQuantity',
            type: FieldType.NUMBER,
            label: 'Unidades Entregadas',
            defaultValue: pendingQuantity,
            min: 0,
            max: pendingQuantity,
            allowDecimals: false,
            focus: true
        }
    ]
    if (product.loted) {
        const lot = findLot(define(hoardItem.lotId))
        const lotField = {
            name: 'lot',
            type: FieldType.TABLE,
            table: TableName.LOTS,
            label: 'Partida',
            render: () => <LotInfo lot={lot} />
        }
        fields.splice(1, 0, lotField)
    }

    return <WizardStep
        wizardKey={wizardKey}
        stepIndex={stepIndex}
        fields={fields}
    />
}
