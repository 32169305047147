import styles from './percentField.module.css'
import { InputGroup } from '../input-group/inputGroup'
import { TextOrNumber } from '../../types'

type Props = {
    id: string
    inputKey?: string
    value?: number
    defaultValue?: number
    optional?: boolean
    disabled?: boolean
    onChange?: (value?: number) => void
}

export const PercentField = (props: Props) => {
    const { id, inputKey, value, defaultValue, optional, disabled, onChange } = props

    const handleChange = (newValue?: TextOrNumber) => {
        onChange && onChange(newValue as number | undefined)
    }

    return (
        <InputGroup
            id={id}
            inputKey={inputKey}
            value={value}
            defaultValue={defaultValue}
            postLabel="%"
            min={0}
            optional={optional}
            disabled={disabled}
            fit={true}
            className={styles.field}
            onChange={handleChange}
        />
    )
}
