import { TableName } from '../../../../../tables'
import { Data, UIPermission, DetailPanel, DetailFieldProps, FieldType, FieldValue, Action, Spinner, Tab, useGetCrumbs, SpinnerDiv } from '../../../../../features/ui'
import { Customer, CustomerType, SaleItem, SaleOrder } from '../../../state/types'
import { define } from '../../../../../utils/typeUtils'
import { useSalesActions } from '../../../actions/salesActions'
import { useEffect, useState } from 'react'
import { Operation, OperationItemsTable, DeliveryStatus, DeliveryList, OrderDelivery, useOperationsActions, PendingItemsList, PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { useSystemStateHooks } from '../../../../system'
import { PaymentType } from '../../../../administration'
import { saleWizardKey } from '../../../utils/salesUtils'
import { OpenWizardModal, useWizardActions } from '../../../../../features/wizard'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { useFind } from '../../../../../state/reducers/hooks'
import { useDescriptorHooks, Labels } from '../../../../../features/data-types'

export const SaleDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const sale = define(dataType) as SaleOrder
  const wizardKey = saleWizardKey(sale)

  const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
  const [saleItems, setSaleItems] = useState<SaleItem[]>([])
  const [customer, setCustomer] = useState<Customer>()
  const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
  
  const { userHasPermission } = useSystemStateHooks()
  const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
  const descriptorHooks = useDescriptorHooks()
  const find = useFind()

  const { goToWizard } = useWizardActions()
  const salesActions = useSalesActions()
  const operationsActions = useOperationsActions()

  const readOnly = !userHasPermission(UIPermission.SAVE_SALES)

  const init = async () => {
    const stateSaleItems = await salesActions().fetchSaleItemsByOrder(sale.id) as SaleItem[]
    setSaleItems(stateSaleItems)
    const stateDeliveries = await operationsActions().fetchDeliveries(sale.id) as OrderDelivery[]
    setDeliveries(stateDeliveries)
    const stateCustomer = (await salesActions().fetchCustomer(sale.customerId)) as Customer
    setCustomer(stateCustomer)
    setAsyncDataLoaded(true)
  }

  useEffect(() => {
    init()
  }, [])

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const crumbs = useGetCrumbs('Ventas', RoutePath.SALES, descriptorHooks.sale.code(sale))

  if (!sale) return <SpinnerDiv />

  const customerType = find(TableName.CUSTOMER_TYPES, customer?.customerTypeId) as CustomerType | undefined
  const paymentType = find(TableName.PAYMENT_TYPES, sale.paymentTypeId) as PaymentType | undefined
  const priceBreakdown = getPriceBreakdownFromItems(saleItems, customerType?.yield, paymentType?.yield)

  const fields: DetailFieldProps[] = [
    { name: 'branchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal de Entrega' },
    { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Entrega' },
    { name: 'sellerId', type: FieldType.TABLE, table: TableName.EMPLOYEES, label: 'Vendedor' },
    { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
    {
      name: 'saleItems',
      type: FieldType.TABLE,
      table: TableName.SALE_ITEMS,
      render: () => renderAsync(
        <OperationItemsTable
            rows={saleItems as Data[]}
            operation={Operation.SALE}
            readOnly
        />
      )
    },
    { name: 'paymentTypeId', type: FieldType.TABLE, table: TableName.PAYMENT_TYPES, label: 'Medio de Pago' },
    {
      name: 'priceBreakdown',
      type: FieldType.PRICE,
      label: 'Precio',
      render: () => renderAsync(
        <PriceBreakdown data={priceBreakdown} />
      )
    },
    {
      name: 'deliveryStatus',
      type: FieldType.TEXT,
      label: 'Estado de Entrega',
      render: (value?: FieldValue) => <>{Labels.deliveryStatusLabel(define(value) as DeliveryStatus)}</>
    }
  ]
  if (sale.deliveryStatus !== DeliveryStatus.DELIVERED && deliveries.length > 0) {
    const pendingItemsField = {
      name: 'pendingItems',
      type: FieldType.TABLE,
      table: TableName.SALE_ITEMS,
      render: () => renderAsync(
        <PendingItemsList orderId={sale.id} operation={Operation.SALE} />
      )
    }
    fields.splice(5, 0, pendingItemsField)
  }

  const onDeliver = () => {
    goToWizard(saleWizardKey(sale), RoutePath.DELIVERY)
  }

  const onArchive = () => {}

  const actions: Action[] = []
  if (!readOnly) {
    const archiveAction = { label: 'Archivar', icon: 'archive', kind: 'secondary', onClick: onArchive }
    const deliverAction = { label: 'Registrar Entrega', icon: 'truck', onClick: onDeliver }
    actions.push(sale.deliveryStatus === DeliveryStatus.DELIVERED ? archiveAction : deliverAction)
  }

  const tabs: Tab[] = deliveries.length > 0 ? [
    {
      id: 'deliveries',
      title: 'Entregas',
      content: <DeliveryList
        orderId={sale.id}
        operation={Operation.SALE}
      />
    }
  ] : []

  return (<>
    <DetailPanel
      tableName={TableName.SALE_ORDERS}
      crumbs={crumbs}
      fields={fields}
      data={sale}
      icon='tags'
      imgSpace={3}
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <OpenWizardModal
      wizardKey={wizardKey}
      path={RoutePath.DELIVERY}
    />
  </>)
}
