import { useEffect, useState } from "react"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { PanelLoader, useGetCrumbs } from "../../../../../features/ui"
import { PaymentOrderForm } from "./paymentOrderForm"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { SelectedTickets } from "../../../../purchases/state/constants"
import { PurchaseTicket, usePurchasesActions, usePurchasesStateActions } from "../../../../purchases"
import { useNavigate } from "react-router-dom"
import { dedupe } from "../../../../../utils/listUtils"

export const PaymentOrderFormLoader = () => {
  const { dataTypeId } = useRoutesHooks().parseUrl()
  const paymentOrderId = dataTypeId

  const [ready, setReady] = useState(false)

  const { selectTickets } = usePurchasesStateActions()

  const navigate = useNavigate()
  const { getRoutePath } = useRoutesHooks()

  const administrationActions = useAdministrationActions()
  const purchasesActions = usePurchasesActions()

  const populate = async () => {
    const { fetchAllTaxes, fetchAllPaymentTypes, fetchAllCashFunds, fetchAllChecks, fetchAllCreditCards, fetchAllPaymentOrders } = administrationActions()
    const { fetchPurchaseTickets, fetchPurchases } = purchasesActions()
    await fetchAllTaxes()
    await fetchAllPaymentTypes()
    await fetchAllCashFunds()
    await fetchAllChecks()
    await fetchAllCreditCards()
    await fetchAllPaymentOrders()
    
    const purchaseTicketIds = localStorage.getItem(SelectedTickets)?.split(':') || []
    if (purchaseTicketIds.length > 0) {
      const purchaseTickets = await fetchPurchaseTickets(purchaseTicketIds) as PurchaseTicket[]
      selectTickets(purchaseTickets)
      const purchaseIds = purchaseTickets.map(purchaseTicket => purchaseTicket.purchaseId)
      await fetchPurchases(dedupe(purchaseIds))
    } else {
      navigate(getRoutePath(RoutePath.PENDING_PAYMENTS))
    }
  }

  useEffect(() => {
    populate().then(() => setReady(true))
  }, [])

  const title = `${paymentOrderId ? 'Editar' : 'Crear'} Orden de Pago`
  const crumbs = useGetCrumbs(
    'Pagos Pendientes',
    RoutePath.PENDING_PAYMENTS,
    undefined,
    undefined,
    'Crear'
  )

  return (
    <PanelLoader title={title} crumbs={crumbs} ready={ready}>
      <PaymentOrderForm />
    </PanelLoader>
  )
}
