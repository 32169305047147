import { useAdministrationActions } from "../../actions/administrationActions"
import { Transaction } from "../../state/types"

export const useActions = () => {
    const administrationActions = useAdministrationActions()

    const fetchTransactions = async (
        dateFrom: Date,
        dateTo: Date
    ) => {
        const { fetchTransactions } = administrationActions()
        
        const transactions = (await fetchTransactions(dateFrom, dateTo)).dataTypes as Transaction[]
        return transactions.sort((transaction_A, transaction_B) => transaction_B.date.localeCompare(transaction_A.date))
    }
    
    return {
        fetchTransactions
    }
}
