import styles from './notFoundElement.module.css'

export const NotFoundElement = () => {
    return (
        <div className="container">
            <section className="section error-404 d-flex flex-column align-items-center justify-content-center">
                <h5 className={styles.label}>La página que está buscando no existe.</h5>
                <img src="/assets/img/not-found.png" className="img-fluid" alt="Page Not Found" />
            </section>
        </div>
    )
}
