import { useState } from "react"
import { DataTypeList, Labels, useDataTypesHooks } from "../../../../../features/data-types"
import { PanelWrapper, Column, Data, FieldType, FieldValue, SpinnerDiv, UIPermission, Badge } from "../../../../../features/ui"
import { DataType } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { PaymentCategory, PaymentType } from "../../../state/types"
import { PaymentTypeFilters } from "./paymentTypeFilters"
import { define } from "../../../../../utils/typeUtils"

export const PaymentTypeList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    
    const { formatValue } = useDataTypesHooks()
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PAYMENT_TYPES)

    const columns: Column[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'category',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => <Badge label={Labels.paymentCategoryLabel(define(value) as PaymentCategory)} />
        },
        {
            name: 'yield',
            type: FieldType.PERCENTAGE,
            label: 'Ajuste',
            render: (value?: FieldValue) => {
                const paymentTypeYield = value as number
                const absYield = Math.abs(paymentTypeYield)
                return <>{formatValue(absYield, FieldType.NUMBER)}% de {paymentTypeYield <= 0 ? 'Descuento' : 'Recargo'}</>
            }
        }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as PaymentType[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Medios de Pago">
            <PaymentTypeFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                    minItems={1}
                    showDetail={false}
                />
            )}
        </PanelWrapper>
    )
}
