import { useNavigate } from "react-router-dom"
import { Data, DataTypeForm, Labels, useDescriptorHooks, validateTaxId } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { PanelWrapper, FieldType, FormField, option, useGetCrumbs } from "../../../../../features/ui"
import { Check, CheckType } from "../../../state/types"
import { useActions } from "./actions"

export const CheckForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const check = dataType as Check | undefined
    const createMode = !check

    const navigate = useNavigate()
    const descriptorHooks = useDescriptorHooks()

    const { submitCheck } = useActions()

    const title = `${createMode ? 'Crear' : 'Editar'} Cheque`
    const checkLabel = check ? descriptorHooks.check.info(check) : undefined
    const crumbs = useGetCrumbs('Cheques', RoutePath.CHECKS, checkLabel, check?.id, createMode ? 'Crear' : 'Editar')

    const fields: FormField[] = [
        {
            name: 'type',
            type: FieldType.SELECT,
            label: 'Tipo',
            options: Object.values(CheckType).map(type => option(type, Labels.checkTypeLabel(type)))
        },
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre / Razón Social'
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        },
        {
            name: 'issueDate',
            type: FieldType.DATE,
            label: 'Fecha de Emisión',
            defaultValue: new Date()
        },
        {
            name: 'paymentDate',
            type: FieldType.DATE,
            label: 'Fecha de Pago'
        },
        {
            name: 'taxId',
            type: FieldType.NUMBER,
            label: 'CUIT / CUIL',
            validate: validateTaxId
        },
        {
            name: 'endorsable',
            type: FieldType.BOOLEAN,
            label: 'A la Orden'
        }
    ]

    const onSubmit = (checkData: Data) => submitCheck(checkData, createMode)

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="check-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
