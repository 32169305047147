import styles from "./header.module.css"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "../link/link"
import { TableName } from "../../../../tables"
import { useFind } from "../../../../state/reducers/hooks"
import { define } from "../../../../utils/typeUtils"
import { Spinner, useUIState } from "../.."
import { useSystemState, useSystemStateHooks } from "../../../../modules/system"
import { Branch, useManagerState, useManagerStateActions } from "../../../../modules/manager"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../routes"

export const Header = () => {
    const { user, isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0()
    const { branches, currentBranch } = useManagerState()
    const systemState = useSystemState()
    const stateUser = systemState.user
    const { disableBranchSelector } = useUIState()

    const { hasCompanyAccess } = useSystemStateHooks()
    const { setCurrentBranch } = useManagerStateActions()
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()
    const find = useFind()

    const userName = stateUser?.name || user?.name
    const userEmail = stateUser?.email || user?.email
    const userPicture = stateUser?.pictureUrl || user?.picture
    const hasAccess = hasCompanyAccess()

    const onLogIn = () => {
        !isLoading && loginWithRedirect()
    }

    const onLogOut = () => {
        logout({ returnTo: window.location.origin })
    }

    const switchBranch = (branchId: string) => () => {
        const branch = find(TableName.BRANCHES, branchId)
        setCurrentBranch(define(branch) as Branch)
    }

    const goToUserPanel = () => {
        navigate(getRoutePath(RoutePath.USER))
    }

    const branchClassName = disableBranchSelector ? styles.disabled : ''

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">

            <div className="d-flex align-items-center justify-content-between">
                <div className={`logo d-flex align-items-center ${styles.logo}`}>
                    <img src="/assets/img/logo.png" alt="" />
                    <span className="d-none d-lg-block">CONSTRUFY</span>
                </div>
            </div>
            
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    {isAuthenticated ? (
                        <>
                            <li className="nav-item dropdown pe-3">
                                <Link
                                    kind="primary"
                                    className={`${branchClassName} nav-link nav-profile d-flex align-items-center pe-0`}
                                    dataBsToggle={disableBranchSelector ? '' : 'dropdown'}
                                >
                                    <i className='bi bi-building'></i>
                                    <span className="d-none d-md-block dropdown-toggle ps-2">
                                        {currentBranch?.name}
                                    </span>
                                </Link>
                                <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${styles.branchMenu}`}>
                                    <li className="dropdown-header">
                                        <h6>Sucursal</h6>
                                    </li>
                                    {branches.map(branch => {
                                        const activeClassName = branch.code === currentBranch?.code ? styles.active : ''
                                        return (
                                            <div key={branch.code}>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li>
                                                    <Link onClick={switchBranch(branch.id)} className={`${activeClassName} dropdown-item d-flex align-items-center`}>
                                                        <i className="bi bi-building"></i>
                                                        <span>{branch.name}</span>
                                                    </Link>
                                                </li>
                                            </div>
                                        )}
                                    )}
                                </ul>
                            </li>
                        
                            <li className="nav-item dropdown pe-3">
                                <Link className="nav-link nav-profile d-flex align-items-center pe-0" dataBsToggle="dropdown">
                                    <img src={userPicture} alt="Profile" className={styles.profileImg} />
                                    <span className="d-none d-md-block dropdown-toggle ps-2">{userName}</span>
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                    <li className="dropdown-header">
                                        <h6>{userName}</h6>
                                        <span>{userEmail}</span>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    {hasAccess && (
                                        <>
                                            <li>
                                                <Link onClick={goToUserPanel} className="dropdown-item d-flex align-items-center">
                                                    <i className="bi bi-person"></i>
                                                    <span>Mi Perfil</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                        </>
                                    )}
                                    <li>
                                        <Link onClick={onLogOut} className="dropdown-item d-flex align-items-center">
                                            <i className="bi bi-box-arrow-right"></i>
                                            <span>Salir</span>
                                        </Link>
                                    </li>                
                                </ul>
                            </li>
                        </>
                    ) : (
                        <li key="header-log-in" className={`nav-item dropdown pe-3 ${styles.logIn}`}>
                            {isLoading ? (
                                <Spinner label="Ingresando..." />
                            ) : (
                                <Link onClick={onLogIn} className="nav-link nav-profile d-flex align-items-center pe-0">
                                    <span className="d-none d-md-block ps-2">Ingresar</span>
                                </Link>
                            )}
                        </li>
                    )}
                </ul>
            </nav>
        
        </header>
    )
}
