import { fileName } from '../../../../../features/files'
import { DownloadFileModal, ModalId } from '../../../../../features/ui'
import { DataType, TableName } from '../../../../../tables'
import { useAdministrationHooks } from '../../../hooks/administrationHooks'
import { Expense } from '../../../state/types'

type Props = {
    expense?: Expense
}

export const ExpenseReceiptModal = (props: Props) => {
    const { expense } = props

    const { getSalaryExpenseTypeId } = useAdministrationHooks()

    const isSalaryType = expense?.expenseTypeId === getSalaryExpenseTypeId()
    const salaryFileName = isSalaryType && expense?.receiptUrl ? fileName(TableName.EXPENSES, expense, 'Sueldo_Liquidado') : undefined
    
    return (
        <DownloadFileModal
            modalId={ModalId.EXPENSE_RECEIPT}
            dataType={expense}
            tableName={TableName.EXPENSES}
            fileProp={'receiptUrl' as keyof DataType}
            title="Descargar Comprobante"
            fileNameParam={salaryFileName}
        />
    )
}
