import { Budget, BudgetItem } from "../../../state/types"
import { BudgetPDFPage } from './budgetPDFPage'
import { useEffect, useState } from "react"
import { useSalesActions } from '../../../actions/salesActions'
import { PDFWrapper } from '../../../../../features/ui'
import { DataType } from '../../../../../tables'

type Props = {
    id: string
    budget: Budget
    previewMode?: boolean
}

export const BudgetPDF = (props: Props) => {
    const { id, budget, previewMode } = props

    const [budgetItems, setBudgetItems] = useState<BudgetItem[]>([])

    const salesActions = useSalesActions()

    const init = async () => {
        const stateBudgetItems = await salesActions().fetchBudgetItemsByOrder(budget.id) as BudgetItem[]
        setBudgetItems(stateBudgetItems)
    }

    useEffect(() => {
        init()
    }, [])

    const renderPDFPage = (
        pageIndex: number,
        dataType: DataType,
        items: DataType[],
        pagesAmount: number
    ) => (
        <BudgetPDFPage
            pageIndex={pageIndex}
            budget={dataType as Budget}
            budgetItems={items as BudgetItem[]}
            pagesAmount={pagesAmount}
        />
    )

    return (
        <PDFWrapper
            id={id}
            dataType={budget}
            items={budgetItems}
            previewMode={previewMode}
            renderPDFPage={renderPDFPage}
        />
    )
}
