import { useEffect, useState } from 'react'
import styles from './textArea.module.css'

type Props = {
    id: string
    defaultValue?: string
    value?: string
    optional?: boolean
    disabled?: boolean
    className?: string
    onChange?: (value?: string) => void
}

export const TextArea = (props: Props) => {
    const { id, defaultValue, value, optional, disabled, className, onChange } = props
    
    const [stateValue, setStateValue] = useState(defaultValue)

    useEffect(() => {
        value && setValue(value)
    }, [value])
    
    const setValue = (newValue: string) => {
        setStateValue(newValue)
        onChange && onChange(newValue)
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value
        setValue(newValue)
        onChange && onChange(newValue)
    }

    return (
        <textarea
            id={id}
            name={id}
            defaultValue={!stateValue ? defaultValue : undefined}
            value={stateValue}
            required={!optional}
            disabled={disabled}
            className={`form-control ${styles.textarea} ${className || ''}`}
            onChange={handleChange}
        />
    )
}
