import styles from './radioField.module.css'
import { Option } from '../../types'
import { useState } from 'react'
import { firstItem } from '../../../../../utils/listUtils'

type Props = {
    id: string
    defaultValue?: string
    options: Option[]
    disabled?: boolean
    disabledOptions?: string[]
    onChange?: (value?: string) => void
}

export const RadioField = (props: Props) => {
    const { id, defaultValue, options, disabled, disabledOptions, onChange } = props

    const [stateValue, setStateValue] = useState<string | undefined>(defaultValue || firstItem(options)?.value)

    const optionId = (index: number) => `${id}-${index}`

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target?.value
        setStateValue(newValue)
        onChange && onChange(newValue)
    }

    return (
        <div className={styles.container}>
            {options.map((option, index) => {
                return (
                    <div key={option.value} className="form-check">
                        <input
                            type="radio"
                            name={id}
                            id={optionId(index)}
                            value={option.value}
                            checked={stateValue === option.value}
                            disabled={disabled || disabledOptions?.includes(option.value)}
                            className={`form-check-input ${styles.option}`}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor={optionId(index)}>
                            {option.label}
                        </label>
                    </div>
                )
            })}
        </div>
    )
}
