import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { usePurchasesHooks, usePurchasesState } from '../../../hooks/purchasesHooks'
import { FieldType, FieldValue, option, FilterField, Filters } from '../../../../../features/ui'
import { useManagerState } from '../../../../manager'
import { define } from '../../../../../utils/typeUtils'
import { usePurchasesActions } from '../../../actions/purchasesActions'
import { PurchaseOrder } from '../../../state/types'
import { DeliveryStatus } from '../../../../operations'
import { Labels } from '../../../../../features/data-types'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const PurchaseFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const purchasesState = usePurchasesState()
    const statePurchases = purchasesState.purchases
    const suppliers = purchasesState.suppliers
    const { currentBranch } = useManagerState()
    const currentBranchId = define(currentBranch).id

    const [purchases, setPurchases] = useState<PurchaseOrder[]>()
    const [searchText, setSearchText] = useState<string>()
    const [deliveryStatus, setDeliveryStatus] = useState<DeliveryStatus>()
    const [supplierId, setSupplierId] = useState<string>()
    const [dateFrom, setDateFrom] = useState<Date>()
    const [dateTo, setDateTo] = useState<Date>()
    const [statePurchasesLength, setStatePurchasesLength] = useState<number>(statePurchases.length)

    const { searchPurchases } = usePurchasesHooks()

    const purchasesActions = usePurchasesActions()

    const fetchAndSearch = async () => {
        onSearchStart()
        const branchPurchases = await purchasesActions().fetchAllPurchases(currentBranchId) as PurchaseOrder[]
        setPurchases(branchPurchases)
        search(branchPurchases, searchText, deliveryStatus, supplierId, dateFrom, dateTo)
    }

    useEffect(() => {
        if (statePurchases.length < statePurchasesLength) {
            fetchAndSearch()
        }
        setStatePurchasesLength(statePurchases.length)
    }, [statePurchases])

    useEffect(() => {
        fetchAndSearch()
    }, [currentBranchId])

    const search = (
        basePurchaseList: PurchaseOrder[] = [],
        searchTextParam?: string,
        deliveryStatusParam?: string,
        supplierIdParam?: string,
        dateFromParam?: Date,
        dateToParam?: Date
    ) => {
        onSearchStart()
        let purchaseList = basePurchaseList.filter(purchase => purchase.deliveryBranchId === currentBranchId)
        purchaseList = purchaseList.filter(purchase => !deliveryStatusParam || purchase.deliveryStatus === deliveryStatusParam)
        purchaseList = purchaseList.filter(purchase => !supplierIdParam || purchase.supplierId === supplierIdParam)
        purchaseList = dateFromParam ? purchaseList.filter(purchase => new Date(purchase.deliveryDate) >= dateFromParam) : purchaseList
        purchaseList = dateToParam ? purchaseList.filter(purchase => new Date(purchase.deliveryDate) <= dateToParam) : purchaseList
        purchaseList = searchPurchases(purchaseList, searchTextParam)
        setTimeout(() => onSearchDone(purchaseList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(purchases, newSearchText, deliveryStatus, supplierId, dateFrom, dateTo)
    }

    const onChangeDeliveryStatus = (value?: FieldValue) => {
        const newDeliveryStatus = value as DeliveryStatus | undefined
        if (newDeliveryStatus !== deliveryStatus) {
            setDeliveryStatus(newDeliveryStatus)
            search(purchases, searchText, newDeliveryStatus, supplierId, dateFrom, dateTo)
        }
    }

    const onChangeSupplier = (value?: FieldValue) => {
        const newSupplierId = value as string | undefined
        if (newSupplierId !== supplierId) {
            setSupplierId(newSupplierId)
            search(purchases, searchText, deliveryStatus, newSupplierId, dateFrom, dateTo)
        }
    }

    const onChangeDateFrom = (value?: FieldValue) => {
        const newDateFrom = value as Date | undefined
        newDateFrom?.setHours(11, 59, 59)
        if (newDateFrom !== dateFrom) {
            setDateFrom(newDateFrom)
            search(purchases, searchText, deliveryStatus, supplierId, newDateFrom, dateTo)
        }
    }
    
    const onChangeDateTo = (value?: FieldValue) => {
        const newDateTo = value as Date | undefined
        newDateTo?.setHours(11, 59, 59)
        if (newDateTo !== dateTo) {
            setDateTo(newDateTo)
            search(purchases, searchText, deliveryStatus, supplierId, dateFrom, newDateTo)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por código o proveedor',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'deliveryStatus',
            type: FieldType.SELECT,
            options: Object.values(DeliveryStatus).map(status => option(status, Labels.deliveryStatusLabel(status))),
            label: 'Estado de Entrega',
            space: 3,
            onChange: onChangeDeliveryStatus
        },
        {
            name: 'supplier',
            type: FieldType.SELECT,
            options: suppliers.map(supplier => option(supplier.id, supplier.name)),
            label: 'Proveedor',
            onChange: onChangeSupplier
        },
        {
            name: 'dateFrom',
            type: FieldType.DATE,
            label: 'Recepción: Desde',
            space: 2,
            onChange: onChangeDateFrom
        },
        {
            name: 'dateTo',
            type: FieldType.DATE,
            label: 'Recepción: Hasta',
            space: 2,
            onChange: onChangeDateTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
