import { CashFund, CashFundType } from "../../../state/types"
import { Data, useUIActions } from "../../../../../features/ui"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { Currency } from "../../../../../tables/types/types"

export const useActions = () => {
    const administrationActions = useAdministrationActions()
    const { onSubmitDataType } = useUIActions()

    const submitCashFund = async (
        cashFundData: Data,
        createMode: boolean
    ) => {
        const { companyId, id, name, type } = cashFundData
        const cashFund: CashFund = {
            companyId: companyId as string,
            id: id as string,
            name: name as string,
            type: type as CashFundType,
            availableAmount: 0,
            reservedAmount: 0,
            currency: Currency.ARS
        }
        
        await administrationActions().saveCashFund(cashFund)
        onSubmitDataType(createMode)
    }
    
    return {
        submitCashFund
    }
}
