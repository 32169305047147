import { useEffect, useState } from "react"
import { DataType } from "../../../../../tables"
import { useSalesHooks, useSalesState } from "../../../hooks/salesHooks"
import { CustomerCategory } from "../../../state/types"
import { FieldType, FieldValue, FilterField, Filters, option } from "../../../../../features/ui"
import { Labels } from "../../../../../features/data-types"

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const CustomerFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { customers, customerTypes } = useSalesState()

    const [searchText, setSearchText] = useState<string>()
    const [category, setCategory] = useState<CustomerCategory>()
    const [customerTypeId, setCustomerTypeId] = useState<string>()

    const { searchCustomers } = useSalesHooks()

    useEffect(() => {
        search(searchText, category, customerTypeId)
    }, [customers])

    const search = (searchTextParam?: string, categoryParam?: CustomerCategory, customerTypeIdParam?: string) => {
        onSearchStart()
        let customerList = customers.filter(customer => !categoryParam || customer.category === categoryParam)
        customerList = customerList.filter(customer => !customerTypeIdParam || customer.customerTypeId === customerTypeIdParam)
        customerList = searchCustomers(customerList, searchTextParam)
        setTimeout(() => onSearchDone(customerList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(newSearchText, category, customerTypeId)
    }

    const onChangeCategory = (value?: FieldValue) => {
        const newCategory = value as CustomerCategory | undefined
        if (newCategory !== category) {
            setCategory(newCategory)
            search(searchText, newCategory, customerTypeId)
        }
    }

    const onChangeCustomerType = (value?: FieldValue) => {
        const newCustomerTypeId = value as string | undefined
        if (newCustomerTypeId !== customerTypeId) {
            setCustomerTypeId(newCustomerTypeId)
            search(searchText, category, newCustomerTypeId)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por nombre, DNI / CUIT o email',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'category',
            type: FieldType.SELECT,
            options: Object.values(CustomerCategory).map(category => option(category, Labels.customerCategoryLabel(category))),
            label: 'Categoría',
            onChange: onChangeCategory
        },
        {
            name: 'customerTypeId',
            type: FieldType.SELECT,
            options: customerTypes.map(customerType => option(customerType.id, customerType.name)),
            label: 'Tipo',
            onChange: onChangeCustomerType
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
