import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import * as actionCreators from "./actionCreators"
import { ManagerState } from "./reducer"
import { useSelector } from "react-redux"
import { State } from "../../../state"

export const useManagerState = (): ManagerState => useSelector((state: State) => state.managerReducer)

export const useManagerStateActions = () => bindActionCreators(actionCreators, useDispatch())
