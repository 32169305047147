import { Budget, BudgetItem, Customer, CustomerType, HoardItem, HoardOrder, SaleItem, SaleOrder } from "./types"

export enum ActionType {
    UPDATE_SALES = 'updateSales',
    REMOVE_SALES = 'removeSales',
    UPDATE_SALE_ITEMS = 'updateSaleItems',
    REMOVE_SALE_ITEMS = 'removeSaleItems',
    UPDATE_BUDGETS = 'updateBudgets',
    REMOVE_BUDGETS = 'removeBudgets',
    UPDATE_BUDGET_ITEMS = 'updateBudgetItems',
    REMOVE_BUDGET_ITEMS = 'removeBudgetItems',
    UPDATE_HOARDS = 'updateHoards',
    REMOVE_HOARDS = 'removeHoards',
    UPDATE_HOARD_ITEMS = 'updateHoardItems',
    REMOVE_HOARD_ITEMS = 'removeHoardItems',
    UPDATE_CUSTOMERS = 'updateCustomers',
    REMOVE_CUSTOMERS = 'removeCustomers',
    UPDATE_CUSTOMER_TYPES = 'updateCustomerTypes',
    REMOVE_CUSTOMER_TYPES = 'removeCustomerTypes'
}

interface UpdateSales {
    type: ActionType.UPDATE_SALES
    payload: SaleOrder[]
}

interface RemoveSales {
    type: ActionType.REMOVE_SALES
    payload: string[]
}

interface UpdateSaleItems {
    type: ActionType.UPDATE_SALE_ITEMS
    payload: SaleItem[]
}

interface RemoveSaleItems {
    type: ActionType.REMOVE_SALE_ITEMS
    payload: string[]
}

interface UpdateBudgets {
    type: ActionType.UPDATE_BUDGETS
    payload: Budget[]
}

interface RemoveBudgets {
    type: ActionType.REMOVE_BUDGETS
    payload: string[]
}

interface UpdateBudgetItems {
    type: ActionType.UPDATE_BUDGET_ITEMS
    payload: BudgetItem[]
}

interface RemoveBudgetItems {
    type: ActionType.REMOVE_BUDGET_ITEMS
    payload: string[]
}

interface UpdateHoards {
    type: ActionType.UPDATE_HOARDS
    payload: HoardOrder[]
}

interface RemoveHoards {
    type: ActionType.REMOVE_HOARDS
    payload: string[]
}

interface UpdateHoardItems {
    type: ActionType.UPDATE_HOARD_ITEMS
    payload: HoardItem[]
}

interface RemoveHoardItems {
    type: ActionType.REMOVE_HOARD_ITEMS
    payload: string[]
}

interface UpdateCustomers {
    type: ActionType.UPDATE_CUSTOMERS
    payload: Customer[]
}

interface RemoveCustomers {
    type: ActionType.REMOVE_CUSTOMERS
    payload: string[]
}

interface UpdateCustomerTypes {
    type: ActionType.UPDATE_CUSTOMER_TYPES
    payload: CustomerType[]
}

interface RemoveCustomerTypes {
    type: ActionType.REMOVE_CUSTOMER_TYPES
    payload: string[]
}

export type SalesAction =
    SalesUpdateAction |
    SalesRemoveAction

export type SalesUpdateAction =
    UpdateSales |
    UpdateSaleItems |
    UpdateBudgets |
    UpdateBudgetItems |
    UpdateHoards |
    UpdateHoardItems |
    UpdateCustomers |
    UpdateCustomerTypes

export type SalesRemoveAction =
    RemoveSales |
    RemoveSaleItems |
    RemoveBudgets |
    RemoveBudgetItems |
    RemoveHoards |
    RemoveHoardItems |
    RemoveCustomers |
    RemoveCustomerTypes
