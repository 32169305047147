import { EventEmitter } from "fbemitter";
import { useListeners } from "./listeners";

export let Emitter: EventEmitter

export enum Event {
    DB_SUBMIT = 'dbSubmit',
    DB_SUBMIT_DONE = 'dbSubmitDone',
    UPDATE_STOCK = 'updateStock',
    EMPLOYEE_MISSING = 'employeeMissing',
    RESOLVE_REQUEST = 'resolveRequest'
}

export const useInitEmitter = () => {
    const { onDbSubmit, onDbSubmitDone, onUpdateStock, onEmployeeMissing } = useListeners()

    Emitter = new EventEmitter()
    Emitter.addListener(Event.DB_SUBMIT, onDbSubmit)
    Emitter.addListener(Event.DB_SUBMIT_DONE, onDbSubmitDone)
    Emitter.addListener(Event.UPDATE_STOCK, onUpdateStock)
    Emitter.addListener(Event.EMPLOYEE_MISSING, onEmployeeMissing)
    Emitter.addListener(Event.RESOLVE_REQUEST, onEmployeeMissing)
}
