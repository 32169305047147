import { useState } from "react"
import { PanelWrapper, FieldType, UIPermission, Action, Column, FieldValue, SpinnerDiv, Data } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { SaleOrder } from "../../../state/types"
import { useFind } from "../../../../../state/reducers/hooks"
import { define, toNumber } from "../../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../../features/data-types"
import { DeliveryStatus } from "../../../../operations"
import { OpenWizardModal, useWizardActions } from "../../../../../features/wizard"
import { saleWizardKey } from "../../../utils/salesUtils"
import { SaleFilters } from "./saleFilters"
import { RoutePath } from "../../../../../features/routes"
import { useNavigate } from "react-router-dom"

export const SaleList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentSale, setCurrentSale] = useState<SaleOrder>()
    const wizardKey = currentSale ? saleWizardKey(currentSale) : undefined

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_SALES)
    const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_SALE_DELIVERIES)
    const navigate = useNavigate()
    const find = useFind()

    const { goToWizard } = useWizardActions()

    const deliveryPath = (sale?: SaleOrder) => sale ? `${sale.id}/${RoutePath.DELIVERY}` : '#'

    const setRowsState = (sales: SaleOrder[]) => {
        setRows(sales)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        { name: 'creationDate', type: FieldType.DATE, label: 'Fecha de Creación' },
        { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Entrega' },
        { name: 'sellerId', type: FieldType.TABLE, table: TableName.EMPLOYEES, label: 'Vendedor' },
        { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
        {
            name: 'deliveryStatus',
            type: FieldType.TEXT,
            label: 'Estado de Entrega',
            render: (value?: FieldValue) => Labels.deliveryStatusLabel(define(value) as DeliveryStatus)
        }
    ]

    const findSale = (code?: string) => define(find(TableName.SALE_ORDERS, toNumber(code), 'code') as SaleOrder)

    const onView = (code?: string) => {
        const sale = findSale(code)
        if (sale) {
            navigate(sale.id)
        }
    }

    const onDeliver = (code?: string) => {
        const sale = findSale(code)
        setCurrentSale(sale)
        goToWizard(saleWizardKey(sale), deliveryPath(sale))
    }

    const onArchive = (code?: string) => {
        findSale(code)
    }

    const actions = (code?: string) => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView }
        ]
        if (hasDeliveryPermission) {
            const sale = findSale(code)
            const archiveAction = { icon: 'archive', kind: 'secondary', tooltip: 'Archivar', onClick: onArchive }
            const deliverAction = { icon: 'truck', tooltip: 'Registrar Entrega', onClick: onDeliver }
            actionList.push(sale.deliveryStatus === DeliveryStatus.DELIVERED ? archiveAction : deliverAction)
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as SaleOrder[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Ventas" wide>
            <SaleFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentSale}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
            <OpenWizardModal
                wizardKey={wizardKey}
                path={deliveryPath(currentSale)}
            />
        </PanelWrapper>
    )
}
