import styles from './priceBreakdown.module.css'
import { useDataTypesHooks } from '../../../../features/data-types'
import { FieldType } from '../../../../features/ui'
import { PriceBreakdownType } from '../../hooks/types'

type Props = {
    data: PriceBreakdownType
}

export const PriceBreakdown = (props: Props) => {
    const { data } = props
    const { subtotal, customerAdjustmentAmount, paymentTypeAdjustmentAmount, totalWithoutVAT, vatAmount, total } = data

    const { formatValue } = useDataTypesHooks()
    
    const adjustmentLabel = (positive: boolean) => positive ? 'Recargo' : 'Descuento'

    return (
        <div className={styles.container}>
            <div>
                Subtotal: {formatValue(subtotal, FieldType.PRICE)}
            </div>
            {customerAdjustmentAmount !== 0 && (
                <div>
                    {adjustmentLabel(customerAdjustmentAmount > 0)} Cliente: {formatValue(customerAdjustmentAmount, FieldType.PRICE)}
                </div>
            )}
            {paymentTypeAdjustmentAmount !== 0 && (
                <div>
                    {adjustmentLabel(paymentTypeAdjustmentAmount > 0)} M. de Pago: {formatValue(paymentTypeAdjustmentAmount, FieldType.PRICE)}
                </div>
            )}
            {vatAmount !== 0 && (customerAdjustmentAmount !== 0 || paymentTypeAdjustmentAmount !== 0) && (
                <div>
                    Total s/IVA: {formatValue(totalWithoutVAT, FieldType.PRICE)}
                </div>
            )}
            {vatAmount !== 0 && (
                <div>
                    IVA: {formatValue(vatAmount, FieldType.PRICE)}
                </div>
            )}
            <div className={styles.totalPrice}>
                Total: {formatValue(total, FieldType.PRICE)}
            </div>
        </div>
    )
}
