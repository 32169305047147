import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toListResponse } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useChangedIdMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyId: string,
        dataType: DataType,
        tablesToUpdate: TableName[],
        propName: string
    ) => {
        const { getAll, createOrUpdate } = dynamoActions(DynamoUserApi, companyId)
        
        tablesToUpdate.forEach(async tableToUpdate => {
            const { dbTableName } = define(TablesData.get(tableToUpdate))
            
            const listResponse = await getAll(dbTableName)
            const { dataTypes } = (toListResponse(listResponse))

            const updatedDataTypes = dataTypes
                .filter(dataTypeToUpdate => dataTypeToUpdate[propName as keyof DataType] === dataType.id)
                .map(dataTypeToUpdate => ({
                    ...dataTypeToUpdate,
                    [propName]: dataType.id
                })) as AttributeMap[]
            
            await createOrUpdate(dbTableName, updatedDataTypes)
        })
    }
}
