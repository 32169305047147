import { Data, useUIActions } from "../../../../../features/ui"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { define } from "../../../../../utils/typeUtils"
import { CreditCard } from "../../../state/types"

export const useActions = () => {
    const administrationActions = useAdministrationActions()
    const { onSubmitDataType } = useUIActions()

    const submitCreditCard = async (
        creditCardData: Data,
        createMode: boolean
    ) => {
        const { companyId, id, type, issuer, cardholder, last4Numbers } = creditCardData
        const dueDate = define(creditCardData.dueDate) as Date
        const creditCard: CreditCard = {
            companyId: companyId as string,
            id: id as string,
            type: define(type) as string,
            issuer: define(issuer) as string,
            cardholder: define(cardholder) as string,
            last4Numbers: define(last4Numbers) as number,
            dueDate: dueDate.toISOString()
        }
        
        await administrationActions().saveCreditCard(creditCard)
        onSubmitDataType(createMode)
    }
    
    return {
        submitCreditCard
    }
}
