export enum ModalId {
    BUDGET_PDF = 'budget-pdf-modal',
    CATEGORY_SALE_YIELD_FORM = 'category-sale-yield-form-modal',
    CLOSE_OPERATION = 'close-operation-modal',
    CONFIRM_PAYMENT_FORM = 'confirm-payment-form-modal',
    EXPENSE_RECEIPT = 'expense-receipt-modal',
    FINISH_WIZARD = 'finish-wizard-modal',
    LOT_DATA_FORM = 'lot-data-form-modal',
    MEASURE_FORM = 'measure-form-modal',
    MESSAGE = 'message-modal',
    NON_HOARDABLE_PRODUCT_FORM = 'non-hoardable-product-form-modal',
    OPEN_WIZARD = 'open-wizard-modal',
    OPERATION_ITEM_FORM = 'operation-item-form-modal',
    PARTIAL_PAYMENT_MODAL = 'partial-payment-modal',
    PAYMENT_ITEM_FORM = 'payment-item-form-modal',
    PAYMENT_ORDER_RECEIPT = 'payment-order-modal',
    PURCHASE_PDF = 'purchase-pdf-modal',
    PURCHASE_TICKET_PDF = 'purchase-ticket-pdf-modal',
    QUICK_FORM = 'quick-form-modal',
    REMOVE = 'remove-modal',
    REMOVE_CATEGORY_SALE_YIELD = 'remove-category-sale-yield-modal',
    RESOLVE_REQUEST = 'resolve-request-modal',
    SPINNER = 'spinner-modal',
    TAX_ITEM_FORM = 'tax-item-form-modal',
    UNIGNORE_PRODUCT = 'unignore-product-modal',
    UPLOAD_PURCHASE_TICKET = 'upload-purchase-ticket-modal',
    VALIDATION = 'validation-modal'
}
