import { combineReducers } from 'redux'
import * as Administration from '../../modules/administration'
import * as Manager from '../../modules/manager'
import * as Operations from '../../modules/operations'
import * as Products from '../../modules/products'
import * as Purchases from '../../modules/purchases'
import * as Sales from '../../modules/sales'
import * as Stock from '../../modules/stock'
import * as System from '../../modules/system'
import * as UI from '../../features/ui'
import { ReducerName } from './types'

const reducers = combineReducers({
    [ReducerName.ADMINISTRATION_REDUCER]: Administration.reducer,
    [ReducerName.MANAGER_REDUCER]: Manager.reducer,
    [ReducerName.OPERATIONS_REDUCER]: Operations.reducer,
    [ReducerName.PRODUCTS_REDUCER]: Products.reducer,
    [ReducerName.PURCHASES_REDUCER]: Purchases.reducer,
    [ReducerName.SALES_REDUCER]: Sales.reducer,
    [ReducerName.STOCK_REDUCER]: Stock.reducer,
    [ReducerName.SYSTEM_REDUCER]: System.reducer,
    [ReducerName.UI_REDUCER]: UI.reducer,
})

export default reducers

export type State = ReturnType<typeof reducers>
