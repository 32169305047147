import { Customer, CustomerCategory, useSalesActions } from "../../../modules/sales"
import { IdentifierType } from "../../../modules/system"
import { define, isDefined, normalize, removeSpaces, toNumber } from "../../../utils/typeUtils"
import { Labels, newId } from "../../data-types"
import { IndexedHeader } from "../types"

enum Header {
    NAME = 'Nombre',
    CATEGORY = 'Categoria',
    IDENTIFIER_TYPE = 'TipoDocumento',
    IDENTIFIER = 'Documento',
    CUSTOMER_TYPE = 'TipoCliente',
    EMAIL = 'Email',
    ADDRESS = 'Direccion',
    PHONE = 'Telefono'
}

export const useImportCustomers = () => {
    const salesActions = useSalesActions()
    
    return (
        file: File,
        companyId: string
    ) => {
        const reader = new FileReader()
        reader.onload = async e => {
            const data = e.target?.result as string
            const lines = data.trim().split(/[\r\n]+/)
            const firstLine = lines[0]
            const headersData = firstLine.split(';')

            const indexedHeaders: IndexedHeader[] = Object.values(Header).map(header => {
                const index = headersData.map(headerData => removeSpaces(normalize(headerData))).indexOf(normalize(header))
                return {
                    index,
                    header
                }
            })
            const validHeaders = !indexedHeaders.some(({ index }) => index === -1)

            if (validHeaders) {
                const rows = lines.slice(1)
                const customers: Customer[] = []
                const invalidRows: string[] = []

                const { fetchAllCustomerTypes, saveCustomers } = salesActions(companyId)
                const customerTypes = await fetchAllCustomerTypes()

                const getIndex = (headers: IndexedHeader[], headerValue: Header) => headers.find(({ header }) => header === headerValue)?.index

                for (const row of rows) {
                    const cells = row.split(';')

                    const nameIndex = getIndex(indexedHeaders, Header.NAME)
                    const nameValue = cells[define(nameIndex)]

                    const customerTypeIndex = getIndex(indexedHeaders, Header.CUSTOMER_TYPE)
                    const customerTypeValue = cells[define(customerTypeIndex)]
                    const customerType = customerTypes.find(type => type.name === customerTypeValue)

                    const categoryIndex = getIndex(indexedHeaders, Header.CATEGORY)
                    const categoryValue = cells[define(categoryIndex)]
                    const category = Object.values(CustomerCategory).find(value => normalize(categoryValue) === normalize(Labels.customerCategoryLabel(value)))

                    const identifierTypeIndex = getIndex(indexedHeaders, Header.IDENTIFIER_TYPE)
                    const identifierTypeValue = cells[define(identifierTypeIndex)]
                    const transformIdentifierType = (value: string) => value === 'cuit' || value === 'cuil' ? 'CUIT / CUIL' : value || 'DNI'
                    const identifierType = Object.values(IdentifierType).find(value => normalize(transformIdentifierType(identifierTypeValue)) === normalize(Labels.identifierTypeLabel(value)))

                    const identifierIndex = getIndex(indexedHeaders, Header.IDENTIFIER)
                    const identifierValue = cells[define(identifierIndex)]
                    const identifier = toNumber(identifierValue)

                    const emailIndex = getIndex(indexedHeaders, Header.EMAIL)
                    const emailValue = cells[define(emailIndex)]

                    const addressIndex = getIndex(indexedHeaders, Header.ADDRESS)
                    const addressValue = cells[define(addressIndex)]

                    const phoneIndex = getIndex(indexedHeaders, Header.PHONE)
                    const phoneValue = cells[define(phoneIndex)]
                    const phoneN = toNumber(phoneValue)
                    const phone = phoneN && isNaN(phoneN) ? phoneN : undefined

                    const validCell = !!nameValue &&
                        !!category &&
                        !!customerType &&
                        !!identifierType &&
                        isDefined(identifier)

                    if (validCell) {
                        const customer: Customer = {
                            companyId,
                            id: newId(),
                            name: nameValue,
                            identifierType,
                            identifier: define(identifier),
                            category,
                            customerTypeId: customerType.id,
                            ...(emailValue && {
                                email: emailValue
                            }),
                            ...(addressValue && {
                                address: addressValue
                            }),
                            ...(phone && { phone })
                        }
                        customers.push(customer)
                    } else {
                        invalidRows.push(row)
                    }
                }

                await saveCustomers(customers)

                console.log('Imported Data:', customers)
                console.log('Invalid Rows:', invalidRows)
            } else {
                console.log('Invalid Headers')
            }
        }
        reader.readAsText(file)
    }
}
