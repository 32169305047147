import { Data, useUIActions } from "../../../../../features/ui"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { ExpenseType } from "../../../state/types"

export const useActions = () => {
    const administrationActions = useAdministrationActions()
    const { onSubmitDataType } = useUIActions()

    const submitExpenseType = async (
        expenseTypeData: Data,
        createMode: boolean
    ) => {
        const { companyId, id, name } = expenseTypeData
        const expenseType: ExpenseType = {
            companyId: companyId as string,
            id: id as string,
            name: name as string
        }
        
        await administrationActions().saveExpenseType(expenseType)
        onSubmitDataType(createMode)
    }
    
    return { submitExpenseType }
}
