import styles from './badge.module.css'

type BadgeKind = 'primary' | 'secondary' | 'tertiary'

type Props = {
    label?: string
    kind?: BadgeKind
    icon?: string
    small?: boolean
    className?: string
    onClick?: () => void
}

export const Badge = (props: Props) => {
    const { label, kind, icon, small, className, onClick } = props

    const propClass = className || ''
    const getKindClass = () => {
        switch(kind) {
            case 'tertiary':
                return styles.badgeTertiary
            case 'secondary':
                return styles.badgeSecondary
            case 'primary':
            default:
                return styles.badgePrimary
        }
    }
    const sizeClass = small ? styles.small : ''

    return (
        <span onClick={onClick} className={`badge ${styles.badge} ${propClass} ${getKindClass()} ${sizeClass}`}>
            {icon && <i className={`bi bi-${icon} ${styles.rightMargin}`}></i>}
            {label}
        </span>
    )
}