import { useDefaultModuleActions } from "../../common"
import { Emitter, Event } from "../../../features/event-emitter"
import { DynamoUserApi } from "../../../services"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { Company, Request, RequestStatus, useSystemActions, useSystemStateHooks } from "../../system"
import { Branch } from "../state/types"

export const useManagerActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    
    const defaultModuleActions = useDefaultModuleActions()
    const systemActions = useSystemActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, save, remove } = defaultModuleActions(DynamoUserApi, define(companyId))

        const saveCompany = systemActions().saveCompany

        const fetchAllBranches = async () => {
            return fetchAll(TableName.BRANCHES).then(response => response.dataTypes as Branch[])
        }

        const saveBranch = (branch: Branch) => {
            return save(TableName.BRANCHES, branch)
        }
        
        const removeBranch = (id: string) => {
            return remove(TableName.BRANCHES, id)
        }

        const resolveRequest = async (
            request: Request,
            company: Company,
            resolution: RequestStatus,
            saveState = true
        ) => {
            const resolvedRequest: Request = {
                ...request as Request,
                status: resolution
            }
            
            const savedRequest = await systemActions(company.id).saveRequest(resolvedRequest, saveState) as Request
            
            Emitter.emit(Event.RESOLVE_REQUEST, savedRequest)
            return savedRequest
        }

        return {
            saveCompany,
            fetchAllBranches,
            saveBranch,
            removeBranch,
            resolveRequest,
        }
    }
}
