import { FieldValue } from "../../features/ui"
import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toListResponse } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useUpdatedValueMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyIds: string[],
        table: TableName,
        propName: string,
        updateValue: (value: FieldValue) => FieldValue
    ) => {
        companyIds.forEach(async companyId => {
            const { getAll, createOrUpdate } = dynamoActions(DynamoUserApi, companyId)
            const { dbTableName } = define(TablesData.get(table))

            const listResponse = await getAll(dbTableName)
            const { dataTypes } = (toListResponse(listResponse))
            const updatedDataTypes = dataTypes.map(dataType => {
                const oldValue = dataType[propName as keyof DataType] as FieldValue
                return {
                    ...dataType,
                    [propName]: updateValue(oldValue)
                }
            }) as AttributeMap[]

            await createOrUpdate(dbTableName, updatedDataTypes)
        })
    }
}
