import { define, isDefined } from "../../../utils/typeUtils"
import { useFind } from "../../../state/reducers/hooks"
import { TableName } from "../../../tables"
import { FieldType, FieldValue, FormatType } from "../../ui"
import { Currency, NamedDataType } from "../../../tables/types/types"

export const useDataTypesHooks = () => {
    const find = useFind()

    const formatValue = (
        value?: FieldValue,
        type: FieldType = FieldType.TEXT,
        table?: TableName
    ): string => {
        let result: string = ''
        
        if (!isDefined(value)) {
            result = ''
        } else if (type === FieldType.TEXT) {
            result = value as string
        } else if (type === FieldType.NUMBER) {
            result = Intl.NumberFormat('es-AR').format(value as number)
        } else if (type === FieldType.PRICE) {
            result = Intl.NumberFormat('es-AR', { style: 'currency', currency: Currency.ARS }).format(value as number)
        } else if (type === FieldType.PERCENTAGE) {
            const percentage = value as number
            result = `${percentage >= 0 ? '+' : ''}${Intl.NumberFormat('es-AR').format(percentage)} %`
        } else if (type === FieldType.DATE) {
            const date = value as Date
            result = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
        } else if (type === FieldType.BOOLEAN) {
            result = value ? 'Sí' : 'No'
        } else if (type === FieldType.TABLE) {
            const tableName = define(table)
            const dataType = define(find(tableName, value as string)) as NamedDataType
            result = dataType.name || dataType.id
        }
    
        return result
    }

    const formatCustomValue = (
        value?: FieldValue,
        formatType: FormatType = FormatType.DNI
    ) => {
        let result: string = ''

        if (!isDefined(value)) {
            result = ''
        } else if (formatType === FormatType.DNI) {
            result = formatValue(value, FieldType.NUMBER)
        } else if (formatType === FormatType.CUIT_CUIL) {
            const cuitOrCuil = define(value?.toString())
            const part1 = cuitOrCuil.slice(0, 2)
            const part2 = cuitOrCuil.slice(2, 4)
            const part3 = cuitOrCuil.slice(4, 7)
            const part4 = cuitOrCuil.slice(7, 10)
            const part5 = cuitOrCuil.slice(10, 11)
            
            result = `${part1}-${part2}.${part3}.${part4}-${part5}`
        } else if (formatType === FormatType.PHONE) {
            const phone = define(value?.toString())
            let parts: string[] = []
            let currentPart = ''
            for (let i = phone.length - 1; i >= 0; i--) {
                currentPart = phone[i] + currentPart
                if (currentPart.length === 4) {
                    parts.unshift(currentPart)
                    currentPart = ''
                }
            }
            if (currentPart.length > 0) {
                parts.unshift(currentPart)
            }
            
            result = parts.join('-')
        }

        return result
    }

    return {
        formatValue,
        formatCustomValue
    }
}
