import styles from './confirmPaymentForm.module.css'
import { Form, FormField, ModalId, useUIActions, FieldType, FileField, Validatable, Spinner } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { PaymentOrder } from '../../../state/types'
import { useState } from "react"
import { toList } from "../../../../../utils/listUtils"
import { useActions } from "./actions"
import { useDataTypesHooks, useDescriptorHooks } from "../../../../../features/data-types"

type Props = {
    paymentOrder?: PaymentOrder
}

export const ConfirmPaymentForm = (props: Props) => {
    const { paymentOrder } = props
    
    const [receiptFile, setReceiptFile] = useState<File>()
    const [receiptValidation, setReceiptValidation] = useState<string>()

    const descriptorHooks = useDescriptorHooks()
    const { formatValue } = useDataTypesHooks()

    const { submitReceipt } = useActions()
    const { toggleModal } = useUIActions()

    if (!paymentOrder) return <Spinner />

    const fields: FormField[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: 'Orden de Pago',
            render: () => <>{descriptorHooks.paymentOrder.code(paymentOrder)}</>
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Importe',
            render: () => <>{formatValue(paymentOrder.amount, FieldType.PRICE)}</>
        },
        {
            name: 'receiptUrl',
            type: FieldType.FILE,
            label: 'Comprobante',
            hint: 'Formato: PDF',
            render: () => (
              <Validatable validations={toList(receiptValidation)} left className={styles.receiptValidation}>
                <FileField
                  id="receiptUrl"
                  onChange={setReceiptFile}
                />
              </Validatable>
            )
        }
    ]
    
    const validate = (): boolean => {
        if (!receiptFile) {
          setReceiptValidation('Debe subir el comprobante')
          return false
        }
    
        return true
    }

    const onSubmit = () => {
        if (validate()) {
            submitReceipt(paymentOrder, define(receiptFile))
        }
    }

    const handleCancel = () => toggleModal(ModalId.CONFIRM_PAYMENT_FORM)

    return (
        <Form
            formId="confirm-payment-form"
            fields={fields}
            createMode={false}
            updateLabel="Confirmar"
            onSubmit={onSubmit}
            onCancel={handleCancel}
        />
    )
}
