import { useNavigate } from "react-router-dom"
import { DataTypeForm, Standards } from "../../../../../features/data-types"
import { PanelWrapper, Data, FieldType, FormField, useGetCrumbs } from "../../../../../features/ui"
import { ExpenseType } from "../../../state/types"
import { useActions } from "./actions"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const ExpenseTypeForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const expenseType = dataType as ExpenseType | undefined
    const createMode = !expenseType

    const navigate = useNavigate()

    const { submitExpenseType } = useActions()

    const expenseTypeLabel = expenseType ? `: ${expenseType.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Tipo de Gasto${expenseTypeLabel}`

    const crumbs = useGetCrumbs('Tipos de Gasto', RoutePath.EXPENSE_TYPES, expenseType?.name, undefined, createMode ? 'Crear' : 'Editar')

    const validateName = (expenseTypeData: Data) => {
        return !createMode && expenseType.name === Standards.SalaryExpenseTypeName && expenseTypeData.name !== Standards.SalaryExpenseTypeName ?
        'No se puede modificar el nombre del tipo de gasto "Sueldos"' : null
    }

    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre',
            validate: validateName
        }
    ]

    const onSubmit = (expenseTypeData: Data) => {
        submitExpenseType(expenseTypeData, createMode)
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="expense-type-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
