import { newId } from ".."
import { Tax, TaxType, TaxBase } from "../../../modules/administration"

export const taxes = (companyId: string): Tax[] => [
    {
        companyId,
        id: newId(),
        code: 'IVA21',
        legalCode: 'IVA21',
        type: TaxType.VAT,
        taxBase: TaxBase.PRICE_WITHOUT_VAT,
        name: 'IVA 21%',
        nonTaxableMin: 0,
        yield: 21
    },
    {
        companyId,
        id: newId(),
        code: 'IVA10,5',
        legalCode: 'IVA10,5',
        type: TaxType.VAT,
        taxBase: TaxBase.PRICE_WITHOUT_VAT,
        name: 'IVA 10,5%',
        nonTaxableMin: 0,
        yield: 10.5
    },
    {
        companyId,
        id: newId(),
        code: 'IVA4',
        legalCode: 'IVA4',
        type: TaxType.VAT,
        taxBase: TaxBase.PRICE_WITHOUT_VAT,
        name: 'IVA 4%',
        nonTaxableMin: 0,
        yield: 4
    }
]
