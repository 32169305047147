import { useFind } from "../../../../state/reducers/hooks"
import { TableName } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { Product } from "../../../products"
import { Supplier } from "../../../purchases"
import { Stock } from "../../state/types"

export const useActions = () => {
    const find = useFind()

    const findStock = (id?: string) => define(find(TableName.STOCK, id)) as Stock

    const findProduct = (id?: string) => define(find(TableName.PRODUCTS, id)) as Product
    
    const findSupplier = (id?: string) => define(find(TableName.SUPPLIERS, id)) as Supplier

    return {
        findStock,
        findProduct,
        findSupplier
    }
}
