import { useUIActions } from "../../../../../features/ui"
import { useSalesActions } from "../../../actions/salesActions"
import { SaleItem, SaleOrder } from "../../../state/types"
import { Data } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { Currency } from "../../../../../tables/types/types"
import { DeliveryStatus, PaymentStatus } from "../../../../operations"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const useActions = () => {
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()
    
    const salesActions = useSalesActions()
    const { onSubmitDataType } = useUIActions()

    const submitSale = async (
        saleData: Data,
        code: number,
        branchId: string,
        sellerId: string,
        customerId: string,
        saleItemsData: Data[],
        paymentTypeId: string,
    ) => {
        const deliveryDate = define(saleData.deliveryDate) as Date
        const sale: SaleOrder = {
            companyId: saleData.companyId as string,
            id: saleData.id as string,
            code,
            creationDate: new Date().toISOString(),
            currency: Currency.ARS,
            deliveryDate: deliveryDate.toISOString(),
            sellerId,
            customerId,
            paymentTypeId,
            branchId,
            deliveryStatus: saleData.deliveryStatus as DeliveryStatus || DeliveryStatus.PENDING,
            paymentStatus: saleData.paymentStatus as PaymentStatus || PaymentStatus.PENDING
        }
        
        const savedSale = await salesActions().saveSaleWithItems(sale, saleItemsData) as SaleItem
        
        onSubmitDataType(true, false)
        navigate(getRoutePath(RoutePath.SALES, savedSale.id))
    }
    
    return {
        submitSale
    }
}
