import { useDefaultDbActions } from "../modules/common";
import { useSystemStateHooks } from "../modules/system";
import { DynamoUserApi } from "../services";
import { getNextFreeNumber } from "../utils/listUtils";
import { define } from "../utils/typeUtils";
import { NumberProp, TableName } from "./types/types";

export const useFetchPropList = () => {
    const systemStateHooks = useSystemStateHooks()

    const defaultDbActions = useDefaultDbActions()

    return async (
        tableName: TableName,
        propName: string,
        paramCompanyId?: string
    ) => {
        const companyId = paramCompanyId || systemStateHooks.companyId

        const response = await defaultDbActions(DynamoUserApi, define(companyId)).getAll(tableName, undefined, undefined, propName)
        const values = response.dataTypes
            .map(dataType => (dataType as NumberProp)[propName as keyof NumberProp] as number)
        
        return values
    }
}

export const useGenerateNextValue = () => {
    const systemStateHooks = useSystemStateHooks()

    const defaultDbActions = useDefaultDbActions()

    return async (
        tableName: TableName,
        propName: string,
        ignoreValues?: number[],
        paramCompanyId?: string
    ) => {
        const companyId = paramCompanyId || systemStateHooks.companyId

        const response = await defaultDbActions(DynamoUserApi, define(companyId)).getAll(tableName, undefined, undefined, propName)
        const values = response.dataTypes
            .map(dataType => (dataType as NumberProp)[propName as keyof NumberProp] as number)
            .concat(ignoreValues || [])
        
        return getNextFreeNumber(values)
    }
}

export const useGenerateNextValue2 = () => {
    const fetchPropList = useFetchPropList()

    return async (
        tableName: TableName,
        propName: string,
        paramCompanyId?: string,
        list?: number[]
    ) => {
        const values = list && list.length > 0 ? list : await fetchPropList(tableName, propName, paramCompanyId)
        return getNextFreeNumber(values)
    }
}
