import { TableName } from '../../../../tables'
import { Data, UIPermission, DetailPanel, DetailFieldProps, Tab, FieldType, StockAction, useGetCrumbs } from '../../../../features/ui'
import { define } from '../../../../utils/typeUtils'
import { useSystemStateHooks } from '../../../system'
import { Action } from '../../../../features/ui'
import { Stock } from '../../state/types'
import { ProductName } from '../../../products'
import { useActions } from './actions'
import { useDescriptorHooks } from '../../../../features/data-types'
import { BranchStockTables } from '../branch-stock-tables/branchStockTables'
import { useManagerState } from '../../../manager'
import { useEffect, useState } from 'react'
import { useStockHooks } from '../../hooks/stockHooks'
import { RoutePath, useRoutesHooks } from '../../../../features/routes'

export const StockDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()

  const [stock, setStock] = useState<Stock>(define(dataType) as Stock)

  const { currentBranch } = useManagerState()
  const branch = define(currentBranch)

  const { userHasPermission } = useSystemStateHooks()
  const { getBaseStock } = useStockHooks()
  const descriptorHooks = useDescriptorHooks()

  const { findProduct, onEditStock } = useActions()

  useEffect(() => {
    const branchStock = getBaseStock(stock.productId, branch.id)
    setStock(branchStock)
  }, [branch])

  const product = findProduct(stock.productId)
  const stockData: Data = {
    ...stock,
    imageUrl: product.imageUrl
  }
  const readOnly = !userHasPermission(UIPermission.SAVE_BUDGETS)
  
  const title = `Stock: ${product.name}`

  const crumbs = useGetCrumbs('Stock', RoutePath.STOCK, product.name)

  const fields: DetailFieldProps[] = [
    {
      name: 'productCode',
      type: FieldType.TEXT,
      label: 'Código',
      render: () => <>{product.code}</>
    },
    {
      name: 'productSupplierCode',
      type: FieldType.TEXT,
      label: 'Código Proveedor',
      render: () => <>{product.supplierCode}</>
    },
    {
      name: 'product',
      type: FieldType.TEXT,
      label: 'Producto',
      render: () => <ProductName product={product} />
    },
    {
      name: 'productContent',
      type: FieldType.TEXT,
      label: 'Contenido /undidad',
      render: () => <>{descriptorHooks.product.content(product)}</>
    },
    {
      name: 'branchId',
      type: FieldType.TABLE,
      table: TableName.BRANCHES,
      label: 'Sucursal'
    },
    {
      name: 'availableUnits',
      type: FieldType.TEXT,
      label: 'Stock Disponible',
      render: () => <>{descriptorHooks.product.availableStock(product, branch.id)}</>
    },
    {
      name: 'reservedUnits',
      type: FieldType.TEXT,
      label: 'Stock Reservado',
      render: () => <>{descriptorHooks.product.reservedStock(product, branch.id)}</>
    },
  ]

  const renderLotsStockContent = (
    <BranchStockTables productId={product.id} />
  )

  const tabs: Tab[] = [
    { id: 'lots-stock', title: 'Partidas', content: renderLotsStockContent },
  ]

  const onIncreaseStock = () => onEditStock(StockAction.ADD)
  const onDecreaseStock = () => onEditStock(StockAction.SUBSTRACT)

  const actions: Action[] = readOnly ? [] : [
    { label: 'Alta manual', icon: 'plus-circle-fill', onClick: onIncreaseStock },
    { label: 'Baja manual', icon: 'dash-circle-fill', kind: 'secondary', onClick: onDecreaseStock },
  ]

  return (
    <DetailPanel
      title={title}
      tableName={TableName.STOCK}
      crumbs={crumbs}
      fields={fields}
      data={stockData}
      icon='boxes'
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
  )
}
