import { ReactElement, useEffect, useState } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { ModalId, useUIActions, useUIStateActions } from "../../../../../features/ui"
import { WizardPanel } from "../../../../../features/wizard"
import { define } from "../../../../../utils/typeUtils"
import { SaleItem, SaleOrder } from "../../../state/types"
import { saleWizardKey } from "../../../utils/salesUtils"
import { SaleInfoStep } from "./saleInfoStep"
import { SaleItemData } from "./types"
import { OrderDelivery, useOperationsActions, useOperationsHooks } from "../../../../operations"
import { SaleItemStep } from "./saleItemStep"
import { useSalesActions } from "../../../actions/salesActions"
import { DataType } from "../../../../../tables"
import { useActions } from "./actions"
import { useRoutesHooks } from "../../../../../features/routes"

export const SaleDeliveryForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const sale = define(dataType) as SaleOrder
    const wizardKey = saleWizardKey(sale)

    const [saleItems, setSaleItems] = useState<SaleItem[]>([])
    const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
    const [steps, setSteps] = useState<ReactElement[]>([])
    const [saleItemsData, setSaleItemsData] = useState<SaleItemData[]>([])
    const [validations, setValidations] = useState<string[]>([])

    const descriptorHooks = useDescriptorHooks()
    const { getPendingQuantity } = useOperationsHooks()

    const { validateDelivery, finishDelivery } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { toggleModal } = useUIActions()
    const salesActions = useSalesActions()
    const operationsActions = useOperationsActions()

    useEffect(() => {
        setDisableBranchSelector(true)
        
        operationsActions().fetchDeliveries(sale.id).then((deliveriesDT: DataType[]) => {
            setDeliveries(deliveriesDT as OrderDelivery[])
            salesActions().fetchSaleItemsByOrder(sale.id).then((saleItemsDT: DataType[]) => {
                const sortedSaleItems = saleItemsDT.sort((item1, item2) => item1.id > item2.id ? 1 : -1) as SaleItem[]
                setSaleItems(sortedSaleItems)
            })
        })

        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => {
        if (saleItems.length === 0) {
            return
        }

        const infoStep = <SaleInfoStep
            wizardKey={wizardKey}
            stepIndex={0}
            sale={sale}
        />
        const stepList = [infoStep]
            
        const saleItemsDataList: SaleItemData[] = []
        saleItems.forEach(saleItem => {
            const pendingQuantity = getPendingQuantity(saleItem, deliveries)
            if (pendingQuantity > 0) {
                const stepIndex = stepList.length
                const itemStep = <SaleItemStep
                    key={stepIndex}
                    wizardKey={wizardKey}
                    stepIndex={stepIndex}
                    saleItem={saleItem}
                    pendingQuantity={pendingQuantity}
                />
                saleItemsDataList.push({ stepIndex, saleItem })
                stepList.push(itemStep)
            }
        })

        setSteps(stepList)
        setSaleItemsData(saleItemsDataList)
    }, [saleItems, deliveries])

    const title = `Entrega de Venta ${descriptorHooks.sale.code(sale)}`

    const onFinish = async () => {
        const stockValidations = await validateDelivery(wizardKey, sale, saleItemsData)
        if (stockValidations.length > 0) {
            setValidations(stockValidations)
            toggleModal(ModalId.VALIDATION)
        } else {
            await finishDelivery(wizardKey, sale, saleItemsData)
        }
    }

    return (
        <WizardPanel
            wizardKey={wizardKey}
            title={title}
            steps={steps}
            validations={validations}
            onFinish={onFinish}
        />
    )
}
