import styles from './branchStockTables.module.css'
import { useManagerState } from '../../../manager'
import { LotsStockTable } from '../lots-stock-table/lotsStockTable'
import { useStockHooks } from '../../hooks/stockHooks'

type Props = {
    productId: string
}

export const BranchStockTables = (props: Props) => {
    const { productId } = props
    const { branches, currentBranch } = useManagerState()
    const { getBranchStock } = useStockHooks()
    
    const totalStock = (branchId: string) => {
        const productStockList = getBranchStock(branchId).filter(stock => stock.productId === productId)
        return productStockList.reduce((sum, stock) => sum + stock.availableUnits + stock.reservedUnits, 0)
    }

    const branchesWithStock = branches
        .filter(branch => branch.id === currentBranch?.id || totalStock(branch.id) > 0)
        .sort(stock => stock.id !== currentBranch?.id ? 1 : -1)
    
    return (
        <div className={styles.container}>
            {branchesWithStock.map(branch => (
                <div key={branch.code} className={styles.lotContainer}>
                    <span>{branch.name}:</span>
                    <LotsStockTable
                        productId={productId}
                        branch={branch}
                        className={styles.table}
                    />
                </div>
            ))}
        </div>
    )
}
