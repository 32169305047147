import styles from './modal.module.css'
import { useUIState, useUIStateActions } from '../../hooks/uiHooks'
import { ModalId } from './types'
import { useUIActions } from '../../actions/uiActions'
import { ActionButton } from '../action-button/actionButton'
import { Action } from '../types'
import { useEffect } from 'react'
import { Button } from '../button/button'
import { ButtonKind } from '../button/types'

type Props = {
    modalId: ModalId
    title: string
    children: JSX.Element | JSX.Element[]
    submitLabel?: string
    submitIcon?: string
    submitKind?: ButtonKind
    cancelLabel?: string
    closeable?: boolean
    widthSize?: 's' | 'm' | 'l'
    actions?: Action[]
    focusSubmit?: boolean
    onSubmit?: () => void
}

export const Modal = (props: Props) => {
    const { modalId, title, children, submitLabel, submitIcon, submitKind, cancelLabel, closeable = true, widthSize = 's', actions, focusSubmit, onSubmit } = props
    const { openedModal } = useUIState()
    const { setOpenedModal } = useUIStateActions()
    const { closeModal, closeModalOverlay } = useUIActions()

    useEffect(() => {
        return () => {
            closeModalOverlay()
        }
    }, [])

    const showFooter = !actions || actions.length > 0

    const containerClassName = `${styles.container} ${widthSize === 's' ? styles.s : (widthSize === 'm' ? styles.m : styles.l)}`

    const onClose = () => openedModal && setOpenedModal(undefined)

    const onCancel = () => {
        onClose()
        closeModal(modalId)
    }

    const handleSubmit = () => {
        closeModal(modalId)
        onSubmit && onSubmit()
    }
    
    const onModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()

    const renderActionBtn = (action: Action, index: number) => {
        return <ActionButton
            key={index}
            action={action}
            onClick={action.onClick}
        />
    }

    const renderSubmitBtn = () => {
        return <Button
            label={submitLabel || 'Confirmar'}
            kind={submitKind || 'primary'}
            icon={submitIcon}
            focus={focusSubmit}
            onClick={handleSubmit}
        />
    }

    const renderCancelBtn = () => {
        return <Button
            label={cancelLabel || 'Cancelar'}
            kind='tertiary'
            onClick={onCancel}
        />
    }

    return (
        <div className="modal fade" id={modalId} onClick={onClose} tabIndex={-1}>
            {!closeable && <div className={styles.overlay} />}
            <div className={`modal-dialog modal-dialog-centered ${containerClassName}`} onClick={onModalClick}>
                <div className={`modal-content ${styles.content}`}>
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        {closeable && (
                            <button
                                type="button"
                                onClick={onClose}
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        )}
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    {showFooter && (
                        <div className="modal-footer">
                            {actions ? actions.map(renderActionBtn) : (
                                <>
                                    {onSubmit && renderSubmitBtn()}
                                    {renderCancelBtn()}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <button
                id={`open-${modalId}`}
                data-bs-toggle="modal"
                data-bs-target={`#${modalId}`}
                className={styles.openerBtn}
            />
        </div>
    )
}
