import { useState } from "react"
import { Action, ButtonKind, Column, FieldType, FieldValue, ModalId, SpinnerDiv, UIPermission, useUIActions } from "../../../../../features/ui"
import { Data, DataTypeList, Labels } from "../../../../../features/data-types"
import { useSystemStateHooks } from "../../../../system"
import { DataType, TableName } from "../../../../../tables"
import { PaymentOrderFilters } from "./paymentOrderFilters"
import { define } from "../../../../../utils/typeUtils"
import { PaymentOrder, PaymentOrderStatus } from "../../../state/types"
import { useFind } from "../../../../../state/reducers/hooks"
import { useNavigate } from "react-router-dom"
import { ConfirmPaymentFormModal } from "../confirm-payment-modal/confirmPaymentFormModal"
import { PaymentOrderReceiptModal } from "../payment-order-receipt-modal/paymentOrderReceiptModal"

export const PaymentOrderList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [currentPaymentOrder, setCurrentPaymentOrder] = useState<PaymentOrder>()
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PAYMENT_ORDERS)
    const navigate = useNavigate()
    const find = useFind()

    const { toggleModal } = useUIActions()

    const columns: Column[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: '#',
            render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Importe'
        },
        {
            name: 'creationDate',
            type: FieldType.DATE,
            label: 'Fecha'
        },
        {
            name: 'status',
            type: FieldType.TEXT,
            label: 'Estado',
            render: (value?: FieldValue) => Labels.paymentOrderStatusLabel(define(value) as PaymentOrderStatus)
        }
    ]

    const findPaymentOrder = (id?: string) => define(find(TableName.PAYMENT_ORDERS, id)) as PaymentOrder

    const onView = (id?: string) => {
        const paymentOrder = findPaymentOrder(id)
        navigate(paymentOrder.id)
    }

    const onDownloadReceipt = (id?: string) => {
        const paymentOrder = findPaymentOrder(id)
        setCurrentPaymentOrder(paymentOrder)
        toggleModal(ModalId.PAYMENT_ORDER_RECEIPT)
    }

    const onConfirmPayment = (id?: string) => {
        const paymentOrder = findPaymentOrder(id)
        setCurrentPaymentOrder(paymentOrder)
        toggleModal(ModalId.CONFIRM_PAYMENT_FORM)
    }

    const actions = (id?: string): Action[] => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary' as ButtonKind, tooltip: 'Ver Detalle', onClick: onView },
        ]
        
        const paymentOrder = findPaymentOrder(id)
        if (paymentOrder?.receiptUrl) {
            actionList.push(
                { icon: 'download', kind: 'tertiary' as ButtonKind, tooltip: 'Descargar Comprobante', narrowTooltip: true, onClick: onDownloadReceipt }
            )
        }
        if (!readOnly && paymentOrder.status === PaymentOrderStatus.EMITTED) {
            actionList.push(
                { icon: 'check-lg', tooltip: 'Confirmar Pago', onClick: onConfirmPayment }
            )
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as Data[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <PaymentOrderFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    actions={actions}
                    readOnly
                />
            )}
            <PaymentOrderReceiptModal paymentOrder={currentPaymentOrder} />
            <ConfirmPaymentFormModal paymentOrder={currentPaymentOrder} />
        </>
    )
}
