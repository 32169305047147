import { useDefaultModuleActions } from "../../common"
import { useDataTypeFileActions, Standards, newId } from "../../../features/data-types"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { DataType, TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { Company, useSystemActions, useSystemStateHooks } from "../../system"
import { CreditCard, Employee, Expense, ExpenseType, PaymentCategory, PaymentOrder, PaymentType, Settings, SettingsModule, Tax, Transaction, TransactionDestinationType, TransactionSourceType } from "../state/types"
import { dedupe } from "../../../utils/listUtils"
import { useCashFundActions } from "./cashFundActions"
import { useTransactionActions } from "./transactionActions"
import { useCheckActions } from "./checkActions"
import { Currency } from "../../../tables/types/types"

export const useAdministrationActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const systemActions = useSystemActions()
    const cashFundActions = useCashFundActions()
    const checkActions = useCheckActions()
    const transactionActions = useTransactionActions()
    const dataTypeFileActions = useDataTypeFileActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, fetchByParams, fetchMultipleByParams, count, save, remove } = defaultModuleActions(DynamoUserApi, define(companyId))
        const { fetchAllCashFunds, saveCashFund, reserveCashFundAmount, transferFromCashFund, removeCashFund } = cashFundActions(companyId)
        const { fetchAllChecks, saveCheck, reserveCheck, removeCheck } = checkActions(companyId)
        const { fetchTransactions, countTransactions, saveTransaction } = transactionActions(companyId)

        const fetchAllSettings = async () => {
            return fetchAll(TableName.SETTINGS).then(response => response.dataTypes)
        }

        const fetchSettings = (module: SettingsModule) => {
            return fetchByParams(TableName.SETTINGS, { module })
        }
        
        const saveSettings = (settings: Settings) => {
            return save(TableName.SETTINGS, settings)
        }

        const saveCompany = (company: Company) => {
            return systemActions().saveCompany(company)
        }

        const fetchAllTaxes = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.TAXES, limit, startKey)
        }

        const fetchTax = (id: string) => {
            return fetchByParams(TableName.TAXES, { id })
        }
        
        const saveTax = (tax: Tax) => {
            return save(TableName.TAXES, tax)
        }

        const removeTax = (id: string) => {
            return remove(TableName.TAXES, id)
        }

        const fetchAllCreditCards = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.CREDIT_CARDS, limit, startKey)
        }

        const saveCreditCard = (creditCard: CreditCard) => {
            return save(TableName.CREDIT_CARDS, creditCard)
        }

        const removeCreditCard = (id: string) => {
            return remove(TableName.CREDIT_CARDS, id)
        }

        const fetchAllEmployees = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.EMPLOYEES, limit, startKey)
        }

        const fetchEmployee = (id: string) => {
            return fetchByParams(TableName.EMPLOYEES, { id })
        }

        const fetchEmployeeByEmail = (email: string) => {
            return fetchByParams(TableName.EMPLOYEES, { email })
        }

        const saveEmployee = (employee: Employee) => {
            return save(TableName.EMPLOYEES, employee)
        }

        const removeEmployee = (id: string) => {
            return remove(TableName.EMPLOYEES, id)
        }

        const fetchAllPaymentTypes = async () => {
            return fetchAll(TableName.PAYMENT_TYPES).then(response => response.dataTypes as PaymentType[])
        }        

        const fetchPaymentType = (id: string) => {
            return fetchByParams(TableName.PAYMENT_TYPES, { id })
        }

        const savePaymentType = async (paymentType: PaymentType) => {
            return save(TableName.PAYMENT_TYPES, paymentType)
        }

        const removePaymentType = async (id: string) => {
            const paymentTypes = await fetchAllPaymentTypes()
            if (paymentTypes.length > 1) {
                return remove(TableName.PAYMENT_TYPES, id)
            }
        }

        const fetchAllExpenseTypes = async () => {
            return fetchAll(TableName.EXPENSE_TYPES).then(response => response.dataTypes as ExpenseType[])
        }

        const saveExpenseType = (expenseType: ExpenseType) => {
            return save(TableName.EXPENSE_TYPES, expenseType)
        }

        const removeExpenseType = async (id: string) => {
            const expenseTypes = await fetchAllExpenseTypes()
            const isSalary = expenseTypes.find(expenseType => expenseType.id === id)?.name === Standards.SalaryExpenseTypeName
            if (expenseTypes.length > 1 && !isSalary) {
                return remove(TableName.EXPENSE_TYPES, id)
            }
        }

        const fetchAllExpenses = (branchId: string) => {
            return fetchMultipleByParams(TableName.EXPENSES, { branchId })
        }

        const countExpenses = (propName: string, propValue: string) => {
            return count(TableName.EXPENSES, { [propName]: propValue })
        }

        const saveExpense = async (
            expense: Expense,
            receiptFile?: File
        ) => {
            const { saveWithFile } = dataTypeFileActions(define(companyId))
            return saveWithFile(TableName.EXPENSES, expense, 'receiptUrl' as keyof DataType, receiptFile)
        }

        const removeExpense = (id: string) => {
            const { removeWithFile } = dataTypeFileActions(define(companyId))
            return removeWithFile(TableName.EXPENSES, id, 'receiptUrl' as keyof DataType)
        }

        const fetchAllPaymentOrders = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.PAYMENT_ORDERS, limit, startKey)
        }

        const fetchPaymentOrder = (id: string) => {
            return fetchByParams(TableName.PAYMENT_ORDERS, { id })
        }

        const countPaymentOrders = async (taxCodeValue: string) => {
            const paymentOrders = (await fetchAllPaymentOrders()).dataTypes as PaymentOrder[]
            const taxCodes = paymentOrders.flatMap(paymentOrder => paymentOrder.taxItems.map(taxItem => taxItem.taxCode))
            return dedupe(taxCodes).filter(taxCode => taxCode === taxCodeValue).length
        }

        const savePaymentOrder = async (
            paymentOrder: PaymentOrder,
            receiptFile?: File
        ) => {
            const statePaymentOrder = await fetchPaymentOrder(paymentOrder.id) as PaymentOrder | undefined

            const { saveWithFile } = dataTypeFileActions(define(companyId))
            const savedPaymentOrder = await saveWithFile(TableName.PAYMENT_ORDERS, paymentOrder, 'receiptUrl' as keyof DataType, receiptFile)

            if (!statePaymentOrder) {
                paymentOrder.paymentItems.forEach(async paymentItem => {
                    let sourceType: TransactionSourceType = TransactionSourceType.CREDIT_CARD
                    if (paymentItem.paymentCategory === PaymentCategory.CASH_FUND) {
                        sourceType = TransactionSourceType.CASH_FUND
                        await reserveCashFundAmount(paymentItem.sourceId, paymentItem.amount)
                    } else if (paymentItem.paymentCategory === PaymentCategory.CHECK) {
                        sourceType = TransactionSourceType.CHECK
                        await reserveCheck(paymentItem.sourceId)
                    }
    
                    const transaction: Transaction = {
                        companyId: define(companyId),
                        id: newId(),
                        date: new Date().toISOString(),
                        sourceType,
                        sourceId: paymentItem.sourceId,
                        amount: paymentItem.amount,
                        destinationType: TransactionDestinationType.PAYMENT_ORDER,
                        destinationId: savedPaymentOrder.id,
                        currency: Currency.ARS
                    }
                    await saveTransaction(transaction)
                })
            }

            return savedPaymentOrder
        }

        const removePaymentOrder = (id: string) => {
            const { removeWithFile } = dataTypeFileActions(define(companyId))
            return removeWithFile(TableName.PAYMENT_ORDERS, id, 'receiptUrl' as keyof DataType)
        }

        return {
            fetchAllSettings,
            fetchSettings,
            saveSettings,
            saveCompany,
            fetchAllTaxes,
            fetchTax,
            saveTax,
            removeTax,
            fetchAllCashFunds,
            saveCashFund,
            transferFromCashFund,
            removeCashFund,
            fetchAllChecks,
            saveCheck,
            removeCheck,
            fetchAllCreditCards,
            saveCreditCard,
            removeCreditCard,
            fetchTransactions,
            countTransactions,
            saveTransaction,
            fetchAllPaymentTypes,
            fetchPaymentType,
            savePaymentType,
            removePaymentType,
            fetchAllEmployees,
            fetchEmployee,
            fetchEmployeeByEmail,
            saveEmployee,
            removeEmployee,
            fetchAllExpenseTypes,
            saveExpenseType,
            removeExpenseType,
            fetchAllExpenses,
            countExpenses,
            saveExpense,
            removeExpense,
            fetchAllPaymentOrders,
            countPaymentOrders,
            savePaymentOrder,
            removePaymentOrder
        }
    }
}
