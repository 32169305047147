import { Currency } from "../../../tables/types/types"

export type Stock = {
    companyId: string
    id: string
    productId: string
    lotId: string
    availableUnits: number
    reservedUnits: number
    currency: Currency
    branchId: string
}

export enum UnitTypeCategory {
    BASIC = 'Basic',
    LENGTH = 'Length',
    WEIGHT = 'Weight',
    AREA = 'Area',
    VOLUME = 'Volume'
}

export type UnitType = {
    id: string
    category: UnitTypeCategory
    name: string
    abbreviation?: string
}
