import styles from './breadcrumbs.module.css'
import { useNavigate } from 'react-router-dom'
import { Crumb } from '../types'

type Props = {
    crumbs: Crumb[]
}

export const Breadcrumbs = (props: Props) => {
    const { crumbs } = props

    const navigate = useNavigate()

    const onClick = (path?: string) => () =>  path && navigate(path)

    return (
        <nav className={styles.container}>
            <ol className="breadcrumb">
                {crumbs.map((crumb, index) => {
                    const className = crumb.path ? styles.link : styles.crumb
                    return (
                        <li
                            key={`crumb-${index}`}
                            onClick={onClick(crumb.path)}    
                            className={`breadcrumb-item ${className}`}
                        >
                            {crumb.label}
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}
