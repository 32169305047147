import { Branch } from "./types"

export enum ActionType {
    SET_CURRENT_BRANCH = 'setCurrentBranch',
    UPDATE_BRANCHES = 'updateBranches',
    REMOVE_BRANCHES = 'removeBranches'
}

interface SetCurrentBranch {
    type: ActionType.SET_CURRENT_BRANCH
    payload: Branch
}

interface UpdateBranches {
    type: ActionType.UPDATE_BRANCHES
    payload: Branch[]
}

interface RemoveBranches {
    type: ActionType.REMOVE_BRANCHES
    payload: string[]
}

export type ManagerAction =
    SetCurrentBranch |
    ManagerUpdateAction |
    ManagerRemoveAction

export type ManagerUpdateAction =
    UpdateBranches

export type ManagerRemoveAction =
    RemoveBranches
