import { showToast } from "../../../../../features/ui"
import { usePurchasesActions } from "../../../actions/purchasesActions"

export const useActions = () => {
    const purchasesActions = usePurchasesActions()
    
    const onUpdateSettings = () => showToast('La configuración fue actualizada.')

    const updatePurchaseDocFooter = async (docFooter: string) => {
        await purchasesActions().savePurchasesSetting('purchaseDocFooter', docFooter)
        onUpdateSettings()
    }

    return {
        updatePurchaseDocFooter
    }
}
