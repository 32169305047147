import { useDefaultModuleActions } from "../../common"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { CashFund, Transaction } from "../state/types"
import { useTransactionActions } from "./transactionActions"

export const useCashFundActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const transactionActions = useTransactionActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, fetchByParams, save, remove } = defaultModuleActions(DynamoUserApi, define(companyId))
        const { saveTransaction } = transactionActions(companyId)

        const fetchAllCashFunds = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.CASH_FUNDS, limit, startKey)
        }

        const fetchCashFund = (id: string) => {
            return fetchByParams(TableName.CASH_FUNDS, { id })
        }

        const saveCashFund = (cashFund: CashFund) => {
            return save(TableName.CASH_FUNDS, cashFund)
        }

        const reserveCashFundAmount = async (
            cashFundId: string,
            amount: number
        ) => {
            const stateCashFund = define(await fetchCashFund(cashFundId)) as CashFund
            const cashFund: CashFund = {
                ...stateCashFund,
                availableAmount: stateCashFund.availableAmount - amount,
                reservedAmount: stateCashFund.reservedAmount + amount
            }
            return await saveCashFund(cashFund)
        }

        const transferFromCashFund = async (transaction: Transaction) => {
            const sourceCashFund = define(await fetchCashFund(transaction.sourceId)) as CashFund
            const updatedSourceCashFund: CashFund = {
                ...sourceCashFund,
                availableAmount: sourceCashFund.availableAmount - transaction.amount
            }
            await saveCashFund(updatedSourceCashFund)

            const destinationCashFund = define(await fetchCashFund(transaction.destinationId)) as CashFund
            const udpatedDestinationCashFund: CashFund = {
                ...destinationCashFund,
                availableAmount: destinationCashFund.availableAmount + transaction.amount
            }
            await saveCashFund(udpatedDestinationCashFund)

            const savedTransaction = await saveTransaction(transaction)
            return savedTransaction
        }

        const removeCashFund = (id: string) => {
            return remove(TableName.CASH_FUNDS, id)
        }

        return {
            fetchAllCashFunds,
            saveCashFund,
            reserveCashFundAmount,
            transferFromCashFund,
            removeCashFund
        }
    }
}
