import { useState } from "react"
import { Form, Data, FormField, ModalId, useUIActions, FieldType, option, FieldValue } from "../../../../../features/ui"
import { firstItem } from "../../../../../utils/listUtils"
import { LotAttribute } from '../../../state/types'
import { Labels } from "../../../../../features/data-types"

type Props = {
    lotDataItem?: Data
    filterAttributes?: (attr: LotAttribute) => boolean
    onSubmit: (data: Data) => void
}

export const LotDataForm = (props: Props) => {
    const { lotDataItem, filterAttributes, onSubmit } = props
    const createMode = !lotDataItem
    const attributes = filterAttributes ? Object.values(LotAttribute).filter(filterAttributes) : Object.values(LotAttribute)
    const attrOptions = attributes.map(attr => option(attr, Labels.lotAttributeLabel(attr)))

    const defaultAttr = (lotDataItem?.lotAttr || firstItem(attrOptions)?.value) as LotAttribute | undefined
    const [lotAttr, setlotAttr] = useState<LotAttribute | undefined>(defaultAttr)

    const { toggleModal } = useUIActions()

    const onChangeAttr = (value?: FieldValue) => {
        setlotAttr(value as LotAttribute | undefined)
    }
    
    const fields: FormField[] = [
        {
            name: 'lotAttr',
            type: FieldType.SELECT,
            label: 'Atributo',
            defaultValue: defaultAttr,
            options: attrOptions,
            placeholder: 'Seleccionar atributo',
            onChange: onChangeAttr
        }
    ]
    
    if (lotAttr === LotAttribute.DUE_DATE) {
        const dueDateField = {
            name: 'lotAttrValue',
            type: FieldType.DATE,
            label: 'Fecha',
            defaultValue: lotDataItem?.lotAttrValue
        }
        fields.push(dueDateField)
    } else {
        const textValueField = {
            name: 'lotAttrValue',
            type: FieldType.TEXT,
            label: 'Valor',
            defaultValue: lotDataItem?.lotAttrValue
        }
        fields.push(textValueField)
    }

    const handleSubmit = (data: Data) => {
        onSubmit(data)
        toggleModal(ModalId.LOT_DATA_FORM)
    }

    const handleCancel = () => toggleModal(ModalId.LOT_DATA_FORM)

    return (
        <Form
            formId="lot-data-form"
            fields={fields}
            createMode={createMode}
            createLabel="Agregar"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    )
}
