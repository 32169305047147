import { useDefaultModuleActions } from "../../common"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { DynamoUserApi } from "../../../services"
import { Lot } from "../state/types"
import { TextParams } from "../../../state/reducers/types"

export const useLotsActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const { fetchAll, fetchByParams, fetchMultipleByParams, save, remove } = defaultModuleActions(DynamoUserApi, companyId)

        const fetchAllLots = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.LOTS, limit, startKey)
        }

        const fetchLot = (id: string) => {
            return fetchByParams(TableName.LOTS, { id })
        }

        const fetchLots = (productId: string) => {
            return fetchMultipleByParams(TableName.LOTS, { productId })
        }

        const saveLot = (lot: Lot) => {
            return save(TableName.LOTS, lot)
        }

        const removeLot = (id: string) => {
            return remove(TableName.LOTS, id)
        }

        return {
            fetchAllLots,
            fetchLot,
            fetchLots,
            saveLot,
            removeLot
        }
    }
}
