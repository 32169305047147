import { Modal, ModalId } from "../../../ui"
import { DataType, TableName, TablesData } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { useActions } from "./actions"
import { useDescriptorHooks } from "../../hooks/descriptorHooks"

type Props = {
    tableName: TableName
    dataType?: DataType
    onRemove?: (id: string) => void,
    afterRemove?: () => void
}

export const RemoveModal = (props: Props) => {
    const { tableName, dataType, onRemove, afterRemove } = props

    const descriptorHooks = useDescriptorHooks()

    const { removeDataType } = useActions()

    const { singular } = define(TablesData.get(tableName))
    const title = `Eliminar ${singular.toLowerCase()}?`
    
    const renderBody = () => dataType ?
        `Está por eliminar: ${singular} "${descriptorHooks.dataType(tableName, dataType)}". Esta acción es irreversible.` :
        <></>

    const onSubmit = () => removeDataType(tableName, define(dataType).id, onRemove, afterRemove)
    
    return (
        <Modal
            modalId={ModalId.REMOVE}
            title={title}
            submitLabel="Confirmar"
            submitKind="secondary"
            onSubmit={onSubmit}
        >
            <>{renderBody()}</>
        </Modal>
    )
}
