import { DownloadFileModal, ModalId } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { PurchaseTicket } from "../../../state/types"

type Props = {
    purchaseTicket?: PurchaseTicket
    file?: File
}

export const PurchaseTicketPDFModal = (props: Props) => {
    const { purchaseTicket, file } = props

    return (
        <DownloadFileModal
            modalId={ModalId.PURCHASE_TICKET_PDF}
            dataType={purchaseTicket}
            tableName={TableName.PURCHASE_TICKETS}
            fileProp={'ticketUrl' as keyof DataType}
            title="Descargar Factura"
            fileNameParam={file?.name}
        />
    )
}
