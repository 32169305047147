import { Modal, ModalId } from "../../../../features/ui"
import { define } from "../../../../utils/typeUtils"
import { Operation, OperationOrder } from "../../state/types"
import { useActions } from "./actions"

type Props = {
    operationType: Operation
    operation?: OperationOrder
}

export const CloseOperationModal = (props: Props) => {
    const { operationType, operation } = props
    const { getOperationLabel, closeOperation } = useActions()

    const title = `Cerrar operación?`

    const renderBody = () => operation ? 
        `Esta acción actualizará ${getOperationLabel(operationType, operation)} con estado "Entregado". Desea confirmar el cierre?` :
        <></>

    const onClose = () => closeOperation(operationType, define(operation))

    return (
        <Modal
            modalId={ModalId.CLOSE_OPERATION}
            title={title}
            submitLabel="Confirmar"
            onSubmit={onClose}
        >
            <>{renderBody()}</>
        </Modal>
    )
}
