import { define } from "../../../../../utils/typeUtils"
import { HoardOrder, HoardType } from "../../../state/types"
import { ProductHoardDeliveryForm } from "./productHoardDeliveryForm"
import { FinancialHoardDeliveryForm } from "./financialHoardDeliveryForm"
import { useRoutesHooks } from "../../../../../features/routes"

export const HoardDeliveryForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const hoard = define(dataType) as HoardOrder
    const isProductHoard = hoard.type === HoardType.PRODUCT

    return isProductHoard ? <ProductHoardDeliveryForm /> : <FinancialHoardDeliveryForm />
}
