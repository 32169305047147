import { Modal, ModalId } from "../.."

type Props = {
    title: string
    body: string
}

export const MessageModal = (props: Props) => {
    const { title, body } = props

    return (
        <Modal
            modalId={ModalId.MESSAGE}
            title={title}
            cancelLabel="Cerrar"
        >
            <>{body}</>
        </Modal>
    )
}
