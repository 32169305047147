import { useSystemActions, useSystemStateActions } from "../../../../modules/system"
import { useServicesHooks } from "../../../../services"
import { ConstrufyAdminRoleName } from "../../../../modules/construfy-admin"
import { define } from "../../../../utils/typeUtils"
import { TenantKey, UniqueKey } from "../../../../tables/constants"
import { useNavigate } from "react-router-dom"
import { useInitEmitter } from "../../../event-emitter"
import { useRoutesHooks } from "../../hooks/routesHooks"
import { RoutePath } from "../../types"

export const useLoad = () => {
    const { loadUser } = useRoutesHooks()
    const { initUserApis } = useServicesHooks()
    const navigate = useNavigate()

    const { setConstrufyAdminId } = useSystemStateActions()
    const systemActions = useSystemActions()

    useInitEmitter()

    return async () => {
        const user = await loadUser()

        if (user)  {
            const admin = await systemActions().fetchAdmin(user.id)
            if (!admin || admin.id !== user.id) {
                navigate(RoutePath.ERROR)
            } else {
                initUserApis(ConstrufyAdminRoleName, define(user.email), TenantKey, UniqueKey)
                setConstrufyAdminId(admin.id)
            }
        }
    }
}
