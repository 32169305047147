import styles from './lotDataFormModal.module.css'
import { useEffect, useState } from "react"
import { Data, Modal, ModalId, useUIState } from "../../../../../features/ui"
import { LotDataForm } from "./lotDataForm"
import { LotAttribute } from "../../../state/types"

type Props = {
    lotDataItem?: Data
    filterAttributes: (attr: LotAttribute) => boolean
    onSubmit: (data: Data) => void
}

export const LotDataFormModal = (props: Props) => {
    const { lotDataItem, filterAttributes, onSubmit } = props
    const [key, setKey] = useState(0)
    const { openedModal } = useUIState()

    useEffect(() => setKey(key + 1), [openedModal])
    
    return (
        <Modal
            modalId={ModalId.LOT_DATA_FORM}
            title="Agregar Atributo"
            actions={[]}
        >
            <div className={styles.container}>
                <LotDataForm
                    key={key}
                    lotDataItem={lotDataItem}
                    filterAttributes={filterAttributes}
                    onSubmit={onSubmit}
                />
            </div>
        </Modal>
    )
}
