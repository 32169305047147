import { AttributeMap } from "aws-sdk/clients/dynamodb"
import { KeyParams, RangeParams } from "./types"

export class ParamsProvider {
    constructor(
        readonly tenantKey: string,
        readonly uniqueKey: string
    ) {}

    scanParams = (
        tableName: string,
        tKeyValue?: string,
        limit?: number,
        startKey?: KeyParams,
        params?: KeyParams,
        count = false
    ) => {
        const baseParams = {
            TableName: tableName,
            ...(limit && {
                Limit: limit
            }),
            ...(startKey && {
                ExclusiveStartKey: startKey
            }),
            ...(count && {
                Select: "COUNT"
            }),
        }
        if (!params) {
            return baseParams
        }
    
        const paramsWithTKey: KeyParams = {
            ...params,
            ...(tKeyValue && {
                [this.tenantKey]: tKeyValue
            }),
        }
        const keys = Object.keys(paramsWithTKey)
        let filter = ''
        const names: KeyParams = {}
        const values: KeyParams = {}
    
        keys.forEach(key => {
            const maybeAnd = filter === '' ? '' : ' AND '
            filter = filter.concat(maybeAnd, `#${key} = :${key}`)
            names[`#${key}`] = key
            values[`:${key}`] = paramsWithTKey[key]
        })
        
        return {
            ...baseParams,
            FilterExpression: filter,
            ExpressionAttributeNames: names,
            ExpressionAttributeValues: values
        }
    }

    queryParams = (
        tableName: string,
        tKeyValue: string,
        limit?: number,
        startKey?: KeyParams,
        propName?: string,
        propFilters?: KeyParams,
        rangeFilters?: RangeParams,
        selectCount?: boolean
    ) => {
        const expressionAttributeNames: KeyParams = {}
        const expressionAttributeValues: KeyParams = {}
        const filterExpressions = []

        const tKeyAttributeName = `#${this.tenantKey}`
        const tKeyAttributeValue = ':tKey'
        expressionAttributeNames[tKeyAttributeName] = this.tenantKey
        expressionAttributeValues[tKeyAttributeValue] = tKeyValue

        if (propFilters) {
            for (const [prop, value] of Object.entries(propFilters)) {
                const attributeName = `#${prop}`
                const attributeValue = `:${prop}`
                expressionAttributeNames[attributeName] = prop
                expressionAttributeValues[attributeValue] = value
                filterExpressions.push(`${attributeName} = ${attributeValue}`)
            }
        }

        if (rangeFilters) {
            for (const [prop, range] of Object.entries(rangeFilters)) {
                const attributeName = `#${prop}`
                const startValue = `:${prop}Start`
                const endValue = `:${prop}End`
                expressionAttributeNames[attributeName] = prop
                expressionAttributeValues[startValue] = range.start
                expressionAttributeValues[endValue] = range.end
                filterExpressions.push(`${attributeName} BETWEEN ${startValue} AND ${endValue}`)
            }
        }

        const filterExpression = filterExpressions.join(' AND ')

        return {
            TableName: tableName,
            KeyConditionExpression: `${tKeyAttributeName} = ${tKeyAttributeValue}`,
            ...(filterExpression && {
                FilterExpression: filterExpression
            }),
            ExpressionAttributeNames: expressionAttributeNames,
            ExpressionAttributeValues: expressionAttributeValues,
            ...(propName && {
                ProjectionExpression: propName
            }),
            ...(limit && {
                Limit: limit
            }),
            ...(startKey && {
                ExclusiveStartKey: startKey
            }),
            ...(selectCount && {
                Select: 'COUNT'
            })
        }
    }

    getParams = (tableName: string, uKeyValue: string, tKeyValue?: string) => ({
        TableName: tableName,
        Key: tKeyValue ? {
            [this.tenantKey]: tKeyValue,
            [this.uniqueKey]: uKeyValue
        } : {
            [this.uniqueKey]: uKeyValue
        }
    })

    batchGetParams = (tableName: string, uKeyValues: string[], tKeyValue?: string) => ({
        RequestItems: {
            [tableName]: {
                Keys: uKeyValues.map(uKeyValue => ({
                    [this.tenantKey]: tKeyValue,
                    [this.uniqueKey]: uKeyValue
                }))
            }
        }
    })

    batchCreateOrUpdateParams = (tableName: string, list: AttributeMap[]) => ({
        RequestItems: {
            [tableName]: list.map(item => ({
                PutRequest: {
                    Item: item
                }
            }))
        }
    })

    deleteParams = (tableName: string, uKeyValue: string, tKeyValue?: string) => ({
        TableName: tableName,
        Key: {
            [this.tenantKey]: tKeyValue,
            [this.uniqueKey]: uKeyValue
        }
    })

    batchDeleteParams = (tableName: string, uKeyValues: string[], tKeyValue?: string) => ({
        RequestItems: {
            [tableName]: uKeyValues.map(uKeyValue => ({
                DeleteRequest: this.deleteParams(tableName, uKeyValue, tKeyValue)
            }))
        }
    })

    createTablesParams = (tableName: string, hasTKey: boolean) => {
        const keySchema = hasTKey ? [
            {
                AttributeName: this.tenantKey, KeyType: 'HASH'
            },
            {
                AttributeName: this.uniqueKey, KeyType: 'RANGE'
            }
        ] : [
            {
                AttributeName: this.uniqueKey, KeyType: 'HASH'
            }
        ]
        const attributeDefinitions = hasTKey ? [
            {
                AttributeName: this.tenantKey, AttributeType: 'S'
            },
            {
                AttributeName: this.uniqueKey, AttributeType: 'S'
            }
        ] : [
            {
                AttributeName: this.uniqueKey, AttributeType: 'S'
            }
        ]

        return {
            TableName: tableName,
            KeySchema: keySchema,
            AttributeDefinitions: attributeDefinitions,
            ProvisionedThroughput: {
                ReadCapacityUnits: 1,
                WriteCapacityUnits: 1,
            }
        }
    }

    deleteTableParams = (tableName: string) => {
        return {
            TableName: tableName,
        }
    }
}
