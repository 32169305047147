import $ from 'jquery'
import 'jquery-ui-dist/jquery-ui'
import { showErrorStrToast, showToast } from './toastActions'
import { useUIStateActions } from '../hooks/uiHooks'
import { isModalOpened } from '../utils/uiUtils'
import { ModalId } from '../components/modal/types'
import { useNavigate } from 'react-router-dom'

export const useUIActions = () => {
    const navigate = useNavigate()
    const { setOpenedModal } = useUIStateActions()

    const onSubmitDataType = (createMode: boolean, goBack = true) => {
        showToast(createMode ? 'El nuevo registro fue guardado.' : 'El registro fue actualizado.')
        goBack && navigate(-1)
    }

    const onRemoveDataType = () => {
        showErrorStrToast('El registro fue eliminado.')
    }

    const toggleModal = (modalId: string) => {
        setOpenedModal(isModalOpened(modalId) ? undefined : modalId as ModalId)
        $(`#open-${modalId}`).trigger('click')
    }

    const openModal = (modalId: string) => {
        if (!isModalOpened(modalId)) {
            setOpenedModal(modalId as ModalId)
            $(`#open-${modalId}`).trigger('click')
        }
    }

    const closeModal = (modalId: string) => {
        if (isModalOpened(modalId)) {
            setOpenedModal(undefined)
            $(`#open-${modalId}`).trigger('click')
        }
    }

    const closeModalOverlay = () => {
        const overlay = $('.modal-backdrop.fade.show')
        if (overlay.length > 0) {
            overlay.remove()
        }
    }

    return {
        onSubmitDataType,
        onRemoveDataType,
        toggleModal,
        openModal,
        closeModal,
        closeModalOverlay
    }
}
