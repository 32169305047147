import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data } from "../../../../../features/ui"
import { Currency } from "../../../../../tables/types/types"
import { define } from "../../../../../utils/typeUtils"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { useAdministrationState } from "../../../hooks/administrationHooks"
import { Expense, ExpenseStatus } from "../../../state/types"

export const useActions = () => {
    const { currentEmployee } = useAdministrationState()

    const administrationActions = useAdministrationActions()
    
    const submitExpense = async (
        expenseData: Data,
        createMode: boolean,
        code: number,
        branchId: string,
        supplierId?: string,
        detail?: string,
        receiptFile?: File
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const date = define(expenseData.date) as Date
        const expense: Expense = {
            companyId: define(expenseData.companyId) as string,
            id: define(expenseData.id) as string,
            code,
            name: define(expenseData.name) as string,
            expenseTypeId: define(expenseData.expenseTypeId) as string,
            date: date.toISOString(),
            branchId,
            amount: define(expenseData.amount) as number,
            currency: Currency.ARS,
            detail,
            status: expenseData.status as ExpenseStatus || ExpenseStatus.PENDING,
            employeeId: define(currentEmployee).id,
            supplierId
        }
        
        await administrationActions().saveExpense(expense, receiptFile)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitExpense
    }
}
