import { StockAction } from './types'
import { ActionType, UIAction } from './actionTypes'
import { ModalId } from '../components/modal/types'

export type UIState = {
    disableBranchSelector: boolean
    openedModal?: ModalId
    stockAction?: StockAction
    spinnerModalMessage?: string
}

const initialState: UIState = {
    disableBranchSelector: false
}

const reducer = (state = initialState, action: UIAction) => {
    switch(action.type) {
        case ActionType.SET_DISABLE_BRANCH_SELECTOR:
            return {
                ...state,
                disableBranchSelector: action.payload
            }
        case ActionType.SET_OPENED_MODAL:
            return {
                ...state,
                openedModal: action.payload
            }
        case ActionType.SET_STOCK_ACTION:
            return { 
                ...state,
                stockAction: action.payload
            }
        case ActionType.SET_SPINNER_MODAL_MESSAGE:
            return { 
                ...state,
                spinnerModalMessage: action.payload
            }
        default:
            return state
    }
}

export default reducer
