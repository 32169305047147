import { TableName } from "../../../../../tables"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { FieldType, FormField } from "../../../../../features/ui"
import { WizardStep } from "../../../../../features/wizard"
import { SaleOrder } from "../../../state/types"

type Props = {
    wizardKey: string
    stepIndex: number
    sale: SaleOrder
}

export const SaleInfoStep = (props: Props) => {
    const { wizardKey, stepIndex, sale } = props
    const descriptorHooks = useDescriptorHooks()

    const fields: FormField[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: 'Venta',
            render: () => <>{descriptorHooks.sale.code(sale)}</>
        },
        {
            name: 'date',
            type: FieldType.DATE,
            defaultValue: new Date(sale.deliveryDate),
            label: 'Fecha de Entrega',
            focus: true
        },
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            defaultValue: sale.branchId,
            label: 'Sucursal de Entrega'
        }
    ]

    return <WizardStep
        wizardKey={wizardKey}
        stepIndex={stepIndex}
        fields={fields}
    />
}
