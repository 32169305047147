import { Expense } from '../../../modules/administration'
import { PaymentOrder } from '../../../modules/administration/state/types'
import { PurchaseOrder } from '../../../modules/purchases'
import { DataType, TableName } from '../../../tables'
import { define, isDefined } from '../../../utils/typeUtils'

export const fileName = (tableName: TableName, dataType: DataType, label?: string, index?: number) => {
    const partsMap = new Map<TableName, [string, string]>()
        .set(TableName.EXPENSES, ['Gasto', (dataType as Expense).code.toString()])
        .set(TableName.PAYMENT_ORDERS, ['OrdenPago', (dataType as PaymentOrder).code.toString()])
        .set(TableName.PURCHASE_TICKETS, ['FacturaCompra', (dataType as PurchaseOrder).code.toString()])
    
    const parts = define(partsMap.get(tableName))
    return `${label || parts[0]}_${parts[1]}${isDefined(index) ? '_' + index : ''}.pdf`
}

export const fileSubPath = (tableName: TableName, id: string) => {
    if (tableName === TableName.COMPANIES) {
        return 'logo'
    }

    const partsMap = new Map<TableName, [string, string]>()
        .set(TableName.CATEGORIES, ['category', 'image'])
        .set(TableName.EXPENSES, ['expense', 'receipt'])
        .set(TableName.PAYMENT_ORDERS, ['paymentOrder', 'receipt'])
        .set(TableName.PRODUCTS, ['product', 'image'])
        .set(TableName.PURCHASE_TICKETS, ['purchaseTicket', 'ticket'])
    
    const parts = define(partsMap.get(tableName))
    return `${parts[0]}/${id}/${parts[1]}`
}
