import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toListResponse } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useChangedEnumValueMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyIds: string[],
        table: TableName,
        propName: string,
        oldEnumValue: string,
        newEnumValue: string
    ) => {
        companyIds.forEach(async companyId => {
            const { getAll, createOrUpdate } = dynamoActions(DynamoUserApi, companyId)
            const { dbTableName } = define(TablesData.get(table))

            const listResponse = await getAll(dbTableName)
            const { dataTypes } = (toListResponse(listResponse))
            const updatedDataTypes = dataTypes
                .filter(dataType => dataType[propName as keyof DataType] === oldEnumValue)
                .map(dataType => ({
                    ...dataType,
                    [propName]: newEnumValue
                })) as AttributeMap[]
            
            await createOrUpdate(dbTableName, updatedDataTypes)
        })
    }
}
