import './index.css';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './state';
import { Auth0Provider } from '@auth0/auth0-react';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { RouterProvider } from 'react-router-dom';
import { Router } from './features/routes/router';

disableReactDevTools()

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <Auth0Provider
      domain={domain || ''}
      clientId={clientId || ''}
      redirectUri={window.location.origin}
    >
      <RouterProvider router={Router()} />
    </Auth0Provider>
  </Provider>
)

reportWebVitals();
