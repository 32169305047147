import { useState } from "react"
import { FieldType, UIPermission, Action, Column, FieldValue, SpinnerDiv, Data, Badge, useUIActions, ModalId, ButtonKind } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { PurchaseOrder, Supplier } from "../../../state/types"
import { useFind } from "../../../../../state/reducers/hooks"
import { define, toNumber } from "../../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../../features/data-types"
import { DeliveryStatus, PaymentStatus } from "../../../../operations"
import { OpenWizardModal, useWizardActions } from "../../../../../features/wizard"
import { purchaseWizardKey } from "../../../utils/purchaseUtils"
import { Branch } from "../../../../manager"
import { PurchaseFilters } from "./purchaseFilters"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"
import { PurchasePDFModal } from "../purchase-pdf-modal/purchasePDFModal"
import { UploadPurchaseTicketModal } from "../upload-purchase-ticket-modal/uploadPurchaseTicketModal"
import { usePurchasesState } from "../../../hooks/purchasesHooks"

export const PurchaseList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentPurchase, setCurrentPurchase] = useState<PurchaseOrder>()
    const wizardKey = currentPurchase ? purchaseWizardKey(currentPurchase) : undefined

    const { suppliers, purchaseTickets } = usePurchasesState()

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PURCHASES)
    const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_PURCHASE_DELIVERIES)
    const navigate = useNavigate()
    const find = useFind()

    const { goToWizard } = useWizardActions()
    const { toggleModal } = useUIActions()

    const deliveryPath = (purchase?: PurchaseOrder) => purchase ? `${purchase.id}/${RoutePath.DELIVERY}` : '#'
    const findPurchase = (code?: string) => define(find(TableName.PURCHASE_ORDERS, toNumber(code), 'code') as PurchaseOrder)

    const setRowsState = (purchases: PurchaseOrder[]) => {
        setRows(purchases)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        {
            name: 'deliveryBranchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal de Recepción',
            render: (value?: FieldValue) => {
                const branch = define(find(TableName.BRANCHES, value as string)) as Branch
                return <Badge label={branch.name} />
            }
        },
        { name: 'creationDate', type: FieldType.DATE, label: 'Fecha de Creación' },
        { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Recepción' },
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            render: (value?: FieldValue) => {
                const supplier = define(suppliers.find(stateSupplier => stateSupplier.id === value)) as Supplier
                return <Badge label={supplier.name} />
            }
        },
        {
            name: 'deliveryStatus',
            type: FieldType.TEXT,
            label: 'Estado de Entrega',
            render: (value?: FieldValue) => Labels.deliveryStatusLabel(define(value) as DeliveryStatus)
        }
    ]

    const onView = (code?: string) => {
        const purchase = findPurchase(code)
        if (purchase) {
            navigate(purchase.id)
        }
    }

    const onDownloadPDF = (code?: string) => {
        setCurrentPurchase(findPurchase(code))
        toggleModal(ModalId.PURCHASE_PDF)
    }

    const onEdit = (code?: string) => {
        const purchase = findPurchase(code)
        navigate(`${purchase.id}/${RoutePath.EDIT}`)
    }

    const onUploadTicket = (code?: string) => {
        const purchase = findPurchase(code)
        setCurrentPurchase(purchase)
        const tickets = purchaseTickets.filter(purchaseTicket => purchaseTicket.purchaseId === purchase.id)
        if (tickets.length > 0) {
            toggleModal(ModalId.UPLOAD_PURCHASE_TICKET)
        } else {
            navigate(`${purchase.id}/${RoutePath.TICKETS}/${RoutePath.CREATE}`)
        }
    }

    const onDeliver = (code?: string) => {
        const purchase = findPurchase(code)
        setCurrentPurchase(purchase)
        goToWizard(purchaseWizardKey(purchase), deliveryPath(purchase))
    }

    const onArchive = (code?: string) => {
        findPurchase(code)
    }

    const actions = (code?: string) => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView },
            { icon: 'download', kind: 'tertiary', tooltip: 'Descargar', onClick: onDownloadPDF }
        ]
        const purchase = findPurchase(code)
        const isEditable = purchase.deliveryStatus === DeliveryStatus.PENDING && purchase.paymentStatus === PaymentStatus.PENDING
        const isDelivered = purchase.deliveryStatus === DeliveryStatus.DELIVERED
        if (!readOnly) {
            if (isEditable) {
                const editAction = { icon: 'pencil-fill', tooltip: 'Edit', onClick: onEdit }
                actionList.push(editAction)
            }
            const uploadTicketAction = { icon: 'upload', tooltip: 'Subir Factura', onClick: onUploadTicket }
            actionList.push(uploadTicketAction)
            if (isDelivered) {
                const archiveAction = { icon: 'archive', kind: 'secondary' as ButtonKind, tooltip: 'Archivar', onClick: onArchive }
                actionList.push(archiveAction)
            }
        }
        if (hasDeliveryPermission && !isDelivered) {
            const deliverAction = { icon: 'truck', tooltip: 'Registrar Recepción', onClick: onDeliver }
            actionList.push(deliverAction)
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as PurchaseOrder[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <PurchaseFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentPurchase}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
            <PurchasePDFModal purchase={currentPurchase} />
            <UploadPurchaseTicketModal routePathPrefix={currentPurchase?.id} />
            <OpenWizardModal
                wizardKey={wizardKey}
                path={deliveryPath(currentPurchase)}
            />
        </>
    )
}
