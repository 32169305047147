import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../features/ui"
import { TransactionList } from "./transactionList"
import { useAdministrationActions } from "../../actions/administrationActions"

export const TransactionListLoader = () => {
    const [ready, setReady] = useState(false)

    const administrationActions = useAdministrationActions()

    const populate = async () => {
        const { fetchAllCashFunds, fetchAllChecks, fetchAllCreditCards, fetchAllPaymentOrders } = administrationActions()
        await fetchAllCashFunds()
        await fetchAllChecks()
        await fetchAllCreditCards()
        await fetchAllPaymentOrders()
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    return (
        <PanelLoader title="Transacciones" ready={ready} wide>
            <TransactionList />
        </PanelLoader>
    )
}
