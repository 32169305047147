import { TableName } from "../../tables"

export enum Action {
    GET_ALL = 'GetAll',
    GET_BY_PARAMS = 'GetByParams',
    GET_BY_IDS = 'GetByIds',
    SAVE = 'Save',
    REMOVE = 'Remove'
}

export type TableActions = {
    tableName: TableName,
    actions: Action[]
}

export type Permission = {
    tableNames: TableName[],
    hasTKey: boolean,
    actions: Action[]
}
