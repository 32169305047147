import { Dispatch } from 'redux'
import { ActionType, ManagerAction } from './actionTypes'
import { Branch } from './types'

export const setCurrentBranch = (branch: Branch) => {
    return (dispatch: Dispatch<ManagerAction>) => {
        dispatch({
            type: ActionType.SET_CURRENT_BRANCH,
            payload: branch
        })
    }
}

export const updateBranches = (branches: Branch[]) => {
    return (dispatch: Dispatch<ManagerAction>) => {
        dispatch({
            type: ActionType.UPDATE_BRANCHES,
            payload: branches
        })
    }
}

export const removeBranches = (ids: string[]) => {
    return (dispatch: Dispatch<ManagerAction>) => {
        dispatch({
            type: ActionType.REMOVE_BRANCHES,
            payload: ids
        })
    }
}
