import { useMemo, useState } from "react"
import { DataTypeList, useDescriptorHooks } from "../../../../features/data-types"
import { Action, Column, Data, FieldType, FieldValue, SpinnerDiv } from "../../../../features/ui"
import { Transaction, TransactionDestinationType, TransactionSourceType } from "../../state/types"
import { DataType } from "../../../../tables"
import { TransactionFilters } from "./transactionFilters"
import { define } from "../../../../utils/typeUtils"
import { useTransactionHooks } from "../../hooks/transactionHooks"

export const TransactionList = () => {
    const [transactions, setTransactions] = useState<Transaction[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { getSourceTableName, getSource, getDestinationTableName, getDestination } = useTransactionHooks()
    const descriptorHooks = useDescriptorHooks()

    const rows: Data[] = useMemo(() => transactions.map(transaction => {
        return {
            ...transaction,
            sourceId: transaction.id,
            destinationId: transaction.id
        }
    }), [transactions])

    const setRowsState = (transactions: Transaction[]) => {
        setTransactions(transactions)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        {
            name: 'date',
            type: FieldType.DATE,
            label: 'Fecha'
        },
        {
            name: 'sourceId',
            type: FieldType.TABLE,
            label: 'Origen',
            render: (value?: FieldValue) => {
                const transaction = define(transactions.find(transaction => transaction.id === value))
                const tableName = define(getSourceTableName(transaction.sourceType))
                const source = define(getSource(transaction.sourceType, transaction.sourceId))
                const prefix = transaction.sourceType === TransactionSourceType.CHECK ? 'Cheque: ' : ''
                return prefix + descriptorHooks.dataType(tableName, source)
            }
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        },
        {
            name: 'destinationId',
            type: FieldType.TABLE,
            label: 'Destino',
            render: (value?: FieldValue) => {
                const transaction = define(transactions.find(transaction => transaction.id === value))
                const tableName = define(getDestinationTableName(transaction.destinationType))
                const destination = define(getDestination(transaction.destinationType, transaction.destinationId))
                const prefix = transaction.destinationType === TransactionDestinationType.PAYMENT_ORDER ? 'Orden de Pago ' : ''
                return prefix + descriptorHooks.dataType(tableName, destination)
            }
        }
    ]

    const actions = (): Action[] => {
        return [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: () => {} },
        ]
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Transaction[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <TransactionFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    actions={actions}
                    readOnly
                />
            )}
        </>
    )
}
