import { option } from "../../../../features/ui"
import { Customer } from "../../../../modules/sales"
import { DataType, TableName } from "../../../../tables"
import { SearchField } from "../searchField"

type Props = {
    id?: string
    defaultValue?: string
    onChange?: (value?: string) => void
}

export const CustomerSearchField = (props: Props) => {
    const { id = 'customerId', defaultValue, onChange } = props

    const toOption = (dataType: DataType) => {
        const { id, name, identifier } = dataType as Customer
        return option(id, `${name} (${identifier})`)
    }

    const filterFn = (value: string) => (dataType: DataType) => {
        const { name, identifier } = dataType as Customer
        return name.toLowerCase().includes(value) || identifier.toString().includes(value)
    }

    return <SearchField
        id={id}
        tableName={TableName.CUSTOMERS}
        defaultValue={defaultValue}
        placeholder='Buscar por nombre o DNI/CUIT/CUIL'
        toOption={toOption}
        filterFn={filterFn}
        onChange={onChange}
    />
}
