import { Data } from "../../../features/data-types"
import { useFind } from "../../../state/reducers/hooks"
import { TableName } from "../../../tables"
import { define, isDefined, round } from "../../../utils/typeUtils"
import { PaymentType, useAdministrationHooks, useAdministrationState } from "../../administration"
import { Product, ProductPrices, PurchasePricesData, useProductsState } from "../../products"
import { usePurchasesHooks } from "../../purchases"
import { CustomerType, useSalesState } from "../../sales"
import { OperationItem } from "../state/types"
import { usePriceBreakdownHooks } from "./priceBreakdownHooks"
import { SalePriceItem } from "./types"

export const usePricesHooks = () => {
    const { paymentTypes } = useAdministrationState()
    const { productPrices } = useProductsState()
    const { customerTypes } = useSalesState()
    const { getParentSaleYield, getTotalSaleTax } = useAdministrationHooks()
    const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
    const { getSettings } = usePurchasesHooks()
    const find = useFind()

    const getProduct = (id: string) => define(find(TableName.PRODUCTS, id)) as Product

    const multiplier = (): number => {
        const totalSaleTax = getTotalSaleTax()
        const saleTaxYieldCent = totalSaleTax > 0 ? totalSaleTax / 100 : 0
        const paymentTypeDiscounts = paymentTypes.filter(paymentType => paymentType.yield < 0)
        const maxPaymentTypeYieldCent = Math.max.apply(null, paymentTypeDiscounts.map(paymentType => paymentType.yield * -1).concat(0)) / 100
        const customerTypeDiscounts = customerTypes.filter(customerType => customerType.yield < 0)
        const maxCustomerTypeYieldCent = Math.max.apply(null, customerTypeDiscounts.map(customerType => customerType.yield * -1).concat(0)) / 100
    
        return (1 - saleTaxYieldCent) * (1 - maxPaymentTypeYieldCent) * (1 - maxCustomerTypeYieldCent)
    }

    const calculateAdjustmentAmount = (base: number, yieldPercentage: number) => round(base * yieldPercentage / 100)

    const applyAdjustment = (base: number, yieldPercentage: number) => base + calculateAdjustmentAmount(base, yieldPercentage)

    const applyAdjustments = (
        price: number,
        paymentType?: PaymentType,
        customerType?: CustomerType
    ): number => {
        const paymentTypeAdjustedPrice = paymentType ? applyAdjustment(price, paymentType.yield) : price
        const customerTypeAdjustedPrice = customerType ? applyAdjustment(paymentTypeAdjustedPrice, customerType.yield) : paymentTypeAdjustedPrice
        return round(customerTypeAdjustedPrice)
    }

    const adjustment = {
        calculate: calculateAdjustmentAmount,
        apply: applyAdjustment
    }

    const getPurchasePrices = (): PurchasePricesData => {
        const data: PurchasePricesData = {}
        productPrices.forEach(prices => {
            data[prices.productId] = prices.purchasePrice
        })
        return data
    }

    const getProductPrices = (productId: string) => define(find(TableName.PRODUCT_PRICES, productId, 'productId')) as ProductPrices
    
    const getProductPurchasePrice = (productId: string) => getProductPrices(productId).purchasePrice

    const getBasePrice = (purchasePrice: number, saleYieldParam?: number): number => {
        const generalSaleYield = define(getSettings()?.generalSaleYield) as number
        const saleYield = saleYieldParam || generalSaleYield
        const saleYieldMultiplier = 1 + (define(saleYield) / 100)
        return round(purchasePrice * saleYieldMultiplier / multiplier())
    }

    const getSaleYield = (purchasePrice: number, basePrice: number): number => {
        if (purchasePrice === 0) return 0
        const saleYieldCent = (basePrice / purchasePrice) * multiplier() - 1
        return saleYieldCent * 100
    }

    const getProductBasePrice = (productId: string, purchasePrice?: number): number => {
        const productPrices = getProductPrices(productId)
        const price = isDefined(purchasePrice) ? define(purchasePrice) : productPrices.purchasePrice        
        const parentSaleYield = getParentSaleYield(getProduct(productId).categoryId)
        const saleYieldParam = productPrices.saleYield || parentSaleYield
        const saleYieldMultiplier = 1 + (define(saleYieldParam) / 100)
        return round(price * saleYieldMultiplier / multiplier())
    }

    const getProductAdjustedPrice = (
        productId: string,
        paymentType?: PaymentType,
        customerType?: CustomerType
    ): number => {
        const basePrice = getProductBasePrice(productId)
        return applyAdjustments(basePrice, paymentType, customerType)
    }

    const product = {
        purchasePrices: getPurchasePrices,
        purchasePrice: getProductPurchasePrice,
        basePrice: getProductBasePrice,
        adjustedPrice: getProductAdjustedPrice
    }

    const getTotalPrice = (items: OperationItem[]): number => {
        return getPriceBreakdownFromItems(items).total
    }

    const purchase = {
        totalPrice: getTotalPrice
    }

    const getSaleFullPriceFromItems = (items: SalePriceItem[]): number => {
        if (items.length === 0) {
            return 0
        }

        let fullPriceSum = 0
        items.forEach(item => {
            fullPriceSum = fullPriceSum + ((item.price || 0) * item.quantity)
        })

        return round(fullPriceSum)
    }

    const getHoardDeliveredCredit = (
        hoardItems: Data[]
    ): number => {
        const priceItems = hoardItems.map(hoardItem => ({
            price: hoardItem.price as number,
            quantity: hoardItem.quantity as number
        })) as SalePriceItem[]
        
        return getSaleFullPriceFromItems(priceItems)
    }

    const getHoardRemainingCredit = (
        hoardValue: number,
        hoardItems: Data[]
    ): number => {
        return hoardValue - getHoardDeliveredCredit(hoardItems)
    }

    const hoard = {
        deliveredCredit: getHoardDeliveredCredit,
        remainingCredit: getHoardRemainingCredit
    }

    return {
        adjustment,
        basePrice: getBasePrice,
        saleYield: getSaleYield,
        product,
        purchase,
        hoard
    }
}
