import { Permission, toIamPermissions } from "../../../features/roles"
import { define } from "../../../utils/typeUtils"
import { ErrorType } from "../../common"
import { showErrorToast } from "../../../features/ui"
import { PolicyType } from "../../../services/iam"
import { SystemUserName, User } from "../../system"
import { IAMSystemApi, IAMUserApi } from "../../../services"

export const usePermissionsActions = () => {
    const createIAMUser = async (user: User) => {
        const userEmail = define(user.email)
        try {
            await IAMSystemApi.fetchUser(userEmail)
        } catch {
            await IAMSystemApi.createUser(userEmail)
        }
    }

    const assignGroup = async (user: User, groupName: string) => {
        const userEmail = define(user.email)
        try {
            await IAMSystemApi.addUserToGroup(userEmail, groupName)
        } catch (error) {
            showErrorToast(error as ErrorType)
        }
    }

    const savePolicy = async (name: string, permissions: Permission[], companyId: string, create = false) => {
        try {
            if (!create) {
                const versions = await IAMUserApi.getPolicyVersions(name) || []
                if (versions.length === 5) {
                    await IAMUserApi.removePolicyVersion(name, define(versions[4].VersionId))
                }
            }
            IAMUserApi.createOrUpdatePolicy(name, toIamPermissions(permissions), companyId, create)
        } catch (error) {
            showErrorToast(error as ErrorType)
        }
    }

    const savePolicyFromJson = async (name: string, policy: PolicyType, create = false) => {
        try {
            const versions = await IAMUserApi.getPolicyVersions(name) || []
            if (versions.length === 5) {
                await IAMUserApi.removePolicyVersion(name, define(versions[4].VersionId))
            }
            IAMUserApi.createOrUpdatePolicyFromJson(name, policy, create)
        } catch (error) {
            showErrorToast(error as ErrorType)
        }
    }

    const createRole = async (name: string, policy: string) => {
        try {
            await IAMUserApi.createRole(name, SystemUserName)
            await IAMUserApi.addPolicyToRole(name, policy)
        } catch (error) {
            showErrorToast(error as ErrorType)
        }
    }

    const createGroup = (name: string, policies: string[]) => {
        try {
            IAMUserApi.createGroup(name, policies)
        } catch (error) {
            showErrorToast(error as ErrorType)
        }
    }

    const removeIAMUser = async (user: User) => {
        await IAMSystemApi.removeUser(define(user.email))
    }

    return {
        createIAMUser,
        assignGroup,
        savePolicy,
        savePolicyFromJson,
        createRole,
        createGroup,
        removeIAMUser
    }
}
