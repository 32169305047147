import { Modal, ModalId } from "../../../ui"

type Props = {
    title?: string
    children: JSX.Element
}

export const QuickFormModal = (props: Props) => {
    const { title, children } = props
    
    return (
        <Modal
            modalId={ModalId.QUICK_FORM}
            title={title || 'Crear Nuevo'}
            actions={[]}
            widthSize="m"
        >
            {children}
        </Modal>
    )
}
