import { useEffect, useMemo, useState } from "react"
import { EmployeeSearchField } from "../../../../../components/fields/search-field/employee-search-field/employeeSearchField"
import { PanelWrapper, FormField, Data, ModalId, useUIActions, FieldType, Validatable, Spinner, useGetCrumbs } from "../../../../../features/ui"
import { Budget, BudgetItem, Customer, CustomerType } from "../../../state/types"
import { Area, Employee, useAdministrationActions, useAdministrationState } from "../../../../administration"
import { useSalesActions } from "../../../actions/salesActions"
import { TableName } from "../../../../../tables"
import { CustomerSearchField } from "../../../../../components/fields/search-field/customer-search-field/customerSearchField"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"
import { DataTypeForm, useDescriptorHooks } from "../../../../../features/data-types"
import { useSalesHooks } from "../../../hooks/salesHooks"
import { Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Operation, OperationItemFormModal, OperationItemsTable, PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { BudgetPriceList } from "../budget-price-list/budgetPriceList"
import { BudgetPriceItem } from "../../../hooks/types"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { useFind } from "../../../../../state/reducers/hooks"

export const BudgetForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const budget = dataType as Budget | undefined
    const createMode = !budget
    const { currentEmployee } = useAdministrationState()

    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [code, setCode] = useState(budget?.code || 1)
    const [seller, setSeller] = useState<Employee | undefined>(currentEmployee)
    const [customer, setCustomer] = useState<Customer>()
    const [budgetItemsData, setBudgetItemsData] = useState<Data[]>([])
    const [currentBudgetItem, setCurrentBudgetItem] = useState<Data>()
    const [priceItems, setPriceItems] = useState<BudgetPriceItem[]>([])
    const [validations, setValidations] = useState<string[]>([])

    const { getBudgetPricesList } = useSalesHooks()
    const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
    const descriptorHooks = useDescriptorHooks()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()
    const find = useFind()

    const { submitBudget } = useActions()
    const administrationActions = useAdministrationActions()
    const salesActions = useSalesActions()
    const { toggleModal } = useUIActions()

    const setSellerFromId = async (id?: string) => {
        let stateEmployee: Employee | undefined
        if (id) {
            stateEmployee = await administrationActions().fetchEmployee(id) as Employee | undefined
        }
        setSeller(stateEmployee)
    }

    const setCustomerFromId = async (id?: string) => {
        let stateCustomer: Customer | undefined
        if (id) {
            stateCustomer = await salesActions().fetchCustomer(id) as Customer | undefined
        }
        setCustomer(stateCustomer)
    }

    const init = async () => {
        if (createMode) {
            setCode(await generateNextValue(TableName.BUDGETS, 'code'))
        }
        if (budget) {
            setPriceItems(getBudgetPricesList(priceBreakdown.total))
            const stateBudgetItems = await salesActions().fetchBudgetItemsByOrder(budget.id) as BudgetItem[]
            setBudgetItemsData(stateBudgetItems as Data[])
            await setCustomerFromId(budget.customerId)
            await setSellerFromId(budget.sellerId)
        }
        setAsyncDataLoaded(true)
    }

    useEffect(() => {
        init()
    }, [])

    const priceBreakdown = useMemo(() => {
        const customerType = find(TableName.CUSTOMER_TYPES, customer?.customerTypeId) as CustomerType | undefined
        return getPriceBreakdownFromItems(budgetItemsData, customerType?.yield)
    }, [budgetItemsData, customer])

    useEffect(() => {
        setPriceItems(getBudgetPricesList(priceBreakdown.total))
    }, [priceBreakdown])

    const title = `${createMode ? 'Crear' : 'Editar'} Presupuesto`
    
    const crumbs = useGetCrumbs(
        'Presupuestos',
        RoutePath.BUDGETS,
        budget ? descriptorHooks.budget.code(budget) : undefined,
        budget?.id,
        createMode ? 'Crear' : 'Editar'
    )

    const filterProducts = (product: Product) =>
        currentBudgetItem?.productId === product.id || !budgetItemsData.map(budgetItemData => budgetItemData.productId).includes(product.id)

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const onCreateBudgetItem = () => {
        setCurrentBudgetItem(undefined)
        setValidations([])
        openFormModal()
    }

    const onEditBudgetItem = (productId: string) => {
        setCurrentBudgetItem(budgetItemsData.find(budgetItemData => budgetItemData.productId === productId))
        openFormModal()
    }

    const onRemoveBudgetItem = (productId: string) => {
        const newBudgetItemsData = budgetItemsData.filter(budgetItemData => budgetItemData.productId !== productId)
        setBudgetItemsData(newBudgetItemsData)
    }

    const fields: FormField[] = [
        {
            name: 'date',
            type: FieldType.DATE,
            label: 'Fecha',
            defaultValue: new Date(),
            disabled: true
        },
        {
            name: 'sellerId',
            type: FieldType.TABLE,
            label: 'Vendedor',
            render: () => renderAsync(
                <EmployeeSearchField
                    id='sellerId'
                    defaultValue={seller?.id}
                    areas={[Area.MANAGMENT, Area.SALES]}
                    focus
                    onChange={setSellerFromId}
                />
            )
        },
        {
            name: 'customerId',
            type: FieldType.TABLE,
            label: 'Cliente',
            render: () => renderAsync(
                <CustomerSearchField
                    id='customerId'
                    defaultValue={customer?.id}
                    onChange={setCustomerFromId}
                />
            )
        },
        {
            name: 'budgetItems',
            type: FieldType.TABLE,
            table: TableName.BUDGET_ITEMS,
            render: () => renderAsync(
                <Validatable validations={validations}>
                    <OperationItemsTable
                        rows={budgetItemsData}
                        operation={Operation.SALE}
                        onCreate={onCreateBudgetItem}
                        onEditRow={onEditBudgetItem}
                        onRemoveRow={onRemoveBudgetItem}
                    />
                </Validatable>
            )
        },
        {
            name: 'priceBreakdown',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceBreakdown data={priceBreakdown} />
        }
    ]

    if (priceItems.length > 0 && priceBreakdown.total !== 0) {
        fields.push({
            name: 'priceList',
            type: FieldType.TABLE,
            label: 'Medios de Pago',
            render: () => renderAsync(
                <BudgetPriceList priceItems={priceItems} collapsable />
            )
        })
    }

    const validate = (): boolean => {
        if (budgetItemsData.length === 0) {
            setValidations(['Debe agregar al menos 1 producto'])
            return false
        }

        return true
    }

    const onSubmit = (budgetData: Data) => {
        if (validate()) {
            submitBudget(
                budgetData,
                code,
                define(seller).id,
                define(customer).id,
                budgetItemsData,
                createMode
            )
        }
    }

    const onCancel = () => navigate(-1)

    const onSaveBudgetItem = (newData: Data) => {
        const newBudgetItemsData = currentBudgetItem ?
            budgetItemsData.map(budgetItemData => budgetItemData.productId === currentBudgetItem.productId ? newData : budgetItemData) :
            [newData, ...budgetItemsData]
        setBudgetItemsData(newBudgetItemsData)
    }

    return (
        <PanelWrapper title={title} crumbs={crumbs} wide>
            <DataTypeForm
               formId="budget-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentBudgetItem}
                operation={Operation.SALE}
                filterProducts={filterProducts}
                onSubmit={onSaveBudgetItem}
            />
        </PanelWrapper>
    )
}
