import { BaseCallback, BaseOutput, ClientBatchWriteCallback, CountCallback, ItemCallback, KeyParams, ListCallback, ListOutput, PaginatedListCallback } from "./types"

export class CallbackProvider {
    paginatedListCallback = (callback?: PaginatedListCallback) =>
        (error: AWS.AWSError, data: ListOutput): void => {
            if (error) {
                throw error
            } else if (data && callback) {
                const response = {
                    Items: data.Items || [],
                    LastEvaluatedKey: data.LastEvaluatedKey as KeyParams | undefined
                }
                callback(response)
            }
        }

    itemCallback = (callback?: ItemCallback) =>
        (error: AWS.AWSError, data: AWS.DynamoDB.GetItemOutput): void => {
            if (error) {
                throw error
            } else if (data && callback) {
                callback(data.Item)
            }
        }

    listCallback = (callback?: ListCallback) =>
        (error: AWS.AWSError, data: ListOutput): void => {
            if (error) {
                throw error
            } else if (data && callback) {
                callback(data.Items || [])
            }
        }

    listResponsesCallback = (tableName: string, callback?: ListCallback) =>
        (error: AWS.AWSError, data: AWS.DynamoDB.BatchGetItemOutput): void => {
            if (error) {
                throw error
            } else if (data && data.Responses && callback) {
                callback(data.Responses[tableName])
            }
        }

    countCallback = (callback?: CountCallback) =>
        (error: AWS.AWSError, data: ListOutput): void => {
            if (error) {
                throw error
            } else if (data && callback) {
                callback(data.Count || 0)
            }
        }

    // TODO si cualquier llamado a dynamo falla, la excepcion queda en la nada
    //  probar que falle y que llegue el error al llamado para eventualmente manejar el error
    baseCallback = (callback?: BaseCallback) =>
        (error: AWS.AWSError, data: BaseOutput): void => {
            if (error) {
                throw error
            } else if (data && callback) {
                callback()
            }
        }

    crudMultipleCallback = (callback?: ClientBatchWriteCallback) =>
        (error: AWS.AWSError, data: AWS.DynamoDB.BatchWriteItemOutput): void => {
            if (error) {
                throw error
            } else if (data && callback) {
                callback(data.UnprocessedItems || {})
            }
        }
}
