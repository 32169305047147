import { newId } from ".."
import { Branch } from "../../../modules/manager"

export const DefaultBranchCode = '1'

export const branches = (companyId: string): Branch[] => [
    {
        companyId,
        id: newId(),
        code: DefaultBranchCode,
        name: 'Sucursal Principal'
    }
]
