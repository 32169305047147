import { Data, newId } from "../../../../features/data-types"
import { Currency } from "../../../../tables/types/types"
import { define } from "../../../../utils/typeUtils"
import { DeliveredItem, OrderDelivery, DeliveryStatus, useOperationsActions, usePricesHooks } from "../../../operations"
import { useStockActions } from "../../../stock"
import { useSystemStateHooks } from "../../../system"
import { HoardItem, HoardOrder } from "../../state/types"
import { useCommonActions } from "./commonActions"

export const useFinancialHoardActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const pricesHooks = usePricesHooks()

    const commonActions = useCommonActions()
    const operationsActions = useOperationsActions()
    const stockActions = useStockActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId

        const saveFinancialHoardDelivery = async (
            hoard: HoardOrder,
            hoardItemsData: Data[],
            delivery: OrderDelivery
        ) => {
            const { fetchHoardItemsByOrder, saveHoard, saveHoardItems } = commonActions(define(companyId))
            const { saveDelivery } = operationsActions()

            const hoardItems = hoardItemsData.map(hoardItemData => ({
                ...hoardItemData,
                companyId: define(companyId),
                id: newId(),
                hoardId: hoard.id,
                currency: Currency.ARS
            })) as HoardItem[]

            const savedHoardItems = await saveHoardItems(hoardItems) as HoardItem[]

            const deliveredItems: DeliveredItem[] = savedHoardItems.map(hoardItem => ({
                itemId: hoardItem.id,
                delivered: hoardItem.quantity
            }))
            
            const newDelivery: OrderDelivery = {
                ...delivery,
                deliveredItems
            }

            const savedDelivery = await saveDelivery(newDelivery) as OrderDelivery
            const currentHoardItems = await fetchHoardItemsByOrder(hoard.id) as HoardItem[]
            
            const remainingCredit = pricesHooks.hoard.remainingCredit(define(hoard.value), currentHoardItems)
            const updatedHoard: HoardOrder = {
                ...hoard,
                deliveryStatus: remainingCredit > 0 ? DeliveryStatus.PARTIALLY_DELIVERED : DeliveryStatus.DELIVERED
            }

            const savedHoard = await saveHoard(updatedHoard) as HoardOrder

            savedDelivery.deliveredItems.forEach(deliveredItem => {
                const hoardItem = define(hoardItems.find(hoardItem => hoardItem.id === deliveredItem.itemId))
                stockActions().decreaseStock(hoardItem.productId, savedDelivery.branchId, deliveredItem.delivered, hoardItem.lotId)
            })

            return savedHoard
        }

        return {
            saveFinancialHoardDelivery
        }
    }
}
