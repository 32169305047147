import { useDefaultModuleActions } from "../../common"
import { DynamoUserApi } from "../../../services"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { Transaction } from "../state/types"

export const useTransactionActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchFiltered, count, save } = defaultModuleActions(DynamoUserApi, define(companyId))

        const fetchTransactions = (dateFrom: Date, dateTo: Date) => {
            const rangeFilters = {
                date: {
                    start: dateFrom.toISOString(),
                    end: dateTo.toISOString()
                }
            }
            return fetchFiltered(TableName.TRANSACTIONS, undefined, rangeFilters)
        }

        const countTransactions = (propName: string, propValue: string) => {
            return count(TableName.TRANSACTIONS, { [propName]: propValue })
        }

        const saveTransaction = (transaction: Transaction) => {
            return save(TableName.TRANSACTIONS, transaction)
        }

        return {
            fetchTransactions,
            countTransactions,
            saveTransaction
        }
    }
}
