import styles from './button.module.css'
import { useEffect, useRef } from 'react'
import { ButtonKind } from './types'

type ButtonType = 'submit' | 'reset' | 'button'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string
    kind?: ButtonKind
    type?: ButtonType
    icon?: string
    rightMargin?: boolean
    disabled?: boolean
    className?: string
    small?: boolean
    tooltip?: string
    narrowTooltip?: boolean
    focus?: boolean
    focusable?: boolean
    onClick?: () => void
}

export const Button = (props: Props) => {
    const { label, kind, type, icon, rightMargin, disabled, className, small, tooltip, narrowTooltip, focus, focusable = true, onClick, ...rest } = props

    const inputRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        focus && inputRef && inputRef.current?.focus()
    }, [focus])

    const rightMarginClass = small ? styles.smallRightMargin : styles.rightMargin
    const marginClass = rightMargin ? rightMarginClass : ''
    const mainClass = small ? styles.small : styles.btn
    
    const getKindClass = () => {
        switch(kind) {
            case 'naked':
                return styles.btnNaked
            case 'quaternary':
                return styles.btnQuaternary
            case 'tertiary':
                return styles.btnTertiary
            case 'secondary':
                return styles.btnSecondary
            case 'primary':
            default:
                return styles.btnPrimary
        }
    }
    const labelClass = label ? (small ? styles.smallRightMargin : styles.rightMargin) : ''
    const propClass = className || ''

    const tooltipVaue = narrowTooltip ?
        tooltip?.split(' ').map((word, index) => <div key={index}>{word} <br /></div>) :
        tooltip

    return (
        <div className={`${styles.container} ${marginClass}`}>
            <button
                type={type || 'button'}
                onClick={onClick}
                className={`btn ${mainClass} ${getKindClass()} ${propClass}`}
                disabled={disabled}
                tabIndex={focusable ? 0 : -1}
                ref={inputRef}
                {...rest}
            >
                {icon && <i className={`bi bi-${icon} ${labelClass}`}></i>}
                {label}
            </button>
            {tooltip && (
                <div className={styles.tooltip}>
                    {tooltipVaue}
                </div>
            )}
        </div>
    )
}
