import { useDefaultModuleActions } from "../../common"
import { DynamoUserApi } from "../../../services"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { ProductPrices } from "../state/types"

export const useProductPricesActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, fetchByParams, saveMultiple, remove } = defaultModuleActions(DynamoUserApi, define(companyId))

        const fetchAllProductPrices = async () => {
            return fetchAll(TableName.PRODUCT_PRICES)
        }

        const fetchProductPrices = (productId: string) => {
            return fetchByParams(TableName.PRODUCT_PRICES, { productId })
        }

        const saveProductPrices = (productPricesList: ProductPrices[]) => {
            return saveMultiple(TableName.PRODUCT_PRICES, productPricesList)
        }

        const removeProductPrices = (id: string) => {
            return remove(TableName.PRODUCT_PRICES, id)
        }

        return {
            fetchAllProductPrices,
            fetchProductPrices,
            saveProductPrices,
            removeProductPrices
        }
    }
}
