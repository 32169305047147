import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { FieldType, FieldValue, option, FilterField, Filters } from '../../../../../features/ui'
import { useAdministrationState } from '../../../../administration'
import { useSalesHooks, useSalesState } from '../../../hooks/salesHooks'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const BudgetFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { budgets, customers } = useSalesState()
    const { employees } = useAdministrationState()

    const [searchText, setSearchText] = useState<string>()
    const [sellerId, setSellerId] = useState<string>()
    const [customerId, setCustomerId] = useState<string>()
    const [dateFrom, setDateFrom] = useState<Date>()
    const [dateTo, setDateTo] = useState<Date>()

    const { searchBudgets } = useSalesHooks()

    useEffect(() => search(searchText, sellerId, customerId, dateFrom, dateTo), [budgets])

    const search = (
        searchTextParam?: string,
        sellerIdParam?: string,
        customerIdParam?: string,
        dateFromParam?: Date,
        dateToParam?: Date
    ) => {
        onSearchStart()
        let budgetList = budgets.filter(budget => !sellerIdParam || budget.sellerId === sellerIdParam)
        budgetList = budgetList.filter(budget => !customerIdParam || budget.customerId === customerIdParam)
        budgetList = dateFromParam ? budgetList.filter(budget => new Date(budget.date) >= dateFromParam) : budgetList
        budgetList = dateToParam ? budgetList.filter(budget => new Date(budget.date) <= dateToParam) : budgetList
        budgetList = searchBudgets(budgetList, searchTextParam)
        setTimeout(() => onSearchDone(budgetList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(newSearchText, sellerId, customerId, dateFrom, dateTo)
    }

    const onChangeSeller = (value?: FieldValue) => {
        const newSellerId = value as string | undefined
        if (newSellerId !== sellerId) {
            setSellerId(newSellerId)
            search(searchText, newSellerId, customerId, dateFrom, dateTo)
        }
    }

    const onChangeCustomer = (value?: FieldValue) => {
        const newCustomerId = value as string | undefined
        if (newCustomerId !== customerId) {
            setCustomerId(newCustomerId)
            search(searchText, sellerId, newCustomerId, dateFrom, dateTo)
        }
    }

    const onChangeDateFrom = (value?: FieldValue) => {
        const newDateFrom = value as Date | undefined
        newDateFrom?.setHours(11, 59, 59)
        if (newDateFrom !== dateFrom) {
            setDateFrom(newDateFrom)
            search(searchText, sellerId, customerId, newDateFrom, dateTo)
        }
    }
    
    const onChangeDateTo = (value?: FieldValue) => {
        const newDateTo = value as Date | undefined
        newDateTo?.setHours(11, 59, 59)
        if (newDateTo !== dateTo) {
            setDateTo(newDateTo)
            search(searchText, sellerId, customerId, dateFrom, newDateTo)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por código, vendedor o cliente',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'seller',
            type: FieldType.SELECT,
            options: employees.map(employee => option(employee.id, employee.name)),
            label: 'Vendedor',
            space: 3,
            onChange: onChangeSeller
        },
        {
            name: 'customer',
            type: FieldType.SELECT,
            options: customers.map(customer => option(customer.id, customer.name)),
            label: 'Cliente',
            space: 3,
            onChange: onChangeCustomer
        },
        {
            name: 'dateFrom',
            type: FieldType.DATE,
            label: 'Fecha: Desde',
            space: 2,
            onChange: onChangeDateFrom
        },
        {
            name: 'dateTo',
            type: FieldType.DATE,
            label: 'Fecha: Hasta',
            space: 2,
            onChange: onChangeDateTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
