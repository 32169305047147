import { useNavigate } from "react-router-dom"
import { Data, DataTypeForm, Labels } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { FieldType, FormField, option, useGetCrumbs, PanelWrapper } from "../../../../../features/ui"
import { Period, Tax, TaxBase, TaxType } from "../../../state/types"
import { useActions } from "./actions"

export const TaxForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const tax = dataType as Tax | undefined
    const createMode = !tax

    const navigate = useNavigate()

    const { submitTax } = useActions()

    const taxLabel = tax ? `: ${tax.name}` : undefined
    const title = `${createMode ? 'Crear' : 'Editar'} Impuesto${taxLabel || ''}`

    const crumbs = useGetCrumbs('Impuestos', RoutePath.TAXES, tax?.name, tax?.id, createMode ? 'Crear' : 'Editar')

    const fields: FormField[] = [
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
        {
            name: 'type',
            type: FieldType.SELECT,
            label: 'Tipo',
            defaultValue: TaxType.SALE_TAX,
            options: Object.values(TaxType).map(type => option(type, Labels.taxTypeLabel(type))),
        },
        { name: 'code', type: FieldType.TEXT, label: 'Código' },
        { name: 'legalCode', type: FieldType.TEXT, label: 'Código AFIP' },
        {
            name: 'nonTaxableMin',
            type: FieldType.PRICE,
            label: 'Mínimo No Imponible',
            defaultValue: 0
        },
        {
            name: 'taxBase',
            type: FieldType.SELECT,
            label: 'Base Imponible',
            defaultValue: TaxBase.PRICE_WITH_VAT,
            options: Object.values(TaxBase).map(taxBase => option(taxBase, Labels.taxBaseLabel(taxBase))),
            optional: true,
        },
        {
            name: 'nonTaxablePeriod',
            type: FieldType.SELECT,
            label: 'Período No Imponible',
            options: Object.values(Period).map(period => option(period, Labels.periodLabel(period))),
            optional: true
        },
        { name: 'yield', type: FieldType.PERCENTAGE, label: 'Porcentaje' },
        { name: 'unregisteredYield', type: FieldType.PERCENTAGE, label: 'Porcentaje No Registrado', optional: true }
    ]

    const onSubmit = (taxData: Data) => submitTax(taxData, createMode)

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="tax-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
