import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { FieldType, FieldValue, FilterField, Filters, option } from '../../../../../features/ui'
import { TaxType, useAdministrationHooks, useAdministrationState } from '../../..'
import { Labels } from '../../../../../features/data-types'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const TaxFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { taxes } = useAdministrationState()

    const [searchText, setSearchText] = useState<string>()
    const [type, setType] = useState<TaxType>()

    const { searchTaxes } = useAdministrationHooks()

    useEffect(() => search(searchText, type), [taxes])

    const search = (
        searchTextParam?: string,
        typeParam?: TaxType
    ) => {
        onSearchStart()
        let taxList = taxes.filter(tax => !typeParam || tax.type === typeParam)
        taxList = searchTaxes(taxList, searchTextParam)
        setTimeout(() => onSearchDone(taxList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(newSearchText, type)
    }

    const onChangeType = (value?: FieldValue) => {
        const newType = value as TaxType | undefined
        if (newType !== type) {
            setType(newType)
            search(searchText, newType)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por tipo, nombre o código',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'type',
            type: FieldType.SELECT,
            options: Object.values(TaxType).map(type => option(type, Labels.taxTypeLabel(type))),
            label: 'Tipo',
            space: 3,
            onChange: onChangeType
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
