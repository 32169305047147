import { useState } from "react"
import { DataTypeList, useDataTypesHooks } from "../../../../../features/data-types"
import { PanelWrapper, Column, Data, FieldType, FieldValue, SpinnerDiv, UIPermission } from "../../../../../features/ui"
import { useSystemStateHooks } from "../../../../system"
import { DataType } from "../../../../../tables"
import { CustomerType } from "../../../state/types"
import { CustomerTypeFilters } from "./customerTypeFilters"

export const CustomerTypeList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { formatValue } = useDataTypesHooks()
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_CUSTOMER_TYPES)

    const columns: Column[] = [
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
        {
            name: 'yield',
            type: FieldType.PERCENTAGE,
            label: 'Ajuste',
            render: (value?: FieldValue) => {
                const paymentTypeYield = value as number
                const absYield = Math.abs(paymentTypeYield)
                return <>{formatValue(absYield, FieldType.NUMBER)}% de {paymentTypeYield <= 0 ? 'Descuento' : 'Recargo'}</>
            }
        }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as CustomerType[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Tipos de Cliente">
            <CustomerTypeFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                    minItems={1}
                    showDetail={false}
                />
            )}
        </PanelWrapper>
    )
}
