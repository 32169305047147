import { Action, TableActions, readPermissions } from "../../../features/roles";
import { TableName } from "../../../tables";

export const productsPermissions = (): TableActions[] => readPermissions().concat([
    { tableName: TableName.CATEGORIES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.MEASURES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PRODUCTS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PRODUCT_PRICES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.STOCK, actions: [Action.SAVE, Action.REMOVE] }
])
