import { useDefaultModuleActions } from "../../common"
import { TextParams } from "../../../state/reducers/types"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { Category, Lot, Measure, Product, ProductPrices } from "../state/types"
import { DataType } from "../../../tables/types/types"
import { Stock, useStockActions } from "../../stock"
import { useSystemStateHooks } from "../../system"
import { Branch, useManagerState } from "../../manager"
import { useProductPricesActions } from "./productPricesActions"
import { DynamoUserApi } from "../../../services"
import { useLotsActions } from "./lotsActions"
import { useChildrenActions, useDataTypeFileActions, RemoveChildrenFn, SaveChildrenFn, newId } from "../../../features/data-types"
import { Data } from "../../../features/ui"
import { NO_LOT } from "../utils/lotUtils"
import { firstItem } from "../../../utils/listUtils"

export const useProductsActions = () => {
    const { branches } = useManagerState()
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const productPricesActions = useProductPricesActions()
    const lotsActions = useLotsActions()
    const stockActions = useStockActions()
    const { submitChildren } = useChildrenActions()
    const dataTypeFileActions = useDataTypeFileActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const { fetchAll, fetchByParams, fetchMultipleByParams, fetchByIds, count, saveMultiple, removeMultiple } = defaultModuleActions(DynamoUserApi, companyId)
        const { fetchAllProductPrices, fetchProductPrices, saveProductPrices, removeProductPrices } = productPricesActions(companyId)
        const { fetchAllLots, fetchLot, fetchLots, saveLot, removeLot } = lotsActions(companyId)
    
        const fetchAllProducts = (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.PRODUCTS, limit, startKey)
        }

        const fetchProduct = (id: string) => {
            return fetchByParams(TableName.PRODUCTS, { id })
        }

        const fetchProductsByIds = (ids: string[]) => {
            return fetchByIds(TableName.PRODUCTS, ids)
        }

        const countProducts = (propName: string, propValue: string) => {
            return count(TableName.PRODUCTS, { [propName]: propValue })
        }
        
        const saveProduct = async (
            product: Product,
            pricesData: Data,
            measuresData: Data[],
            imageFile?: File
        ) => {
            const definedCompanyId = define(companyId)
            const { fetchProductStock, saveStock } = stockActions(definedCompanyId)

            const { saveWithImage } = dataTypeFileActions(definedCompanyId)
            const newProduct: Product = await saveWithImage(TableName.PRODUCTS, product, 'imageUrl' as keyof DataType, imageFile) as Product

            const measures = measuresData.map(measureData => {
                return (measureData.id ? measureData : {
                    ...measureData,
                    companyId: define(companyId),
                    id: newId(),
                    productId: product.id
                }) as Measure
            })

            const stateMeasures = await fetchMeasures(product.id) as Measure[]
            await submitChildren(stateMeasures, measures, saveMeasures as SaveChildrenFn, removeMeasures as RemoveChildrenFn)

            const statePrices = await fetchProductPrices(product.id) as ProductPrices | null
            const purchasePriceChanged = statePrices?.purchasePrice !== pricesData.purchasePrice
            const saleYieldChanged = statePrices?.saleYield !== pricesData.saleYield
            let currency = statePrices?.currency

            if (!statePrices || (purchasePriceChanged || saleYieldChanged)) {
                const productPrices = (pricesData.id ? pricesData : {
                    companyId: definedCompanyId,
                    id: statePrices?.id || newId(),
                    productId: product.id,
                    ...pricesData
                }) as ProductPrices
                
                const productPricesList: ProductPrices[] = await saveProductPrices([productPrices]) as ProductPrices[]
                currency = firstItem(productPricesList)?.currency
            }
            
            const stateStockList: Stock[] = await fetchProductStock(product.id) as Stock[]
            const isNewProduct = stateStockList.length === 0
            if (isNewProduct) {
                const baseLot: Lot = {
                    companyId: definedCompanyId,
                    id: newId(),
                    code: NO_LOT,
                    productId: product.id
                }

                const lot = await saveLot(baseLot) as Lot
                
                let stateBranches = branches
                if (stateBranches.length === 0) {
                    stateBranches = (await fetchAll(TableName.BRANCHES)).dataTypes as Branch[]
                }
                for (const branch of stateBranches) {
                    const stock: Stock = {
                        companyId: definedCompanyId,
                        id: newId(),
                        productId: product.id,
                        lotId: lot.id,
                        availableUnits: 0,
                        reservedUnits: 0,
                        currency: define(currency),
                        branchId: branch.id
                    }
                    await saveStock(stock)
                }
            }
            
            return newProduct
        }

        const removeProduct = async (id: string) => {
            const definedCompanyId = define(companyId)
            const { fetchProductStock, removeStock } = stockActions(definedCompanyId)    

            const { removeWithImage } = dataTypeFileActions(definedCompanyId)
            await removeWithImage(TableName.PRODUCTS, id, 'imageUrl' as keyof DataType)
            
            const measures = await fetchMeasures(id) as Measure[]
            await removeMeasures(measures)
            
            const pricesDT = await fetchProductPrices(id)
            if (pricesDT) {
                await removeProductPrices(pricesDT.id)
            }

            const stockList = await fetchProductStock(id) as Stock[]
            stockList.forEach(async stock => await removeStock(stock.id))
            
            const lots = await fetchLots(id) as Lot[]
            lots.forEach(async lot => await removeLot(lot.id))
        }

        const fetchAllCategories = async () => {
            return fetchAll(TableName.CATEGORIES).then(response => response.dataTypes as Category[])
        }

        const saveCategory = (
            category: Category,
            imageFile?: File
        ) => {
            const { saveWithImage } = dataTypeFileActions(define(companyId))
            return saveWithImage(TableName.CATEGORIES, category, 'imageUrl' as keyof DataType, imageFile)
        }

        const removeCategory = (id: string) => {
            const { removeWithImage } = dataTypeFileActions(define(companyId))
            return removeWithImage(TableName.CATEGORIES, id, 'imageUrl' as keyof DataType)
        }

        const fetchAllMeasures = async () => {
            return fetchAll(TableName.MEASURES)
        }

        const fetchMeasures = (productId: string) => {
            return fetchMultipleByParams(TableName.MEASURES, { productId })
        }

        const saveMeasures = (measures: Measure[]) => {
            return saveMultiple(TableName.MEASURES, measures)
        }
        
        const removeMeasures = (measures: Measure[]) => {
            return removeMultiple(TableName.MEASURES, measures.map(measure => measure.id))
        }

        return {
            fetchAllProducts,
            fetchProduct,
            fetchProductsByIds,
            countProducts,
            saveProduct,
            removeProduct,
            fetchAllCategories,
            saveCategory,
            removeCategory,
            fetchAllProductPrices,
            fetchProductPrices,
            saveProductPrices,
            removeProductPrices,
            fetchAllMeasures,
            fetchMeasures,
            fetchAllLots,
            fetchLot,
            fetchLots,
            saveLot,
            removeLot
        }
    }
}
