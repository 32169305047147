import styles from './fieldList.module.css'
import { FieldRow } from '../field-row/fieldRow'
import { Field } from '../fields/field/field'
import { FormField, FieldValue } from '../types'

type Props = {
    fields: FormField[]
    onChange?: (field: FormField, value?: FieldValue) => void
}

// TODO ver si puedo usar esto en form
export const FieldList = (props: Props) => {
    const { fields, onChange } = props
    
    const handleChange = (field: FormField) => (value?: FieldValue) => {
        onChange && onChange(field, value)
    }

    return (
        <div className={styles.container}>
            {fields.map(field => (
                <FieldRow
                    key={field.name}
                    id={field.name}
                    label={field.label}
                    hint={field.hint}
                >
                    {field.render ? field.render() : (
                        <Field field={field} onChange={handleChange(field)} />
                    )}
                </FieldRow>
            ))}
        </div>
    )
}
