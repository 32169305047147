import styles from './styledText.module.css'
import { normalize } from '../../../../utils/typeUtils'

type Props = {
    text: string
    highlighted: string
    className?: string
    onClick?: (e: React.MouseEvent<HTMLLabelElement>) => void
}

export const StyledText = (props: Props) => {
    const { text, highlighted = '', className, onClick } = props
    
    const normalizedText = normalize(text)
    const normalizedHighlighted = normalize(highlighted)
    const startIndex = normalizedText.indexOf(normalizedHighlighted)
    const endIndex = startIndex > -1 ? startIndex + highlighted.length : -1
    const preMatch = text.substring(0, startIndex)
    const match = text.substring(startIndex, endIndex)
    const postMatch = text.substring(endIndex)

    return (
        <label onClick={onClick} className={className}>
            {preMatch}
            <span className={styles.highlighted}>{match}</span>
            {postMatch}
        </label>
    )
}
