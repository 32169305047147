import { useNavigate } from "react-router-dom"
import { newId, useDescriptorHooks } from "../../../../../features/data-types"
import { Data, showToast } from "../../../../../features/ui"
import { useWizardActions, useWizardHooks } from "../../../../../features/wizard"
import { useFind, useFindMultipleByParams } from "../../../../../state/reducers/hooks"
import { TableName } from "../../../../../tables"
import { define, isDefined } from "../../../../../utils/typeUtils"
import { DeliveredItem, OrderDelivery, useOperationsHooks } from "../../../../operations"
import { Lot, Product } from "../../../../products"
import { useSalesActions } from "../../../actions/salesActions"
import { HoardOrder } from "../../../state/types"
import { useSalesUIHooks } from "../../salesUiHooks"
import { HoardItemData } from "./types"

export const useActions = () => {
    const { getPendingQuantity } = useOperationsHooks()
    const descriptorHooks = useDescriptorHooks()
    const { getStepData } = useWizardHooks()
    const { validateItemsData, validateStock } = useSalesUIHooks()
    const navigate = useNavigate()
    const find = useFind()
    const findMultipleByParams = useFindMultipleByParams()

    const { clearData } = useWizardActions()
    const salesActions = useSalesActions()

    const findProduct = (id: string) => define(find(TableName.PRODUCTS, id) as Product)

    const findLot = (id: string) => define(find(TableName.LOTS, id)) as Lot

    const onFinishDelivery = (
        wizardKey: string,
        hoard: HoardOrder
    ) => {
        showToast(`La entrega del Acopio ${descriptorHooks.hoard.code(hoard)} fue registrada.`)
        clearData(wizardKey)
        navigate(-1)
    }

    const getBranchId = (wizardKey: string, hoard: HoardOrder): string => {
        const infoStepData = getStepData(wizardKey, 0)
        return (infoStepData.branchId || hoard.branchId) as string
    }

    const validateFinancialHoardDelivery = async (
        hoardItemsData: Data[],
        branchId: string
    ) => {
        return validateItemsData(hoardItemsData, define(branchId))
    }

    const finishFinancialHoardDelivery = async (
        wizardKey: string,
        hoard: HoardOrder
    ) => {
        const infoStepData = getStepData(wizardKey, 0)
        const companyId = hoard.companyId as string

        const date = infoStepData.date ? new Date(infoStepData.date) : new Date()
        const branchId = infoStepData.branchId || hoard.branchId

        const selectItemsStepData = getStepData(wizardKey, 1)
        const hoardItemsData: Data[] = JSON.parse(selectItemsStepData.hoardItemsData)

        const delivery: OrderDelivery = {
            companyId,
            id: newId(),
            orderId: hoard.id,
            date: date.toISOString(),
            deliveredItems: [],
            branchId
        }

        await salesActions().saveHoardDelivery(hoard, hoardItemsData, delivery)
        
        onFinishDelivery(wizardKey, hoard)
    }

    const getItemsData = (
        wizardKey: string,
        hoard: HoardOrder,
        hoardItemsData: HoardItemData[]
    ): Data[] => {
        const deliveries = findMultipleByParams(TableName.ORDER_DELIVERIES, { orderId: hoard.id }) as OrderDelivery[]

        return hoardItemsData.map(hoardItemData => {
            const { stepIndex, hoardItem } = hoardItemData
            const itemStepData = getStepData(wizardKey, stepIndex)
            const deliveredQuantity = itemStepData.deliveredQuantity as number | undefined
            const pendingQuantity = getPendingQuantity(hoardItem, deliveries)
            const delivered = (isDefined(deliveredQuantity) ? deliveredQuantity : pendingQuantity) as number

            return {
                ...hoardItem,
                quantity: delivered
            }
        })
    }

    const validateProductHoardDelivery = async (
        wizardKey: string,
        hoard: HoardOrder,
        hoardItemsData: HoardItemData[],
    ) => {
        const branchId = getBranchId(wizardKey, hoard)
        const itemsData = getItemsData(wizardKey, hoard, hoardItemsData)
        return validateStock(itemsData, branchId)
    }

    const finishProductHoardDelivery = async (
        wizardKey: string,
        hoard: HoardOrder,
        hoardItemsData: HoardItemData[]
    ) => {
        const itemsData = getItemsData(wizardKey, hoard, hoardItemsData)
        const infoStepData = getStepData(wizardKey, 0)
        const companyId = hoard.companyId as string
        const date = infoStepData.date ? new Date(infoStepData.date) : new Date()
        const branchId = getBranchId(wizardKey, hoard)

        const hoardItems = hoardItemsData.map(hoardItemData => hoardItemData.hoardItem)
        const deliveredItems: DeliveredItem[] = itemsData.map(itemData => ({
            itemId: define(itemData.id) as string,
            delivered: define(itemData.quantity) as number
        }))
        const delivery: OrderDelivery = {
            companyId,
            id: newId(),
            orderId: hoard.id,
            date: date.toISOString(),
            deliveredItems,
            branchId
        }
        
        await salesActions().saveHoardDelivery(hoard, hoardItems, delivery)
        
        onFinishDelivery(wizardKey, hoard)
    }
    
    return {
        findProduct,
        findLot,
        getBranchId,
        validateFinancialHoardDelivery,
        finishFinancialHoardDelivery,
        validateProductHoardDelivery,
        finishProductHoardDelivery
    }
}
