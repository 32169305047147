import { Table, Column, FieldType, FieldValue, Section } from "../../../../features/ui"
import { Product } from "../../state/types"
import { useProductsHooks } from "../../hooks/productsHooks"
import { PaymentType, PaymentTypeName } from "../../../administration"
import { useFind } from "../../../../state/reducers/hooks"
import { TableName } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"

type Props = {
    product: Product
}

export const ProductPriceList = (props: Props) => {
    const { product } = props
    
    const { getPriceList } = useProductsHooks()
    const pricesList = getPriceList(product.id)
    const find = useFind()

    const pricesColumns: Column[] = [
        {
            name: 'customerTypeId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMER_TYPES,
            label: 'Cliente'
        },
        {
            name: 'paymentTypeId',
            type: FieldType.TEXT,
            label: 'Medio de Pago',
            render: (value?: FieldValue) => {
                const paymentType = define(find(TableName.PAYMENT_TYPES, value as string)) as PaymentType
                return <PaymentTypeName paymentType={paymentType} />
            }
        },
        {
            name: 'price',
            type: FieldType.PRICE,
            label: 'Precio'
        }
    ]

    const rows = pricesList.map(priceItem => ({
        id: `${priceItem.customerTypeId}:${priceItem.paymentTypeId}`,
        ...priceItem
    }))

    return (
        <Section id="priceList">
            <Table
                columns={pricesColumns}
                rows={rows}
                rowId="id"
                pageSize={20}
                readOnly
            />
        </Section>
    )
}
