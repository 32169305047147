import { Dispatch } from 'redux'
import { ActionType, SystemAction } from './actionTypes'
import { Company, Request, User } from './types'

export const setCompany = (company: Company) => {
    return (dispatch: Dispatch<SystemAction>) => {
        dispatch({
            type: ActionType.SET_COMPANY,
            payload: company
        })
    }
}

export const setUser = (user: User) => {
    return (dispatch: Dispatch<SystemAction>) => {
        dispatch({
            type: ActionType.SET_USER,
            payload: user
        })
    }
}

export const setConstrufyAdminId = (adminId: string) => {
    return (dispatch: Dispatch<SystemAction>) => {
        dispatch({
            type: ActionType.SET_CONSTRUFY_ADMIN_ID,
            payload: adminId
        })
    }
}

export const updateRequests = (requests: Request[]) => {
    return (dispatch: Dispatch<SystemAction>) => {
        dispatch({
            type: ActionType.UPDATE_REQUESTS,
            payload: requests
        })
    }
}

export const setUserRequest = (request: Request) => {
    return (dispatch: Dispatch<SystemAction>) => {
        dispatch({
            type: ActionType.SET_USER_REQUEST,
            payload: request
        })
    }
}

export const updateCompanyUsers = (users: User[]) => {
    return (dispatch: Dispatch<SystemAction>) => {
        dispatch({
            type: ActionType.UPDATE_COMPANY_USERS,
            payload: users
        })
    }
}
