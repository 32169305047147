import { FieldType, FieldValue, FilterField, Filters, option } from "../../../../../features/ui"
import { useEffect, useState } from "react"
import { DataType } from '../../../../../tables'
import { useAdministrationHooks, useAdministrationState } from "../../../hooks/administrationHooks"
import { CashFundType } from "../../../state/types"
import { Labels } from "../../../../../features/data-types"

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const CashFundFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { cashFunds } = useAdministrationState()

    const [searchText, setSearchText] = useState<string>()
    const [type, setType] = useState<CashFundType>()
    const [amountFrom, setAmountFrom] = useState<number>()
    const [amountTo, setAmountTo] = useState<number>()

    const { searchCashFunds } = useAdministrationHooks()

    useEffect(() => search(searchText, type), [cashFunds])

    const search = (
        searchTextParam?: string,
        typeParam?: CashFundType,
        amountFromParam?: number,
        amountToParam?: number
    ) => {
        onSearchStart()
        let cashFundList = cashFunds.filter(cashFund => !typeParam || cashFund.type === typeParam)
        cashFundList = amountFromParam ? cashFundList.filter(cashFund => cashFund.availableAmount >= amountFromParam) : cashFundList
        cashFundList = amountToParam ? cashFundList.filter(cashFund => cashFund.availableAmount <= amountToParam) : cashFundList
        cashFundList = searchCashFunds(cashFundList, searchTextParam)
        setTimeout(() => onSearchDone(cashFundList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(newSearchText, type)
    }

    const onChangeType = (value?: FieldValue) => {
        const newType = value as CashFundType | undefined
        if (newType !== type) {
            setType(newType)
            search(searchText, newType)
        }
    }

    const onChangeAmountFrom = (value?: FieldValue) => {
        const newAmountFrom = (value || 0) as number
        setAmountFrom(newAmountFrom)
        search(searchText, type, newAmountFrom, amountTo)
    }

    const onChangeAmountTo = (value?: FieldValue) => {
        const newAmountTo = value as number | undefined
        setAmountTo(newAmountTo)
        search(searchText, type, amountFrom, newAmountTo)
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por nombre o tipo',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'type',
            type: FieldType.SELECT,
            options: Object.values(CashFundType).map(type => option(type, Labels.cashFundTypeLabel(type))),
            label: 'Tipo',
            onChange: onChangeType
        },
        {
            name: 'amountFrom',
            type: FieldType.NUMBER,
            label: 'Disponible: Desde',
            max: amountTo,
            onChange: onChangeAmountFrom
        },
        {
            name: 'amountTo',
            type: FieldType.NUMBER,
            label: 'Disponible: Hasta',
            min: amountFrom,
            onChange: onChangeAmountTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
