import { ReducerState } from "../../../state/reducers/types"
import { addOrUpdate, remove } from "../../../state/reducers/utils"
import { ActionType, OperationsAction } from './actionTypes'
import { OrderDelivery } from "./types"

export type OperationsState = {
    deliveries: OrderDelivery[]
}

const initialState: OperationsState = {
    deliveries: [],
}

const reducer = (state = initialState, action: OperationsAction) => {
    switch(action.type) {
        case ActionType.UPDATE_DELIVERIES:
            return { 
                ...state,
                deliveries: addOrUpdate(state, action, 'deliveries' as keyof ReducerState)
            }
        case ActionType.REMOVE_DELIVERIES:
            return { 
                ...state,
                deliveries: remove(state, action, 'deliveries' as keyof ReducerState)
            }
        default:
            return state
    }
}

export default reducer
