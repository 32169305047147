import { PanelWrapper } from "../panel-wrapper/panelWrapper"
import { DataType, TableName, TablesData } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { Detail } from "../detail/detail"
import { Data, DetailFieldProps, Tab, Action, Crumb } from "../types"
import { useDescriptorHooks } from "../../../data-types"

type Props = {
    tableName: TableName
    fields: DetailFieldProps[]
    data: Data
    title?: string
    crumbs?: Crumb[]
    icon?: string
    tabs?: Tab[]
    imgSpace?: number
    readOnly?: boolean
    actions?: Action[]
}

export const DetailPanel = (props: Props) => {
    const { tableName, fields, data, title, crumbs, icon, tabs, imgSpace, readOnly, actions } = props
    const dataType = data as DataType
    const TableData = define(TablesData.get(tableName))

    const descriptorHooks = useDescriptorHooks()

    return (
        <PanelWrapper
            title={title || `${TableData.singular}: ${descriptorHooks.dataType(tableName, dataType)}`}
            crumbs={crumbs}
            wide
        >
            <Detail
                tableName={tableName}
                fields={fields}
                data={data}
                icon={icon}
                tabs={tabs}
                imgSpace={imgSpace}
                readOnly={readOnly}
                actions={actions}
            />
        </PanelWrapper>
    )
}
