import { Data } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { LotAttributes, LotAttribute } from "../../../../products"

export const toLotAttributesList = (lotAttributesStr: string) => {
    const parsedLotAttributes = JSON.parse(lotAttributesStr) as Data[]
    const lotAttributesList = parsedLotAttributes.map(lotAttribute => lotAttribute.lotAttr === LotAttribute.DUE_DATE ? {
        lotAttr: LotAttribute.DUE_DATE,
        lotAttrValue: new Date(lotAttribute.lotAttrValue as string)
    }: lotAttribute)
    
    return lotAttributesList
}

export const toLotAttributes = (lotAttributesStr: string): LotAttributes => {
    const parsedLotAttributes = JSON.parse(lotAttributesStr) as Data[]
    const lotAttributes: LotAttributes = {}
    parsedLotAttributes.map(lotAttribute => {
        const lotAttr = define(lotAttribute.lotAttr) as LotAttribute
        lotAttributes[lotAttr] = define(lotAttribute.lotAttrValue) as string
    })

    return lotAttributes
}
