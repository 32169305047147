import styles from './table.module.css'
import { Button } from "../button/button"
import { define } from '../../../../utils/typeUtils'
import { Pagination } from '../pagination/pagination'
import { useEffect, useMemo, useState } from 'react'
import { Action, Column, Data, FieldType, FieldValue } from '../types'
import { StaticValue } from '../static-value/staticValue'
import { ActionButton } from '../action-button/actionButton'

type Props = {
    columns: Column[]
    rows: Data[]
    rowId: string
    pageSize?: number
    readOnly?: boolean
    disableCreate?: boolean
    minItems?: number
    small?: boolean
    createLabel?: string
    createIcon?: string
    focusCreate?: boolean
    onCreate?: () => void
    onEdit?: (rowIdValue: string) => void
    onRemove?: (rowIdValue: string) => void
    actions?: (rowIdValue: string) => Action[]
    renderActions?: (rowIdValue: string) => JSX.Element
    renderDetail?: (row: Data) => JSX.Element
    className?: string
    createClassName?: string
}

export const Table = (props: Props) => {
    const { columns, rows, rowId, pageSize = 10, readOnly, disableCreate, minItems = 0, small, createLabel, createIcon, focusCreate, onCreate, onEdit, onRemove, actions, renderActions, renderDetail, className, createClassName } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [openedRow, setOpenedRow] = useState<string>()
    
    const pagesAmount = Math.ceil(rows.length / pageSize)
    const showActionsColumn = !readOnly || actions || renderActions
    const columnsAmount = columns.length + (showActionsColumn ? 1 : 0)
    const pageRows = useMemo(() => {
        const start = (currentPage - 1) * pageSize
        return rows.slice(start, start + pageSize)
    }, [currentPage, rows])

    const goToPreviousPage = () => setCurrentPage(page => page - 1)

    useEffect(() => {
        pageRows.length === 0 && currentPage > 1 && goToPreviousPage()
    }, [pageRows, currentPage])

    const defaultRender = (column: Column, rawValue?: FieldValue) => {
        const value = column.type === FieldType.DATE ? new Date(rawValue as string) : rawValue
        return <StaticValue type={column.type} table={column.table} value={value} />
    }

    const onActionsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()

    const renderActionBtn = (rowIdValue: string) => (action: Action, index: number) => {
        const onClick = () => action.onClick(rowIdValue)
        const className = actions && index < actions(rowIdValue).length - 1 ? styles.actionBtn : ''
        return <ActionButton
            key={index}
            action={action}
            className={className}
            onClick={onClick}
        />
    }

    const renderEditBtn = (rowIdValue: string) => {
        const onEditRow = () => onEdit && onEdit(rowIdValue)
        return <Button
            icon="pencil-fill"
            tooltip='Editar'
            disabled={rows.length <= minItems}
            small={small}
            rightMargin
            focusable={false}
            onClick={onEditRow}
        />
    }
    
    const renderRemoveBtn = (rowIdValue: string) => {
        const onRemoveRow = () => onRemove && onRemove(rowIdValue)
        return <Button
            kind='secondary'
            icon='trash-fill'
            tooltip='Eliminar'
            small={small}
            focusable={false}
            onClick={onRemoveRow}
        />
    }

    const toggleDetail = (rowIdValue: string) => () => {
        renderDetail && setOpenedRow(prevValue => prevValue === rowIdValue ? undefined : rowIdValue)
    }

    return (
        <>
            <table className={`table ${openedRow ? '' : 'table-hover'} ${className || ''}`}>
                <thead>
                    <tr>
                        {columns.map(colum => (
                            <th key={colum.name} scope="col" className={styles.th}>
                                {rows.length > 0 ? colum.label : ''}
                            </th>
                        ))}
                        {showActionsColumn && (
                            <th scope="col">
                                {!readOnly && onCreate && (
                                    <div className={styles.actionsContainer}>
                                        <Button
                                            label={createLabel || 'Agregar'}
                                            icon={createIcon}
                                            small={small}
                                            disabled={disableCreate}
                                            focus={focusCreate}
                                            onClick={onCreate}
                                            className={createClassName}
                                        />
                                    </div>
                                )}
                            </th>
                        )}
                    </tr>
                </thead>
                {pageRows.map(row => {
                    const rowIdValue = define(row[rowId]).toString() as string
                    const showDetail = openedRow === rowIdValue
                    const detail = renderDetail && renderDetail(row)
                    const expandedClass = showDetail ? styles.expanded : ''
                    
                    return (
                        <tbody key={rowIdValue}>
                            <tr onClick={toggleDetail(rowIdValue)}>
                                {columns.map(column => {
                                    const value = row[column.name]
                                    const tdClass = `${styles.td} ${column.type === FieldType.IMAGE ? styles.imgTd : ''}`
                                    return (
                                        <td key={column.name} className={tdClass}>
                                            {column.render ? column.render(value) : defaultRender(column, value)}
                                        </td>
                                    )
                                })}
                                {showActionsColumn && (
                                    <td className={`${styles.td} ${styles.actionsTd}`}>
                                        <div className={styles.actionsContainer} onClick={onActionsClick}>
                                            {actions ? actions(rowIdValue).map(renderActionBtn(rowIdValue)) : (
                                                !readOnly && (
                                                    <>
                                                        {onEdit && renderEditBtn(rowIdValue)}
                                                        {onRemove && renderRemoveBtn(rowIdValue)}
                                                    </>
                                                )
                                            )}
                                            {renderActions && renderActions(rowIdValue)}
                                            {detail && <i
                                                onClick={toggleDetail(rowIdValue)}
                                                className={`bi bi-chevron-down ${styles.expandIcon} ${expandedClass}`}
                                            />}
                                        </div>
                                    </td>
                                )}
                            </tr>
                            {detail && showDetail && (
                                <tr className={styles.detailTr}>
                                    <td colSpan={columnsAmount}>
                                        {detail}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    )
                })}
            </table>
            <Pagination
                pagesAmount={pagesAmount}
                page={currentPage}
                onGoToPage={setCurrentPage}
            />
        </>
    )
}
