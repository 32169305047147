import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import * as actionCreators from "./actionCreators"
import { SystemState } from "./reducer"
import { useSelector } from "react-redux"
import { State } from "../../../state"
import { Request, RequestStatus } from "./types"
import { uiWritePermissions, UIPermission } from "../../../features/ui"

export const useSystemState = (): SystemState => useSelector((state: State) => state.systemReducer)

export const useSystemStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useSystemStateHooks = () => {
    const { company, userRequest } = useSystemState()

    const hasCompanyAccess = () => userRequest?.status === RequestStatus.APPROVED

    const getUserRole = () => userRequest?.role

    const userHasPermission = (permission: UIPermission, request?: Request) => {
        const role = request?.role || getUserRole()
        const roles = uiWritePermissions().get(permission) || []
        return role && roles.includes(role)
    }

    return {
        companyId: company?.id,
        companyCode: company?.code,
        hasCompanyAccess,
        getUserRole,
        userHasPermission
    }
}
