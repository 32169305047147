import { ModalId, useUIActions } from "../../../../../features/ui"
import { useSalesActions } from "../../../actions/salesActions"
import { Budget } from "../../../state/types"
import { Data } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { Currency } from "../../../../../tables/types/types"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const useActions = () => {
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()
    
    const salesActions = useSalesActions()
    const { onSubmitDataType, toggleModal } = useUIActions()
    
    const refreshBudget = async (
        budgetData: Data,
        code: number,
        sellerId: string,
        customerId: string,
        budgetItemsData: Data[],
    ) => {
        const date = define(budgetData.date) as Date
        const budget: Budget = {
            companyId: budgetData.companyId as string,
            id: budgetData.id as string,
            code,
            date: date.toISOString(),
            sellerId,
            customerId,
            currency: Currency.ARS
        }
        
        const savedBudget = await salesActions().saveBudgetWithItems(budget, budgetItemsData)
        
        onSubmitDataType(true, false)
        navigate(getRoutePath(RoutePath.BUDGETS, savedBudget.id))
        setTimeout(() => toggleModal(ModalId.BUDGET_PDF), 500)
    }
    
    return {
        refreshBudget
    }
}
