import { ReducerState } from "../../../state/reducers/types"
import { addOrUpdate, remove } from "../../../state/reducers/utils"
import { ActionType, ManagerAction } from './actionTypes'
import { Branch } from "./types"

export type ManagerState = {
    currentBranch?: Branch
    branches: Branch[]
}

const initialState: ManagerState = {
    branches: []
}

const reducer = (state = initialState, action: ManagerAction) => {
    switch(action.type) {
        case ActionType.SET_CURRENT_BRANCH:
            return { 
                ...state,
                currentBranch: action.payload
            }
        case ActionType.UPDATE_BRANCHES:
            return { 
                ...state,
                branches: addOrUpdate(state, action, 'branches' as keyof ReducerState)
            }
        case ActionType.REMOVE_BRANCHES:
            return { 
                ...state,
                branches: remove(state, action, 'branches' as keyof ReducerState)
            }
        default:
            return state
    }
}

export default reducer
