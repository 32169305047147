import { Modal, ModalId } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"

type Props = {
    categoryId?: string
    onSubmit: (categoryId: string) => void
}

export const RemoveCategorySaleYieldModal = (props: Props) => {
    const { categoryId, onSubmit } = props

    const title = 'Eliminar Rentabilidad por Categoría?'
    const body = 'Al eliminar esta configuración, los productos de esta categoría que no tengan definida una rentabilidad específica utilizarán la rentabilidad general.'

    const handleSubmit = () => onSubmit(define(categoryId))
    
    return (
        <Modal
            modalId={ModalId.REMOVE_CATEGORY_SALE_YIELD}
            title={title}
            submitLabel="Confirmar"
            submitKind="secondary"
            onSubmit={handleSubmit}
        >
            <>{body}</>
        </Modal>
    )
}
