import { DataTypeList, Standards } from "../../../../../features/data-types"
import { PanelWrapper, Column, FieldType, UIPermission } from "../../../../../features/ui"
import { useFind } from "../../../../../state/reducers/hooks"
import { TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { ExpenseType } from "../../../state/types"

export const ExpenseTypeList = () => {
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_EXPENSE_TYPES)

    const find = useFind()

    const columns: Column[] = [
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' }
    ]

    const isEditable = (rowIdValue?: string) => {
        const expenseType = find(TableName.EXPENSE_TYPES, rowIdValue) as ExpenseType | undefined
        return expenseType?.name !== Standards.SalaryExpenseTypeName
    }

    return (
        <PanelWrapper title="Tipos de Gasto">
            <DataTypeList
                columns={columns}
                readOnly={readOnly}
                minItems={1}
                showDetail={false}
                isEditable={isEditable}
            />
        </PanelWrapper>
    )
}
