import styles from './form.module.css'
import { Button } from '../button/button'
import { useState } from 'react'
import { getDefaultData } from './formUtils'
import { FieldRow } from '../field-row/fieldRow'
import { Field } from '../fields/field/field'
import { Data, FormField, FieldValue, FieldType } from '../types'
import { Validatable } from '../validatable/validatable'
import { toList } from '../../../../utils/listUtils'
import { ValidationResult } from './types'

type Props = {
    formId: string
    fields: FormField[]
    createMode?: boolean
    createLabel?: string
    updateLabel?: string
    disableSubmit?: boolean
    onSubmit: (data: Data) => void
    onCancel: () => void
}

export const Form = (props: Props) => {
    const { formId, fields, createMode = true, createLabel, updateLabel, disableSubmit, onSubmit, onCancel } = props
    
    const [data, setData] = useState<Data>(getDefaultData(fields))
    const [validationResults, setValidationResults] = useState<ValidationResult>({})

    const validate = (): boolean => {
        let someIsInvalid = false

        fields.forEach(field => {
            const { validate } = field
            if (validate) {
                const validationMessage = validate(data)
                validationMessage && setValidationResults(prevResults => ({
                    ...prevResults,
                    [field.name]: validationMessage
                }))
                someIsInvalid = someIsInvalid || !!validationMessage
            }
        })

        return !someIsInvalid
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (validate()) {
            onSubmit(data)
        }
    }

    const onChange = (field: FormField) => (value?: FieldValue) => {
        setData(prevData => ({
            ...prevData,
            [field.name]: value
        }))
        setValidationResults({})
        field.onChange && field.onChange(value)
    }

    return (
        <form id={formId} onSubmit={handleSubmit} className="needs-validation">
            {fields.map(field => (
                <FieldRow
                    key={field.name}
                    id={field.name}
                    label={field.label}
                    hint={field.hint}
                    hideLabel={field.type === FieldType.BOOLEAN}
                    narrow={field.narrow}
                >
                    {field.render ? field.render() : (
                        <Validatable validations={toList(validationResults[field.name])} left>
                            <Field key={field.key} field={field} onChange={onChange(field)} />
                        </Validatable>
                    )}
                </FieldRow>
            ))}

            <div className={styles.btnsContainer}>
                <Button
                    type="submit"
                    label={createMode ? (createLabel || 'Crear') : (updateLabel || 'Guardar')}
                    rightMargin={true}
                    disabled={disableSubmit}
                />
                <Button
                    label="Cancelar"
                    kind="tertiary"
                    type="button"
                    onClick={onCancel}
                />
            </div>
        </form>
    )
}
