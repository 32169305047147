import { useUIActions } from "../../../../../features/ui"
import { useSalesActions } from "../../../actions/salesActions"
import { HoardOrder, HoardType } from "../../../state/types"
import { Data } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { Currency } from "../../../../../tables/types/types"
import { DeliveryStatus, PaymentStatus } from "../../../../operations"
import { PurchasePricesData } from "../../../../products"

export const useActions = () => {
    const salesActions = useSalesActions()
    const { onSubmitDataType } = useUIActions()
    
    const submitHoard = async (
        hoardData: Data,
        code: number,
        type: HoardType,
        branchId: string,
        sellerId: string,
        customerId: string,
        hoardItemsData: Data[],
        paymentTypeId: string,
        purchasePricesList: PurchasePricesData
    ) => {
        const dueDate = define(hoardData.dueDate) as Date
        const value = hoardData.value as number | undefined
        const hoard: HoardOrder = {
            companyId: hoardData.companyId as string,
            id: hoardData.id as string,
            code,
            type,
            creationDate: new Date().toISOString(),
            currency: Currency.ARS,
            dueDate: dueDate.toISOString(),
            sellerId,
            customerId,
            paymentTypeId,
            branchId,
            deliveryStatus: hoardData.deliveryStatus as DeliveryStatus || DeliveryStatus.PENDING,
            paymentStatus: hoardData.paymentStatus as PaymentStatus || PaymentStatus.PENDING,
            purchasePricesList,
            ...(type === HoardType.FINANCIAL && { value: define(value) }),
        }

        await salesActions().saveHoard(hoard, hoardItemsData)
        onSubmitDataType(true)
    }
    
    return {
        submitHoard
    }
}
