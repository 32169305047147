import { DownloadFileModal, ModalId } from '../../../../../features/ui'
import { DataType, TableName } from '../../../../../tables'
import { PaymentOrder } from '../../../state/types'

type Props = {
    paymentOrder?: PaymentOrder
}

export const PaymentOrderReceiptModal = (props: Props) => {
    const { paymentOrder } = props

    return (
        <DownloadFileModal
            modalId={ModalId.PAYMENT_ORDER_RECEIPT}
            dataType={paymentOrder}
            tableName={TableName.PAYMENT_ORDERS}
            fileProp={'receiptUrl' as keyof DataType}
            title="Descargar Comprobante"
        />
    )
}
