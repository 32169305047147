import { Action, TableActions, readPermissions } from "../../../features/roles";
import { TableName } from "../../../tables";

export const salesPermissions = (): TableActions[] => readPermissions().concat([
    { tableName: TableName.BUDGETS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.BUDGET_ITEMS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.CUSTOMER_TYPES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.CUSTOMERS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.EMPLOYEES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.HOARD_ITEMS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.HOARD_ORDERS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.ORDER_DELIVERIES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.SALE_ITEMS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.SALE_ORDERS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.SETTINGS, actions: [Action.SAVE] },
    { tableName: TableName.STOCK, actions: [Action.SAVE] },
])
