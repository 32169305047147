import { useDefaultModuleActions } from "../../../common"
import { DynamoUserApi } from "../../../../services"
import { TableName } from "../../../../tables"
import { HoardOrder, HoardItem } from "../../state/types"

export const useCommonActions = () => {
    const defaultModuleActions = useDefaultModuleActions()

    return (companyId: string) => {
        const { fetchByParams, fetchMultipleByParams, fetchByIds, count, save, saveMultiple, remove, removeMultiple } = defaultModuleActions(DynamoUserApi, companyId)

        const fetchAllHoards = (branchId: string) => {
            return fetchMultipleByParams(TableName.HOARD_ORDERS, { branchId })
        }

        const fetchHoard = (id: string) => {
            return fetchByParams(TableName.HOARD_ORDERS, { id })
        }

        const countHoards = (propName: string, propValue: string) => {
            return count(TableName.HOARD_ORDERS, { [propName]: propValue })
        }

        const saveHoard = (hoard: HoardOrder) => {
            return save(TableName.HOARD_ORDERS, hoard)
        }

        const removeHoard = (id: string) => {
            return remove(TableName.HOARD_ORDERS, id)
        }

        const fetchHoardItemsByOrder = (hoardId: string) => {
            return fetchMultipleByParams(TableName.HOARD_ITEMS, { hoardId })
        }

        const fetchHoardItemsByIds = (ids: string[]) => {
            return fetchByIds(TableName.HOARD_ITEMS, ids)
        }

        const countHoardItems = (propName: string, propValue: string) => {
            return count(TableName.HOARD_ITEMS, { [propName]: propValue })
        }

        const saveHoardItems = (hoardItems: HoardItem[]) => {
            return saveMultiple(TableName.HOARD_ITEMS, hoardItems)
        }

        const removeHoardItems = (ids: string[]) => {
            return removeMultiple(TableName.HOARD_ITEMS, ids)
        }

        return {
            fetchAllHoards,
            fetchHoard,
            countHoards,
            saveHoard,
            removeHoard,
            fetchHoardItemsByOrder,
            fetchHoardItemsByIds,
            countHoardItems,
            saveHoardItems,
            removeHoardItems
        }
    }
}
