import { useAuth0 } from "@auth0/auth0-react"
import { User, useSystemActions, useSystemState, useSystemStateActions, useSystemStateHooks } from "../../../modules/system"
import { define } from "../../../utils/typeUtils"
import { RoutePath } from "../types"
import { useLocation } from "react-router-dom"
import { useFind } from "../../../state/reducers/hooks"
import { DataType, TableName } from "../../../tables"
import { getRouteTableNames } from "../utils/routesUtils"

export const useRoutesHooks = () => {
    let stateUser = useSystemState().user
    
    const { user } = useAuth0()
    const { companyCode } = useSystemStateHooks()
    const { pathname } = useLocation()
    const find = useFind()

    const { setUser } = useSystemStateActions()
    const systemActions = useSystemActions()

    const loadUser = async () => {
        const { fetchUserById, saveUser } = systemActions()

        const userId = user?.sub
        if (userId && !stateUser)  {
            stateUser = await fetchUserById(userId)
            if (stateUser) {
                setUser(stateUser)
            } else {
                const { name, email, picture } = user
                const newUser: User = {
                    id: userId,
                    name,
                    email: define(email),
                    pictureUrl: picture
                }
                stateUser = await saveUser(newUser, true)
                saveUser(stateUser)
            }    
        }

        return stateUser
    }

    const getRoutePath = (
        listPath: RoutePath,
        id?: string
    ) => {
        const idSubPath = id ? `/${define(id)}` : ''
        return `/${companyCode}/${listPath}${idSubPath}`
    }

    const parseUrl = () => {    
        const pathParts = pathname.split('/').filter(part => !!part)
    
        let tableName: TableName | undefined
        let dataTypeId: string | undefined
        let dataType: DataType | undefined
        let secondTableName: TableName | undefined
        let secondDataTypeId: string | undefined
        let secondDataType: DataType | undefined

        const isRoutePath = (value: string) => Object.values(RoutePath).includes(value as RoutePath)
    
        if (pathParts.length > 1) {
            tableName = getRouteTableNames().get(pathParts[1])
            
            if (pathParts.length > 2 && !isRoutePath(pathParts[2])) {
                dataTypeId = pathParts[2]
                dataType = tableName && find(tableName, dataTypeId)

                if (pathParts.length > 3) {
                    secondTableName = getRouteTableNames().get(pathParts[3])

                    if (pathParts.length > 4 && !isRoutePath(pathParts[4])) {
                        secondDataTypeId = pathParts[4]
                        secondDataType = secondTableName && find(secondTableName, secondDataTypeId)
                    }
                }
            }
        }
    
        return {
            pathParts,
            tableName,
            dataTypeId,
            dataType,
            secondTableName,
            secondDataTypeId,
            secondDataType
        }
    }

    return {
        loadUser,
        getRoutePath,
        parseUrl
    }
}
