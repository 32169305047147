import { ReducerState } from "../../../state/reducers/types"
import { addOrUpdate, remove } from "../../../state/reducers/utils"
import { ActionType, ProductsAction } from './actionTypes'
import { Product, Category, ProductPrices, Measure, Lot } from "./types"

export type ProductsState = {
    products: Product[],
    categories: Category[],
    productPrices: ProductPrices[],
    lots: Lot[],
    measures: Measure[]
}

const initialState: ProductsState = {
    products: [],
    categories: [],
    productPrices: [],
    lots: [],
    measures: []
}

const reducer = (state = initialState, action: ProductsAction) => {
    switch(action.type) {
        case ActionType.UPDATE_PRODUCTS:
            return { 
                ...state,
                products: addOrUpdate(state, action, 'products' as keyof ReducerState)
            }
        case ActionType.REMOVE_PRODUCTS:
            return { 
                ...state,
                products: remove(state, action, 'products' as keyof ReducerState)
            }
        case ActionType.UPDATE_CATEGORIES:
            return { 
                ...state,
                categories: addOrUpdate(state, action, 'categories' as keyof ReducerState)
            }
        case ActionType.REMOVE_CATEGORIES:
            return { 
                ...state,
                categories: remove(state, action, 'categories' as keyof ReducerState)
            }
        case ActionType.UPDATE_PRODUCT_PRICES:
            return { 
                ...state,
                productPrices: addOrUpdate(state, action, 'productPrices' as keyof ReducerState)
            }
        case ActionType.REMOVE_PRODUCT_PRICES:
            return { 
                ...state,
                productPrices: remove(state, action, 'productPrices' as keyof ReducerState)
            }
        case ActionType.UPDATE_MEASURES:
            return { 
                ...state,
                measures: addOrUpdate(state, action, 'measures' as keyof ReducerState)
            }
        case ActionType.REMOVE_MEASURES:
            return { 
                ...state,
                measures: remove(state, action, 'measures' as keyof ReducerState)
            }
        case ActionType.UPDATE_LOTS:
            return {
                ...state,
                lots: addOrUpdate(state, action, 'lots' as keyof ReducerState)
            }
        case ActionType.REMOVE_LOTS:
            return {
                ...state,
                lots: remove(state, action, 'lots' as keyof ReducerState)
            }
        default:
            return state
    }
}

export default reducer
