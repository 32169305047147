import { useNavigate } from "react-router-dom"
import { RequestStatus, useSystemState } from "../../../../modules/system"
import { getInitialRoute } from "../../../roles"
import { useRoutesHooks } from "../../hooks/routesHooks"

export const useLoad = () => {
    const { userRequest } = useSystemState()

    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()

    return async () => {
        if (userRequest && userRequest.status === RequestStatus.APPROVED) {
            const initialRoute = getInitialRoute(userRequest.role)
            navigate(getRoutePath(initialRoute))
        }
    }
}
