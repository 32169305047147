import { useSystemStateHooks } from "../../../modules/system"
import { define, toNumber } from "../../../utils/typeUtils"
import { CsvColumn, CsvResult, CsvRow } from "../types"
import { useImportCsv } from "./importCsv"
import { showErrorStrToast, showToast } from "../../ui"
import { Product, ProductPrices, useProductsActions } from "../../../modules/products"
import { standarize } from "../utils/importerUtils"

export const useImportProductPrices = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const importCsv = useImportCsv()

    const productsActions = useProductsActions()

    const importProductPrices = (
        file: File,
        companyId: string,
        stateProducts: Product[],
        statePricesList: ProductPrices[]
    ) => {
        const validateSupplierCode = (value: string) => {
            return !!stateProducts.find(stateProduct => stateProduct.supplierCode === value)
        }

        const supplierCodeColumn = standarize('CódigoProveedor')
        const priceColumn = standarize('Precio')

        const columns: CsvColumn[] = [
            { name: supplierCodeColumn, validate: validateSupplierCode },
            { name: priceColumn },
        ]

        const manageData = (data: CsvRow[]) => {
            const pricesList: ProductPrices[] = []
    
            data.forEach(row => {
                const product = stateProducts.find(stateProduct => stateProduct.supplierCode === row[supplierCodeColumn])
                const prices = define(statePricesList.find(prices => prices.productId === product?.id)) as ProductPrices
                const newPrices: ProductPrices = {
                    ...prices,
                    purchasePrice: define(toNumber(row[priceColumn]))
                }
                pricesList.push(newPrices)
            })
    
            productsActions(companyId).saveProductPrices(pricesList)
        }

        const onImport = (result: CsvResult) => {
            if (result.error) {
                showErrorStrToast(result.error)
            } else {
                showToast('La lista de precios fue actualizada.')
            }
        }

        importCsv(file, columns, manageData, onImport)
    }
    
    return async (file: File, paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const stateProducts = (await productsActions(companyId).fetchAllProducts()).dataTypes as Product[]
        const statePricesList = (await productsActions(companyId).fetchAllProductPrices()).dataTypes as ProductPrices[]
        importProductPrices(file, companyId, stateProducts, statePricesList)
    }
}
