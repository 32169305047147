import { define } from "../../../../utils/typeUtils"
import { Action, Modal, ModalId } from "../../../ui"
import { useActions } from "./actions"

type Props = {
    wizardKey?: string
    path: string
}

export const OpenWizardModal = (props: Props) => {
    const { wizardKey, path } = props

    const { restartWizard, resumeWizard } = useActions()

    const title = `Continuar con últimos datos ingresados?`
    
    const renderBody = () => <>
        Este proceso ya se inició en el pasado. <br />
        Deseas <b>continuar el proceso</b> con los datos ya ingresados o <b>iniciar un nuevo proceso</b>?
    </>
    
    const onRestartClick = () => restartWizard(define(wizardKey), path)
    const onResumeClick = () => resumeWizard(path)

    const actions: Action[] = [
        { label: 'Iniciar Nuevo Proceso', kind: 'tertiary', onClick: onRestartClick },
        { label: 'Continuar Proceso', kind: 'primary', onClick: onResumeClick }
    ]

    return (
        <Modal
            modalId={ModalId.OPEN_WIZARD}
            title={title}
            actions={actions}
        >
            <>{renderBody()}</>
        </Modal>
    )
}
