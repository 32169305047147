import styles from './paymentTypeField.module.css'
import { FieldType, FieldValue, FormField, Option } from "../../../../../features/ui";
import { TableName } from "../../../../../tables";
import { useFind } from "../../../../../state/reducers/hooks";
import { define } from "../../../../../utils/typeUtils";
import { PaymentType } from "../../..";
import { useDataTypesHooks } from "../../../../../features/data-types";

type Props = {
    onChange: (value?: FieldValue) => void
}

export const PaymentTypeField = (props: Props): FormField => {
    const { onChange } = props
    
    const { formatValue } = useDataTypesHooks()
    const find = useFind()

    const renderPaymentTypeOption = (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>) => void) => {
        const paymentType = define(find(TableName.PAYMENT_TYPES, option.value)) as PaymentType
        return (
            <div className={styles.paymentTypeOption} onClick={onClick}>
                <span>{paymentType.name}</span>
                <span className={styles.paymentTypeYield}>
                    {formatValue(paymentType.yield, FieldType.PERCENTAGE)}
                </span>
            </div>
        )
    }

    return {
        name: 'paymentTypeId',
        type: FieldType.TABLE,
        table: TableName.PAYMENT_TYPES,
        label: 'Medio de Pago',
        narrow: true,
        renderOption: renderPaymentTypeOption,
        onChange
    }
}
