import { define, equalsNormalized, isDefined, normalize, removeSpaces, toNumber } from "../../../utils/typeUtils"
import { IndexedHeader } from "../types"
import { Product, useProductsActions } from "../../../modules/products"
import { newId } from "../../data-types"
import { Supplier, usePurchasesActions } from "../../../modules/purchases"
import { useStockActions } from "../../../modules/stock"
import { Data } from "../../ui"
import { Currency, TableName } from "../../../tables/types/types"
import { useFetchPropList, useGenerateNextValue2 } from "../../../tables/hooks"
import { TaxType, useAdministrationState } from "../../../modules/administration"

enum Header {
    NAME = 'Nombre',
    SUPPLIER = 'Proveedor',
    SUPPLIER_CODE = 'CodigoProveedor',
    CATEGORY = 'Categoria',
    UNITS = 'Unidades',
    UNIT_TYPE = 'UnidadMedida',
    LOTED = 'Partidas',
    DESCRIPTION = 'Descripción',
    PURCHASE_PRICE = 'PrecioCompra',
    SALE_YIELD = 'Rentabilidad'
}

export const useImportProducts = () => {
    const { taxes } = useAdministrationState()
    const fetchPropList = useFetchPropList()
    const generateNextValue = useGenerateNextValue2()
    const purchasesActions = usePurchasesActions()
    const productsActions = useProductsActions()
    const stockActions = useStockActions()

    return (
        file: File,
        companyId: string
    ) => {
        const reader = new FileReader()
        reader.onload = async e => {
            const data = e.target?.result as string
            const lines = data.trim().split(/[\r\n]+/)
            const firstLine = lines[0]
            const headersData = firstLine.split(';')

            const indexedHeaders: IndexedHeader[] = Object.values(Header).map(header => {
                const index = headersData.map(headerData => removeSpaces(normalize(headerData))).indexOf(normalize(header))
                return {
                    index,
                    header
                }
            })
            const validHeaders = !indexedHeaders.some(({ index }) => index === -1)

            if (validHeaders) {
                const rows = lines.slice(1)
                const products: Product[] = []
                const invalidRows: string[] = []

                const { fetchAllCategories, saveProduct } = productsActions(companyId)
                const suppliers = await purchasesActions(companyId).fetchAllSuppliers().then(response =>
                    response.dataTypes as Supplier[]
                )
                const categories = await fetchAllCategories()
                const unitTypes = await stockActions(companyId).fetchAllUnitTypes()

                const getIndex = (headers: IndexedHeader[], headerValue: Header) => headers.find(({ header }) => header === headerValue)?.index

                const codes = await fetchPropList(TableName.SUPPLIERS, 'code', companyId)

                for (const row of rows) {
                    const cells = row.split(';')

                    const nameIndex = getIndex(indexedHeaders, Header.NAME)
                    const nameValue = cells[define(nameIndex)]

                    const supplierIndex = getIndex(indexedHeaders, Header.SUPPLIER)
                    const supplierValue = cells[define(supplierIndex)]
                    const supplier = suppliers.find(supplier => equalsNormalized(supplier.name, supplierValue))

                    const supplierCodeIndex = getIndex(indexedHeaders, Header.SUPPLIER_CODE)
                    const supplierCodeValue = cells[define(supplierCodeIndex)]

                    const categoryIndex = getIndex(indexedHeaders, Header.CATEGORY)
                    const categoryValue = cells[define(categoryIndex)]
                    const category = categories.find(category => equalsNormalized(category.name, categoryValue))

                    const unitsIndex = getIndex(indexedHeaders, Header.UNITS)
                    const unitsValue = cells[define(unitsIndex)]
                    const units = toNumber(unitsValue)

                    const unitTypeIndex = getIndex(indexedHeaders, Header.UNIT_TYPE)
                    const unitTypeValue = cells[define(unitTypeIndex)]
                    const unitType = unitTypes.find(unitType => equalsNormalized(unitType.abbreviation, unitTypeValue))

                    const lotedIndex = getIndex(indexedHeaders, Header.LOTED)
                    const lotedValue = cells[define(lotedIndex)]
                    const transformLoted = (value: string) => equalsNormalized(value, 'si')
                    const loted = transformLoted(lotedValue)

                    const descriptionIndex = getIndex(indexedHeaders, Header.DESCRIPTION)
                    const descriptionValue = cells[define(descriptionIndex)]

                    const purchasePriceIndex = getIndex(indexedHeaders, Header.PURCHASE_PRICE)
                    const purchasePriceValue = cells[define(purchasePriceIndex)]
                    const purchasePrice = toNumber(purchasePriceValue)

                    const saleYieldIndex = getIndex(indexedHeaders, Header.SALE_YIELD)
                    const saleYieldValue = cells[define(saleYieldIndex)]
                    const saleYield = toNumber(saleYieldValue)

                    const validCell = !!nameValue &&
                        !!supplier &&
                        !!supplierCodeValue &&
                        !!category &&
                        isDefined(units) &&
                        !!unitType &&
                        isDefined(purchasePrice)

                    if (validCell) {
                        const code = await generateNextValue(TableName.SUPPLIERS, 'code', companyId, codes)
                        codes.push(code)

                        const vatTax = define(taxes.find(tax => tax.type === TaxType.VAT))
                        const product: Product = {
                            companyId,
                            id: newId(),
                            code,
                            name: nameValue,
                            supplierId: supplier.id,
                            supplierCode: supplierCodeValue,
                            categoryId: category.id,
                            units: define(units),
                            unitTypeId: unitType.id,
                            loted,
                            ...(descriptionValue && {
                                description: descriptionValue
                            }),
                            purchasable: true,
                            salable: true,
                            vatTaxId: vatTax.id
                        }
                        const pricesData: Data = {
                            purchasePrice: define(purchasePrice),
                            saleYield,
                            currency: Currency.ARS
                        }

                        products.push(product)
                        await saveProduct(product, pricesData, [])
                    } else {
                        invalidRows.push(row)
                    }
                }

                console.log('Imported Data:', products)
                console.log('Invalid Rows:', invalidRows)
            } else {
                console.log('Invalid Headers')
            }
        }
        reader.readAsText(file)
    }
}
