import { useEffect, useMemo, useState } from "react"
import { EmployeeSearchField } from "../../../../../components/fields/search-field/employee-search-field/employeeSearchField"
import { PanelWrapper, FormField, Data, useUIStateActions, ModalId, useUIActions, FieldValue, FieldType, option, useGetCrumbs, Badge } from "../../../../../features/ui"
import { Customer, CustomerType, HoardType } from "../../../state/types"
import { Area, Employee, PaymentType, PaymentTypeField, useAdministrationActions, useAdministrationState } from "../../../../administration"
import { useSalesActions } from "../../../actions/salesActions"
import { TableName } from "../../../../../tables"
import { CustomerSearchField } from "../../../../../components/fields/search-field/customer-search-field/customerSearchField"
import { useActions } from "./actions"
import { define, isDefined } from "../../../../../utils/typeUtils"
import { DataTypeForm, Labels } from "../../../../../features/data-types"
import { Lot, Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Validatable } from "../../../../../features/ui"
import { Operation, OperationItemFormModal, OperationItemsTable, usePricesHooks, PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { useFind } from "../../../../../state/reducers/hooks"
import { useSalesUIHooks } from "../../salesUiHooks"
import { Branch, useManagerState } from "../../../../manager"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"
import { useSalesHooks } from "../../../hooks/salesHooks"

export const HoardForm = () => {
    const { currentBranch } = useManagerState()
    const branchId = currentBranch?.id
    const { currentEmployee } = useAdministrationState()

    const find = useFind()
    const findPaymentType = (id?: string) => find(TableName.PAYMENT_TYPES, id) as PaymentType | undefined

    const [code, setCode] = useState(1)
    const [type, setType] = useState<HoardType | undefined>(HoardType.PRODUCT)
    const [seller, setSeller] = useState<Employee | undefined>(currentEmployee)
    const [customer, setCustomer] = useState<Customer>()
    const [hoardValue, setHoardValue] = useState<number>()
    const [hoardItemsData, setHoardItemsData] = useState<Data[]>([])
    const [currentHoardItem, setCurrentHoardItem] = useState<Data>()
    const [paymentType, setPaymentType] = useState<PaymentType>()
    const [validations, setValidations] = useState<string[]>([])

    const { validateItemsData } = useSalesUIHooks()
    const { getSettings } = useSalesHooks()
    const hoardIgnoredProductIds = (getSettings()?.hoardIgnoredProductIds || []) as string[]
    const pricesHooks = usePricesHooks()
    const purchasePricesList = pricesHooks.product.purchasePrices()
    const { getPriceBreakdownFromItems, getPriceBreakdown } = usePriceBreakdownHooks()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()

    const { submitHoard } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const administrationActions = useAdministrationActions()
    const salesActions = useSalesActions()
    const { toggleModal } = useUIActions()

    const isProductHoard = type === HoardType.PRODUCT

    const setSellerFromId = async (id?: string) => {
        let stateEmployee: Employee | undefined
        if (id) {
            stateEmployee = await administrationActions().fetchEmployee(id) as Employee | undefined
        }
        setSeller(stateEmployee)
    }

    const setCustomerFromId = async (id?: string) => {
        let stateCustomer: Customer | undefined
        if (id) {
            stateCustomer = await salesActions().fetchCustomer(id) as Customer | undefined
        }
        setCustomer(stateCustomer)
    }

    const init = async () => {
        setDisableBranchSelector(true)
        setCode(await generateNextValue(TableName.HOARD_ORDERS, 'code'))
    }

    useEffect(() => {
        init()
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => {
        setHoardValue(0)
    }, [type])

    const priceBreakdown = useMemo(() => {
        const customerType = find(TableName.CUSTOMER_TYPES, customer?.customerTypeId) as CustomerType | undefined
        return isProductHoard ?
            getPriceBreakdownFromItems(hoardItemsData, customerType?.yield, paymentType?.yield) :
            getPriceBreakdown(hoardValue || 0, customerType?.yield, paymentType?.yield)
    }, [type, hoardItemsData, customer, paymentType, hoardValue])

    const crumbs = useGetCrumbs('Acopios', RoutePath.HOARDS, undefined, undefined, 'Crear')

    const defaultDueDate = new Date()
    defaultDueDate.setMonth(defaultDueDate.getMonth() + 3)

    const filterProducts = (product: Product) =>
        !hoardIgnoredProductIds.includes(product.id) &&
        (currentHoardItem?.productId === product.id || !hoardItemsData.map(hoardItemData => hoardItemData.productId).includes(product.id))
    const filterLots = (lot: Lot) =>
        currentHoardItem?.lotId === lot.id || !hoardItemsData.map(hoardItemData => hoardItemData.lotId).includes(lot.id)

    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const hoardItemMatches = (data: Data, productId: string, lotId?: string) =>
        data.productId === productId && data.lotId === lotId

    const onTypeChange = (value?: FieldValue) => {
        const newType = value as HoardType | undefined
        setType(newType)
        if (newType === HoardType.FINANCIAL) {
            setHoardItemsData([])
        }
    }

    const onPaymentTypeChange = (value?: FieldValue) => setPaymentType(findPaymentType(value as string | undefined))

    const onCreateHoardItem = () => {
        setCurrentHoardItem(undefined)
        setValidations([])
        openFormModal()
    }

    const onEditHoardItem = (productId: string, lotId?: string) => {
        setCurrentHoardItem(hoardItemsData.find(hoardItemData => hoardItemMatches(hoardItemData, productId, lotId)))
        setValidations([])
        openFormModal()
    }

    const onRemoveHoardItem = (productId: string, lotId?: string) => {
        const newHoardItemsData = hoardItemsData.filter(hoardItemData => !hoardItemMatches(hoardItemData, productId, lotId))
        setHoardItemsData(newHoardItemsData)
        setValidations([])
    }

    const onValueChange = (value?: FieldValue) => {
        const newValue = value as number | undefined
        setHoardValue(newValue)
    }

    const fields: FormField[] = [
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal de Entrega',
            render: () => {
                const branch = define(find(TableName.BRANCHES, branchId))as Branch
                return <Badge label={branch.name} />
            }
        },
        {
            name: 'type',
            type: FieldType.SELECT,
            label: 'Tipo',
            defaultValue: type,
            options: Object.values(HoardType).map(type => option(type, Labels.hoardTypeLabel(type))),
            focus: true,
            onChange: onTypeChange
        },
        {
            name: 'sellerId',
            type: FieldType.TABLE,
            table: TableName.EMPLOYEES,
            label: 'Vendedor',
            render: () => (
                <EmployeeSearchField
                    id='sellerId'
                    defaultValue={seller?.id}
                    areas={[Area.MANAGMENT, Area.SALES]}
                    onChange={setSellerFromId}
                />
            )
        },
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS,
            label: 'Cliente',
            render: () => (
                <CustomerSearchField
                    id='customerId'
                    onChange={setCustomerFromId}
                />
            )
        },
        {
            name: 'dueDate',
            type: FieldType.DATE,
            label: 'Fecha Límite',
            defaultValue: defaultDueDate,
            min: new Date()
        },
        PaymentTypeField({
            onChange: onPaymentTypeChange
        }),
        {
            name: 'priceBreakdown',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceBreakdown data={priceBreakdown} />
        }
    ]
    if (isProductHoard) {
        const itemsField = {
            name: 'hoardItems',
            type: FieldType.TABLE,
            table: TableName.HOARD_ITEMS,
            render: () => (
                <Validatable validations={validations}>
                    <OperationItemsTable
                        rows={hoardItemsData}
                        operation={Operation.SALE}
                        onCreate={onCreateHoardItem}
                        onEditRow={onEditHoardItem}
                        onRemoveRow={onRemoveHoardItem}
                    />
                </Validatable>
            )
        }
        fields.splice(3, 0, itemsField)
    } else {
        const valueField = {
            name: 'value',
            type: FieldType.PRICE,
            label: 'Monto',
            onChange: onValueChange
        }
        fields.splice(4, 0, valueField)
    }

    const validate = async () => {
        if (isProductHoard) {
            const itemValidations = await validateItemsData(hoardItemsData, define(branchId))
            setValidations(itemValidations)
            return itemValidations.length === 0
        } else {
            return true
        }
    }

    const onSubmit = async (hoardData: Data) => {
        const valid = await validate()
        if (valid) {
            submitHoard(
                hoardData,
                code,
                define(type),
                define(branchId),
                define(seller).id,
                define(customer).id,
                hoardItemsData,
                define(paymentType).id,
                purchasePricesList
            )
        }
    }

    const onCancel = () => navigate(-1)

    const onSaveHoardItem = (newData: Data) => {
        let newHoardItemsData = hoardItemsData
        if (currentHoardItem) {
            const { productId, lotId } = currentHoardItem
            newHoardItemsData = hoardItemsData.map(hoardItemData => hoardItemMatches(hoardItemData, define(productId) as string, lotId as string | undefined) ? newData : hoardItemData)
        } else {
            newHoardItemsData = [newData, ...hoardItemsData]
        }
        setHoardItemsData(newHoardItemsData)
    }
    
    return (
        <PanelWrapper title="Crear Acopio" crumbs={crumbs} wide>
            <DataTypeForm
               formId="hoard-form"
               fields={fields}
               createMode
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentHoardItem}
                operation={Operation.SALE}
                withLots
                filterProducts={filterProducts}
                filterLots={filterLots}
                onSubmit={onSaveHoardItem}
            />
        </PanelWrapper>
    )
}
