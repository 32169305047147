import { useMemo } from "react"
import { Data, useDataTypesHooks, useDescriptorHooks } from "../../../../../features/data-types"
import { FieldType, Modal, ModalId } from "../../../../../features/ui"
import { useFind } from "../../../../../state/reducers/hooks"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { PurchaseOrder, PurchaseTicket, usePurchasesState } from "../../../../purchases"
import { PaymentTicketItem } from "../../../state/types"

type Props = {
    data?: Data,
    paymentTicketItems: PaymentTicketItem[],
    onSubmit: (data: Data) => void
}

export const PartialPaymentModal = (props: Props) => {
    const { data, paymentTicketItems, onSubmit } = props

    const { selectedTickets } = usePurchasesState()

    const { formatValue } = useDataTypesHooks()
    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const title = `Pago Parcial de Facturas`

    const pendingTickets = useMemo(() =>
        selectedTickets.filter(ticket => !paymentTicketItems.map(item => item.ticketId).includes(ticket.id)),
    [selectedTickets, paymentTicketItems])
    
    const renderBody = () => <>
        Se está efectuando un pago parcial del total de las facturas:<br />
        <ul>
            {paymentTicketItems.map((item, index) => {
                const purchaseTicket = define(find(TableName.PURCHASE_TICKETS, item.ticketId)) as PurchaseTicket
                const purchase = define(find(TableName.PURCHASE_ORDERS, purchaseTicket.purchaseId)) as PurchaseOrder
                const paymentLabel = item.pendingAmount === 0 ? 'Pago completo' : 'Pago parcial'
                return <li key={index}>
                    Compra {descriptorHooks.purchase.code(purchase)} -
                    Vto. {formatValue(new Date(purchaseTicket.dueDate), FieldType.DATE)}:
                    <b> {formatValue(item.payedAmount, FieldType.PRICE)} - {paymentLabel}</b>
                </li>
            })}

            {pendingTickets.map((purchaseTicket, index) => {
                const purchase = define(find(TableName.PURCHASE_ORDERS, purchaseTicket.purchaseId)) as PurchaseOrder
                const paymentLabel = 'Pago pendiente'
                return <li key={index}>
                    Compra {descriptorHooks.purchase.code(purchase)} -
                    Vto. {formatValue(new Date(purchaseTicket.dueDate), FieldType.DATE)}:
                    <b>{formatValue(0, FieldType.PRICE)} - {paymentLabel}</b>
                </li>
            })}
        </ul>
        Deseas confirmar el pago pacial?
    </>

    const handleSubmit = () => {
        data && onSubmit(data)
    }

    return (
        <Modal
            modalId={ModalId.PARTIAL_PAYMENT_MODAL}
            title={title}
            submitLabel="Confirmar"
            widthSize="m"
            onSubmit={handleSubmit}
        >
            <>{renderBody()}</>
        </Modal>
    )
}
