import { ActionButton } from '../action-button/actionButton'
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs'
import { Action, Crumb } from '../types'
import styles from './panelWrapper.module.css'

type Props = {
    title?: string
    children?: JSX.Element | JSX.Element[]
    crumbs?: Crumb[]
    wide?: boolean
    hideTitle?: boolean
    headerActionsRenderer?: () => JSX.Element // borrar
    actions?: Action[]
}

export const PanelWrapper = (props: Props) => {
    const { title, children, crumbs = [], wide, hideTitle, headerActionsRenderer, actions } = props
    const containerSpace = wide ? 12 : 8

    return (
        <>
            {crumbs.length > 0 && (
                <div className="pagetitle">
                    <Breadcrumbs crumbs={crumbs} />
                </div>
            )}
            <section className="section">
                <div className="row">
                    <div className={`col-lg-${containerSpace}`}>
                        <div className="card">
                            <div className="card-body">
                                <div className={styles.header}>
                                    {!hideTitle && (
                                        <h5 className="card-title">{title}</h5>
                                    )}
                                    {headerActionsRenderer && headerActionsRenderer()}
                                    {actions && actions.map((action, index) => (
                                        <ActionButton
                                            key={index}
                                            action={action}
                                            className={styles.actionBtn}
                                            onClick={action.onClick}
                                        />
                                    ))}
                                </div>
                                <div className="col-sm-12">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
