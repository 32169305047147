import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toListResponse } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useRemovedTableTenantKeyMigration = () => {
    const dynamoActions = useDynamoActions()
    
    const newDbTableName = (table: string) => table + '_TEMP'

    const createTable = (
        name: string,
        hasTKey: boolean,
        callback?: () => void
    ) => {
        const tables = [{ name, hasTKey }]
        DynamoUserApi.createTables(tables, callback)
    }

    const createNewTemporaryTable = (
        sourceTable: TableName
    ) => {
        const { dbTableName } = define(TablesData.get(sourceTable))
        createTable(newDbTableName(dbTableName), false)
    }

    const fillTemporaryTable = async (
        sourceTable: TableName
    ) => {
        const { getAll, createOrUpdate } = dynamoActions(DynamoUserApi)
        const { dbTableName } = define(TablesData.get(sourceTable))

        const listResponse = await getAll(dbTableName)
        const { dataTypes } = (toListResponse(listResponse))

        const newDataTypes = dataTypes.map(dataType => {
            const newDataType: DataType = {
                id: dataType.id
            }
            Object.keys(dataType)
                .filter(key => key !== 'companyId')
                .forEach(key => {
                    const propName = key as keyof DataType
                    newDataType[propName] = dataType[propName]
                })
            return newDataType
        }) as DataType[] as AttributeMap[]
        
        await createOrUpdate(newDbTableName(dbTableName), newDataTypes)
    }

    const reCreateTable = (
        sourceTable: TableName
    ) => {
        const { getAll, createOrUpdate } = dynamoActions(DynamoUserApi)
        const { dbTableName } = define(TablesData.get(sourceTable))
        
        const onCreateTable = async () => {
            const listResponse = await getAll(newDbTableName(dbTableName))
            const { dataTypes } = (toListResponse(listResponse))
            await createOrUpdate(dbTableName, dataTypes as AttributeMap[])
        }
        
        const onRemoveTable = () => createTable(dbTableName, false, onCreateTable)
        
        DynamoUserApi.removeTable(dbTableName, onRemoveTable)
    }

    const removeTemporaryTable = (
        sourceTable: TableName
    ) => {
        const { dbTableName } = define(TablesData.get(sourceTable))
        DynamoUserApi.removeTable(newDbTableName(dbTableName))
    }

    return {
        createNewTemporaryTable,
        fillTemporaryTable,
        reCreateTable,
        removeTemporaryTable
    }
}
