import { Data } from "../../../../features/ui"
import { define } from "../../../../utils/typeUtils"
import { OrderDelivery, DeliveryStatus, useOperationsActions } from "../../../operations"
import { useSystemStateHooks } from "../../../system"
import { HoardOrder, HoardItem, HoardType } from "../../state/types"
import { useCommonActions } from "./commonActions"
import { useFinancialHoardActions } from "./financialHoardActions"
import { useProductHoardActions } from "./productHoardActions"

export const useHoardActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const commonActions = useCommonActions()
    const productHoardActions = useProductHoardActions()
    const financialHoardActions = useFinancialHoardActions()
    const operationsActions = useOperationsActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const hoardCommonActions = commonActions(companyId)
        
        const fetchAllHoards = hoardCommonActions.fetchAllHoards

        const countHoards = hoardCommonActions.countHoards

        const saveHoard = async (
            hoard: HoardOrder,
            hoardItemsData: Data[]
        ) => {
            let savedHoard: HoardOrder
            if (hoard.type === HoardType.PRODUCT) {
                savedHoard = await productHoardActions(companyId).saveProductHoard(hoard, hoardItemsData)
            } else {
                savedHoard = await hoardCommonActions.saveHoard(hoard) as HoardOrder
            }
            
            return savedHoard
        }

        const removeHoard = async (id: string) => {
            const { fetchHoard, removeHoard, fetchHoardItemsByOrder, removeHoardItems } = commonActions(companyId)
            const { fetchDeliveries, removeDeliveries } = operationsActions()
            
            const stateHoard = define(await fetchHoard(id)) as HoardOrder
            
            if (stateHoard.deliveryStatus !== DeliveryStatus.DELIVERED) {
                await removeHoard(id)

                const hoardItems = await fetchHoardItemsByOrder(id) as HoardItem[]
                await removeHoardItems(hoardItems.map(hoardItem => hoardItem.id))

                const deliveries = await fetchDeliveries(id) as OrderDelivery[]
                const deliveryIds = deliveries.map(delivery => delivery.id)
                
                return removeDeliveries(deliveryIds)
            }
        }

        const fetchHoardItemsByOrder = commonActions(companyId).fetchHoardItemsByOrder

        const countHoardItems = hoardCommonActions.countHoardItems

        const saveHoardDelivery = async (
            hoard: HoardOrder,
            hoardItemsData: Data[],
            delivery: OrderDelivery
        ) => {
            if (hoard.type === HoardType.PRODUCT) {
                return productHoardActions(companyId).saveProductHoardDelivery(hoard, hoardItemsData as HoardItem[], delivery)
            } else {
                return financialHoardActions(companyId).saveFinancialHoardDelivery(hoard, hoardItemsData, delivery)
            }
        }

        const closeHoard = async (hoard: HoardOrder) => {
            const closedHoard: HoardOrder = {
                ...hoard,
                deliveryStatus: DeliveryStatus.DELIVERED
            }
            const savedHoard = await hoardCommonActions.saveHoard(closedHoard) as HoardOrder
            return savedHoard
        }

        return {
            fetchAllHoards,
            countHoards,
            saveHoard,
            removeHoard,
            fetchHoardItemsByOrder,
            countHoardItems,
            saveHoardDelivery,
            closeHoard
        }
    }
}
