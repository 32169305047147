import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../../features/ui"
import { usePurchasesActions } from "../../../actions/purchasesActions"
import { PurchaseList } from "./purchaseList"

export const PurchaseListLoader = () => {
    const [ready, setReady] = useState(false)

    const purchasesActions = usePurchasesActions()

    const populate = async () => {
        const { fetchAllSuppliers, fetchAllPurchaseTickets } = purchasesActions()
        await fetchAllSuppliers()
        await fetchAllPurchaseTickets()
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    return (
        <PanelLoader title="Órdenes de Compras" ready={ready} wide>
            <PurchaseList />
        </PanelLoader>
    )
}
