import { useEffect, useState } from "react"
import { Data, Modal, ModalId, useUIState } from "../../../../features/ui"
import { Lot, Product } from "../../../products"
import { Operation } from "../../../operations"
import { OperationItemForm } from "./operationItemForm"
import { PricesListItem } from "./types"

type Props = {
    operationItem?: Data
    operation: Operation
    branchId?: string
    withLots?: boolean
    pricesList?: PricesListItem[]
    filterProducts?: (product: Product) => boolean
    filterLots?: (lot: Lot) => boolean
    onSubmit: (data: Data) => void
}

export const OperationItemFormModal = (props: Props) => {
    const { operationItem, operation, branchId, withLots, pricesList, filterProducts, filterLots, onSubmit } = props
    const [key, setKey] = useState(0)
    const { openedModal } = useUIState()

    useEffect(() => setKey(key + 1), [openedModal])
    
    return (
        <Modal
            modalId={ModalId.OPERATION_ITEM_FORM}
            title="Agregar Producto"
            actions={[]}
            widthSize="l"
        >
            <OperationItemForm
                key={key}
                operationItem={operationItem}
                operation={operation}
                branchId={branchId}
                withLots={withLots}
                pricesList={pricesList}
                filterProducts={filterProducts}
                filterLots={filterLots}
                onSubmit={onSubmit}
            />
        </Modal>
    )
}
