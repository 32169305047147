import { Data, FieldType, Form, FormField, Modal, ModalId, option, useUIActions, useUIState } from '../../../../../features/ui'
import { TableName } from '../../../../../tables'
import { useProductsState } from '../../../../products'
import { useEffect, useState } from 'react'

type Props = {
    categorySaleYield?: Data
    filterCategories?: (categoryId: string) => boolean
    onSubmit: (data: Data) => void
}

export const CategorySaleYieldFormModal = (props: Props) => {
    const { categorySaleYield, filterCategories, onSubmit } = props
    const createMode = !categorySaleYield

    const [key, setKey] = useState(0)

    const { categories } = useProductsState()
    const { openedModal } = useUIState()

    const { closeModal } = useUIActions()
    
    useEffect(() => setKey(key + 1), [openedModal])

    const categoryOptions = categories
        .filter(category => filterCategories && filterCategories(category.id))
        .map(category => option(category.id, category.name))

    const fields: FormField[] = [
        {
            name: 'categoryId',
            type: FieldType.TABLE,
            table: TableName.CATEGORIES,
            defaultValue: categorySaleYield?.categoryId,
            label: 'Categoría',
            options: categoryOptions,
            disabled: !createMode
        },
        {
            name: 'saleYield',
            type: FieldType.PERCENTAGE,
            defaultValue: categorySaleYield?.saleYield,
            label: 'Rentabilidad'
        }
    ]

    const handleSubmit = (data: Data) => {
        onSubmit(data)
        closeModal(ModalId.CATEGORY_SALE_YIELD_FORM)
    }

    const handleCancel = () => closeModal(ModalId.CATEGORY_SALE_YIELD_FORM)

    return (
        <Modal
            modalId={ModalId.CATEGORY_SALE_YIELD_FORM}
            title="Agregar Rentabilidad por Categoría"
            actions={[]}
        >
            <Form
                key={key}
                formId="category-sale-yield-form"
                fields={fields}
                createMode={createMode}
                createLabel="Agregar"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
        </Modal>
    )
}
