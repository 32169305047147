import { TaxCategory } from "../../../modules/operations"
import { Supplier, usePurchasesActions } from "../../../modules/purchases"
import { TableName } from "../../../tables"
import { useFetchPropList, useGenerateNextValue2 } from "../../../tables/hooks"
import { define, isDefined, normalize, removeSpaces, toNumber } from "../../../utils/typeUtils"
import { Labels, newId, validateTaxId } from "../../data-types"
import { IndexedHeader } from "../types"

enum Header {
    NAME = 'Nombre',
    TAX_ID = 'Cuit/Cuil',
    TAX_CATEGORY = 'CategoriaFiscal',
    PRODUCT_SUPPLIER = 'DeMateriales',
    ADDRESS = 'Direccion'
}

export const useImportSuppliers = () => {
    const fetchPropList = useFetchPropList()
    const generateNextValue = useGenerateNextValue2()
    const purchasesActions = usePurchasesActions()
    
    return (
        file: File,
        companyId: string
    ) => {
        const reader = new FileReader()
        reader.onload = async e => {
            const data = e.target?.result as string
            const lines = data.trim().split(/[\r\n]+/)
            const firstLine = lines[0]
            const headersData = firstLine.split(';')
            
            const indexedHeaders: IndexedHeader[] = Object.values(Header).map(header => {
                const index = headersData.map(headerData => removeSpaces(normalize(headerData))).indexOf(normalize(header))
                return {
                    index,
                    header
                }
            })
            const validHeaders = !indexedHeaders.some(({ index }) => index === -1)

            if (validHeaders) {
                const rows = lines.slice(1)
                const suppliers: Supplier[] = []
                const invalidRows: string[] = []

                const getIndex = (headers: IndexedHeader[], headerValue: Header) => headers.find(({ header }) => header === headerValue)?.index

                const codes = await fetchPropList(TableName.SUPPLIERS, 'code', companyId)

                for (const row of rows) {
                    const cells = row.split(';')
                    
                    const nameIndex = getIndex(indexedHeaders, Header.NAME)
                    const nameValue = cells[define(nameIndex)]

                    const taxIdIndex = getIndex(indexedHeaders, Header.TAX_ID)
                    const taxIdValue = cells[define(taxIdIndex)]
                    const taxId = toNumber(taxIdValue)
                    
                    const taxCategoryIndex = getIndex(indexedHeaders, Header.TAX_CATEGORY)
                    const taxCategoryValue = cells[define(taxCategoryIndex)]
                    const taxCategory = Object.values(TaxCategory).find(value => normalize(taxCategoryValue) === normalize(Labels.taxCategoryLabel(value)))

                    const productSupplierIndex = getIndex(indexedHeaders, Header.PRODUCT_SUPPLIER)
                    const productSupplierValue = cells[define(productSupplierIndex)]
                    const transformLoted = (value: string) => normalize(value) === 'si'
                    const productSupplier = transformLoted(productSupplierValue)

                    const addressIndex = getIndex(indexedHeaders, Header.ADDRESS)
                    const addressValue = cells[define(addressIndex)]

                    const validCell = !!nameValue &&
                        (isDefined(taxId) && validateTaxId({ taxId }) === null) &&
                        !!taxCategory
                    
                    if (validCell) {
                        const code = await generateNextValue(TableName.SUPPLIERS, 'code', companyId, codes)
                        codes.push(code)
    
                        const supplier: Supplier = {
                            companyId,
                            id: newId(),
                            code,
                            name: nameValue,
                            taxId: define(taxId),
                            taxCategory,
                            productSupplier,
                            ...(addressValue && {
                                address: addressValue
                            })
                        }
                        suppliers.push(supplier)
                    } else {
                        invalidRows.push(row)
                    }
                }

                await purchasesActions(companyId).saveSuppliers(suppliers)

                console.log('Imported Data:', suppliers)
                console.log('Invalid Rows:', invalidRows)
            } else {
                console.log('Invalid Headers')
            }
        }
        reader.readAsText(file)
    }
}
