export const useWizardHooks = () => {
    const getData = (wizardKey: string) => {
        const wizardDataStr = localStorage.getItem(wizardKey) || '{}'
        try {
            return JSON.parse(wizardDataStr)
        } catch (error) {}
    }

    const getStepData = (wizardKey: string, stepIndex: number) => {
        const wizardData = getData(wizardKey)
        return wizardData[stepIndex] || {}
    }

    const containsData = (wizardKey: string) => {
        return Object.keys(getData(wizardKey)).length > 0
    }

    return {
        getData,
        getStepData,
        containsData
    }
}
