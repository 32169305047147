import { define } from '../../../utils/typeUtils'
import { DataType, TableName, TablesData } from '../../../tables'
import { ListResponse } from '../types'
import { AttributeMap, useDynamoActions, DynamoApi } from '../../../services/dynamo'
import { RangeParams, TextParams } from '../../../state/reducers/types'
import { toListResponse } from '../../../services/utils'

export const useDefaultDbActions = () => {
    const dynamoActions = useDynamoActions()

    const getDbTableName = (tableName: TableName) => define(TablesData.get(tableName)).dbTableName

    return (dynamoApi: DynamoApi, companyId?: string) => {
        const getAll = async (
            tableName: TableName,
            limit?: number,
            startKey?: TextParams,
            propName?: string,
        ) => {
            const { getAll } = dynamoActions(dynamoApi, companyId)
            return getAll(getDbTableName(tableName), limit, startKey, propName)
                .then(toListResponse)
        }

        const getByParams = async (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<DataType[]> => {
            const { getByParams } = dynamoActions(dynamoApi, companyId)            
            return getByParams(getDbTableName(tableName), paramsFilter)
                .then(response => response as DataType[])
        }

        const getByIds = async (
            tableName: TableName,
            ids: string[]
        ): Promise<DataType[]> => {
            const { getMultiple } = dynamoActions(dynamoApi, companyId)
            return getMultiple(getDbTableName(tableName), ids)
                .then(response => response as DataType[])
        }

        const getFiltered = async (
            tableName: TableName,
            propFilters?: TextParams,
            rangeFilters?: RangeParams
        ): Promise<ListResponse> => {
            const { getFiltered } = dynamoActions(dynamoApi, companyId)            
            return getFiltered(getDbTableName(tableName), propFilters, rangeFilters)
                .then(response => toListResponse(response))
        }

        const count = (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<number> => {
            const { count } = dynamoActions(dynamoApi, companyId)            
            return count(getDbTableName(tableName), paramsFilter)
        }

        const save = async (
            tableName: TableName,
            dataTypes: DataType[]
        ): Promise<DataType[]> => {
            const { createOrUpdate } = dynamoActions(dynamoApi, companyId)            
            return createOrUpdate(getDbTableName(tableName), dataTypes as AttributeMap[])
                .then(unprocessedItems => unprocessedItems as DataType[])
        }

        const remove = async (
            tableName: TableName,
            ids: string[]
        ): Promise<void> => {
            const actions = dynamoActions(dynamoApi, companyId)
            return actions.remove(getDbTableName(tableName), ids)
                .then(_ => {})
        }

        return {
            getAll,
            getByParams,
            getByIds,
            getFiltered,
            count,
            save,
            remove
        }
    }
}
