import { useNavigate } from "react-router-dom"
import { StockAction } from "../../../../features/ui"
import { useFind } from "../../../../state/reducers/hooks"
import { TableName } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { Product } from "../../../products"
import { useStockHooks } from "../../hooks/stockHooks"

export const useActions = () => {
    const navigate = useNavigate()
    const find = useFind()
    const { getStockActionRoute } = useStockHooks()

    const findProduct = (id?: string) => define(find(TableName.PRODUCTS, id)) as Product
    
    const onEditStock = (stockAction: StockAction) => {
        const actionPath = getStockActionRoute(stockAction)
        navigate(actionPath)
    }

    return {
        findProduct,
        onEditStock
    }
}
