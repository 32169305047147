import { UserRole } from '../../../modules/system'
import { UIPermission } from '../state/types'

export const uiWritePermissions = (): Map<UIPermission, UserRole[]> => new Map()
    .set(UIPermission.READ_TRANSACTIONS, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.READ_PRODUCT_PURCHASE_PRICE, [UserRole.MANAGER, UserRole.PURCHASES])
    .set(UIPermission.SAVE_COMPANY, [UserRole.MANAGER])
    .set(UIPermission.SAVE_REQUESTS, [UserRole.MANAGER])
    .set(UIPermission.SAVE_TAXES, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_CASH_FUNDS, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_CHECKS, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_CREDIT_CARDS, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_TRANSACTIONS, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_PAYMENT_TYPES, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_EMPLOYEES, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_EXPENSE_TYPES, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_EXPENSES, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_PAYMENT_ORDERS, [UserRole.MANAGER, UserRole.ADMINISTRATION])
    .set(UIPermission.SAVE_PURCHASE_TICKETS, [UserRole.MANAGER, UserRole.ADMINISTRATION, UserRole.PURCHASES])
    .set(UIPermission.SAVE_PURCHASES, [UserRole.MANAGER, UserRole.PURCHASES])
    .set(UIPermission.SAVE_PRODUCTS, [UserRole.MANAGER, UserRole.PURCHASES])
    .set(UIPermission.SAVE_PRODUCT_PRICES, [UserRole.MANAGER, UserRole.PURCHASES])
    .set(UIPermission.SAVE_SUPPLIERS, [UserRole.MANAGER, UserRole.PURCHASES])
    .set(UIPermission.SAVE_CATEGORIES, [UserRole.MANAGER, UserRole.PURCHASES])
    .set(UIPermission.SAVE_PURCHASES_SETTINGS, [UserRole.MANAGER, UserRole.PURCHASES])
    .set(UIPermission.SAVE_SALES, [UserRole.MANAGER, UserRole.SALES])
    .set(UIPermission.SAVE_SALE_DELIVERIES, [UserRole.MANAGER, UserRole.SALES, UserRole.WAREHOUSE])
    .set(UIPermission.SAVE_BUDGETS, [UserRole.MANAGER, UserRole.SALES])
    .set(UIPermission.SAVE_HOARDS, [UserRole.MANAGER, UserRole.SALES])
    .set(UIPermission.SAVE_HOARD_DELIVERIES, [UserRole.MANAGER, UserRole.SALES, UserRole.WAREHOUSE])
    .set(UIPermission.SAVE_CUSTOMER_TYPES, [UserRole.MANAGER, UserRole.SALES])
    .set(UIPermission.SAVE_CUSTOMERS, [UserRole.MANAGER, UserRole.SALES])
    .set(UIPermission.SAVE_SALES_SETTINGS, [UserRole.MANAGER, UserRole.SALES])
    .set(UIPermission.SAVE_PURCHASE_DELIVERIES, [UserRole.MANAGER, UserRole.WAREHOUSE])
    .set(UIPermission.SAVE_STOCK, [UserRole.MANAGER, UserRole.WAREHOUSE])
    