import { DataType } from '../../../tables'
import { RemoveChildrenFn, SaveChildrenFn } from '../types'
import { areDataTypesListsEqual } from '../utils/dataTypesUtils'

export const useChildrenActions = () => {
    const submitChildren = async (
        children: DataType[],
        newChildren: DataType[],
        saveChildrenFn: SaveChildrenFn,
        removeChildrenFn?: RemoveChildrenFn
    ) => {
        if (!areDataTypesListsEqual(children, newChildren)) {
            const newChildrenIds = newChildren.map(newChild => newChild.id)
            const childrenToRemove = children.filter(child => !newChildrenIds.includes(child.id))
            
            await saveChildrenFn(newChildren)
            if (childrenToRemove.length > 0 && removeChildrenFn) {
                await removeChildrenFn(childrenToRemove)
            }
        }
    }

    return {
        submitChildren
    }
}
