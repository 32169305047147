import { Currency } from "../../../tables/types/types"
import { TaxItem } from "../../administration"
import { DeliveryStatus, TaxCategory, PaymentStatus } from "../../operations"

export type Supplier = {
    companyId: string
    id: string
    code: number
    name: string
    taxId: number
    taxCategory: TaxCategory
    productSupplier: boolean
    phone?: number
    email?: string
    address?: string
    paymentDays?: number
}

export type PurchaseOrder = {
    companyId: string
    id: string
    code: number
    currency: Currency
    creationDate: string
    deliveryDate: string
    supplierId: string
    employeeId: string
    deliveryStatus: DeliveryStatus
    deliveryBranchId: string
    paymentStatus: PaymentStatus
}

export type PurchaseItem = {
    companyId: string
    id: string
    purchaseId: string
    productId: string
    quantity: number
    price: number
    currency: Currency
}

export enum PurchaseTicketType {
    TICKET = "Ticket",
    CREDIT_NOTE = 'CreditNote'
}

export type PurchaseTicket = {
    companyId: string
    id: string
    purchaseId: string
    type: PurchaseTicketType
    ticketUrl?: string
    amount: number
    vatAmount: number
    taxItems: TaxItem[]
    finalAmount: number
    creationDate: string
    dueDate: string
    currency: Currency
}
