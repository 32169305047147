import { ModalId } from "../components/modal/types"
import { StockAction } from "./types"

export enum ActionType {
    SET_DISABLE_BRANCH_SELECTOR = 'setDisableBranchSelector',
    SET_OPENED_MODAL = 'setOpenedModal',
    SET_SIDEBAR_EXPANDED = 'setSidebarExpanded',
    SET_STOCK_ACTION = 'setStockAction',
    SET_SPINNER_MODAL_MESSAGE = 'setSpinnerModalMessage'
}

interface SetDisableBranchSelector {
    type: ActionType.SET_DISABLE_BRANCH_SELECTOR
    payload: boolean
}

interface SetOpenedModal {
    type: ActionType.SET_OPENED_MODAL
    payload?: ModalId
}

interface SetStockAction {
    type: ActionType.SET_STOCK_ACTION
    payload?: StockAction
}

interface SetSpinnerModalMessage {
    type: ActionType.SET_SPINNER_MODAL_MESSAGE
    payload: string
}

export type UIAction =
    SetDisableBranchSelector |
    SetOpenedModal |
    SetStockAction |
    SetSpinnerModalMessage
