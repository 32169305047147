import styles from './pdfWrapper.module.css'
import { useMemo, useState } from "react"
import { DataType } from '../../../../tables'

type Props = {
    id: string
    dataType: DataType
    items: DataType[]
    previewMode?: boolean
    renderPDFPage: (
        pageIndex: number,
        dataType: DataType,
        items: DataType[],
        pagesAmount: number
    ) => JSX.Element
}

export const PDFWrapper = (props: Props) => {
    const { id, dataType, items, previewMode, renderPDFPage } = props

    const [currentPage, setCurrentPage] = useState(0)

    const getPagesItems = (dataTypeItems: DataType[]) => {
        const pages: DataType[][] = []
        const pushPages = (startIndex = 0) => {
            const isFirstPage = startIndex === 0
            const items = dataTypeItems.slice(startIndex)
            if (items.length > 0) {
                const maxPageSize = isFirstPage ? 10 : 13
                const pageSize = items.length < maxPageSize ? maxPageSize - 2 : (items.length === maxPageSize ? maxPageSize - 1 : maxPageSize)
                pages.push(items.slice(0, pageSize))
                pushPages(startIndex + pageSize)
            }
        }
        pushPages()
        
        return pages
    }

    const pagesItems: DataType[][] = useMemo(() => dataType ? getPagesItems(items) : [], [items])

    const previousPage = () => setCurrentPage(page => page - 1)
    const nextPage = () => setCurrentPage(page => page + 1)

    return (
        <div className={styles.container}>
            <div className={`${styles.arrow} ${styles.prevArrow}`}>
                {currentPage > 0 && <i className="bi bi-arrow-left-circle" onClick={previousPage} />}
            </div>
            <div className={styles.pdfContainer}>
                <div id={id}>
                    {pagesItems.map((pageItems, index) => (
                        <div
                            key={index}
                            className={`${styles.pageContainer} ${!previewMode || index === currentPage ? styles.currentPage : ''}`}
                        >
                            {renderPDFPage(index, dataType, pageItems, pagesItems.length)}
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${styles.arrow} ${styles.nextArrow}`}>
                {currentPage < pagesItems.length - 1 && <i className="bi bi-arrow-right-circle" onClick={nextPage} />}
            </div>
        </div>
    )
}
