import { Dispatch } from 'redux'
import { StockAction } from './types'
import { ActionType, UIAction } from './actionTypes'
import { ModalId } from '../components/modal/types'

export const setDisableBranchSelector = (disabled: boolean) => {
    return (dispatch: Dispatch<UIAction>) => {
        dispatch({
            type: ActionType.SET_DISABLE_BRANCH_SELECTOR,
            payload: disabled
        })
    }
}

export const setOpenedModal = (modalId?: ModalId) => {
    return (dispatch: Dispatch<UIAction>) => {
        dispatch({
            type: ActionType.SET_OPENED_MODAL,
            payload: modalId
        })
    }
}

export const setStockAction = (stockAction: StockAction) => {
    return (dispatch: Dispatch<UIAction>) => {
        dispatch({
            type: ActionType.SET_STOCK_ACTION,
            payload: stockAction
        })
    }
}

export const setSpinnerModalMessage = (message: string) => {
    return (dispatch: Dispatch<UIAction>) => {
        dispatch({
            type: ActionType.SET_SPINNER_MODAL_MESSAGE,
            payload: message
        })
    }
}
