import { Check, CheckStatus, CheckType } from "../../../state/types"
import { Data, useUIActions } from "../../../../../features/ui"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { define } from "../../../../../utils/typeUtils"

export const useActions = () => {
    const administrationActions = useAdministrationActions()
    const { onSubmitDataType } = useUIActions()

    const submitCheck = async (
        checkData: Data,
        createMode: boolean
    ) => {
        const { companyId, id, type, name, amount, taxId, endorsable } = checkData
        const issueDate = define(checkData.issueDate) as Date
        const paymentDate = define(checkData.paymentDate) as Date
        const check: Check = {
            companyId: companyId as string,
            id: id as string,
            type: type as CheckType,
            name: define(name) as string,
            amount: define(amount) as number,
            issueDate: issueDate.toISOString(),
            paymentDate: paymentDate.toISOString(),
            taxId: define(taxId) as number,
            endorsable: !!endorsable,
            status: CheckStatus.ENABLED
        }
        
        await administrationActions().saveCheck(check)
        onSubmitDataType(createMode)
    }
    
    return {
        submitCheck
    }
}
