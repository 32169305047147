import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toListResponse } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useRenamedPropMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyIds: string[],
        table: TableName,
        oldPropName: string,
        newPropName: string
    ) => {
        companyIds.forEach(async companyId => {
            const { getAll, createOrUpdate } = dynamoActions(DynamoUserApi, companyId)
            const { dbTableName } = define(TablesData.get(table))

            const listResponse = await getAll(dbTableName)
            const { dataTypes } = (toListResponse(listResponse))

            const updatedDataTypes = dataTypes.map(dataType => {
                const value = dataType[oldPropName as keyof DataType] || dataType[newPropName as keyof DataType]
                return {
                    ...dataType,
                    [oldPropName]: undefined,
                    [newPropName]: value
                }
            }) as AttributeMap[]

            await createOrUpdate(dbTableName, updatedDataTypes)
        })
    }
}
