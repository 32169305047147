import { useNavigate } from "react-router-dom"
import { Data, DataTypeForm, useDescriptorHooks, validateNumberLength } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { PanelWrapper, FieldType, FormField, useGetCrumbs } from "../../../../../features/ui"
import { CreditCard } from "../../../state/types"
import { useActions } from "./actions"

export const CreditCardForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const creditCard = dataType as CreditCard | undefined
    const createMode = !creditCard

    const navigate = useNavigate()
    const descriptorHooks = useDescriptorHooks()

    const { submitCreditCard } = useActions()

    const creditCardLabel = creditCard ? descriptorHooks.creditCard.info(creditCard) : undefined
    const creditCardTitleLabel = creditCard ? `: ${creditCardLabel}` : undefined
    const title = `${createMode ? 'Crear' : 'Editar'} Tarjeta${creditCardTitleLabel || ''}`
    const crumbs = useGetCrumbs('Tarjetas', RoutePath.CREDIT_CARDS, creditCardLabel, creditCard?.id, createMode ? 'Crear' : 'Editar')

    const fields: FormField[] = [
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            hint: 'VISA, AMEX, MasterCard, etc.'
        },
        {
            name: 'issuer',
            type: FieldType.TEXT,
            label: 'Emisor (Banco / Red)'
        },
        {
            name: 'cardholder',
            type: FieldType.TEXT,
            label: 'Titular'
        },
        {
            name: 'last4Numbers',
            type: FieldType.NUMBER,
            label: 'Últimos 4 Nros.',
            validate: (data: Data) => validateNumberLength(4, data.last4Numbers)
        },
        {
            name: 'dueDate',
            type: FieldType.DATE,
            label: 'Fecha de Vto.'
        }
    ]

    const onSubmit = (creditCardData: Data) => submitCreditCard(creditCardData, createMode)

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="credit-card-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
