import { Area } from "../../administration";
import { UserRole } from "../state/types";

export const getAreaFromRole = (role: UserRole): Area | undefined => {
    return new Map<UserRole, Area>()
        .set(UserRole.ADMINISTRATION, Area.ADMINISTRATION)
        .set(UserRole.MANAGER, Area.MANAGMENT)
        .set(UserRole.PURCHASES, Area.PURCHASES)
        .set(UserRole.SALES, Area.SALES)
        .set(UserRole.WAREHOUSE, Area.WAREHOUSE)
        .get(role)
}
