import { SpinnerDiv } from "../../../ui"

type Props = {
  ready: boolean
  children: JSX.Element
}

export const ElementLoader = (props: Props) => {
  const { ready, children } = props

  return ready ? children : <SpinnerDiv />
}
