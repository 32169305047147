import { Dispatch } from 'redux'
import { ActionType, AdministrationAction } from './actionTypes'
import { Employee, Settings, PaymentType, CashFund, Transaction, ExpenseType, Expense, Check, CreditCard, Tax, PaymentOrder } from './types'

export const setCurrentEmployee = (employee: Employee) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.SET_CURRENT_EMPLOYEE,
            payload: employee
        })
    }
}

export const updateTaxes = (taxes: Tax[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_TAXES,
            payload: taxes
        })
    }
}

export const removeTaxes = (ids: string[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.REMOVE_TAXES,
            payload: ids
        })
    }
}

export const updateSettings = (settings: Settings[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_SETTINGS,
            payload: settings
        })
    }
}

export const updateCashFunds = (cashFunds: CashFund[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_CASH_FUNDS,
            payload: cashFunds
        })
    }
}

export const removeCashFunds = (ids: string[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.REMOVE_CASH_FUNDS,
            payload: ids
        })
    }
}

export const updateChecks = (checks: Check[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_CHECKS,
            payload: checks
        })
    }
}

export const removeChecks = (ids: string[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.REMOVE_CHECKS,
            payload: ids
        })
    }
}

export const updateCreditCards = (creditCards: CreditCard[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_CREDIT_CARDS,
            payload: creditCards
        })
    }
}

export const removeCreditCards = (ids: string[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.REMOVE_CREDIT_CARDS,
            payload: ids
        })
    }
}

export const updateTransactions = (transactions: Transaction[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_TRANSACTIONS,
            payload: transactions
        })
    }
}

export const updateEmployees = (employees: Employee[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_EMPLOYEES,
            payload: employees
        })
    }
}

export const removeEmployees = (ids: string[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.REMOVE_EMPLOYEES,
            payload: ids
        })
    }
}

export const updatePaymentTypes = (paymentTypes: PaymentType[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_PAYMENT_TYPES,
            payload: paymentTypes
        })
    }
}

export const removePaymentTypes = (ids: string[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.REMOVE_PAYMENT_TYPES,
            payload: ids
        })
    }
}

export const updateExpenseTypes = (expenseTypes: ExpenseType[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_EXPENSE_TYPES,
            payload: expenseTypes
        })
    }
}

export const removeExpenseTypes = (ids: string[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.REMOVE_EXPENSE_TYPES,
            payload: ids
        })
    }
}

export const updateExpenses = (expenses: Expense[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_EXPENSES,
            payload: expenses
        })
    }
}

export const removeExpenses = (ids: string[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.REMOVE_EXPENSES,
            payload: ids
        })
    }
}

export const updatePaymentOrders = (orders: PaymentOrder[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.UPDATE_PAYMENT_ORDERS,
            payload: orders
        })
    }
}

export const removePaymentOrders = (ids: string[]) => {
    return (dispatch: Dispatch<AdministrationAction>) => {
        dispatch({
            type: ActionType.REMOVE_PAYMENT_ORDERS,
            payload: ids
        })
    }
}
