import { showToast } from "../../../../features/ui"
import { define } from "../../../../utils/typeUtils"
import { useManagerActions } from "../.."
import { useSystemState, Request, RequestStatus } from "../../../system"

export const useActions = () => {
    const { company } = useSystemState()
    const managerActions = useManagerActions()

    const resolveRequest = async (
        request: Request,
        approveMode: boolean
    ) => {
        const resolution = approveMode ? RequestStatus.APPROVED : RequestStatus.DENIED
        await managerActions().resolveRequest(define(request), define(company), resolution)
        showToast(`La solicitud de acceso fue ${approveMode ? 'aprobada' : 'rechazada'}.`)
    }

    return { resolveRequest }
}
