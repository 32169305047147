import { useEffect, useState } from "react"
import { Modal, ModalId, useUIState } from "../../../ui"

type Props = {
    onFinish: () => void
}

export const FinishWizardModal = (props: Props) => {
    const { onFinish } = props
    
    const { openedModal } = useUIState()

    const [focusFinish, setFocusFinish] = useState(false)

    useEffect(() => {
        setTimeout(() => setFocusFinish(openedModal === ModalId.FINISH_WIZARD), 500)
    }, [openedModal])

    const title = 'Finalizar proceso?'
    
    const renderBody = () => <>Desea confirmar la finalización del proceso?</>
    
    return (
        <Modal
            modalId={ModalId.FINISH_WIZARD}
            title={title}
            submitLabel="Confirmar"
            focusSubmit={focusFinish}
            onSubmit={onFinish}
        >
            <>{renderBody()}</>
        </Modal>
    )
}
