import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.css'
import styles from './dateField.module.css'
import DatePicker from 'react-datepicker'
import { useEffect, useState } from 'react'

type Props = {
    id: string
    value?: Date
    defaultValue?: Date
    minDate?: Date
    maxDate?: Date
    optional?: boolean
    disabled?: boolean
    placeholder?: string
    className?: string
    onChange?: (value?: Date) => void
}

export const DateField = (props: Props) => {
    const { id, value, defaultValue, minDate, maxDate, optional, disabled, placeholder, className, onChange } = props
    const [date, setDate] = useState<Date | undefined>(value || defaultValue)
    const [initialRender, setInitialRender] = useState(true)

    useEffect(() => {
        !initialRender && date !== value && setDate(value)
        setInitialRender(false)
    }, [value])

    const handleChange = (newDate: Date | null) => {
        setDate(newDate || undefined)
        onChange && onChange(newDate || undefined)
    }

    return (
        <div className={styles.container}>
            <DatePicker
                id={id}
                name={id}
                selected={date}
                dateFormat="dd/MM/yyyy"
                placeholderText={placeholder || 'Seleccionar fecha'}
                required={!optional}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                onChange={handleChange}
                className={`form-control ${className || styles.datePicker}`}
                autoComplete="off"
                aria-label={`${id} date field.`}
            />
        </div>
    )
}
