import { Currency } from "../../../tables/types/types"

export type Product = {
    companyId: string
    id: string
    code: number
    supplierCode: string
    name: string
    categoryId: string
    description?: string
    imageUrl?: string
    units: number
    unitTypeId: string
    loted: boolean
    purchasable: boolean
    salable: boolean
    supplierId: string
    vatTaxId: string
}

export type Category = {
    companyId: string
    id: string
    name: string
    imageUrl?: string
}

export type ProductPrices = {
    companyId: string
    id: string
    productId: string
    purchasePrice: number
    saleYield?: number
    currency: Currency
}

export enum LotAttribute {
    TONE = 'Tone',
    CALIBER = 'Caliber',
    DUE_DATE = 'DueDate'
}

export type LotAttributes = {
    [key in LotAttribute]?: string
}

export type Lot = {
    companyId: string
    id: string
    code: string
    productId: string
    attributes?: LotAttributes
}

export enum MeasureType {
    AREA = 'Area',
    HEIGHT = 'Height',
    DEPTH = 'Depth',
    DIAMETER = 'Diameter',
    THICKNESS = 'Thickness',
    VOLUME = 'Volume',
    WEIGHT = 'Weight',
    WIDTH = 'Width'
}

export type Measure = {
    companyId: string
    id: string
    productId: string
    type: MeasureType
    units: number
    unitTypeId: string
}

export type PurchasePricesData = {
    [key: string]: number
}
