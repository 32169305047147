import { ReducerState } from '../../../state/reducers/types'
import { addOrUpdate } from '../../../state/reducers/utils'
import { ActionType, SystemAction } from './actionTypes'
import { Company, Request, User } from "./types"

export type SystemState = {
    company?: Company
    user?: User
    construfyAdminId?: string
    requests: Request[]
    userRequest?: Request
    companyUsers: User[]
}

const initialState: SystemState = {
    requests: [],
    companyUsers: []
}

const reducer = (state = initialState, action: SystemAction) => {
    switch(action.type) {
        case ActionType.SET_COMPANY:
            return { 
                ...state,
                company: action.payload
            }
        case ActionType.SET_USER:
            return { 
                ...state,
                user: action.payload
            }
        case ActionType.SET_CONSTRUFY_ADMIN_ID:
            return {
                ...state,
                construfyAdminId: action.payload
            }
        case ActionType.UPDATE_REQUESTS:
            return { 
                ...state,
                requests: addOrUpdate(state, action, 'requests' as keyof ReducerState)
            }
        case ActionType.SET_USER_REQUEST:
            return {
                ...state,
                userRequest: action.payload
            }
        case ActionType.UPDATE_COMPANY_USERS:
            return { 
                ...state,
                companyUsers: addOrUpdate(state, action, 'companyUsers' as keyof ReducerState)
            }
        default:
            return state
    }
}

export default reducer
