import { useEffect, useState } from "react"
import { Action, PanelLoader } from "../../../../features/ui"
import { PendingPaymentList } from "./pendingPaymentList"
import { PurchaseTicket, usePurchasesActions } from "../../../purchases"
import { useAdministrationActions } from "../../actions/administrationActions"
import { dedupe } from "../../../../utils/listUtils"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"
import { useNavigate } from "react-router-dom"

export const PendingPaymentListLoader = () => {
    const [ready, setReady] = useState(false)

    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()

    const purchasesActions = usePurchasesActions()
    const administrationActions = useAdministrationActions()

    const populate = async () => {
        const { fetchAllPurchaseTickets, fetchPurchases, fetchAllSuppliers } = purchasesActions()
        const { fetchAllPaymentOrders } = administrationActions()
        const statePurchaseTickets = (await fetchAllPurchaseTickets()).dataTypes as PurchaseTicket[]
        const purchaseIds = statePurchaseTickets.map(purchaseTicket => purchaseTicket.purchaseId)
        await fetchPurchases(dedupe(purchaseIds))
        await fetchAllSuppliers()
        await fetchAllPaymentOrders()
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    const onGoToPaymentOrders = () => {
        navigate(getRoutePath(RoutePath.PAYMENT_ORDERS))
    }

    const headerActions: Action[] = [
        { label: 'Órdenes de Pago', kind: 'tertiary', onClick: onGoToPaymentOrders }
    ]

    return (
        <PanelLoader
            title="Pagos Pendientes"
            actions={headerActions}
            ready={ready}
            wide
        >
            <PendingPaymentList />
        </PanelLoader>
    )
}
