import styles from './inputGroup.module.css'
import { TextField } from '../text-field/textField'
import { NumberField } from '../number-field/numberField'
import { FieldType, TextOrNumber } from '../../types'

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    id: string
    inputKey?: string
    type?: FieldType
    value?: TextOrNumber
    defaultValue?: TextOrNumber
    preLabel?: string
    postLabel?: string
    optional?: boolean
    fit?: boolean
    className?: string
    onChange?: (value?: TextOrNumber) => void
}

export const InputGroup = (props: Props) => {
    const { id, inputKey, type = FieldType.NUMBER, value, defaultValue, preLabel, postLabel, optional, fit, className, onChange, ...rest } = props

    const renderField = () => {
        const radiusClass = `${preLabel ? styles.noLeftRadius : ''} ${postLabel ? styles.noRightRadius : ''}`
        const fieldClassName = `${styles.field} ${radiusClass}`

        if (type === FieldType.TEXT) {
            return <TextField
                id={id}
                inputKey={inputKey}
                value={value as string | undefined}
                defaultValue={defaultValue as string | undefined}
                freeValue={true}
                optional={optional}
                className={fieldClassName}
                onChange={onChange}
                {...rest}
            />
        } else if (type === FieldType.NUMBER) {
            return <NumberField
                id={id}
                inputKey={inputKey}
                value={value as number | undefined}
                defaultValue={defaultValue as number | undefined}
                optional={optional}
                fit={fit}
                className={fieldClassName}
                onChange={onChange}
                {...rest}
            />
        }
    }

    return (
        <div className={`input-group ${styles.container} ${className || ''}`}>
            {preLabel && <span className="input-group-text">{preLabel}</span>}
            {renderField()}
            {postLabel && <span className="input-group-text">{postLabel}</span>}
        </div>
    )
}
