import styles from './spinnerModal.module.css'
import { Modal, ModalId, Spinner, useUIState } from "../.."

export const SpinnerModal = () => {
    const { spinnerModalMessage } = useUIState()

    return (
        <Modal
            modalId={ModalId.SPINNER}
            title="No cierre la ventana"
            closeable={false}
            actions={[]}
        >
            <div className={styles.container}>
                <Spinner
                    label={spinnerModalMessage || "Guardando registro..."}
                    size="m"
                />
            </div>
        </Modal>
    )
}
