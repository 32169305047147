import styles from './productForm.module.css'
import { DataTypeForm, QuickFormModal, useDataTypesHooks, useDescriptorHooks } from "../../../../features/data-types"
import { PanelWrapper, Data, FieldType, FieldValue, FormField, ImageField, ModalId, NumberField, SelectField, Spinner, StaticValue, TextArea, option, useGetCrumbs, useUIActions, useUIState } from "../../../../features/ui"
import { useActions } from "./actions"
import { MeasureType, Product, ProductPrices } from "../../state/types"
import { useEffect, useState } from "react"
import { useFileActions } from "../../../../features/files"
import { useGenerateNextValue } from "../../../../tables/hooks"
import { TableName } from "../../../../tables"
import { DefaultUnitTypeId } from "../../../../features/data-types/standards/unitTypes"
import { useStockState } from "../../../stock"
import { define } from "../../../../utils/typeUtils"
import { Supplier, SupplierQuickForm, usePurchasesState } from '../../../purchases'
import { useFind } from '../../../../state/reducers/hooks'
import { MeasuresTable } from '../measures-table/measuresTable'
import { MeasureFormModal } from '../measure-form-modal/measureFormModal'
import { useProductsActions } from '../../actions/productsActions'
import { TaxType, useAdministrationHooks, useAdministrationState } from '../../../administration'
import { usePricesHooks } from '../../../operations'
import { PriceChoice } from './types'
import { useNavigate } from 'react-router-dom'
import { RoutePath, useRoutesHooks } from '../../../../features/routes'

export const ProductForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const product = dataType as Product | undefined
    const createMode = !product

    const { openedModal } = useUIState()
    const { suppliers } = usePurchasesState()
    const { unitTypes } = useStockState()
    const { taxes } = useAdministrationState()

    const { getParentSaleYield } = useAdministrationHooks()
    const generateNextValue = useGenerateNextValue()
    const descriptorHooks = useDescriptorHooks()
    const pricesHooks = usePricesHooks()
    const { formatValue } = useDataTypesHooks()
    const navigate = useNavigate()
    const find = useFind()
    
    const productPrices = find(TableName.PRODUCT_PRICES, product?.id, 'productId') as ProductPrices | undefined

    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [code, setCode] = useState(product?.code || 1)
    const [supplierId, setSupplierId] = useState<string | undefined>(product?.supplierId)
    const [categoryId, setCategoryId] = useState<string | undefined>(product?.categoryId)
    const [imageFile, setImageFile] = useState<File>()
    const [units, setUnits] = useState<number | undefined>(product?.units)
    const [unitTypeId, setUnitTypeId] = useState<string | undefined>(product?.unitTypeId || DefaultUnitTypeId)
    const [description, setDescription] = useState<string | undefined>(product?.description)
    const [measuresData, setMeasuresData] = useState<Data[]>([])
    const [currentMeasure, setCurrentMeasure] = useState<Data>()
    const [purchasePrice, setPurchasePrice] = useState<number | undefined>(productPrices?.purchasePrice)
    const [priceChoice, setPriceChoice] = useState<PriceChoice | undefined>(productPrices?.saleYield ? PriceChoice.PRODUCT_YIELD : PriceChoice.GLOBAL_YIELD)
    const [saleYield, setSaleYield] = useState<number | undefined>(productPrices?.saleYield)
    const [basePrice, setBasePrice] = useState<number | undefined>()
    const [key, setKey] = useState(0)

    const { submitProduct } = useActions()
    const productsActions = useProductsActions()
    const { fetchFile } = useFileActions()
    const { toggleModal } = useUIActions()

    const loadProductAsyncData = async () => {
        if (createMode) {
            setCode(await generateNextValue(TableName.PRODUCTS, 'code'))
        } else if (product) {
            const measuresDT = await productsActions().fetchMeasures(product.id)
            setMeasuresData(measuresDT as Data[])
            if (product.imageUrl) {
                const file = await fetchFile(product.imageUrl, 'product-image', true)
                setImageFile(file)
            }
        }
    }

    useEffect(() => {
        loadProductAsyncData().then(() => setAsyncDataLoaded(true))
    }, [])

    useEffect(() => setKey(key + 1), [openedModal])

    const productLabel = product ? `: ${descriptorHooks.product.code(product) }` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Producto${productLabel}`

    const crumbs = useGetCrumbs('Productos', RoutePath.PRODUCTS, product?.name, product?.id, createMode ? 'Crear' : 'Editar')
      
    const parentSaleYield = getParentSaleYield(categoryId)
    const supplierOptions = suppliers.filter(supplier => supplier.productSupplier).map(supplier => option(supplier.id, supplier.name))
    const filterMeasureTypes = (measureType: MeasureType) =>
        currentMeasure?.type === measureType || !measuresData.map(measureData => measureData.type).includes(measureType)

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

    const openFormModal = () => toggleModal(ModalId.MEASURE_FORM)

    const onCreateNewSupplier = () => toggleModal(ModalId.QUICK_FORM)

    const onChangeCategory = (value?: FieldValue) => setCategoryId(value as string | undefined)

    const onCreateMeasure = () => {
        setCurrentMeasure(undefined)
        openFormModal()
    }

    const onEditMeasure = (measureType: string) => {
        setCurrentMeasure(measuresData.find(measureData => measureData.type === measureType))
        openFormModal()
    }

    const onRemoveMeasure = (measureType: string) => {
        const newMeasuresData = measuresData.filter(measureData => measureData.type !== measureType)
        setMeasuresData(newMeasuresData)
    }

    const priceChoiceOptions = [
        option(PriceChoice.GLOBAL_YIELD, 'Usar rentabilidad general'),
        option(PriceChoice.PRODUCT_YIELD, 'Definir rentabilidad del producto'),
        option(PriceChoice.PRODUCT_PRICE, 'Definir precio de lista del producto'),
    ]

    const onChangePurchaePrice = (value?: FieldValue) => setPurchasePrice(value as number | undefined)
    
    const onChangePriceChoice = (value?: FieldValue) => {
        const newChoice = value as PriceChoice | undefined
        setPriceChoice(newChoice)
        setSaleYield(newChoice === PriceChoice.PRODUCT_YIELD ? parentSaleYield : undefined)
        setBasePrice(newChoice === PriceChoice.PRODUCT_PRICE ? pricesHooks.basePrice(purchasePrice || 0, parentSaleYield) : undefined)
    }

    const onChangeSaleYield = (value?: FieldValue) => setSaleYield(value as number | undefined)

    const onChangeBasePrice = (value?: FieldValue) => setBasePrice(value as number | undefined)

    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            value: supplierId,
            label: 'Proveedor',
            options: supplierOptions,
            onCreateNew: onCreateNewSupplier
        },
        {
            name: 'supplierCode',
            type: FieldType.TEXT,
            label: 'Código Proveedor'
        },
        {
            name: 'categoryId',
            type: FieldType.TABLE,
            table: TableName.CATEGORIES,
            label: 'Categoría',
            onChange: onChangeCategory
        },
        {
            name: 'units',
            type: FieldType.NUMBER,
            label: 'Contenido',
            render: () => {
                return <div className={styles.unitsContainer}>
                    <NumberField
                        id="units"
                        defaultValue={units}
                        min={0.01}
                        className={styles.units}
                        onChange={setUnits}
                    />
                    <SelectField
                        id="unitTypeId"
                        defaultValue={unitTypeId}
                        options={unitTypes.map(unitType => option(unitType.id, unitType.name))}
                        disabled={!createMode}
                        onChange={setUnitTypeId}
                    />
                </div>
            }
        },
        {
            name: 'measures',
            type: FieldType.TABLE,
            table: TableName.MEASURES,
            label: 'Medidas',
            render: () => renderAsync(
                <MeasuresTable
                    rows={measuresData}
                    onCreate={onCreateMeasure}
                    onEditRow={onEditMeasure}
                    onRemoveRow={onRemoveMeasure}
                />
            )
        },
        {
            name: 'loted',
            type: FieldType.BOOLEAN,
            label: 'Contiene Partidas'
        },
        {
            name: 'description',
            type: FieldType.TEXT,
            label: 'Descripción',
            render: () => <TextArea
                id="description"
                defaultValue={description}
                optional
                onChange={setDescription}
            />
        },
        {
            name: 'imageUrl',
            type: FieldType.IMAGE,
            label: 'Imagen',
            render: () => renderAsync(<ImageField
                id="imageUrl"
                value={imageFile}
                onChange={setImageFile}
            />)
        },
        {
            name: 'purchasePrice',
            type: FieldType.PRICE,
            defaultValue: purchasePrice,
            label: 'Costo',
            onChange: onChangePurchaePrice
        },
        {
            name: 'vatTaxId',
            type: FieldType.TABLE,
            table: TableName.TAXES,
            label: 'Condición de IVA',
            options: taxes.filter(tax => tax.type === TaxType.VAT).map(tax => option(tax.id, tax.name))
        },
        {
            name: 'priceChoice',
            type: FieldType.SELECT,
            label: ' ',
            defaultValue: priceChoice,
            options: priceChoiceOptions,
            onChange: onChangePriceChoice
        }
    ]

    if (priceChoice === PriceChoice.PRODUCT_YIELD) {
        const saleYieldField = {
            name: 'saleYield',
            type: FieldType.PERCENTAGE,
            defaultValue: saleYield,
            label: 'Rentabilidad de Venta',
            onChange: onChangeSaleYield
        }
        fields.push(saleYieldField)
    } else {
        const saleYieldField = {
            name: 'saleYield',
            type: FieldType.PERCENTAGE,
            label: 'Rentabilidad de Venta',
            render: () => {
                const value = priceChoice === PriceChoice.GLOBAL_YIELD ? parentSaleYield : pricesHooks.saleYield(purchasePrice || 0, basePrice || 0)
                return <>{formatValue(value, FieldType.NUMBER)}%</>
            }
        }
        fields.push(saleYieldField)
    }
    if (priceChoice === PriceChoice.PRODUCT_PRICE) {
        const minBasePrice = pricesHooks.basePrice(purchasePrice || 0, 0.01)
        const basePriceField = {
            name: 'basePrice',
            type: FieldType.PRICE,
            defaultValue: basePrice,
            label: 'Precio de Lista (sin IVA)',
            min: minBasePrice,
            onChange: onChangeBasePrice
        }
        fields.push(basePriceField)
    } else {
        const basePriceField = {
            name: 'basePrice',
            type: FieldType.PRICE,
            label: 'Precio de Lista (sin IVA)',
            render: () => {
                const value = pricesHooks.basePrice(purchasePrice || 0, priceChoice === PriceChoice.GLOBAL_YIELD ? parentSaleYield : saleYield)
                return <StaticValue
                    type={FieldType.PRICE}
                    value={value}
                />
            }
        }
        fields.push(basePriceField)
    }
    
    const onSubmit = (productData: Data) => {
        submitProduct(
            productData,
            createMode,
            code,
            define(units),
            define(unitTypeId),
            measuresData,
            description,
            imageFile,
            saleYield,
            basePrice
        )
    }

    const onCancel = () => navigate(-1)

    const onSaveMeasure = (newData: Data) => {
        const newMeasuresData = currentMeasure ?
            measuresData.map(measureData => measureData.type === currentMeasure.type ? newData : measureData) :
            [newData, ...measuresData]
        setMeasuresData(newMeasuresData)
    }

    const onSubmitSupplier = (supplier: Supplier) => {
        setSupplierId(supplier.id)
    }

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="product-form"
               fields={fields}
               createMode={createMode}
               disableSubmit={!asyncDataLoaded}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <QuickFormModal title="Crear Proveedor">
                <SupplierQuickForm
                    key={key}
                    productSupplier={true}
                    onSubmit={onSubmitSupplier}
                />
            </QuickFormModal>
            <MeasureFormModal
                measureData={currentMeasure}
                filterMeasureTypes={filterMeasureTypes}
                onSubmit={onSaveMeasure}
            />
        </PanelWrapper>
    )
}
