import { useState } from "react"
import { useFind, useFindMultipleByFilter } from "../../../state/reducers/hooks"
import { DataType, NamedDataType, TableName } from "../../../tables"
import { Option, TextField, option } from "../../../features/ui"

type Props = {
    id: string
    tableName: TableName
    defaultValue?: string
    placeholder?: string
    disabled?: boolean
    focus?: boolean
    filterFn: (value: string) => (dataType: DataType) => boolean
    toOption?: (dataType: DataType) => Option
    onChange?: (value?: string) => void
}

// TODO hacer que fetchee dynamicamente con los filtros que se le pasan (ej: name contains "jua")
export const SearchField = (props: Props) => {
    const { id, tableName, defaultValue, placeholder, disabled, focus, toOption, filterFn, onChange } = props
    const find = useFind()
    const defaultDataType = defaultValue && find(tableName, defaultValue)
    const renderOption = (dataType: DataType) => toOption ? toOption(dataType) : option(dataType.id, (dataType as NamedDataType).name || dataType.id)
    const defaultOptions = defaultDataType ? [renderOption(defaultDataType)] : []
    const [options, setOptions] = useState<Option[]>(defaultOptions)
    const findMultipleByFilter = useFindMultipleByFilter()    
    
    const onInputChange = (value?: string) => {
        if (value && value.length > 1) {
            const dataTypes = findMultipleByFilter(tableName, filterFn(value))
            setOptions(dataTypes.map(renderOption))
        } else {
            setOptions([])
        }
    }

    return (
        <TextField
            id={id}
            options={options}
            icon='search'
            defaultValue={defaultValue}
            placeholder={placeholder || 'Buscar por algún atributo'}
            disabled={disabled}
            focus={focus}
            onChange={onChange}
            onInputChange={onInputChange}
            aria-label={`${id} search field`}
        />
    )
}
