import { TableName } from "../../../../tables"
import { Column, FieldType, FieldValue, Section, Table } from "../../../../features/ui"
import { useFind, useFindMultipleByParams } from "../../../../state/reducers/hooks"
import { define } from "../../../../utils/typeUtils"
import { Product, ProductName } from "../../../products"
import { HoardItem, SaleItem } from '../../../sales'
import { PurchaseItem } from "../../../purchases"
import { Operation, OrderDelivery } from "../../state/types"

type Item = PurchaseItem | SaleItem | HoardItem

type Props = {
  orderId: string
  operation: Operation
}

export const PendingItemsList = (props: Props) => {
  const { orderId, operation } = props

  const find = useFind()
  const findMultipleByParams = useFindMultipleByParams()
  
  const tableName = () => {
    switch (operation) {
      case Operation.PURCHASE:
        return TableName.PURCHASE_ITEMS
      case Operation.HOARD:
        return TableName.HOARD_ITEMS
      case Operation.SALE:
      default:
        return TableName.SALE_ITEMS
    }
  }

  const params = {
    ...(operation === Operation.HOARD && { hoardId: orderId }),
    ...(operation === Operation.PURCHASE && { purchaseId: orderId }),
    ...(operation === Operation.SALE && { saleId: orderId })
  }
  const items = findMultipleByParams(tableName(), params) as Item[]
  const deliveries = findMultipleByParams(TableName.ORDER_DELIVERIES, { orderId }) as OrderDelivery[]

  const columns: Column[] = [
    {
      name: 'productId',
      type: FieldType.TEXT,
      label: 'Producto',
      render: (value?: FieldValue) => {
        const product = define(find(TableName.PRODUCTS, value as string | undefined)) as Product
        return <ProductName product={product} showSupplierCode />
      }
    },
    {
      name: 'quantity',
      type: FieldType.NUMBER,
      label: 'Cantidad Pendiente'
    }
  ]
  
  const deliveriesMap = deliveries.reduce((map, delivery) => {
    delivery.deliveredItems.forEach(deliveredItem => {
      const delivered = map.get(deliveredItem.itemId) || 0
      const deliveredSum = delivered + deliveredItem.delivered
      deliveredSum > 0 && map.set(deliveredItem.itemId, deliveredSum)
    })
    return map
  }, new Map<string, number>())

  const pendingItems = items
    .map(items => ({
      productId: items.productId,
      quantity: items.quantity - (deliveriesMap.get(items.id) || 0)
    }))
    .filter(item => item.quantity > 0)

  return (
    <Section
      id="delivery"
      title="Pendientes de Entrega"
      collapsable
      space={12}
    >
      <Table
        columns={columns}
        rows={pendingItems}
        rowId='productId'
        readOnly
        small
      />
    </Section>
  )
}
