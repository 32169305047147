import { Dispatch } from 'redux'
import { ActionType, ProductsAction } from './actionTypes'
import { Product, Category, ProductPrices, Measure, Lot } from './types'

export const updateProducts = (products: Product[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.UPDATE_PRODUCTS,
            payload: products
        })
    }
}

export const removeProducts = (ids: string[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.REMOVE_PRODUCTS,
            payload: ids
        })
    }
}

export const updateCategories = (categories: Category[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.UPDATE_CATEGORIES,
            payload: categories
        })
    }
}

export const removeCategories = (ids: string[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.REMOVE_CATEGORIES,
            payload: ids
        })
    }
}

export const updateProductPrices = (productPricesList: ProductPrices[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.UPDATE_PRODUCT_PRICES,
            payload: productPricesList
        })
    }
}

export const removeProductPrices = (ids: string[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.REMOVE_PRODUCT_PRICES,
            payload: ids
        })
    }
}

export const updateLots = (lots: Lot[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.UPDATE_LOTS,
            payload: lots
        })
    }
}

export const removeLots = (ids: string[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.REMOVE_LOTS,
            payload: ids
        })
    }
}

export const updateMeasures = (measures: Measure[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.UPDATE_MEASURES,
            payload: measures
        })
    }
}

export const removeMeasures = (ids: string[]) => {
    return (dispatch: Dispatch<ProductsAction>) => {
        dispatch({
            type: ActionType.REMOVE_MEASURES,
            payload: ids
        })
    }
}
