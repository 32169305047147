import { fileName, useFileActions } from '../../../../features/files'
import { FileTile, FileType, Modal, ModalId } from '../../../../features/ui'
import { DataType, TableName } from '../../../../tables'

type Props = {
    modalId: ModalId
    dataType?: DataType
    tableName: TableName
    fileProp: keyof DataType
    title?: string
    fileNameParam?: string
}

export const DownloadFileModal = (props: Props) => {
    const { modalId, dataType, tableName, fileProp, title, fileNameParam } = props

    const dataTypeFileName = fileNameParam || (dataType && dataType[fileProp] ? fileName(tableName, dataType) : undefined)

    const { downloadFile } = useFileActions()

    const onSubmit = () => {
        if (dataType && dataType[fileProp] && dataTypeFileName) {
            downloadFile(dataType[fileProp], dataTypeFileName)
        }
    }
    
    return (
        <Modal
            modalId={modalId}
            title={title || 'Descargar Comprobante'}
            submitLabel="Descargar"
            submitIcon="download"
            onSubmit={onSubmit}
        >
            <FileTile
                fileType={FileType.PDF}
                fileName={dataTypeFileName}
            />
        </Modal>
    )
}
