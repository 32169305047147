import { PurchaseItem, PurchaseOrder, PurchaseTicket, Supplier } from "./types"

export enum ActionType {
    UPDATE_PURCHASES = 'updatePurchases',
    REMOVE_PURCHASES = 'removePurchases',
    UPDATE_PURCHASE_ITEMS = 'updatePurchaseItems',
    REMOVE_PURCHASE_ITEMS = 'removePurchaseItems',
    UPDATE_PURCHASE_TICKETS = 'updatePurchaseTickets',
    REMOVE_PURCHASE_TICKETS = 'removePurchaseTickets',
    UPDATE_SUPPLIERS = 'updateSuppliers',
    REMOVE_SUPPLIERS = 'removeSuppliers',
    SELECT_TICKETS = 'selectTickets'
}

interface UpdatePurchases {
    type: ActionType.UPDATE_PURCHASES
    payload: PurchaseOrder[]
}

interface RemovePurchases {
    type: ActionType.REMOVE_PURCHASES
    payload: string[]
}

interface UpdatePurchaseItems {
    type: ActionType.UPDATE_PURCHASE_ITEMS
    payload: PurchaseItem[]
}

interface RemovePurchaseItems {
    type: ActionType.REMOVE_PURCHASE_ITEMS
    payload: string[]
}

interface UpdatePurchaseTickets {
    type: ActionType.UPDATE_PURCHASE_TICKETS
    payload: PurchaseTicket[]
}

interface RemovePurchaseTickets {
    type: ActionType.REMOVE_PURCHASE_TICKETS
    payload: string[]
}

interface UpdateSuppliers {
    type: ActionType.UPDATE_SUPPLIERS
    payload: Supplier[]
}

interface RemoveSuppliers {
    type: ActionType.REMOVE_SUPPLIERS
    payload: string[]
}

interface SelectTickets {
    type: ActionType.SELECT_TICKETS
    payload: PurchaseTicket[]
}

export type PurchasesAction =
    PurchasesUpdateAction |
    PurchasesRemoveAction |
    SelectTickets

export type PurchasesUpdateAction =
    UpdatePurchases |
    UpdatePurchaseItems |
    UpdatePurchaseTickets |
    UpdateSuppliers

export type PurchasesRemoveAction =
    RemovePurchases |
    RemovePurchaseItems |
    RemovePurchaseTickets |
    RemoveSuppliers
