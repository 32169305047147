import styles from './spinnerDiv.module.css'
import { Spinner } from "./spinner"

type Props = {
    size?: 's' | 'm' | 'l'
}
export const SpinnerDiv = (props: Props) => {
    const { size = 'l' } = props
    
    return (
        <div className={styles.container}>
            <Spinner size={size} />
        </div>
    )
}
