import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data, ModalId, useUIActions } from "../../../../../features/ui"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Currency, TableName } from "../../../../../tables/types/types"
import { define, round } from "../../../../../utils/typeUtils"
import { PurchaseTicket, usePurchasesStateActions } from "../../../../purchases"
import { SelectedTickets } from "../../../../purchases/state/constants"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { useAdministrationHooks } from "../../../hooks/administrationHooks"
import { PaymentCategory, PaymentItem, PaymentOrder, PaymentOrderStatus, PaymentTicketItem, TaxItem } from "../../../state/types"

export const useActions = () => {
    const { getTicketPendingAmount } = useAdministrationHooks()
    const generateNextValue = useGenerateNextValue()

    const { selectTickets } = usePurchasesStateActions()
    const administrationActions = useAdministrationActions()
    const { openModal } = useUIActions()

    const addPurchaseTicketItem = (
        amount: number,
        pendingTickets: PurchaseTicket[],
        items: PaymentTicketItem[] = []
    ): PaymentTicketItem[] => {
        if (amount <= 0 || pendingTickets.length === 0) {
            return items
        }
        
        const sortedTickets = pendingTickets.sort((ticket_A, ticket_B) => ticket_A.dueDate.localeCompare(ticket_B.dueDate))
        const ticket = define(sortedTickets.shift())
        const payedVatAmount = amount * ticket.vatAmount / ticket.finalAmount
        const ticketPendingAmount = getTicketPendingAmount(ticket)
        if (amount <= ticketPendingAmount.pendingAmount) {
            items.push({
                ticketId: ticket.id,
                payedAmount: amount,
                pendingAmount: ticketPendingAmount.pendingAmount - amount,
                payedVatAmount,
                pendingVatAmount: ticketPendingAmount.pendingVatAmount - payedVatAmount
            })
            return items
        } else {
            items.push({
                ticketId: ticket.id,
                payedAmount: ticketPendingAmount.pendingAmount,
                pendingAmount: 0,
                payedVatAmount: ticketPendingAmount.pendingVatAmount,
                pendingVatAmount: 0
            })
            return addPurchaseTicketItem(round(amount - ticketPendingAmount.pendingAmount), sortedTickets, items)
        }
    }

    const submitPurchaseOrderOrOpenModal = (
        partialPayment: boolean,
        paymentOrderData: Data,
        ticketItems: PaymentTicketItem[],
        taxItemsData: Data[],
        paymentItemsData: Data[]
    ) => {
        if (partialPayment) {
            openModal(ModalId.PARTIAL_PAYMENT_MODAL)
        } else {
            submitPurchaseOrder(paymentOrderData, ticketItems, taxItemsData, paymentItemsData)
        }
    }
    
    const submitPurchaseOrder = async (
        paymentOrderData: Data,
        ticketItems: PaymentTicketItem[],
        taxItemsData: Data[],
        paymentItemsData: Data[]
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, amount } = paymentOrderData
        const code = await generateNextValue(TableName.PAYMENT_ORDERS, 'code')
        const taxItems: TaxItem[] = taxItemsData.map(taxItemData => ({
            taxCode: define(taxItemData.taxCode) as string,
            taxYield: define(taxItemData.taxYield) as number,
            amount: define(taxItemData.amount) as number
        }))
        const paymentItems: PaymentItem[] = paymentItemsData.map(paymentItemData => ({
            paymentTypeId: define(paymentItemData.paymentTypeId) as string,
            paymentCategory: define(paymentItemData.paymentCategory) as PaymentCategory,
            sourceId: define(paymentItemData.sourceId) as string,
            amount: define(paymentItemData.amount) as number
        }))
        const paymentOrder: PaymentOrder = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            code,
            ticketItems,
            amount: define(amount) as number,
            taxItems,
            paymentItems,
            status: PaymentOrderStatus.EMITTED,
            creationDate: new Date().toISOString(),
            currency: Currency.ARS
        }
        
        await administrationActions().savePaymentOrder(paymentOrder)
        Emitter.emit(Event.DB_SUBMIT_DONE, true)
        cleanSelectedTickets()
    }

    const cleanSelectedTickets = () => {
        selectTickets([])
        localStorage.removeItem(SelectedTickets)
    }
    
    return {
        addPurchaseTicketItem,
        submitPurchaseOrderOrOpenModal,
        submitPurchaseOrder,
        cleanSelectedTickets
    }
}
