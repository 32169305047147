import { newId } from "../../../../features/data-types"
import { define } from "../../../../utils/typeUtils"
import { useSystemActions } from "../../actions/systemActions"
import { useSystemState, useSystemStateActions, useSystemStateHooks } from "../../state/hooks"
import { Request, RequestStatus, UserRole } from "../../state/types"

export const useActions = () => {
    const { user } = useSystemState()
    const { companyId } = useSystemStateHooks()
    const { setUserRequest } = useSystemStateActions()
    const systemActions = useSystemActions()

    const requestAccess = async (role: UserRole) => {
        const request : Request = {
            id: newId(),
            companyId: define(companyId),
            userId: define(user?.id),
            role,
            status: RequestStatus.PENDING
        }
        
        const savedRequest = await systemActions().saveRequest(request, true) as Request
        setUserRequest(savedRequest)
    }
    
    return { requestAccess }
}
