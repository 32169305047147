import { Action, TableActions, readPermissions } from "../../../features/roles";
import { TableName } from '../../../tables'

export const administrationPermissions = (): TableActions[] => readPermissions().concat([
    { tableName: TableName.CASH_FUNDS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.CHECKS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.CREDIT_CARDS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.EMPLOYEES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.EXPENSE_TYPES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.EXPENSES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PAYMENT_ORDERS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PAYMENT_TYPES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PURCHASE_TICKETS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.SETTINGS, actions: [Action.SAVE] },
    { tableName: TableName.TAXES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.TRANSACTIONS, actions: [Action.SAVE, Action.REMOVE] }
])
