import { Emitter, Event } from "../../../../../features/event-emitter"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { PaymentOrder, PaymentOrderStatus } from "../../../state/types"

export const useActions = () => {
    const administrationActions = useAdministrationActions()

    const submitReceipt = async (
        paymentOrder: PaymentOrder,
        receiptFile: File
    ) => {
        Emitter.emit(Event.DB_SUBMIT)
        
        const updatedPaymentOrder: PaymentOrder = {
            ...paymentOrder,
            status: PaymentOrderStatus.PAYED
        }

        await administrationActions().savePaymentOrder(updatedPaymentOrder, receiptFile)
        Emitter.emit(Event.DB_SUBMIT_DONE, false, false)
    }
    
    return {
        submitReceipt
    }
}
