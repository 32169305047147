import { useEffect, useMemo, useState } from "react"
import { FormField, Data, useUIStateActions, ModalId, useUIActions, FieldValue, FieldType, option } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"
import { DataTypeForm } from "../../../../../features/data-types"
import { Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Validatable } from "../../../../../features/ui"
import { Operation, OperationItemFormModal, OperationItemsTable, PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { useFind } from "../../../../../state/reducers/hooks"
import { PurchaseItem, PurchaseOrder, Supplier } from "../../../state/types"
import { useManagerState } from "../../../../manager"
import { useNavigate } from "react-router-dom"
import { usePurchasesState } from "../../../hooks/purchasesHooks"

type Props = {
    purchase?: PurchaseOrder
    purchaseItems: PurchaseItem[]
}

export const PurchaseForm = (props: Props) => {
    const { purchase, purchaseItems } = props
    const createMode = !purchase

    const { suppliers } = usePurchasesState()
    const { currentBranch } = useManagerState()
    
    const [code, setCode] = useState(purchase?.code || 1)
    const purchaseSupplier = suppliers.find(supplierItem => supplierItem.id === purchase?.supplierId)
    const [supplier, setSupplier] = useState<Supplier | undefined>(purchaseSupplier)
    const [branchId, setBranchId] = useState<string>()
    const [purchaseItemsData, setPurchaseItemsData] = useState<Data[]>(purchaseItems)
    const [currentPurchaseItem, setCurrentPurchaseItem] = useState<Data>()
    const [itemsValidations, setItemsValidations] = useState<string[]>([])

    const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()
    const find = useFind()

    const { submitPurchase } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { toggleModal } = useUIActions()

    const init = async () => {
        setDisableBranchSelector(true)
        if (createMode) {
            setCode(await generateNextValue(TableName.PURCHASE_ORDERS, 'code'))
        }
    }

    useEffect(() => {
        init()
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    const purchaseItemMatches = (data: Data, productId: string) => data.productId === productId
    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)
    const filterProducts = (product: Product) => {
        return product.supplierId === supplier?.id &&
        (currentPurchaseItem?.productId === product.id || !purchaseItemsData.map(saleItemData => saleItemData.productId).includes(product.id))
    }
    const priceBreakdown = useMemo(() =>
        getPriceBreakdownFromItems(purchaseItemsData),
    [purchaseItemsData])

    const supplierOptions = suppliers
        .filter(supplier => supplier.productSupplier)
        .map(supplier => option(supplier.id, supplier.name))

    const onSupplierChange = (value?: FieldValue) => {
        const newSupplier = find(TableName.SUPPLIERS, value as string | undefined) as Supplier | undefined
        setSupplier(newSupplier)
        setPurchaseItemsData([])
    }

    const onBranchChange = (value?: FieldValue) => {
        setBranchId(value as string | undefined)
    }

    const onCreatePurchaseItem = () => {
        setCurrentPurchaseItem(undefined)
        setItemsValidations([])
        openFormModal()
    }

    const onEditPurchaseItem = (productId: string) => {
        setCurrentPurchaseItem(purchaseItemsData.find(saleItemData => purchaseItemMatches(saleItemData, productId)))
        openFormModal()
    }

    const onRemovePurchaseItem = (productId: string) => {
        const newPurchaseItemsData = purchaseItemsData.filter(saleItemData => !purchaseItemMatches(saleItemData, productId))
        setPurchaseItemsData(newPurchaseItemsData)
    }

    const fields: FormField[] = [
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            options: supplierOptions,
            focus: true,
            onChange: onSupplierChange
        },
        {
            name: 'deliveryBranchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            defaultValue: currentBranch?.id,
            label: 'Sucursal de Recepción',
            onChange: onBranchChange
        },
        {
            name: 'deliveryDate',
            type: FieldType.DATE,
            defaultValue: purchase?.deliveryDate || new Date(),
            label: 'Fecha de Recepción',
            min: new Date()
        },
        {
            name: 'purchaseItems',
            type: FieldType.TABLE,
            table: TableName.PURCHASE_ITEMS,
            render: () => (
                <Validatable validations={itemsValidations}>
                    <OperationItemsTable
                        rows={purchaseItemsData}
                        operation={Operation.PURCHASE}
                        disableCreate={!supplier}
                        onCreate={onCreatePurchaseItem}
                        onEditRow={onEditPurchaseItem}
                        onRemoveRow={onRemovePurchaseItem}
                    />
                </Validatable>
            )
        },
        {
            name: 'priceBreakdown',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceBreakdown data={priceBreakdown} />
        }
    ]

    const validate = (): boolean => {
        if (purchaseItemsData.length === 0) {
            setItemsValidations(['Debe agregar al menos 1 producto'])
            return false
        }

        return true
    }

    const onSubmit = (purchaseData: Data) => {
        if (validate()) {
            submitPurchase(
                purchaseData,
                code,
                purchaseItemsData,
                createMode
            )
        }
    }

    const onCancel = () => navigate(-1)

    const onSavePurchaseItem = (newData: Data) => {
        let newPurchaseItemsData = purchaseItemsData
        if (currentPurchaseItem) {
            const { productId } = currentPurchaseItem
            newPurchaseItemsData = purchaseItemsData.map(saleItemData => purchaseItemMatches(saleItemData, define(productId) as string) ? newData : saleItemData)
        } else {
            newPurchaseItemsData = [newData, ...purchaseItemsData]
        }
        setPurchaseItemsData(newPurchaseItemsData)
    }

    return (
        <>
            <DataTypeForm
               formId="purchase-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentPurchaseItem}
                operation={Operation.PURCHASE}
                branchId={branchId}
                filterProducts={filterProducts}
                onSubmit={onSavePurchaseItem}
            />
        </>
    )
}
