import { Data, ModalId, useUIActions } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { TaxCategory } from "../../../../operations"
import { usePurchasesActions } from "../../../actions/purchasesActions"
import { Supplier } from "../../../state/types"

export const useActions = () => {
    const purchasesActions = usePurchasesActions()
    const { onSubmitDataType, toggleModal } = useUIActions()

    const submitSupplier = async (
        supplierData: Data,
        code: number,
        productSupplier: boolean
    ) => {
        const { companyId, id, name, taxId, taxCategory } = supplierData
        const supplier: Supplier = {
            companyId: companyId as string,
            id: id as string,
            code,
            name: define(name) as string,
            taxId: define(taxId) as number,
            taxCategory: define(taxCategory) as TaxCategory,
            productSupplier
        }
        
        const savedSupplier = await purchasesActions().saveSupplier(supplier) as Supplier
        onSubmitDataType(true, false)
        toggleModal(ModalId.QUICK_FORM)
        
        return savedSupplier
    }
    
    return {
        submitSupplier
    }
}
