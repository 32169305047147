import { RoutePath, useRoutesHooks } from "../../../../features/routes"
import { DetailFieldProps, DetailPanel, FieldType, UIPermission, useGetCrumbs } from "../../../../features/ui"
import { TableName } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { useSystemStateHooks } from "../../../system"
import { Branch } from "../../state/types"

export const BranchDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const branch = define(dataType) as Branch

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_BRANCHES)

  const crumbs = useGetCrumbs('Sucursales', RoutePath.BRANCHES, branch.name)

  const fields: DetailFieldProps[] = [
    { name: 'code', type: FieldType.TEXT, label: 'Código' },
    { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
    { name: 'address', type: FieldType.TEXT, label: 'Dirección' }
  ]

  return (<>
    <DetailPanel
      tableName={TableName.BRANCHES}
      crumbs={crumbs}
      fields={fields}
      data={branch}
      icon='building'
      readOnly={readOnly}
    />
  </>)
}
