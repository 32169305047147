import { useDescriptorHooks } from "../../../../features/data-types"
import { showToast } from "../../../../features/ui"
import { capitalize, define } from "../../../../utils/typeUtils"
import { PurchaseOrder } from "../../../purchases"
import { HoardOrder, SaleOrder, useSalesActions } from "../../../sales"
import { Operation, OperationOrder } from "../../state/types"

export const useActions = () => {
    const descriptorHooks = useDescriptorHooks()
    const salesActions = useSalesActions()

    const getOperationLabel = (
        operationType: Operation,
        operation: OperationOrder
    ): string => {
        const operationLabel = new Map<Operation, string>()
            .set(Operation.PURCHASE, `la Compra ${descriptorHooks.purchase.code(operation as PurchaseOrder)}`)
            .set(Operation.SALE, `la Venta ${descriptorHooks.sale.code(operation as SaleOrder)}`)
            .set(Operation.HOARD, `el Acopio ${descriptorHooks.hoard.code(operation as HoardOrder)}`)
        return define(operationLabel.get(operationType))
    }

    const closeOperation = async (
        operationType: Operation,
        operation: OperationOrder
    ) => {
        if (operationType == Operation.HOARD) {
            await salesActions().closeHoard(operation as HoardOrder)
            
            const closedLabel = new Map<Operation, string>()
                .set(Operation.PURCHASE, `cerrada`)
                .set(Operation.SALE, `cerrada`)
                .set(Operation.HOARD, `cerrado`)
            showToast(`${capitalize(getOperationLabel(operationType, operation))} fue ${closedLabel.get(operationType)}.`)
        }
    }

    return {
        getOperationLabel,
        closeOperation
    }
}
