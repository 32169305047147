import { IAMApi } from "../iam"
import { DynamoApi } from "../dynamo"
import { S3Api } from "../s3"

export let DynamoSystemApi: DynamoApi
export let DynamoUserApi: DynamoApi
export let IAMSystemApi: IAMApi
export let IAMUserApi: IAMApi
export let S3SystemApi: S3Api

export const initSystemApis = (accessKeyId: string, secretAccessKey: string, tenantKey: string, uniqueKey: string) => {
    DynamoSystemApi = new DynamoApi(accessKeyId, secretAccessKey, tenantKey, uniqueKey)
    S3SystemApi = new S3Api(accessKeyId, secretAccessKey)
}

export const initIamSystemApi = (accessKeyId: string, secretAccessKey: string) =>
    IAMSystemApi = new IAMApi(accessKeyId, secretAccessKey)

export const useServicesHooks = () => {
    const initDynamoUserApi = (accessKeyId: string, secretAccessKey: string, tenantKey: string, uniqueKey: string, sessionToken: string) =>
        DynamoUserApi = new DynamoApi(accessKeyId, secretAccessKey, tenantKey, uniqueKey, sessionToken)

    const initIamUserApi = (accessKeyId: string, secretAccessKey: string, sessionToken: string) =>
        IAMUserApi = new IAMApi(accessKeyId, secretAccessKey, sessionToken)

    const initUserApis = async (roleName: string, userEmail: string, tenantKey: string, uniqueKey: string) => {
        const { Credentials } = await IAMSystemApi.assumeRole(roleName, userEmail)
        
        if (Credentials?.Expiration) {
            const expirationMs = Credentials.Expiration.getTime() - new Date().getTime()
            const tenMinutesMs = 600000
            const credentialsRenewalMs = expirationMs - tenMinutesMs
            setTimeout(() => initUserApis(roleName, userEmail, tenantKey, uniqueKey), credentialsRenewalMs)

            const accessKeyId = Credentials.AccessKeyId!
            const secretAccessKey = Credentials.SecretAccessKey!
            const sessionToken = Credentials.SessionToken!
            initDynamoUserApi(accessKeyId, secretAccessKey, tenantKey, uniqueKey, sessionToken)
            initIamUserApi(accessKeyId, secretAccessKey, sessionToken)
        }
    }

    return {
        initSystemApis,
        initIamSystemApi,
        initUserApis
    }
}
