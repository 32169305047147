import { useSelector } from "react-redux"
import { State } from "../../../state"
import { bindActionCreators } from "redux"
import { useDispatch } from "react-redux"
import * as actionCreators from '../state/actionCreators'
import { PurchasesState } from "../state/reducer"
import { PurchaseOrder, Supplier } from "../state/types"
import { define, normalize } from "../../../utils/typeUtils"
import { useFind } from "../../../state/reducers/hooks"
import { DataType, TableName } from "../../../tables"
import { useFiltersHooks } from "../../../features/ui"
import { useAdministrationState, SettingsModule, Settings } from "../../administration"

export const usePurchasesState = (): PurchasesState => useSelector((state: State) => state.purchasesReducer)

export const usePurchasesStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const usePurchasesHooks = () => {
    const { settings } = useAdministrationState()
    const { purchases } = usePurchasesState()
    const { search } = useFiltersHooks()
    const find = useFind()

    const getSettings = (): Settings | undefined => settings.find(setting => setting.module === SettingsModule.PURCHASES)

    const getBranchPurchases = (branchId: string) => purchases.filter(purchase => purchase.deliveryBranchId === branchId)

    const searchSuppliers = (
        suppliers: Supplier[],
        text = '',
        showAll = false
    ): Supplier[] => {
        const valuesFn = (supplierDT: DataType) => {
            const { code, name, taxId } = supplierDT as Supplier
            return [
                code.toString(),
                normalize(name),
                taxId.toString()
            ]
        }
        const sortFn = (supplierDT_A: DataType, supplierDT_B: DataType) => 
            (supplierDT_A as Supplier).name.localeCompare((supplierDT_B as Supplier).name)

        return search(suppliers, valuesFn, sortFn, text, showAll) as Supplier[]
    }

    const searchPurchases = (
        purchaseList: PurchaseOrder[],
        text = '',
        showAll = false
    ): PurchaseOrder[] => {
        const valuesFn = (purchaseDT: DataType) => {
            const { code, supplierId } = purchaseDT as PurchaseOrder
            const supplier = define(find(TableName.SUPPLIERS, supplierId)) as Supplier
            return [
                code.toString(),
                normalize(supplier.name)
            ]
        }
        const sortFn = (purchaseDT_A: DataType, purchaseDT_B: DataType) =>
            (purchaseDT_B as PurchaseOrder).code > (purchaseDT_A as PurchaseOrder).code ? 1 : -1

        return search(purchaseList, valuesFn, sortFn, text, showAll) as PurchaseOrder[]
    }

    return {
        getSettings,
        getBranchPurchases,
        searchSuppliers,
        searchPurchases
    }
}
