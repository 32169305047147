import { useEffect, useState } from "react"
import { EditableFormField, EditablePanel, FieldType, FieldValue, ModalId, TabsPanel, UIPermission, useUIActions } from "../../../../features/ui"
import { useSystemStateHooks } from "../../../system"
import { useActions } from "./actions"
import { define } from "../../../../utils/typeUtils"
import { usePurchasesHooks } from "../../../purchases/hooks/purchasesHooks"
import { TableName } from "../../../../tables"
import { CategorySaleYieldsTable } from "../../../purchases/components/category-sale-yield/category-sale-yields-table/categorySaleYieldsTable"
import { CategorySaleYield } from "../../../administration"
import { Data } from "../../../../features/data-types"
import { CategorySaleYieldFormModal } from "../../../purchases/components/category-sale-yield/category-sale-yield-form-modal/categorySaleYieldFormModal"
import { RemoveCategorySaleYieldModal } from "../../../purchases/components/category-sale-yield/remove-category-sale-yield-modal/removeCategorySaleYieldModal"

export const ProductYieldPanel = () => {
    const { getSettings } = usePurchasesHooks()
    const settings = getSettings()
    const generalSaleYield = define(settings?.generalSaleYield) as number
    const categorySaleYields = (settings?.categorySaleYields || []) as CategorySaleYield[]
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PURCHASES_SETTINGS)

    const [generalSaleYieldState, setGeneralSaleYieldState] = useState<number>(generalSaleYield)
    const [categorySaleYieldsData, setCategorySaleYieldsData] = useState<CategorySaleYield[]>(categorySaleYields)
    const [currentCategorySaleYield, setCurrentCategorySaleYield] = useState<Data>()

    const { updateGeneralSaleYield, submitAddCategorySaleYield, submitRemoveCategorySaleYield } = useActions()
    const { openModal } = useUIActions()

    useEffect(() => {
        setCategorySaleYieldsData(categorySaleYields)
    }, [categorySaleYields])

    const categoryIdsData = categorySaleYieldsData.map(data => data.categoryId)
    const filterCategories = (categoryId: string) => currentCategorySaleYield?.categoryId === categoryId || !categoryIdsData.includes(categoryId)

    const onSaveGeneralSaleYield = (value?: FieldValue) => {
        const newGeneralSaleYield = define(value) as number
        if (newGeneralSaleYield !== generalSaleYieldState) {
            setGeneralSaleYieldState(newGeneralSaleYield)
            updateGeneralSaleYield(newGeneralSaleYield)
        }
    }

    const onAddCategorySaleYield = () => {
        openModal(ModalId.CATEGORY_SALE_YIELD_FORM)
        setCurrentCategorySaleYield(undefined)
    }

    const onEditCategorySaleYield = (categoryId: string) => {
        setCurrentCategorySaleYield(categorySaleYieldsData.find(data => data.categoryId === categoryId))
        openModal(ModalId.CATEGORY_SALE_YIELD_FORM)
    }

    const onRemoveCategorySaleYield = (categoryId: string) => {
        setCurrentCategorySaleYield(categorySaleYieldsData.find(data => data.categoryId === categoryId))
        openModal(ModalId.REMOVE_CATEGORY_SALE_YIELD)
    }

    const fields: EditableFormField[] = [
        {
            name: 'generalSaleYield',
            type: FieldType.PERCENTAGE,
            defaultValue: generalSaleYieldState,
            label: 'Rentabilidad General',
            onSave: onSaveGeneralSaleYield,
        },
        {
            name: 'categorySaleYields',
            type: FieldType.TABLE,
            table: TableName.CATEGORIES,
            label: 'Rentabilidad por Categoría',
            disabled: true,
            render: () => (
                <CategorySaleYieldsTable
                    rows={categorySaleYieldsData}
                    readOnly={readOnly}
                    onAdd={onAddCategorySaleYield}
                    onEditRow={onEditCategorySaleYield}
                    onRemoveRow={onRemoveCategorySaleYield}
                />
            )
        }
    ]

    const tabs = [
        {
            id: 'purchase',
            title: 'Rentabilidad',
            content: <EditablePanel
                fields={fields}
                readOnly={readOnly}
            />
        }
    ]

    const onSubmitRemoveCategorySaleYield = (categoryId: string) => {
        submitRemoveCategorySaleYield(categoryId)
        setCurrentCategorySaleYield(undefined)
    }

    return (
        <>
            <TabsPanel
                title="Configuración"
                tabs={tabs}
                wide={false}
            />
            <CategorySaleYieldFormModal
                categorySaleYield={currentCategorySaleYield}
                filterCategories={filterCategories}
                onSubmit={submitAddCategorySaleYield}
            />
            <RemoveCategorySaleYieldModal
                categoryId={currentCategorySaleYield?.categoryId as string | undefined}
                onSubmit={onSubmitRemoveCategorySaleYield}
            />
        </>
    )
}
