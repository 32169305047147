import styles from './section.module.css'
import { useState } from 'react'

type Props = {
    id: string
    title?: string
    children?: JSX.Element | JSX.Element[]
    defaultExpanded?: boolean
    collapsable?: boolean
    space?: number
    framed?: boolean
    smallFont?: boolean
    className?: string
}

export const Section = (props: Props) => {
    const { id, title, children, defaultExpanded, collapsable, space, framed = true, smallFont, className } = props
    const [expanded, setExpanded] = useState<boolean>(defaultExpanded || !collapsable || false)

    const expand = () => setExpanded(!expanded)

    const headerId = `${id}-header`
    const collapseId = `${id}-collapse`
    const propClass = className || ''
    const expandedClass = expanded ? styles.expanded : ''
    const frameClass = framed ? styles.framed : ''
    const notCollapsableClass = !collapsable ? styles.notCollapsable : ''
    const smallFontClass = smallFont ? styles.smallFont : ''

    return (
        <div className={`accordion-flush col-sm-${space || 10} ${propClass} ${styles.container} ${expandedClass} ${frameClass} ${notCollapsableClass}`} id={id}>
            <div className={`accordion-item ${styles.content}`}>
                {collapsable && (
                    <div
                        id={headerId}
                        className={`accordion-header ${styles.header} ${expandedClass} ${frameClass}`}
                        data-bs-target={`#${collapseId}`}
                        aria-expanded={expanded}
                        aria-controls={collapseId}
                        onClick={expand}
                    >
                        <span>{title || (expanded ? 'Colapsar' : 'Expandir')}</span>
                        <i className={`bi bi-chevron-down ${styles.expandIcon} ${expandedClass}`}></i>
                    </div>
                )}
                {!collapsable && title && <span>{title}</span>}
                <div id={collapseId} className={`accordion-collapse collapse ${expanded ? 'show' : ''}`} aria-labelledby={headerId} data-bs-parent={`#${id}`}>
                    <div className={`accordion-body ${smallFontClass}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
