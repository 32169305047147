import { useNavigate } from "react-router-dom"
import { Action, DetailFieldProps, DetailPanel, FieldType, FieldValue, ModalId, SpinnerDiv, UIPermission, useGetCrumbs, useUIActions } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { Expense, ExpenseStatus } from "../../../state/types"
import { ExpenseReceiptModal } from "../../expense/expense-receipt-modal/expenseReceiptModal"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { define } from "../../../../../utils/typeUtils"
import { Labels } from "../../../../../features/data-types"

export const SalaryDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const expense = dataType as Expense | undefined

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_EXPENSES)
  const navigate = useNavigate()

  const { toggleModal } = useUIActions()

  const crumbs = useGetCrumbs('Sueldos Liquidados', RoutePath.SALARIES, expense?.name)

  if (!expense) return <SpinnerDiv />

  const title = `Sueldo Liquidado: ${expense.name}`

  const fields: DetailFieldProps[] = [
    {
      name: 'code',
      type: FieldType.NUMBER,
      label: 'Código',
      render: (value?: FieldValue) => <>#{value}</>
    },
    { name: 'date', type: FieldType.DATE, label: 'Fecha' },
    { name: 'branchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal' },
    { name: 'amount', type: FieldType.PRICE, label: 'Monto' },
    {
      name: 'status',
      type: FieldType.TEXT,
      label: 'Estado',
      render: (value?: FieldValue) => <>{Labels.expenseStatusLabel(define(value) as ExpenseStatus)}</>
    }
  ]
  if (expense.detail) {
    const detailField = {
      name: 'detail',
      type: FieldType.TEXT,
      label: 'Detalle'
    }
    fields.splice(4, 0, detailField)
  }

  const onDownloadReceipt = () => toggleModal(ModalId.EXPENSE_RECEIPT)

  const onEdit = () => navigate(RoutePath.EDIT)

  const onRemove = () => toggleModal(ModalId.REMOVE)

  const actions: Action[] = []
  if (expense.receiptUrl) {
    actions.push(
      { label: 'Descargar', icon: 'download', kind: 'tertiary', onClick: onDownloadReceipt }
    )
}
  if (!readOnly && expense.status === ExpenseStatus.PENDING) {
    actions.push(
      { label: 'Editar', icon: 'pencil-fill', onClick: onEdit },
      { label: 'Eliminar', kind: 'secondary', icon: 'trash-fill', onClick: onRemove }
    )
  }

  return (<>
    <DetailPanel
      tableName={TableName.EXPENSES}
      title={title}
      crumbs={crumbs}
      fields={fields}
      data={expense}
      icon='receipt'
      readOnly={readOnly}
      actions={actions}
    />
    <ExpenseReceiptModal expense={expense} />
  </>)
}
