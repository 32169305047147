import styles from './link.module.css'

type LinkKind = 'primary' | 'secondary' | 'tertiary'

type Props = {
    id?: string
    text?: string
    href?: string
    children?: JSX.Element | JSX.Element[]
    kind?: LinkKind
    className?: string
    dataBsTarget?: string
    dataBsToggle?: string
    onClick?: () => void
}

export const Link = (props: Props) => {
    const { id = 'link', text, onClick, href, children, kind, className, dataBsTarget, dataBsToggle } = props
    
    const getKindClass = () => {
        switch(kind) {
            case 'primary':
                return styles.linkPrimary
            default:
                return ''
        }
    }

    const renderLink = () => {
        return (
            <a
                id={id}
                href={href}
                className={`${styles.link} ${styles.clickable} ${className}`}
                data-bs-target={dataBsTarget}
                data-bs-toggle={dataBsToggle}
                target="_blank"
                rel="noreferrer"
            >
                {text || children}
            </a>
        )
    }

    const renderDiv = () => {
        const clickableClass = !!onClick ? styles.clickable : ''
        return (
            <div
                id={id}
                onClick={onClick}
                className={`${styles.link} ${getKindClass()} ${clickableClass} ${className}`}
                data-bs-target={dataBsTarget}
                data-bs-toggle={dataBsToggle}
            >
                {text || children}
            </div>
        )
    }
    
    return href ? renderLink() : renderDiv()
}
