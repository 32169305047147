import { ReactElement, useEffect, useState } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { ModalId, useUIActions, useUIStateActions } from "../../../../../features/ui"
import { WizardPanel } from "../../../../../features/wizard"
import { define } from "../../../../../utils/typeUtils"
import { HoardOrder, HoardItem } from "../../../state/types"
import { hoardWizardKey } from "../../../utils/salesUtils"
import { OrderDelivery, useOperationsActions, useOperationsHooks } from "../../../../operations"
import { useSalesActions } from "../../../actions/salesActions"
import { DataType } from "../../../../../tables"
import { HoardItemData } from "./types"
import { useActions } from "./actions"
import { HoardInfoStep } from "./hoardInfoStep"
import { HoardItemStep } from "./hoardItemStep"
import { useRoutesHooks } from "../../../../../features/routes"

export const ProductHoardDeliveryForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const hoard = define(dataType) as HoardOrder
    const wizardKey = hoardWizardKey(hoard)

    const [hoardItems, setHoardItems] = useState<HoardItem[]>([])
    const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
    const [steps, setSteps] = useState<ReactElement[]>([])
    const [hoardItemsData, setHoardItemsData] = useState<HoardItemData[]>([])
    const [validations, setValidations] = useState<string[]>([])
    
    const descriptorHooks = useDescriptorHooks()
    const { getPendingQuantity } = useOperationsHooks()

    const { validateProductHoardDelivery, finishProductHoardDelivery } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { toggleModal } = useUIActions()
    const salesActions = useSalesActions()
    const operationsActions = useOperationsActions()

    useEffect(() => {
        setDisableBranchSelector(true)
        operationsActions().fetchDeliveries(hoard.id).then((deliveriesDT: DataType[]) => {
            setDeliveries(deliveriesDT as OrderDelivery[])
            salesActions().fetchHoardItemsByOrder(hoard.id).then((hoardItemsDT: DataType[]) => {
                const sortedHoardItems = hoardItemsDT.sort((item1, item2) => item1.id > item2.id ? 1 : -1) as HoardItem[]
                setHoardItems(sortedHoardItems)
            })
        })

        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => {
        if (hoardItems.length === 0) {
            return
        }

        const infoStep = <HoardInfoStep
            wizardKey={wizardKey}
            stepIndex={0}
            hoard={hoard}
        />
        const stepList = [infoStep]

        const hoardItemsDataList: HoardItemData[] = []
        hoardItems.forEach(hoardItem => {
            const pendingQuantity = getPendingQuantity(hoardItem, deliveries)
            if (pendingQuantity > 0) {
                const stepIndex = stepList.length
                const itemStep = <HoardItemStep
                    key={stepIndex}
                    wizardKey={wizardKey}
                    stepIndex={stepIndex}
                    hoardItem={hoardItem}
                    pendingQuantity={pendingQuantity}
                />
                hoardItemsDataList.push({ stepIndex, hoardItem })
                stepList.push(itemStep)
            }
        })
        
        setSteps(stepList)
        setHoardItemsData(hoardItemsDataList)
    }, [hoardItems, deliveries])

    const title = `Entrega de Acopio ${descriptorHooks.hoard.code(hoard)}`

    const onFinish = async () => {
        const itemValidations = await validateProductHoardDelivery(wizardKey, hoard, hoardItemsData)
        if (itemValidations.length > 0) {
            setValidations(itemValidations)
            toggleModal(ModalId.VALIDATION)
        } else {
            await finishProductHoardDelivery(wizardKey, hoard, hoardItemsData)
        }
    }

    return (
        <WizardPanel
            wizardKey={wizardKey}
            title={title}
            steps={steps}
            validations={validations}
            onFinish={onFinish}
        />
    )
}
