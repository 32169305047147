import styles from './paymentOrderForm.module.css'
import { useNavigate } from "react-router-dom"
import { Data, DataTypeForm, useDataTypesHooks, useDescriptorHooks } from "../../../../../features/data-types"
import { FieldType, FieldValue, FormField, ModalId, StaticValue, useEffectAfterMount, useUIActions, Validatable } from "../../../../../features/ui"
import { useActions } from "./actions"
import { PurchaseOrder, usePurchasesState } from '../../../../purchases'
import { define } from '../../../../../utils/typeUtils'
import { useFind } from '../../../../../state/reducers/hooks'
import { TableName } from '../../../../../tables'
import { useMemo, useState } from 'react'
import { TaxItemsTable } from '../../tax/tax-items-table/taxItemsTable'
import { CashFund, Check, CreditCard, PaymentCategory, PaymentTicketItem, Tax, TaxType } from '../../../state/types'
import { TaxItemFormModal } from '../../tax/tax-item-form-modal/taxItemFormModal'
import { useAdministrationHooks, useAdministrationState } from '../../../hooks/administrationHooks'
import { toList } from '../../../../../utils/listUtils'
import { PaymentItemsTable } from '../payment-items-table/paymentItemsTable'
import { PaymentItemFormModal } from '../payment-item-form-modal/paymentItemFormModal'
import { PartialPaymentModal } from '../partial-payment-modal/partialPaymentModal'
import { usePricesHooks } from '../../../../operations'

export const PaymentOrderForm = () => {
  const { selectedTickets } = usePurchasesState()
  const { taxes, paymentTypes } = useAdministrationState()
  const { getTicketPendingAmount } = useAdministrationHooks()

  const ticketsPendingAmounts = useMemo(() => new Map(selectedTickets.map(ticket => [ticket.id, getTicketPendingAmount(ticket)])), [])
  const selectedAmount = useMemo(() => selectedTickets.reduce((sum, ticket) => sum + (ticketsPendingAmounts.get(ticket.id)?.pendingAmount || 0), 0), [])
  const vatAmount = useMemo(() => selectedTickets.reduce((sum, ticket) => sum + (ticketsPendingAmounts.get(ticket.id)?.pendingVatAmount || 0), 0), [])
  const [amount, setAmount] = useState<number | undefined>(selectedAmount)
  const [taxItemsData, setTaxItemsData] = useState<Data[]>([])
  const [currentTaxItem, setCurrentTaxItem] = useState<Data>()
  const [paymentItemsData, setPaymentItemsData] = useState<Data[]>([])
  const [currentPaymentItem, setCurrentPaymentItem] = useState<Data>()
  const [pendingAmount, setPendingAmount] = useState<number>(selectedAmount)
  const [paymentItemsValidation, setPaymentItemsValidation] = useState<string>()
  const [pendingAmountValidation, setPendingAmountValidation] = useState<string>()
  const [modalData, setModalData] = useState<Data | undefined>()

  const descriptorHooks = useDescriptorHooks()
  const pricesHooks = usePricesHooks()
  const { formatValue } = useDataTypesHooks()
  const navigate = useNavigate()
  const find = useFind()

  const { addPurchaseTicketItem, submitPurchaseOrderOrOpenModal, submitPurchaseOrder, cleanSelectedTickets } = useActions()
  const { toggleModal } = useUIActions()

  const payedPercentage = useMemo(() => (amount || 0) / selectedAmount, [amount])
  const payedVatAmount = useMemo(() => vatAmount * payedPercentage, [payedPercentage])
  const taxItemsAmount = useMemo(() => {
    setPendingAmountValidation(undefined)
    return taxItemsData.reduce((sum, taxItemData) => sum + (define(taxItemData.amount) as number), 0)
  }, [taxItemsData])
  const paymentItemsAmount = useMemo(() => {
    setPendingAmountValidation(undefined)
    return paymentItemsData.reduce((sum, paymentItemData) => sum + (define(paymentItemData.amount) as number), 0)
  }, [paymentItemsData])

  useEffectAfterMount(() => {
    if (amount) {
      setTaxItemsData(taxItemsData.map(taxItemData => ({
        ...taxItemData,
        amount: pricesHooks.adjustment.calculate(amount, define(taxItemData.taxYield) as number)
      })))
    }
  }, [amount])

  useEffectAfterMount(() => {
    setPendingAmountValidation(undefined)
    amount && setPendingAmount(amount - taxItemsAmount - paymentItemsAmount)
  }, [amount, taxItemsAmount, paymentItemsAmount])

  const paymentTicketItems: PaymentTicketItem[] = useMemo(() => addPurchaseTicketItem(amount || 0, [...selectedTickets]), [amount])

  const retentions = taxes.filter(tax => tax.type === TaxType.RETENTION)
  const filterTaxes = (tax: Tax) => tax.type === TaxType.RETENTION &&
    (currentTaxItem?.taxCode === tax.code || !taxItemsData.map(taxItemData => taxItemData.taxCode).includes(tax.code))
  const taxItemMatches = (data: Data, taxCode: string) => data.taxCode === taxCode
  const filterSources = (
    paymentCategory: PaymentCategory,
    source: Data,
    additionalFilterFn?: (data: Data) => boolean
  ) =>
    (
      (currentPaymentItem?.paymentCategory === paymentCategory && currentPaymentItem?.sourceId === source.id) ||
      !paymentItemsData
        .filter(paymentItemData => paymentItemData.paymentCategory === paymentCategory)
        .map(paymentItemData => paymentItemData.sourceId)
        .includes(source.id)
    ) && (!additionalFilterFn || additionalFilterFn(source))
  const filterCashFunds = (cashFund: CashFund) => filterSources(PaymentCategory.CASH_FUND, cashFund)
  const filterChecks = (check: Check) => filterSources(PaymentCategory.CHECK, check, (data: Data) => {
    return (data as Check).endorsable
  })
  const filterCreditCards = (creditCard: CreditCard) => filterSources(PaymentCategory.CREDIT_CARD, creditCard)
  const paymentItemMatches = (data: Data, paymentTypeId: string, sourceId: string) =>
    data.paymentTypeId === paymentTypeId && data.sourceId === sourceId

  const onChangeAmount = (value?: FieldValue) => {
    setAmount(value as number | undefined)
  }

  const onAddTaxItem = () => {
    setCurrentTaxItem(undefined)
    toggleModal(ModalId.TAX_ITEM_FORM)
  }

  const onEditTaxItem = (taxCode: string) => {
    setCurrentTaxItem(taxItemsData.find(taxItemData => taxItemMatches(taxItemData, taxCode)))
    toggleModal(ModalId.TAX_ITEM_FORM)
  }

  const onRemoveTaxItem = (taxCode: string) => {
    const newTaxItemsData = taxItemsData.filter(taxItemData => !taxItemMatches(taxItemData, taxCode))
    setTaxItemsData(newTaxItemsData)
  }

  const onAddPaymentItem = () => {
    setCurrentPaymentItem(undefined)
    setPendingAmountValidation(undefined)
    setPaymentItemsValidation(undefined)
    toggleModal(ModalId.PAYMENT_ITEM_FORM)
  }

  const onEditPaymentItem = (paymentTypeId: string, sourceId: string) => {
    setCurrentPaymentItem(paymentItemsData.find(paymentItemData => paymentItemMatches(paymentItemData, paymentTypeId, sourceId)))
    setPendingAmountValidation(undefined)
    toggleModal(ModalId.PAYMENT_ITEM_FORM)
  }

  const onRemovePaymentItem = (paymentTypeId: string, sourceId: string) => {
    const newPaymentItemsData = paymentItemsData.filter(paymentItemData => !paymentItemMatches(paymentItemData, paymentTypeId, sourceId))
    setPaymentItemsData(newPaymentItemsData)
  }

  const fields: FormField[] = [
    {
      name: 'purchaseTickets',
      type: FieldType.TEXT,
      label: 'Facturas',
      render: () => <div className={styles.ticketList}>
        {selectedTickets
          .sort((ticket_A, ticket_B) => ticket_A.dueDate.localeCompare(ticket_B.dueDate))
          .map((ticket, index) => {
            const purchase = define(find(TableName.PURCHASE_ORDERS, ticket.purchaseId)) as PurchaseOrder
            const code = descriptorHooks.purchase.code(purchase)
            const dueDate = formatValue(new Date(ticket.dueDate), FieldType.DATE)
            const ticketPendingAmount = formatValue(ticketsPendingAmounts.get(ticket.id)?.pendingAmount, FieldType.PRICE)
            return <div key={index}>
              Factura Compra {code} - Vto.: {dueDate} - {ticketPendingAmount}
            </div>
          })
        }
      </div>
    },
    {
      name: 'selectedAmount',
      type: FieldType.PRICE,
      label: 'Importe Seleccionado',
      render: () => <StaticValue type={FieldType.PRICE} value={selectedAmount} />
    },
    {
      name: 'amount',
      type: FieldType.PRICE,
      label: 'Importe a Pagar',
      value: amount,
      max: selectedAmount,
      onChange: onChangeAmount
    },
    {
      name: 'taxItems',
      type: FieldType.TABLE,
      label: 'Retenciones',
      render: () => (
        <TaxItemsTable
          rows={taxItemsData}
          disableCreate={!amount || retentions.length === taxItemsData.length}
          onCreate={onAddTaxItem}
          onEditRow={onEditTaxItem}
          onRemoveRow={onRemoveTaxItem}
        />
      )
    },
    {
      name: 'paymentItems',
      type: FieldType.TABLE,
      label: 'Medios de Pago',
      render: () => (
        <Validatable validations={toList(paymentItemsValidation)}>
          <PaymentItemsTable
            rows={paymentItemsData}
            disableCreate={!amount || paymentTypes.length === paymentItemsData.length}
            onCreate={onAddPaymentItem}
            onEditRow={onEditPaymentItem}
            onRemoveRow={onRemovePaymentItem}
          />
        </Validatable>
      )
    },
    {
      name: 'pendingAmount',
      type: FieldType.PRICE,
      label: 'Saldo Pendiente',
      render: () => (
        <Validatable validations={toList(pendingAmountValidation)} left>
          <StaticValue type={FieldType.PRICE} value={pendingAmount} />
        </Validatable>
      )
    }
  ]

  const validate = (): boolean => {
    let isValid = true
    if (paymentItemsData.length === 0) {
      setPaymentItemsValidation('Debe agregar al menos 1 medio de pago')
      isValid = false
    } else if (pendingAmount !== 0) {
      setPendingAmountValidation('El saldo pendiente debe ser $0,00.')
      isValid = false
    }

    return isValid
  }

  const onSubmit = (paymentOrderData: Data) => {
    if (validate()) {
      setModalData(paymentOrderData)
      submitPurchaseOrderOrOpenModal(
        !amount || amount < selectedAmount,
        paymentOrderData,
        paymentTicketItems,
        taxItemsData,
        paymentItemsData
      )
    }
  }

  const onCancel = () => {
    navigate(-1)
    cleanSelectedTickets()
  }

  const onSaveTaxItem = (newData: Data) => {
    let newTaxItemsData = taxItemsData
    if (currentTaxItem) {
      const { taxCode } = currentTaxItem
      newTaxItemsData = taxItemsData.map(taxItemData => taxItemMatches(taxItemData, define(taxCode) as string) ? newData : taxItemData)
    } else {
      newTaxItemsData = [newData, ...taxItemsData]
    }
    setTaxItemsData(newTaxItemsData)
  }

  const onSavePaymentItem = (newData: Data) => {
    let newPaymentItemsData = paymentItemsData
    if (currentPaymentItem) {
      const { paymentTypeId, sourceId } = currentPaymentItem
      newPaymentItemsData = paymentItemsData.map(paymentItemData =>
        paymentItemMatches(paymentItemData, define(paymentTypeId) as string, define(sourceId) as string) ?
        newData : paymentItemData
      )
    } else {
      newPaymentItemsData = [newData, ...paymentItemsData]
    }
    setPaymentItemsData(newPaymentItemsData)
  }

  const onSubmitModal = (paymentOrderData: Data) => {
    submitPurchaseOrder(
      paymentOrderData,
      paymentTicketItems,
      taxItemsData,
      paymentItemsData
    )
  }

  return (
    <>
      <DataTypeForm
        formId="payment-order-form"
        fields={fields}
        createMode
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <TaxItemFormModal
        taxItem={currentTaxItem}
        baseAmount={amount}
        vatAmount={payedVatAmount}
        title="Agregar Retención"
        filterTaxes={filterTaxes}
        onSubmit={onSaveTaxItem}
      />
      <PaymentItemFormModal
        paymentItem={currentPaymentItem}
        pendingAmount={pendingAmount}
        filterCashFunds={filterCashFunds}
        filterChecks={filterChecks}
        filterCreditCards={filterCreditCards}
        onSubmit={onSavePaymentItem}
      />
      <PartialPaymentModal
        data={modalData}
        paymentTicketItems={paymentTicketItems}
        onSubmit={onSubmitModal}
      />
    </>
  )
}
