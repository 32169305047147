import { Employee, useAdministrationActions } from "../../modules/administration"
import { useManagerState } from "../../modules/manager"
import { Lot, NO_LOT, useProductsActions } from "../../modules/products"
import { Stock, useStockActions } from "../../modules/stock"
import { Request, RequestStatus, User, getAreaFromRole, useSystemActions, useSystemStateHooks } from "../../modules/system"
import { firstItem } from "../../utils/listUtils"
import { define } from "../../utils/typeUtils"
import { newId } from "../data-types"
import { ModalId, useUIActions, useUIStateActions } from "../ui"

export const useListeners = () => {
    const { currentBranch, branches } = useManagerState()
    
    const { companyId } = useSystemStateHooks()

    const { setSpinnerModalMessage } = useUIStateActions()
    const systemActions = useSystemActions()
    const stockActions = useStockActions()
    const productsActions = useProductsActions()
    const administrationActions = useAdministrationActions()
    const { openModal, closeModal, onSubmitDataType } = useUIActions()
    
    const onDbSubmit = (remove = false) => {
        setSpinnerModalMessage(`${remove ? 'Eliminando' : 'Guardando'} registro...`)
        openModal(ModalId.SPINNER)
    }

    const onDbSubmitDone = (createMode = true, goBack = true) => setTimeout(() => {
        closeModal(ModalId.SPINNER)
        onSubmitDataType(createMode, goBack)
    }, 1000)

    const onUpdateStock = async (stock: Stock) => {
        if (stock.availableUnits <= 0 && stock.reservedUnits <= 0) {
            const { fetchLot, removeLot } = productsActions()
            
            const stateLot = define(await fetchLot(stock.lotId)) as Lot
            if (stateLot.code !== NO_LOT) {
                await removeLot(stock.lotId)
                return stockActions().removeStock(stock.id)
            }
        }
    }

    const onEmployeeMissing = async (request: Request) => {
        if (request.status === RequestStatus.APPROVED) {
            const user = await systemActions().fetchUserById(request.userId) as User
            
            const area = getAreaFromRole(request.role)
            const branchId = currentBranch?.id || firstItem(branches)?.id
            const employee: Employee = {
                companyId: define(companyId),
                id: newId(),
                name: define(user.name || user.email),
                area: define(area),
                email: define(user.email),
                branchId: define(branchId)
            }
            
            administrationActions().saveEmployee(employee)
        }
    }

    return {
        onDbSubmit,
        onDbSubmitDone,
        onUpdateStock,
        onEmployeeMissing
    }
}
