import styles from './imageField.module.css'
import { Button } from '../../button/button'
import { useEffect, useState } from 'react'
import { EmptyImgImg } from '../../../constants'
import { UploadButton } from '../../upload-button/uploadButton'
import { FileType } from '../../file-tile/types'

type Props = {
    id: string
    value?: File
    onChange: (value?: File) => void
}

export const ImageField = (props: Props) => {
    const { id, value, onChange } = props

    const [file, setFile] = useState<File>()
    const [imageUrl, setImageUrl] = useState<string>(EmptyImgImg)
    
    const setUrl = (newFile?: File) => setImageUrl(newFile ? URL.createObjectURL(newFile) : EmptyImgImg)

    useEffect(() => {
        setFile(value)
        setUrl(value)
    }, [value])

    const onChangeFile = (newFile?: File) => {
        setFile(newFile)
        setUrl(newFile)
        onChange(newFile)
    }

    const onClickRemove = () => {
        setFile(undefined)
        setUrl(undefined)
        onChange(undefined)
    }

    return (
        <div>
            <img src={imageUrl} alt={'Image'} className={styles.img} />
            <div className="pt-2">
                <UploadButton
                    id={id}
                    fileTypes={[FileType.JPEG, FileType.JPG, FileType.PNG]}
                    onChange={onChangeFile}
                />
                <Button
                    icon='trash-fill'
                    kind='secondary'
                    tooltip='Eliminar'
                    disabled={!file}
                    rightMargin
                    onClick={onClickRemove}
                />
            </div>
        </div>
    )
}
