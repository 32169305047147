import styles from '../../../app.module.css'
import { toast } from 'react-toastify'
import { ErrorType } from '../../../modules/common'
import { ToastKind } from '../state/types'

export const showToast = (message: string, kind?: ToastKind) => {
    let toastFn = toast.success
    let kindClass = styles.greenToast
    switch(kind) {
        case ToastKind.GREEN:
            toastFn = toast.success
            kindClass = styles.greenToast
            break
        case ToastKind.RED:
            toastFn = toast.error
            kindClass = styles.redToast
            break
        case ToastKind.YELLOW:
            toastFn = toast.warning
            kindClass = styles.yellowToast
            break
        default:      
            break      
    }
    
    const toastClass = `${styles.toast} ${kindClass}`

    toastFn(message, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        className: toastClass
    })
}

export const showErrorStrToast = (message: string) => showToast(message, ToastKind.RED)

export const showErrorToast = (error: ErrorType) => showErrorStrToast(error.message)
