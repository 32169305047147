import { useFind } from "../../../state/reducers/hooks"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { TransactionDestinationType, TransactionSourceType } from "../state/types"

export const useTransactionHooks = () => {
    const find = useFind()

    const getSourceTableName = (sourceType: TransactionSourceType) => {
        return new Map<TransactionSourceType, TableName>()
            .set(TransactionSourceType.CASH_FUND, TableName.CASH_FUNDS)
            .set(TransactionSourceType.CHECK, TableName.CHECKS)
            .set(TransactionSourceType.CREDIT_CARD, TableName.CREDIT_CARDS)
            .get(sourceType)
    }

    const getSource = (
        sourceType: TransactionSourceType,
        sourceId: string
    ) => {
        const tableName = define(getSourceTableName(sourceType))
        return find(tableName, sourceId)
    }

    const getDestinationTableName = (destinationType: TransactionDestinationType) => {
        return new Map<TransactionDestinationType, TableName>()
            .set(TransactionDestinationType.CASH_FUND, TableName.CASH_FUNDS)
            .set(TransactionDestinationType.PAYMENT_ORDER, TableName.PAYMENT_ORDERS)
            .get(destinationType)
    }

    const getDestination = (
        destinationType: TransactionDestinationType,
        destinationId: string
    ) => {
        const tableName = define(getDestinationTableName(destinationType))
        return find(tableName, destinationId)
    }

    return {
        getSourceTableName,
        getSource,
        getDestinationTableName,
        getDestination
    }
}
