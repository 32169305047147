import { Data, DataValueType } from "../types"

export const validateIdentifier = (data: Data): string | null => {
    const value = data.identifier?.toString()
    return value && !/^\d{7,8}$/.test(value) ? 'El valor debe contener de 7 a 8 dígitos numéricos' : null
}

export const validateTaxId = (data: Data): string | null => validateNumberLength(11, data.taxId)

export const validatePhone = (data: Data): string | null => {
    const value = data.phone?.toString()
    return value && !/^\d{8,15}$/.test(value) ? 'El valor debe contener de 8 a 15 dígitos numéricos' : null
}

export const validateNumberLength = (length: number, value?: DataValueType): string | null => {
    return value && !(new RegExp(`^\\d{${length}}$`).test(value?.toString())) ?
        `El valor debe contener ${length} dígitos numéricos` :
        null
}
