import { Dispatch } from 'redux'
import { ActionType, OperationsAction } from './actionTypes'
import { OrderDelivery } from './types'

export const updateDeliveries = (deliveries: OrderDelivery[]) => {
    return (dispatch: Dispatch<OperationsAction>) => {
        dispatch({
            type: ActionType.UPDATE_DELIVERIES,
            payload: deliveries
        })
    }
}

export const removeDeliveries = (ids: string[]) => {
    return (dispatch: Dispatch<OperationsAction>) => {
        dispatch({
            type: ActionType.REMOVE_DELIVERIES,
            payload: ids
        })
    }
}
