import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data } from "../../../../../features/ui"
import { Currency } from "../../../../../tables/types/types"
import { define, round } from "../../../../../utils/typeUtils"
import { TaxItem } from "../../../../administration"
import { usePurchasesActions } from "../../../actions/purchasesActions"
import { PurchaseTicket, PurchaseTicketType } from "../../../state/types"

export const useActions = () => {
    const purchasesActions = usePurchasesActions()

    const submitPurchaseTicket = async (
        purchaseTicketData: Data,
        purchaseId: string,
        ticketFile: File,
        taxItemsData: Data[],
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, type, amount, vatAmount, finalAmount } = purchaseTicketData
        const dueDate = define(purchaseTicketData.dueDate) as Date
        const taxItems: TaxItem[] = taxItemsData.map(taxItemData => ({
            taxCode: define(taxItemData.taxCode) as string,
            taxYield: define(taxItemData.taxYield) as number,
            amount: define(taxItemData.amount) as number
        }))
        const purchaseTicket: PurchaseTicket = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            purchaseId,
            type: define(type) as PurchaseTicketType,
            amount: define(amount) as number,
            vatAmount: round(define(vatAmount) as number),
            taxItems,
            finalAmount: define(finalAmount) as number,
            creationDate: new Date().toISOString(),
            dueDate: dueDate.toISOString(),
            currency: Currency.ARS
        }
        
        await purchasesActions().savePurchaseTicket(purchaseTicket, ticketFile)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitPurchaseTicket
    }
}
