import styles from './hint.module.css'
import { Icon } from '../icon/icon'

type Props = {
    text: string
}

export const Hint = (props: Props) => {
    const { text } = props
    
    return <p className={styles.text}>
        <Icon
            icon='info-circle'
            color='tertiary'
            className={styles.leftMargin}
            small
            rightMargin
        />
        {text}
    </p>
}
