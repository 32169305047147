import { Icon } from '../icon/icon'
import styles from './validatable.module.css'

type Props = {
    validations?: string[]
    left?: boolean
    expanded?: boolean
    className?: string
    children?: JSX.Element
}

export const Validatable = (props: Props) => {
    const { validations = [], left = false, expanded, className, children } = props

    const sideClassName = left ? styles.left : styles.right
    const expandedClassName = expanded ? styles.expanded : ''
    
    return (
        <div className={styles.container}>
            {children}
            {validations.length > 0 && (
                <div className={`${styles.validationContainer} ${sideClassName} ${expandedClassName} ${className}`}>
                    {validations.map((validation, index) => {
                        return (
                            <div key={index}>
                                <Icon
                                    icon='info-circle'
                                    color='quaternary'
                                    className={styles.leftMargin}
                                    small
                                    rightMargin
                                />
                                {validation}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
