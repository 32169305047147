import { UserRole } from "../../../modules/system"
import { PolicyAction } from "../../../services/iam"
import { TableName, TablesData } from "../../../tables"
import { dedupe, sorted } from "../../../utils/listUtils"
import { define } from "../../../utils/typeUtils"
import { RoutePath } from "../../routes"
import { Action, Permission, TableActions } from "../types"

export const policyName = (companyCode: string, role: UserRole) => `${companyCode}.${role.toLowerCase()}`
export const groupName = (companyCode: string, role: UserRole) => `${companyCode}.${role.toLowerCase()}.group`
export const roleName = (companyCode: string, role: UserRole) => `${companyCode}.${role.toLowerCase()}.role`

export const toIamPermissions = (permissions: Permission[]) =>
    permissions.map(({ tableNames, hasTKey, actions }) => ({
        tables: tableNames,
        actions: dedupe(actions.map(action => toPolicyAction(action, hasTKey)))
    }))

export const toPolicyAction = (action: Action, hasTKey: boolean): PolicyAction => {
    switch(action) {
        case Action.GET_ALL:
            return hasTKey ? PolicyAction.QUERY : PolicyAction.SCAN
        case Action.GET_BY_PARAMS:
            return PolicyAction.SCAN
        case Action.GET_BY_IDS:
            return PolicyAction.BATCH_GET
        case Action.SAVE:
            return PolicyAction.BATCH_WRITE
        default:
            return PolicyAction.QUERY
    }
}

export const toPermissionList = (permissions: TableActions[]): Permission[] => {
    const tableActionsMap = new Map<TableName, Action[]>()
    const tenantTableActionsMap = new Map<TableName, Action[]>()
    permissions.forEach(permission => {
        const { tableName, actions } = permission
        const hasTKey = TablesData.get(tableName)?.hasTKey || false
        const setAction = (map: Map<TableName, Action[]>) => {
            if (map.has(tableName)) {
                const mapActions = define(map.get(tableName))
                actions.forEach(action => !mapActions.includes(action) && mapActions.push(action))
            } else {
                map.set(tableName, [...actions])
            }
        }
        setAction(hasTKey ? tenantTableActionsMap : tableActionsMap)
    })

    const actionsTablesMap = new Map<string, TableName[]>()
    const tenantActionsTablesMap = new Map<string, TableName[]>()
    const setTables = (sourceMap: Map<TableName, Action[]>, map: Map<string, TableName[]>) => {
        sourceMap.forEach((actions, tableName) => {
            const actionsKey = sorted(actions).join(':')
            if (map.has(actionsKey)) {
                const tableNames = define(map.get(actionsKey))
                tableNames.push(tableName)
            } else {
                map.set(actionsKey, [tableName])
            }
        })
    }
    setTables(tableActionsMap, actionsTablesMap)
    setTables(tenantTableActionsMap, tenantActionsTablesMap)
    
    const permissionList: Permission[] = []
    const setPermission = (map: Map<string, TableName[]>, hasTKey: boolean) => {
        map.forEach((tableNames, actionsKey) => {
            const actions = actionsKey.split(':') as Action[]
            permissionList.push({ tableNames, hasTKey, actions })
        })
    }
    setPermission(actionsTablesMap, false)
    setPermission(tenantActionsTablesMap, true)

    return permissionList
}

export const getInitialRoute = (role: UserRole): RoutePath => {
    const initialPanels = new Map<UserRole, RoutePath>()
        .set(UserRole.MANAGER, RoutePath.COMPANY)
        .set(UserRole.ADMINISTRATION, RoutePath.EXPENSES)
        .set(UserRole.PURCHASES, RoutePath.PURCHASES)
        .set(UserRole.SALES, RoutePath.SALES)
        .set(UserRole.WAREHOUSE, RoutePath.STOCK)

    return initialPanels.get(role) || RoutePath.COMPANY
}
