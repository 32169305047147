import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toListResponse } from "../../services/utils"
import { TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useRemovedPropMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyIds: string[],
        table: TableName,
        propName: string
    ) => {
        companyIds.forEach(async companyId => {
            const { getAll, createOrUpdate } = dynamoActions(DynamoUserApi, companyId)
            const { dbTableName } = define(TablesData.get(table))

            const listResponse = await getAll(dbTableName)
            const { dataTypes } = (toListResponse(listResponse))

            const updatedDataTypes = dataTypes.map(dataType => ({
                ...dataType,
                [propName]: undefined
            })) as AttributeMap[]
            
            await createOrUpdate(dbTableName, updatedDataTypes)
        })
    }
}
