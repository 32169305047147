import { define } from "../../../utils/typeUtils"
import { Data } from "../../../features/ui"
import { Stock, useStockActions } from "../../stock"
import { TableName } from "../../../tables"
import { Lot, Product } from "../../products"
import { useFind } from "../../../state/reducers/hooks"
import { Branch } from "../../manager"

export const useSalesUIHooks = () => {
    const find = useFind()
    const stockActions = useStockActions()

    const validateStock = async (
        itemsData: Data[],
        branchId: string
    ) => {
        const { fetchProductBranchStock } = stockActions()

        const validations: string[] = []

        await itemsData.reduce(async (previousPromise, itemData) => {
            await previousPromise
            
            const productId = define(itemData.productId) as string
            const lotId = itemData.lotId as string | undefined
            const quantity = define(itemData.quantity) as number

            const stockList = await fetchProductBranchStock(productId, branchId) as Stock[]
            const product = define(find(TableName.PRODUCTS, productId)) as Product
            const branch = define(find(TableName.BRANCHES, branchId)) as Branch
            const stock = stockList.find(stockItem => stockItem.lotId === lotId) as Stock | undefined
            const units = stock?.reservedUnits || 0
            const lot = find(TableName.LOTS, lotId) as Lot | undefined
            const productLabel = `el producto ${product.name}${lot ? ' (' + lot.code + ')' : ''}`
            
            if (units < quantity) {
                validations.push(`No hay stock reservado suficiente (${units}) para ${productLabel} en la sucursal ${branch.name}`)
            }
        }, Promise.resolve())

        return validations
    }

    const validateItemsData = async (
        itemsData: Data[],
        branchId: string,
        reservedStock?: boolean
    ) => {
        const validations: string[] = []

        if (itemsData.length === 0) {
            validations.push('Debe agregar al menos 1 producto')
        } else {
            const { fetchProductBranchStock } = stockActions()

            await itemsData.reduce(async (previousPromise, itemData) => {
                await previousPromise
                
                const productId = define(itemData.productId) as string
                const lotId = itemData.lotId as string | undefined
                const quantity = define(itemData.quantity) as number

                const stockList = await fetchProductBranchStock(productId, branchId) as Stock[]
                const product = define(find(TableName.PRODUCTS, productId)) as Product
                
                if (product.loted && !lotId) {
                    validations.push(`Debe seleccionar una partida para el producto ${product.name}`)
                } else {
                    const stock = stockList.find(stockItem => stockItem.lotId === lotId) as Stock | undefined
                    const units = (reservedStock ? stock?.reservedUnits : stock?.availableUnits) || 0
                    const lot = find(TableName.LOTS, lotId) as Lot | undefined
                    const productLabel = `el producto ${product.name}${lot ? ' (' + lot.code + ')' : ''}`
                    if (units < quantity) {
                        validations.push(units === 0 ?
                            `Debe eliminar ${productLabel} porque no contiene stock${reservedStock ? ' reservado' : ''}` :
                            `Debe ingresar una cantidad menor o igual al stock${reservedStock ? ' reservado' : ''} (${units}) para ${productLabel}`
                        )
                    }
                }
            }, Promise.resolve())
        }
        
        return validations
    }

    return {
        validateStock,
        validateItemsData
    }
}
