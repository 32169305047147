import styles from './purchaseTicketForm.module.css'
import { useNavigate } from "react-router-dom"
import { DataTypeForm, Labels } from "../../../../../features/data-types"
import { Data, FieldType, FieldValue, FileField, FormField, ModalId, option, useEffectAfterMount, useUIActions, Validatable } from "../../../../../features/ui"
import { PurchaseItem, PurchaseOrder, PurchaseTicket, PurchaseTicketType } from "../../../state/types"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"
import { useMemo, useState } from "react"
import { usePriceBreakdownHooks } from '../../../../operations'
import { Tax, TaxItemFormModal, TaxItemsTable, TaxType, useAdministrationState } from '../../../../administration'
import { toList } from '../../../../../utils/listUtils'

type Props = {
  purchase: PurchaseOrder
  purchaseItems: PurchaseItem[]
  purchaseTicket?: PurchaseTicket
  ticketFileParam?: File
}

export const PurchaseTicketForm = (props: Props) => {
  const { purchase, purchaseItems, purchaseTicket, ticketFileParam } = props
  const createMode = !purchaseTicket
  
  const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
  const priceBreakdown = useMemo(() => getPriceBreakdownFromItems(purchaseItems), [purchaseItems])

  const [ticketFile, setTicketFile] = useState<File | undefined>(ticketFileParam)
  const [ticketValidation, setTicketValidation] = useState<string>()
  const [amount, setAmount] = useState<number | undefined>(purchaseTicket?.amount || priceBreakdown.totalWithoutVAT)
  const [vatAmount, setVatAmount] = useState<number | undefined>(purchaseTicket?.vatAmount || priceBreakdown.vatAmount)
  const [taxItemsData, setTaxItemsData] = useState<Data[]>(purchaseTicket?.taxItems || [])
  const [currentTaxItem, setCurrentTaxItem] = useState<Data>()
  const [finalAmount, setFinalAmount] = useState<number | undefined>(purchaseTicket?.finalAmount || priceBreakdown.totalWithoutVAT + priceBreakdown.vatAmount)

  const { taxes } = useAdministrationState()

  const navigate = useNavigate()

  const { submitPurchaseTicket } = useActions()
  const { toggleModal } = useUIActions()

  useEffectAfterMount(() => {
    amount && setFinalAmount(amount + (vatAmount || 0) + taxItemsData.reduce((sum, taxItemData) => sum + (define(taxItemData.amount) as number), 0))
  }, [amount, vatAmount, taxItemsData])

  const perceptions = taxes.filter(tax => tax.type === TaxType.PERCEPTION)
  const filterTaxes = (tax: Tax) => 
    tax.type === TaxType.PERCEPTION &&
    (currentTaxItem?.taxCode === tax.code || !taxItemsData.map(taxItemData => taxItemData.taxCode).includes(tax.code))
  const taxItemMatches = (data: Data, taxCode: string) => data.taxCode === taxCode
  const openFormModal = () => toggleModal(ModalId.TAX_ITEM_FORM)

  const onChangeTicket = (value?: FieldValue) => {
    setTicketValidation(undefined)
    setTicketFile(value as File | undefined)
  }

  const onChangeAmount = (value?: FieldValue) => {
    setAmount(value as number | undefined)
  }

  const onChangeVatAmount = (value?: FieldValue) => {
    setVatAmount(value as number | undefined)
  }

  const onAddTaxItem = () => {
    setCurrentTaxItem(undefined)
    openFormModal()
  }

  const onEditTaxItem = (taxCode: string) => {
    setCurrentTaxItem(taxItemsData.find(taxItemData => taxItemMatches(taxItemData, taxCode)))
    openFormModal()
  }

  const onRemoveTaxItem = (taxCode: string) => {
    const newTaxItemsData = taxItemsData.filter(taxItemData => !taxItemMatches(taxItemData, taxCode))
    setTaxItemsData(newTaxItemsData)
  }

  const onChangeFinalAmount = (value?: FieldValue) => {
    setFinalAmount(value as number | undefined)
  }

  const fields: FormField[] = [
    {
      name: 'type',
      type: FieldType.SELECT,
      label: 'Tipo',
      defaultValue: purchaseTicket?.type || PurchaseTicketType.TICKET,
      options: Object.values(PurchaseTicketType).map(type => option(type, Labels.purchaseTicketTypeLabel(type)))
    },
    {
      name: 'ticketUrl',
      type: FieldType.FILE,
      label: 'Factura',
      hint: 'Formato: PDF',
      render: () => (
        <Validatable validations={toList(ticketValidation)} left className={styles.ticketValidation}>
          <FileField
            id="ticketUrl"
            value={ticketFile}
            onChange={onChangeTicket}
          />
        </Validatable>
      )
    },
    {
      name: 'dueDate',
      type: FieldType.DATE,
      label: 'Fecha de Vto.',
      min: new Date()
    },
    {
      name: 'amount',
      type: FieldType.PRICE,
      label: 'Subtotal Productos',
      value: amount,
      onChange: onChangeAmount
    },
    {
      name: 'vatAmount',
      type: FieldType.PRICE,
      label: 'IVA',
      value: vatAmount,
      onChange: onChangeVatAmount
    },
    {
      name: 'taxItems',
      type: FieldType.TABLE,
      label: 'Percepciones',
      render: () => (
        <TaxItemsTable
          rows={taxItemsData}
          disableCreate={!amount || perceptions.length === taxItemsData.length}
          onCreate={onAddTaxItem}
          onEditRow={onEditTaxItem}
          onRemoveRow={onRemoveTaxItem}
        />
      )
    },
    {
      name: 'finalAmount',
      type: FieldType.PRICE,
      label: 'Monto Final',
      value: finalAmount,
      onChange: onChangeFinalAmount
    }
  ]

  const validate = (): boolean => {
    if (!ticketFile) {
      setTicketValidation('Debe subir la factura')
      return false
    }

    return true
  }

  const onSubmit = (purchaseTicketData: Data) => {
    if (validate()) {
      submitPurchaseTicket(
        purchaseTicketData,
        purchase.id,
        define(ticketFile),
        taxItemsData,
        createMode
      )
    }
  }

  const onCancel = () => navigate(-1)

  const onSaveTaxItem = (newData: Data) => {
    let newTaxItemsData = taxItemsData
    if (currentTaxItem) {
      const { taxCode } = currentTaxItem
      newTaxItemsData = taxItemsData.map(taxItemData => taxItemMatches(taxItemData, define(taxCode) as string) ? newData : taxItemData)
    } else {
      newTaxItemsData = [newData, ...taxItemsData]
    }
    setTaxItemsData(newTaxItemsData)
  }

  return (
    <>
      <DataTypeForm
        formId="purchase-ticket-form"
        fields={fields}
        createMode={createMode}
        createLabel="Guardar"
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <TaxItemFormModal
        taxItem={currentTaxItem}
        baseAmount={amount}
        title="Agregar Percepción"
        editableAmount
        filterTaxes={filterTaxes}
        onSubmit={onSaveTaxItem}
      />
    </>
  )
}
