import styles from './pendingPaymentList.module.css'
import { Badge, BooleanField, Column, FieldType, FieldValue, SpinnerDiv, Table, UIPermission } from "../../../../features/ui"
import { useState } from "react"
import { PurchaseOrder, PurchaseTicket, PurchaseTicketType, Supplier, usePurchasesStateActions } from "../../../purchases"
import { PendingPaymentFilters } from "./pendingPaymentFilters"
import { Data, Labels } from "../../../../features/data-types"
import { DataType, TableName } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { useNavigate } from 'react-router-dom'
import { RoutePath, useRoutesHooks } from '../../../../features/routes'
import { SelectedTickets } from '../../../purchases/state/constants'
import { useSystemStateHooks } from '../../../system'
import { useFind } from '../../../../state/reducers/hooks'
import { useAdministrationHooks } from '../../hooks/administrationHooks'

export const PendingPaymentList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PAYMENT_ORDERS)
    const { getTicketPendingAmount } = useAdministrationHooks()
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()
    const find = useFind()

    const { selectTickets } = usePurchasesStateActions()

    const columns: Column[] = [
        {
            name: 'purchaseCode',
            type: FieldType.NUMBER,
            label: 'Compra',
            render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => Labels.purchaseTicketTypeLabel(define(value) as PurchaseTicketType)
        },
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            render: (value?: FieldValue) => {
                const supplier = define(find(TableName.SUPPLIERS, value)) as Supplier
                return <Badge label={supplier.name} />
            }
        },
        {
            name: 'creationDate',
            type: FieldType.DATE,
            label: 'Fecha de Creación'
        },
        {
            name: 'dueDate',
            type: FieldType.DATE,
            label: 'Fecha de Vto.'
        },
        {
            name: 'pendingAmount',
            type: FieldType.PRICE,
            label: 'Monto Pendiente'
        }
    ]

    const setRowsState = (purchaseTickets: PurchaseTicket[]) => {
        const newRows = purchaseTickets.map(purchaseTicket => {
            const purchase = define(find(TableName.PURCHASE_ORDERS, purchaseTicket.purchaseId)) as PurchaseOrder
            const pendingAmount = getTicketPendingAmount(purchaseTicket).pendingAmount
            return {
                id: purchaseTicket.id,
                purchaseCode: purchase.code,
                type: purchaseTicket.type,
                supplierId: purchase.supplierId,
                creationDate: purchaseTicket.creationDate,
                dueDate: purchaseTicket.dueDate,
                pendingAmount
            }
        })
        setRows(newRows)
        setAsyncDataLoaded(true)
    }

    const onSearchStart = () => {
        setSelectedIds([])
        setAsyncDataLoaded(false)
    }

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as PurchaseTicket[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    const onChangeCheck = (id: string) => (value?: boolean) => {
        setSelectedIds(prevIds => value ? prevIds.concat(id) : prevIds.filter(prevId => prevId !== id))
    }

    const renderActions = (id: string) => {
        if (readOnly) {
            return <></>
        } else {
            const purchaseTicket = rows.find(row => row.id === id) as PurchaseTicket | undefined
            return <BooleanField
                id={`check-${purchaseTicket?.id}`}
                onChange={onChangeCheck(id)}
                className={styles.checkField}
            />
        }
    }

    const onCreate = () => {
        selectTickets(selectedIds.map(id => define(find(TableName.PURCHASE_TICKETS, id)) as PurchaseTicket))
        localStorage.setItem(SelectedTickets, selectedIds.join(':'))
        navigate(getRoutePath(RoutePath.PAYMENT_ORDERS, RoutePath.CREATE))
    }

    return (
        <>
            <PendingPaymentFilters
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <Table
                    columns={columns}
                    rows={rows}
                    rowId="id"
                    createLabel="Crear Orden de Pago"
                    disableCreate={selectedIds.length === 0}
                    renderActions={renderActions}
                    readOnly={readOnly}
                    onCreate={onCreate}
                    createClassName={styles.createBtn}
                />
            )}
        </>
    )
}
