import { TableName } from '../../../../../tables'
import { Data, UIPermission, DetailPanel, DetailFieldProps, FieldType, FieldValue, Spinner, Action, Tab, useGetCrumbs, PDFTabContent, ModalId, useUIActions } from '../../../../../features/ui'
import { define } from '../../../../../utils/typeUtils'
import { useEffect, useState } from 'react'
import { Operation, OperationItemsTable, OrderDelivery, DeliveryStatus, useOperationsActions, DeliveryList, PendingItemsList, PriceBreakdown, usePriceBreakdownHooks, PaymentStatus } from '../../../../operations'
import { useSystemStateHooks } from '../../../../system'
import { PurchaseItem, PurchaseOrder, PurchaseTicket } from '../../../state/types'
import { purchaseWizardKey } from '../../../utils/purchaseUtils'
import { usePurchasesActions } from '../../../actions/purchasesActions'
import { OpenWizardModal, useWizardActions } from '../../../../../features/wizard'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { Labels, useDescriptorHooks } from '../../../../../features/data-types'
import { PurchasePDF } from '../purchase-pdf/purchasePDF'
import { PurchasePDFModal } from '../purchase-pdf-modal/purchasePDFModal'
import { useNavigate } from 'react-router-dom'
import { UploadPurchaseTicketModal } from '../upload-purchase-ticket-modal/uploadPurchaseTicketModal'
import { TicketList } from '../ticket-list/ticketList'
import { PurchaseTicketPDFModal } from '../purchase-ticket-pdf-modal/purchaseTicketPDFModal'

export const PurchaseDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const purchase = define(dataType) as PurchaseOrder
  const wizardKey = purchaseWizardKey(purchase)

  const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([])
  const [purchaseTickets, setPurchaseTickets] = useState<PurchaseTicket[]>([])
  const [currentPurchaseTicket, setCurrentPurchaseTicket] = useState<PurchaseTicket>()
  const [currentTicketFile, setCurrentTicketFile] = useState<File>()
  const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
  const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
  
  const navigate = useNavigate()
  const { userHasPermission } = useSystemStateHooks()
  const descriptorHooks = useDescriptorHooks()
  const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()

  const { goToWizard } = useWizardActions()
  const purchasesActions = usePurchasesActions()
  const operationsActions = useOperationsActions()
  const { toggleModal } = useUIActions()

  const readOnly = !userHasPermission(UIPermission.SAVE_PURCHASES)

  const init = async () => {
    const { fetchPurchaseItemsByOrder, fetchPurchaseTicketsByPurchaseId } = purchasesActions()
    const statePurchaseItems = await fetchPurchaseItemsByOrder(purchase.id) as PurchaseItem[]
    setPurchaseItems(statePurchaseItems)
    const statePurchaseTicket = await fetchPurchaseTicketsByPurchaseId(purchase.id) as PurchaseTicket[]
    setPurchaseTickets(statePurchaseTicket)
    const stateDeliveries = await operationsActions().fetchDeliveries(purchase.id) as OrderDelivery[]
    setDeliveries(stateDeliveries)
  }

  useEffect(() => {
    init().then(() => setAsyncDataLoaded(true))
  }, [])

  const priceBreakdown = getPriceBreakdownFromItems(purchaseItems)

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const crumbs = useGetCrumbs('Compras', RoutePath.PURCHASES, descriptorHooks.purchase.code(purchase))

  const fields: DetailFieldProps[] = [
    { name: 'supplierId', type: FieldType.TABLE, table: TableName.SUPPLIERS, label: 'Proveedor' },
    { name: 'deliveryBranchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal de Entrega' },
    { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Entrega' },
    {
      name: 'purchaseItems',
      type: FieldType.TABLE,
      table: TableName.PURCHASE_ITEMS,
      render: () => renderAsync(
        <OperationItemsTable
          rows={purchaseItems as Data[]}
          operation={Operation.PURCHASE}
          readOnly
        />
      )
    },
    {
      name: 'priceBreakdown',
      type: FieldType.PRICE,
      label: 'Precio',
      render: () => renderAsync(
        <PriceBreakdown data={priceBreakdown} />
      )
    },
    {
      name: 'deliveryStatus',
      type: FieldType.TEXT,
      label: 'Estado de Entrega',
      render: (value?: FieldValue) => <>{Labels.deliveryStatusLabel(define(value) as DeliveryStatus)}</>
    }
  ]
  if (purchase.deliveryStatus !== DeliveryStatus.DELIVERED && deliveries.length > 0) {
    const pendingItemsField = {
      name: 'pendingItems',
      type: FieldType.TABLE,
      table: TableName.PURCHASE_ITEMS,
      render: () => renderAsync(
        <PendingItemsList orderId={purchase.id} operation={Operation.PURCHASE} />
      )
    }
    fields.splice(4, 0, pendingItemsField)
  }

  const onDownloadPDF = () => toggleModal(ModalId.PURCHASE_PDF)

  const onEdit = () => {
    navigate(RoutePath.EDIT)
  }

  const onUploadTicket = () => {
    if (purchaseTickets.length > 0) {
      toggleModal(ModalId.UPLOAD_PURCHASE_TICKET)
    } else {
      navigate(`${RoutePath.TICKETS}/${RoutePath.CREATE}`)
    }
  }

  const onDeliver = () => {
    goToWizard(purchaseWizardKey(purchase), RoutePath.DELIVERY)
  }

  const onArchive = () => {}

  const actions: Action[] = [
    { label: 'Descargar', kind: 'tertiary', icon: 'download', onClick: onDownloadPDF }
  ]
  if (!readOnly) {
    const isEditable = purchase.deliveryStatus === DeliveryStatus.PENDING &&
      purchase.paymentStatus === PaymentStatus.PENDING &&
      purchaseTickets.length === 0
    if (isEditable) {
      const editAction = { label: 'Editar Compra', icon: 'pencil-fill', onClick: onEdit }
      actions.push(editAction)
    }
    const uploadTicketAction = { label: 'Subir Factura', icon: 'upload', onClick: onUploadTicket }
    actions.push(uploadTicketAction)
    const archiveAction = { label: 'Archivar', icon: 'archive', kind: 'secondary', onClick: onArchive }
    const deliverAction = { label: 'Registrar Recepción', icon: 'truck', onClick: onDeliver }
    actions.push(purchase.deliveryStatus === DeliveryStatus.DELIVERED ? archiveAction : deliverAction)
  }

  const renderPDFContent = (
    <PDFTabContent>
      <PurchasePDF
        id="purchase-pdf-preview"
        purchase={purchase}
        previewMode
      />
    </PDFTabContent>
  )

  const tabs: Tab[] = [
    { id: 'pdf', title: 'PDF', content: renderPDFContent }
  ]
  if (purchaseTickets.length > 0) {
    tabs.push({
      id: 'tickets',
      title: 'Facturas',
      content: <TicketList
        purchase={purchase}
        purchaseTickets={purchaseTickets}
        onChangeCurrentTicket={setCurrentPurchaseTicket}
        onChangeCurrentFile={setCurrentTicketFile}
      />
    })
  }
  if (deliveries.length > 0) {
    tabs.push({
      id: 'deliveries',
      title: 'Entregas',
      content: <DeliveryList
        orderId={purchase.id}
        operation={Operation.PURCHASE}
      />
    })
  }

  return (<>
    <DetailPanel
      tableName={TableName.PURCHASE_ORDERS}
      crumbs={crumbs}
      fields={fields}
      data={purchase}
      icon='cart3'
      imgSpace={3}
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <PurchasePDFModal purchase={purchase} />
    <PurchaseTicketPDFModal
      purchaseTicket={currentPurchaseTicket}
      file={currentTicketFile}
    />
    <UploadPurchaseTicketModal />
    <OpenWizardModal
      wizardKey={wizardKey}
      path={RoutePath.DELIVERY}
    />
  </>)
}
