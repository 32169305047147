import { useEffect, useState } from "react"
import { Modal, ModalId, useUIState } from "../../../../../features/ui"
import { PaymentOrder } from "../../../state/types"
import { ConfirmPaymentForm } from "./confirmPaymentForm"

type Props = {
    paymentOrder?: PaymentOrder
}

export const ConfirmPaymentFormModal = (props: Props) => {
    const { paymentOrder } = props
    const [key, setKey] = useState(0)
    const { openedModal } = useUIState()

    useEffect(() => setKey(key + 1), [openedModal])
    
    return (
        <Modal
            modalId={ModalId.CONFIRM_PAYMENT_FORM}
            title="Confirmar Pago"
            widthSize="m"
            actions={[]}
        >
            <ConfirmPaymentForm
                key={key}
                paymentOrder={paymentOrder}
            />
        </Modal>
    )
}
