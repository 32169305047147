import { Company, Request, User } from "./types"

export enum ActionType {
    SET_COMPANY = 'setCompany',
    SET_USER = 'setUser',
    SET_CONSTRUFY_ADMIN_ID = 'setConstrufyAdminId',
    UPDATE_REQUESTS = 'updateRequests',
    SET_USER_REQUEST = 'setUserRequest',
    UPDATE_COMPANY_USERS = 'updateCompanyUsers'
}

interface SetCompany {
    type: ActionType.SET_COMPANY
    payload: Company
}

interface SetUser {
    type: ActionType.SET_USER
    payload: User
}

interface SetConstrufyAdminId {
    type: ActionType.SET_CONSTRUFY_ADMIN_ID
    payload: string
}

interface UpdateRequests {
    type: ActionType.UPDATE_REQUESTS
    payload: Request[]
}

interface SetUserRequest {
    type: ActionType.SET_USER_REQUEST
    payload?: Request
}

interface UpdateCompanyUsers {
    type: ActionType.UPDATE_COMPANY_USERS
    payload: User[]
}

export type SystemAction =
    SetCompany |
    SetUser |
    SetConstrufyAdminId |
    SetUserRequest |
    SystemUpdateAction

export type SystemUpdateAction =
    UpdateRequests |
    UpdateCompanyUsers
