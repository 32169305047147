import styles from './ticketList.module.css'
import { Button, FieldType, FileTile, FileType, ModalId, Section, useUIActions } from '../../../../../features/ui'
import { TableName } from '../../../../../tables'
import { PurchaseOrder, PurchaseTicket } from '../../../state/types'
import { useEffect, useState } from 'react'
import { fileName, useFileActions } from '../../../../../features/files'
import { define } from '../../../../../utils/typeUtils'
import { useDataTypesHooks } from '../../../../../features/data-types'
import { useNavigate } from 'react-router-dom'
import { RoutePath } from '../../../../../features/routes'

type Props = {
  purchase: PurchaseOrder
  purchaseTickets: PurchaseTicket[]
  onChangeCurrentTicket?: (purchaseTicket: PurchaseTicket) => void
  onChangeCurrentFile?: (file: File) => void
}

type PurchaseTicketData = {
  index: number
  purchaseTicket: PurchaseTicket
  ticketFile: File
}

export const TicketList = (props: Props) => {
  const { purchase, purchaseTickets, onChangeCurrentTicket, onChangeCurrentFile } = props

  const [dataList, setDataList] = useState<PurchaseTicketData[]>([])

  const { formatValue } = useDataTypesHooks()
  const navigate = useNavigate()

  const { fetchFile } = useFileActions()
  const { toggleModal } = useUIActions()

  const fetchData = async () => {
    const purchaseTicketDataList: PurchaseTicketData[] = await Promise.all(
      purchaseTickets
        .sort((ticket_A, ticket_B) => ticket_A.dueDate.localeCompare(ticket_B.dueDate))
        .map(async (purchaseTicket, index) => {
          const ticketFileName = `${fileName(TableName.PURCHASE_TICKETS, purchase, undefined, index + 1)}`
          const ticketFile = await fetchFile(define(purchaseTicket.ticketUrl), ticketFileName)
          return {
            index,
            purchaseTicket,
            ticketFile
          }
        })
    )
    setDataList(purchaseTicketDataList)
  }
  
  useEffect(() => {
    fetchData()
  }, [purchaseTickets])

  const onDownload = (data: PurchaseTicketData) => () => {
    onChangeCurrentTicket && onChangeCurrentTicket(data.purchaseTicket)
    onChangeCurrentFile && onChangeCurrentFile(data.ticketFile)
    toggleModal(ModalId.PURCHASE_TICKET_PDF)
  }

  const onEdit = (purchaseTicketId: string) => () => {
    navigate(`${RoutePath.TICKETS}/${purchaseTicketId}/${RoutePath.EDIT}`)
  }

  return (
    <>
      {dataList
        .sort((data_A, data_B) => data_A.index > data_B.index ? 1 : -1)
        .map((data) => (
          <Section
            key={data.index}
            id={`ticket-${data.index}`}
            title={`Factura ${data.index + 1} - Fecha Vto.: ${formatValue(new Date(data.purchaseTicket.dueDate), FieldType.DATE)}`}
            collapsable
            defaultExpanded
            className={styles.ticketSection}
          >
            <div className={styles.container}>
              <FileTile
                fileType={FileType.PDF}
                fileName={data.ticketFile.name}
              />
              <div>
                <Button
                  icon='download'
                  kind='tertiary'
                  tooltip='Descargar'
                  small
                  rightMargin
                  onClick={onDownload(data)}
                />
                <Button
                  icon='pencil-fill'
                  tooltip='Editar'
                  small
                  onClick={onEdit(data.purchaseTicket.id)}
                />
              </div>
            </div>
          </Section>
        )
      )}
    </>
  )
}
