import { branches } from "./branches";
import { categories } from "./categories";
import { customerTypes } from "./customerTypes";
import { SalaryExpenseTypeName, expenseTypes } from "./expenseTypes";
import { paymentTypes } from "./paymentTypes";
import { taxes } from "./taxes";
import { unitTypes } from "./unitTypes";

export const Standards = {
    taxes,
    branches,
    categories,
    customerTypes,
    expenseTypes,
    paymentTypes,
    unitTypes,
    SalaryExpenseTypeName
}
