import { useEffect, useState } from 'react'
import { DataType } from '../../../../tables'
import { FieldType, FieldValue, option, FilterField, Filters } from '../../../../features/ui'
import { PurchaseTicket, usePurchasesState } from '../../../purchases'
import { define } from '../../../../utils/typeUtils'
import { PaymentStatus } from '../../../operations'
import { useAdministrationState } from '../../hooks/administrationHooks'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const PendingPaymentFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const [supplierId, setSupplierId] = useState<string>()
    const [dueDateFrom, setDueDateFrom] = useState<Date>()
    const [dueDateTo, setDueDateTo] = useState<Date>()

    const { purchaseTickets, purchases, suppliers } = usePurchasesState()
    const { paymentOrders } = useAdministrationState()
    const payedTicketItems = paymentOrders.flatMap(paymentOrder => paymentOrder.ticketItems)

    useEffect(() => search(supplierId, dueDateFrom, dueDateTo), [])

    const findPurchase = (id: string) => define(purchases.find(purchase => purchase.id === id))

    const filterTickets = (purchaseTicket: PurchaseTicket) => {
        const purchase = findPurchase(purchaseTicket.purchaseId)
        return purchase.paymentStatus === PaymentStatus.PENDING &&
            !payedTicketItems.some(ticketItem => ticketItem.ticketId === purchaseTicket.id && ticketItem.pendingAmount === 0)
    }

    const matchSupplierId = (purchaseTicket: PurchaseTicket, supplierIdParam: string) => {
        const purchase = findPurchase(purchaseTicket.purchaseId)
        return purchase.supplierId === supplierIdParam
    }

    const search = (
        supplierIdParam?: string,
        dueDateFromParam?: Date,
        dueDateToParam?: Date
    ) => {
        onSearchStart()
        let purchaseTicketList = purchaseTickets.filter(filterTickets)
        purchaseTicketList = purchaseTicketList.filter(purchaseTicket => !supplierIdParam || matchSupplierId(purchaseTicket, supplierIdParam))
        purchaseTicketList = dueDateFromParam ? purchaseTicketList.filter(purchaseTicket => new Date(purchaseTicket.dueDate) >= dueDateFromParam) : purchaseTicketList
        purchaseTicketList = dueDateToParam ? purchaseTicketList.filter(purchaseTicket => new Date(purchaseTicket.dueDate) <= dueDateToParam) : purchaseTicketList
        purchaseTicketList = purchaseTicketList.sort((ticket_A: PurchaseTicket, ticket_B: PurchaseTicket) => {
            const codesDiff = findPurchase(ticket_B.purchaseId).code - findPurchase(ticket_A.purchaseId).code
            return codesDiff !== 0 ? codesDiff : ticket_B.dueDate.localeCompare(ticket_A.dueDate)
        })
        setTimeout(() => onSearchDone(purchaseTicketList), 100)
    }

    const onChangeSupplier = (value?: FieldValue) => {
        const newSupplierId = value as string | undefined
        if (newSupplierId !== supplierId) {
            setSupplierId(newSupplierId)
            search(newSupplierId, dueDateFrom, dueDateTo)
        }
    }

    const onChangeDueDateFrom = (value?: FieldValue) => {
        const newDueDateFrom = value as Date | undefined
        newDueDateFrom?.setHours(11, 59, 59)
        if (newDueDateFrom !== dueDateFrom) {
            setDueDateFrom(newDueDateFrom)
            search(supplierId, newDueDateFrom, dueDateTo)
        }
    }
    
    const onChangeDueDateTo = (value?: FieldValue) => {
        const newDueDateTo = value as Date | undefined
        newDueDateTo?.setHours(11, 59, 59)
        if (newDueDateTo !== dueDateTo) {
            setDueDateTo(newDueDateTo)
            search(supplierId, dueDateFrom, newDueDateTo)
        }
    }

    const filterFields: FilterField[] = [
        {
            name: 'supplier',
            type: FieldType.SELECT,
            options: suppliers.map(supplier => option(supplier.id, supplier.name)),
            label: 'Proveedor',
            onChange: onChangeSupplier
        },
        {
            name: 'dueDateFrom',
            type: FieldType.DATE,
            label: 'Vencimiento: Desde',
            space: 2,
            onChange: onChangeDueDateFrom
        },
        {
            name: 'dueDateTo',
            type: FieldType.DATE,
            label: 'Vencimiento: Hasta',
            space: 2,
            onChange: onChangeDueDateTo
        }
    ]

    return (
        <Filters
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
