import { useEffect, useState } from "react"
import { PurchaseTicketForm } from "./purchaseTicketForm"
import { usePurchasesActions } from "../../../actions/purchasesActions"
import { PurchaseItem, PurchaseOrder, PurchaseTicket } from "../../../state/types"
import { define } from "../../../../../utils/typeUtils"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { useGetCrumbs, PanelLoader } from "../../../../../features/ui"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { fileName, useFileActions } from "../../../../../features/files"
import { TableName } from "../../../../../tables"
import { useAdministrationActions } from "../../../../administration"

export const PurchaseTicketFormLoader = () => {  
  const { dataTypeId, secondDataTypeId } = useRoutesHooks().parseUrl()
  const purchaseId = define(dataTypeId)
  const purchaseTicketId = secondDataTypeId

  const [ready, setReady] = useState(false)
  const [purchase, setPurchase] = useState<PurchaseOrder>()
  const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([])
  const [purchaseTicket, setPurchaseTicket] = useState<PurchaseTicket>()
  const [ticketFile, setTicketFile] = useState<File>()

  const descriptorHooks = useDescriptorHooks()

  const purchasesActions = usePurchasesActions()
  const administrationActions = useAdministrationActions()
  const { fetchFile } = useFileActions()

  const populate = async () => {
    const { fetchAllTaxes } = administrationActions()
    const { fetchPurchase, fetchPurchaseItemsByOrder, fetchPurchaseTicket } = purchasesActions()
    await fetchAllTaxes()
    const statePurchase = define(await fetchPurchase(purchaseId)) as PurchaseOrder
    setPurchase(statePurchase)
    const statePurchaseItems = define(await fetchPurchaseItemsByOrder(purchaseId)) as PurchaseItem[]
    setPurchaseItems(statePurchaseItems)
    
    if (purchaseTicketId) {
      const statePurchaseTicket = define(await fetchPurchaseTicket(purchaseTicketId)) as PurchaseTicket
      setPurchaseTicket(statePurchaseTicket)
      if (statePurchaseTicket.ticketUrl) {
        const file = await fetchFile(statePurchaseTicket.ticketUrl, fileName(TableName.PURCHASE_TICKETS, statePurchase))
        setTicketFile(file)
      }
    }
  }

  useEffect(() => {
    populate().then(() => setReady(true))
  }, [])

  const title = `${purchaseTicketId ? 'Editar' : 'Subir'} Factura`

  const crumbs = useGetCrumbs(
    'Compras',
    RoutePath.PURCHASES,
    purchase ? descriptorHooks.purchase.code(purchase) : undefined,
    purchase?.id,
    title
  )

  return (
    <PanelLoader title={title} crumbs={crumbs} ready={ready}>
      {purchase && (
        <PurchaseTicketForm
          purchase={purchase}
          purchaseItems={purchaseItems}
          purchaseTicket={purchaseTicket}
          ticketFileParam={ticketFile}
        />
      )}
    </PanelLoader>
  )
}
