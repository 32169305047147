import { EditableFormField } from '../types'
import { EditableField } from './editableField'

type Props = {
    fields: EditableFormField[]
    readOnly?: boolean
}

export const EditablePanel = (props: Props) => {
    const { fields, readOnly } = props

    return (
        <>
            {fields.map(field => (
                <EditableField
                    key={field.name}
                    id={`${field.name}-field`}
                    field={field}
                    readOnly={readOnly}
                    onSubmit={field.onSave}
                />
            ))} 
        </>
    )
}
