import { TableName } from '../../../../../tables'
import { Data, UIPermission, DetailPanel, DetailFieldProps, FieldType, FieldValue, Action, Spinner, ModalId, useUIActions, Tab, useGetCrumbs, ButtonKind, SpinnerDiv } from '../../../../../features/ui'
import { Customer, CustomerType, HoardItem, HoardOrder, HoardType } from '../../../state/types'
import { define } from '../../../../../utils/typeUtils'
import { useSalesActions } from '../../../actions/salesActions'
import { useEffect, useState } from 'react'
import { Operation, OperationItemsTable, DeliveryStatus, CloseOperationModal, DeliveryList, useOperationsActions, OrderDelivery, PendingItemsList, PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { useSystemStateHooks } from '../../../../system'
import { PaymentType } from '../../../../administration'
import { useFind } from '../../../../../state/reducers/hooks'
import { hoardWizardKey } from '../../../utils/salesUtils'
import { OpenWizardModal, useWizardActions } from '../../../../../features/wizard'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { useDescriptorHooks, Labels } from '../../../../../features/data-types'
import { HoardValues } from '../hoard-values/hoardValues'

export const HoardDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const hoard = define(dataType) as HoardOrder
  const wizardKey = hoardWizardKey(hoard)
  const { purchasePricesList, ...rest } = hoard
  const hoardData = rest as Data

  const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
  const [hoardItems, setHoardItems] = useState<HoardItem[]>([])
  const [customer, setCustomer] = useState<Customer>()
  const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])

  const { userHasPermission } = useSystemStateHooks()
  const { getPriceBreakdownFromItems, getPriceBreakdown } = usePriceBreakdownHooks()
  const descriptorHooks = useDescriptorHooks()
  const find = useFind()

  const { goToWizard } = useWizardActions()
  const salesActions = useSalesActions()
  const operationsActions = useOperationsActions()
  const { toggleModal } = useUIActions()

  const hoardCode = descriptorHooks.hoard.code(hoard)

  const title = `${Labels.hoardTypeLabel(hoard.type)}: ${hoardCode}`
  const readOnly = !userHasPermission(UIPermission.SAVE_HOARDS)
  const isProductHoard = hoard.type === HoardType.PRODUCT

  const init = async () => {
    const stateHoardItems = await salesActions().fetchHoardItemsByOrder(hoard.id) as HoardItem[]
    setHoardItems(stateHoardItems)
    const stateDeliveries = await operationsActions().fetchDeliveries(hoard.id) as OrderDelivery[]
    setDeliveries(stateDeliveries)
    const stateCustomer = define(await salesActions().fetchCustomer(hoard.customerId)) as Customer
    setCustomer(stateCustomer)
    setAsyncDataLoaded(true)
  }

  useEffect(() => {
    init()
  }, [])

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const crumbs = useGetCrumbs('Acopios', RoutePath.HOARDS, hoardCode)

  if (!hoard) return <SpinnerDiv />

  const customerType = find(TableName.CUSTOMER_TYPES, customer?.customerTypeId) as CustomerType | undefined
  const paymentType = find(TableName.PAYMENT_TYPES, hoard.paymentTypeId) as PaymentType | undefined
  const priceBreakdown = isProductHoard ?
    getPriceBreakdownFromItems(hoardItems, customerType?.yield, paymentType?.yield) :
    getPriceBreakdown(define(hoard.value), customerType?.yield, paymentType?.yield)

  const fields: DetailFieldProps[] = [
    { name: 'branchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal de Entrega' },
    { name: 'sellerId', type: FieldType.TABLE, table: TableName.EMPLOYEES, label: 'Vendedor' },
    { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
    { name: 'dueDate', type: FieldType.DATE, label: 'Fecha Límite' },
    { name: 'paymentTypeId', type: FieldType.TABLE, table: TableName.PAYMENT_TYPES, label: 'Medio de Pago' },
    {
      name: 'priceBreakdown',
      type: FieldType.PRICE,
      label: 'Precio',
      render: () => renderAsync(
        <PriceBreakdown data={priceBreakdown} />
      )
    },
    {
      name: 'deliveryStatus',
      type: FieldType.TEXT,
      label: 'Estado de Entrega',
      render: (value?: FieldValue) => <>{Labels.deliveryStatusLabel(define(value) as DeliveryStatus)}</>
    }
  ]
  if (hoardItems.length > 0) {
    const itemsField = {
      name: 'hoardItems',
      type: FieldType.TABLE,
      table: TableName.HOARD_ITEMS,
      render: () => renderAsync(
        <OperationItemsTable
            rows={hoardItems as Data[]}
            operation={Operation.SALE}
            readOnly
        />
      )
    }
    fields.splice(2, 0, itemsField)

    if (isProductHoard && hoard.deliveryStatus !== DeliveryStatus.DELIVERED && deliveries.length > 0) {
      const pendingItemsField = {
        name: 'pendingItems',
        type: FieldType.TABLE,
        table: TableName.HOARD_ITEMS,
        render: () => renderAsync(
          <PendingItemsList orderId={hoard.id} operation={Operation.HOARD} />
        )
      }
      fields.splice(3, 0, pendingItemsField)
    }
  }
  if (!isProductHoard && hoard.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED) {
    const hoardValuesField = {
      name: 'hoardValues',
      type: FieldType.TABLE,
      label: ' ',
      render: () => renderAsync(
        <HoardValues
          hoard={hoard}  
          hoardItems={hoardItems}
        />
      )
    }
    fields.push(hoardValuesField)
  }

  const onDeliver = () => {
    goToWizard(hoardWizardKey(hoard), RoutePath.DELIVERY)
  }

  const onArchive = () => {}

  const onClose = () => {
    toggleModal(ModalId.CLOSE_OPERATION)
  }

  const actions: Action[] = []
  if (!readOnly) {
    if (hoard.deliveryStatus === DeliveryStatus.DELIVERED) {
      const archiveAction = { label: 'Archivar', icon: 'archive', kind: 'secondary' as ButtonKind, onClick: onArchive }
      actions.push(archiveAction)
    } else {
      const deliverAction = { label: 'Registrar Entrega', icon: 'truck', onClick: onDeliver }
      const closeAction = { label: 'Cerrar', icon: 'bag-check-fill', onClick: onClose }  
      actions.push(deliverAction, closeAction)
    }
  }

  const tabs: Tab[] = deliveries.length > 0 ? [
    {
      id: 'deliveries',
      title: 'Entregas',
      content: <DeliveryList
        orderId={hoard.id}
        operation={Operation.HOARD}
      />
    }
  ] : []

  return (<>
    <DetailPanel
      title={title}
      tableName={TableName.HOARD_ORDERS}
      crumbs={crumbs}
      fields={fields}
      data={hoardData}
      icon='layers'
      imgSpace={3}
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <OpenWizardModal
      wizardKey={wizardKey}
      path={RoutePath.DELIVERY}
    />
    <CloseOperationModal
      operationType={Operation.HOARD}
      operation={hoard}
    />
  </>)
}
