import styles from './wizardStep.module.css'
import { FieldRow, FormField, Field, StaticValue, FieldValue, FieldType } from "../../../ui"
import { useWizardActions } from '../../actions/wizardActions'
import { isDefined, toDate } from '../../../../utils/typeUtils'
import { useWizardHooks } from '../../hooks/wizardHooks'

type Props = {
    wizardKey: string
    stepIndex: number
    fields: FormField[]
    onChange?: (fieldName: string, value?: FieldValue) => void
}

export const WizardStep = (props: Props) => {
    const { wizardKey, stepIndex, fields } = props

    const { getStepData } = useWizardHooks()
    const { updatedData, saveData } = useWizardActions()

    const handleChange = (field: FormField) => (value?: FieldValue) => {
        const newWizardData = updatedData(wizardKey, stepIndex, field.name, value)
        saveData(wizardKey, newWizardData)
        field.onChange && field.onChange(value)
    }

    const renderField = (field: FormField) => {
        const stepData = getStepData(wizardKey, stepIndex)
        const rawValue = stepData[field.name]
        const value = field.type === FieldType.DATE ? toDate(rawValue as string) : rawValue
        const fieldWithValue = {
            ...field,
            defaultValue: isDefined(value) ? value : field.defaultValue
        }

        return <Field
            key={`${field.name}-${stepIndex}`}
            field={fieldWithValue}
            onChange={handleChange(field)}
        />
    }

    return (
        <div className={styles.container}>
            {fields.map(field => (
                <FieldRow
                    key={field.name}
                    id={field.name}
                    label={field.label}
                    labelSpace={6}
                >
                    {field.render ? field.render() : (
                        field.disabled ?
                            <StaticValue type={field.type} table={field.table} value={field.value} /> :
                            renderField(field)
                    )}
                </FieldRow>
            ))}
        </div>
    )
}
