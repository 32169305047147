import { showToast } from "../../../../features/ui"
import { useSalesActions } from "../../actions/salesActions"
import { useSalesHooks } from "../../hooks/salesHooks"

export const useActions = () => {
    const { getSettings } = useSalesHooks()
    
    const salesActions = useSalesActions()
    
    const onUpdateSettings = () => showToast('La configuración fue actualizada.')

    const submitIgnoreProduct = async (productId: string) => {
        const hoardIgnoredProductIds = (getSettings()?.hoardIgnoredProductIds || []) as string[]
        const newProductIds = hoardIgnoredProductIds.concat(productId)
        await salesActions().saveSalesSetting('hoardIgnoredProductIds', newProductIds)
        onUpdateSettings()
    }

    const submitUnignoreProduct = async (productId: string) => {
        const hoardIgnoredProductIds = (getSettings()?.hoardIgnoredProductIds || []) as string[]
        const newProductIds = hoardIgnoredProductIds.filter(id => id !== productId)
        await salesActions().saveSalesSetting('hoardIgnoredProductIds', newProductIds)
        onUpdateSettings()
    }

    return {
        submitIgnoreProduct,
        submitUnignoreProduct
    }
}
