import Importer from "papaparse"
import { CsvColumn, CsvResult, CsvRow } from "../types"
import { standarize } from "../utils/importerUtils"

export const useImportCsv = () => {
    const validateData = (data: CsvRow[], columns: CsvColumn[]): boolean => {
        if (data.length === 0) {
            return false
        }
        const headerRow = data[0]
        const missingColumns = columns.filter(column =>
            !column.optional && !Object.keys(headerRow).map(header =>
                standarize(header)).includes(standarize(column.name)
            )
        )
        
        if (missingColumns.length > 0) {
            return false
        }
        
        return true
    }

    const importData = (
        columns: CsvColumn[],
        manageData: (rows: CsvRow[]) => void,
        callback: (result: CsvResult) => void
    ) => (result: Importer.ParseResult<CsvRow>) => {
        const { data } = result

        if (!validateData(data, columns)) {
            return callback({
                processedRows: [],
                unprocessedRows: data,
                error: 'CSV Error: File format is not valid'
            })
        }
        
        const standarizedColumns = columns.map(column => ({ ...column, name: standarize(column.name) }))
        const standarizedData = data.map(row => {
            const standarizedRow: CsvRow = {}
            const keys = Object.keys(row)
            keys.forEach(key => {
                standarizedRow[standarize(key)] = row[key]
            })
            return standarizedRow
        })

        const requriedColumns = standarizedColumns.filter(column => !column.optional)
        const validatableColumns = standarizedColumns.filter(column => !!column.validate)

        const validRows: CsvRow[] = []
        const invalidRows: CsvRow[] = []
        standarizedData.forEach(row => {
            const missingValue = !requriedColumns.every(column => row[column.name])
            const invalidValue = !validatableColumns.every(column => column.validate && column.validate(row[column.name]))
            if (missingValue || invalidValue) {
                invalidRows.push(row)
            } else {
                validRows.push(row)
            }
        })

        manageData(validRows)

        callback({
            processedRows: validRows,
            unprocessedRows: invalidRows
        })
    }

    return (
        file: File,
        columns: CsvColumn[],
        manageData: (rows: CsvRow[]) => void,
        callback: (result: CsvResult) => void
    ) => {
        if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                if (e.target) {
                    const content = e.target.result as any
                    Importer.parse(content, {
                        header: true,
                        skipEmptyLines: true,
                        complete: importData(columns, manageData, callback)
                    })
                }
            }

            reader.readAsText(file)
        }
    }
}
