import { Data, FieldType, Form, FormField, Modal, ModalId, Option, option, useUIActions, useUIState } from '../../../../../features/ui'
import { TableName } from '../../../../../tables'
import { Product, ProductName, useProductsState } from '../../../../products'
import { define } from '../../../../../utils/typeUtils'
import { useEffect, useState } from 'react'
import { useFind } from '../../../../../state/reducers/hooks'

type Props = {
    filterProducts?: (product: Product) => boolean
    onSubmit: (productId: string) => void
}

export const NonHoardableProductFormModal = (props: Props) => {
    const { filterProducts, onSubmit } = props

    const [key, setKey] = useState(0)

    const { products } = useProductsState()
    const { openedModal } = useUIState()

    const find = useFind()

    const { toggleModal } = useUIActions()
    
    useEffect(() => setKey(key + 1), [openedModal])

    const productOptions = products
        .filter(product => filterProducts && filterProducts(product))
        .map(product => option(product.id, product.name))

    const renderProductOption = (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>) => void) => {
        const product = define(find(TableName.PRODUCTS, option.value)) as Product
        return (
            <div onClick={onClick}>
                <ProductName product={product} />
            </div>
        )
    }

    const fields: FormField[] = [
        {
            name: 'productId',
            type: FieldType.TABLE,
            table: TableName.PRODUCTS,
            options: productOptions,
            placeholder: 'Seleccionar producto',
            renderOption: renderProductOption
        }
    ]

    const handleSubmit = (data: Data) => {
        onSubmit(define(data.productId) as string)
        toggleModal(ModalId.NON_HOARDABLE_PRODUCT_FORM)
    }

    const handleCancel = () => toggleModal(ModalId.NON_HOARDABLE_PRODUCT_FORM)

    return (
        <Modal
            modalId={ModalId.NON_HOARDABLE_PRODUCT_FORM}
            title="Agregar producto no acopiable"
            actions={[]}
        >
            <Form
                key={key}
                formId="non-hoardable-product-form"
                fields={fields}
                createMode={true}
                createLabel="Ignorar"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
        </Modal>
    )
}
