import { useDescriptorHooks } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { DetailFieldProps, DetailPanel, FieldType, FieldValue, UIPermission, useGetCrumbs } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { useSystemStateHooks } from "../../../../system"
import { CreditCard } from "../../../state/types"

export const CreditCardDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const creditCard = define(dataType) as CreditCard

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_CREDIT_CARDS)
  const descriptorHooks = useDescriptorHooks()

  const info = descriptorHooks.creditCard.info(creditCard)
  const title = info
  const crumbs = useGetCrumbs('Tarjetas', RoutePath.CREDIT_CARDS, info)

  const fields: DetailFieldProps[] = [
    { name: 'type', type: FieldType.TEXT, label: 'Tipo' },
    { name: 'issuer', type: FieldType.TEXT, label: 'Emisor' },
    { name: 'cardholder', type: FieldType.TEXT, label: 'Titular' },
    {
      name: 'last4Numbers',
      type: FieldType.TEXT,
      label: 'Número',
      render: (value?: FieldValue) => <>* - {value}</>
    },
    { name: 'dueDate', type: FieldType.DATE, label: 'Fecha de Vto.' }
  ]

  return (<>
    <DetailPanel
      title={title}
      tableName={TableName.CREDIT_CARDS}
      crumbs={crumbs}
      fields={fields}
      data={creditCard}
      icon='credit-card-2-front'
      readOnly={readOnly}
    />
  </>)
}
