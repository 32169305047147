import styles from './pdfTabContent.module.css'

type Props = {
    children: JSX.Element
}

export const PDFTabContent = (props: Props) => {
    const { children } = props

    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}
