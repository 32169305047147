import { useEffect, useState } from "react"
import { DataType } from "../../../../tables"
import { LAST_14_DAYS, LAST_30_DAYS, LAST_7_DAYS, MANUAL, periodOptions, TODAY } from "./utils"
import { FieldType, FieldValue, FilterField, Filters } from "../../../../features/ui"
import { useActions } from "./actions"
import { Transaction } from "../../state/types"

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const TransactionFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const buildDateFrom = (daysBack: number) => {
        const newDateFrom = new Date()
        newDateFrom.setDate(newDateFrom.getDate() - daysBack)
        newDateFrom.setHours(0, 0, 0, 0)
        return newDateFrom
    }

    const buildDateTo = () => {
        const newDateTo = new Date()
        newDateTo.setHours(23, 59, 59, 999)
        return newDateTo
    }

    const [period, setPeriod] = useState<string>(LAST_7_DAYS)
    const [dateFrom, setDateFrom] = useState<Date>(buildDateFrom(7))
    const [dateTo, setDateTo] = useState<Date>(buildDateTo())
    const [amountFrom, setAmountFrom] = useState<number>()
    const [amountTo, setAmountTo] = useState<number>()

    const { fetchTransactions } = useActions()

    useEffect(() => {
        search(dateFrom, dateTo)
    }, [])

    const search = async (
        dateFromParam: Date,
        dateToParam: Date,
        amountFromParam?: number,
        amountToParam?: number
    ) => {
        onSearchStart()
        let transactionList = await fetchTransactions(dateFromParam, dateToParam) as Transaction[]
        transactionList = amountFromParam ? transactionList.filter(transaction => transaction.amount >= amountFromParam) : transactionList
        transactionList = amountToParam ? transactionList.filter(transaction => transaction.amount <= amountToParam) : transactionList
        setTimeout(() => onSearchDone(transactionList), 100)
    }

    const onChangePeriod = (value?: FieldValue) => {
        const newPeriod = value as string | undefined
        if (newPeriod) {
            setPeriod(newPeriod)
            if (newPeriod !== MANUAL) {
                let newDateFrom = dateFrom
                if (newPeriod === TODAY) {
                    newDateFrom = buildDateFrom(0)
                } else if (value === LAST_7_DAYS) {
                    newDateFrom = buildDateFrom(7)
                } else if (value === LAST_14_DAYS) {
                    newDateFrom = buildDateFrom(14)
                } else if (value === LAST_30_DAYS) {
                    newDateFrom = buildDateFrom(30)
                }
                const newDateTo = buildDateTo()
                setDateFrom(newDateFrom)
                setDateTo(newDateTo)
                search(newDateFrom, newDateTo, amountFrom, amountTo)
            }
        }
    }

    const onChangeDateFrom = (value?: FieldValue) => {
        const newDateFrom = value as Date | undefined
        newDateFrom?.setHours(11, 59, 59)
        if (newDateFrom) {
            setPeriod(MANUAL)
            setDateFrom(newDateFrom)
            search(newDateFrom, dateTo, amountFrom, amountTo)
        }
    }
    
    const onChangeDateTo = (value?: FieldValue) => {
        const newDateTo = value as Date | undefined
        newDateTo?.setHours(11, 59, 59)
        if (newDateTo) {
            setPeriod(MANUAL)
            setDateTo(newDateTo)
            search(dateFrom, newDateTo, amountFrom, amountTo)
        }
    }

    const onChangeAmountFrom = (value?: FieldValue) => {
        const newAmountFrom = (value || 0) as number
        setAmountFrom(newAmountFrom)
        search(dateFrom, dateTo, newAmountFrom, amountTo)
    }

    const onChangeAmountTo = (value?: FieldValue) => {
        const newAmountTo = value as number | undefined
        setAmountTo(newAmountTo)
        search(dateFrom, dateTo, amountFrom, newAmountTo)
    }

    const filterFields: FilterField[] = [
        {
            name: 'period',
            type: FieldType.SELECT,
            value: period,
            options: periodOptions(),
            label: 'Período',
            space: 2,
            onChange: onChangePeriod
        },
        {
            name: 'dateFrom',
            type: FieldType.DATE,
            value: dateFrom,
            label: 'Fecha: Desde',
            max: dateTo,
            space: 2,
            onChange: onChangeDateFrom
        },
        {
            name: 'dateTo',
            type: FieldType.DATE,
            value: dateTo,
            label: 'Fecha: Hasta',
            min: dateFrom,
            max: new Date(),
            space: 2,
            onChange: onChangeDateTo
        },
        {
            name: 'amountFrom',
            type: FieldType.NUMBER,
            label: 'Monto: Desde',
            max: amountTo,
            space: 3,
            onChange: onChangeAmountFrom
        },
        {
            name: 'amountTo',
            type: FieldType.NUMBER,
            label: 'Monto: Hasta',
            min: amountFrom,
            space: 3,
            onChange: onChangeAmountTo
        }
    ]

    return (
        <Filters
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
