import { Table, Column, Data, FieldType, FieldValue, Section } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { Product, ProductName } from "../../../../products"
import { useFind } from "../../../../../state/reducers/hooks"

type Props = {
    rows: Data[]
    readOnly?: boolean
    onAdd?: () => void
    onRemoveRow?: (productId: string) => void
}

export const NonHoardableProductsTable = (props: Props) => {
    const { rows, readOnly, onAdd, onRemoveRow } = props

    const find = useFind()

    const columns: Column[] = [
        {
            name: 'productId',
            type: FieldType.TABLE,
            table: TableName.PRODUCTS,
            label: 'Producto',
            render: (value?: FieldValue) => {
                const productId = value as string | undefined
                const product = define(find(TableName.PRODUCTS, productId)) as Product
                return <ProductName product={product} />
            }
        }
    ]

    return (
        <Section
            id='nonHoardableProducts'
            space={12}
        >
            <Table
                columns={columns}
                rows={rows}
                rowId='productId'
                readOnly={readOnly}
                createIcon="plus"
                small
                onCreate={onAdd}
                onRemove={onRemoveRow}
            />
        </Section>
    )
}
