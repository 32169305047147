import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import * as actionCreators from "../state/actionCreators"
import { AdministrationState } from "../state/reducer"
import { useSelector } from "react-redux"
import { State } from "../../../state"
import { define, normalize } from "../../../utils/typeUtils"
import { CashFund, CategorySaleYield, Check, CheckStatus, CreditCard, Employee, Expense, PaymentOrder, PaymentType, Settings, SettingsModule, Tax, TaxType } from "../state/types"
import { useFind } from "../../../state/reducers/hooks"
import { Labels, Standards } from "../../../features/data-types"
import { DataType, TableName } from "../../../tables"
import { PurchaseTicket, Supplier, usePurchasesHooks } from "../../purchases"
import { useFiltersHooks } from "../../../features/ui"

export const useAdministrationState = (): AdministrationState => useSelector((state: State) => state.administrationReducer)

export const useAdministrationStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useAdministrationHooks = () => {
    const { settings, expenses, taxes, paymentOrders } = useAdministrationState()
    const { search } = useFiltersHooks()
    const { getSettings } = usePurchasesHooks()
    const find = useFind()

    const getSaleTaxes = () => taxes.filter(tax => tax.type === TaxType.SALE_TAX)

    const getTotalSaleTax = () => getSaleTaxes().reduce((sum, tax) => sum + tax.yield, 0)
    
    const applyPaymentTypeAdjustment = (
        price: number,
        paymentType: PaymentType
    ) => {
        return price * (1 + (paymentType.yield / 100))
    }

    const getSalesSettings = (): Settings => define(settings.find(setting => setting.module === SettingsModule.SALES))

    const getParentSaleYield = (categoryId?: string) => {
        const settings = getSettings()
        const generalSaleYield = define(settings?.generalSaleYield) as number
        const categorySaleYields = (settings?.categorySaleYields || []) as CategorySaleYield[]
        const categorySaleYield = categorySaleYields.find(categorySaleYield => categorySaleYield.categoryId === categoryId)?.saleYield
        return categorySaleYield || generalSaleYield
    }

    const getBranchExpenses = (branchId: string) => {
        return expenses.filter(expense => expense.branchId === branchId)
    }

    const getSalaryExpenseTypeId = () => {
        return define(find(TableName.EXPENSE_TYPES, Standards.SalaryExpenseTypeName, 'name')?.id)
    }

    const getTicketPendingAmount = (purchaseTicket: PurchaseTicket) => {
        const ticketItemsList = paymentOrders
            .filter(paymentOrder => paymentOrder.ticketItems.some(ticketItem => ticketItem.ticketId === purchaseTicket.id))  
            .flatMap(paymentOrder => paymentOrder.ticketItems)
            .filter(ticketItem => ticketItem.ticketId === purchaseTicket.id)
        const payedAmount = ticketItemsList.reduce((sum, ticketItem) => sum + ticketItem.payedAmount, 0)
        const payedVatAmount = ticketItemsList.reduce((sum, ticketItem) => sum + ticketItem.payedVatAmount, 0)
        
        return {
            pendingAmount: purchaseTicket.finalAmount - payedAmount,
            pendingVatAmount: purchaseTicket.vatAmount - payedVatAmount
        }
    }

    const searchEmployees = (
        employees: Employee[],
        text = '',
        showAll = false
    ): Employee[] => {
        const valuesFn = (employeeDT: DataType) => {
            const { name, identifier, email } = employeeDT as Employee
            return [
                normalize(name),
                identifier ? identifier.toString() : '',
                normalize(email)
            ]
        }
        const sortFn = (employeeDT_A: DataType, employeeDT_B: DataType) => 
            (employeeDT_A as Employee).name.localeCompare((employeeDT_B as Employee).name)

        return search(employees, valuesFn, sortFn, text, showAll) as Employee[]
    }

    const searchTaxes = (
        taxList: Tax[],
        text = '',
        showAll = false
    ): Tax[] => {
        const valuesFn = (taxDT: DataType) => {
            const { name, type, code, legalCode } = taxDT as Tax
            return [
                normalize(name),
                normalize(Labels.taxTypeLabel(type)),
                normalize(code),
                normalize(legalCode)
            ]
        }
        const sortFn = (taxDT_A: DataType, taxDT_B: DataType) => 
            (taxDT_A as Tax).name.localeCompare((taxDT_B as Tax).name)

        return search(taxList, valuesFn, sortFn, text, showAll) as Tax[]
    }

    const searchCashFunds = (
        cashFunds: CashFund[],
        text = '',
        showAll = false
    ): CashFund[] => {
        const valuesFn = (taxDT: DataType) => {
            const { name, type } = taxDT as CashFund
            return [
                normalize(name),
                normalize(Labels.cashFundTypeLabel(type))
            ]
        }
        const sortFn = (cashFundDT_A: DataType, cashFundDT_B: DataType) => 
            (cashFundDT_A as CashFund).name.localeCompare((cashFundDT_B as CashFund).name)

        return search(cashFunds, valuesFn, sortFn, text, showAll) as CashFund[]
    }

    const searchChecks = (
        checks: Check[],
        text = '',
        showAll = false
    ): Check[] => {
        const valuesFn = (checkDT: DataType) => {
            const { name, taxId } = checkDT as Check
            return [
                normalize(name),
                taxId.toString()
            ]
        }
        const sortFn = (checkDT_A: DataType, checkDT_B: DataType) => {
            const statusPriorities = new Map<CheckStatus, number>()
                .set(CheckStatus.ENABLED, 1)
                .set(CheckStatus.RESERVED, 2)
                .set(CheckStatus.ENDORSED, 3)
            const check_A = checkDT_A as Check
            const check_B = checkDT_B as Check
            const statusOrder = (statusPriorities.get(check_A.status) || 0) - (statusPriorities.get(check_B.status) || 0)
            return statusOrder !== 0 ? statusOrder : check_A.paymentDate.localeCompare(check_B.paymentDate)
        }

        return search(checks, valuesFn, sortFn, text, showAll) as Check[]
    }

    const searchCreditCards = (
        creditCards: CreditCard[],
        text = '',
        showAll = false
    ): CreditCard[] => {
        const valuesFn = (creditCardDT: DataType) => {
            const { type, issuer, cardholder, last4Numbers } = creditCardDT as CreditCard
            return [
                normalize(type),
                normalize(issuer),
                normalize(cardholder),
                last4Numbers.toString()
            ]
        }
        const sortFn = (creditCardDT_A: DataType, creditCardDT_B: DataType) => 
            (creditCardDT_A as CreditCard).dueDate.localeCompare((creditCardDT_B as CreditCard).dueDate)

        return search(creditCards, valuesFn, sortFn, text, showAll) as CreditCard[]
    }

    const searchExpenses = (
        expenseList: Expense[],
        text = '',
        showAll = false
    ): Expense[] => {
        const valuesFn = (expenseDT: DataType) => {
            const { code, name, supplierId } = expenseDT as Expense
            const supplier = find(TableName.SUPPLIERS, supplierId) as Supplier | undefined
            return [
                code.toString(),
                normalize(name),
                supplier ? normalize(supplier.name) : ''
            ]
        }
        const sortFn = (expenseDT_A: DataType, expenseDT_B: DataType) => 
            (expenseDT_B as Expense).date.localeCompare((expenseDT_A as Expense).date)

        return search(expenseList, valuesFn, sortFn, text, showAll) as Expense[]
    }

    const searchPaymentTypes = (
        paymentTypes: PaymentType[],
        text = '',
        showAll = false
    ): PaymentType[] => {
        const valuesFn = (paymentTypeDT: DataType) => {
            const { name } = paymentTypeDT as PaymentType
            return [normalize(name)]
        }
        const sortFn = (paymentTypeDT_A: DataType, paymentTypeDT_B: DataType) => 
            (paymentTypeDT_A as PaymentType).name.localeCompare((paymentTypeDT_B as PaymentType).name)

        return search(paymentTypes, valuesFn, sortFn, text, showAll) as PaymentType[]
    }

    const searchPaymentOrders = (
        paymentOrderList: PaymentOrder[],
        text = '',
        showAll = false
    ): PaymentOrder[] => {
        const valuesFn = (paymentOrderDT: DataType) => {
            const { code } = paymentOrderDT as PaymentOrder
            return [code.toString()]
        }
        const sortFn = (paymentOrderDT_A: DataType, paymentOrderDT_B: DataType) => 
            (paymentOrderDT_B as PaymentOrder).creationDate.localeCompare((paymentOrderDT_A as PaymentOrder).creationDate)

        return search(paymentOrderList, valuesFn, sortFn, text, showAll) as PaymentOrder[]
    }

    return {
        getTotalSaleTax,
        applyPaymentTypeAdjustment,
        getSalesSettings,
        getParentSaleYield,
        getBranchExpenses,
        getSalaryExpenseTypeId,
        getTicketPendingAmount,
        searchEmployees,
        searchTaxes,
        searchCashFunds,
        searchChecks,
        searchCreditCards,
        searchExpenses,
        searchPaymentTypes,
        searchPaymentOrders
    }
}
