import { define } from "../../../../utils/typeUtils"
import { useActions } from "./actions"
import { Modal, ModalId, RequestAction } from "../../../../features/ui"
import { Request, useSystemState } from "../../../system"
import { Labels } from "../../../../features/data-types"

type Props = {
    request?: Request
    requestAction?: RequestAction
}

export const ResolveRequestModal = (props: Props) => {
    const { request, requestAction } = props
    const approveMode = requestAction === RequestAction.APPROVE
    const { company, companyUsers } = useSystemState()
    const user = companyUsers.find(companyUser => companyUser.id === request?.userId)
    const { resolveRequest } = useActions()
    
    const title = `${approveMode ? 'Aprobar' : 'Denegar'} Acceso?`
    
    const renderBody = () => request ? (
            approveMode ?
                `Está a punto de asignar acceso de ${Labels.userRoleLabel(request.role)} en ${company?.name} al usuario ${user?.email}. Asegúrese de que este mail pertenece a alguien de su organización.` :
                `Está a punto de denegarle acceso de ${Labels.userRoleLabel(request.role)} en ${company?.name} al usuario ${user?.email}. Está seguro?`
    ) : ''

    const onSubmit = () => resolveRequest(define(request), approveMode)
    
    return (
        <Modal
            modalId={ModalId.RESOLVE_REQUEST}
            title={title}
            submitLabel="Confirmar"
            submitKind={approveMode ? 'primary' : 'secondary'}
            onSubmit={onSubmit}
        >
            <>{renderBody()}</>
        </Modal>
    )
}
