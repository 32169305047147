import { Stock, UnitType } from "./types"

export enum ActionType {
    UPDATE_STOCK = 'updateStock',
    REMOVE_STOCK = 'removeStock',
    UPDATE_UNIT_TYPES = 'updateUnitTypes'
}

interface UpdateStock {
    type: ActionType.UPDATE_STOCK
    payload: Stock[]
}

interface RemoveStock {
    type: ActionType.REMOVE_STOCK
    payload: string[]
}

interface UpdateUnitTypes {
    type: ActionType.UPDATE_UNIT_TYPES
    payload: UnitType[]
}

export type StockAction =
    StockUpdateAction |
    StockRemoveAction

export type StockUpdateAction =
    UpdateStock |
    UpdateUnitTypes

export type StockRemoveAction =
    RemoveStock
