export enum PolicyAction {
  QUERY = 'Query',
  SCAN = 'Scan',
  BATCH_GET = 'BatchGetItem',
  BATCH_WRITE = 'BatchWriteItem'
}

export type PolicyType = {
  Version: string
  Statement: StatementType[]
}

export type StatementType = {
  Effect: string
  Action: string[]
  Resource: string | string[]
  Condition?: {
    [key: string]: {
      [key: string]: string
    }
  }
}
