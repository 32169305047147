import { DataType } from "../../tables"
import { define } from "../../utils/typeUtils"
import { ReducerState, RemoveAction, UpdateAction } from "./types"

export const addOrUpdate = (state: ReducerState, action: UpdateAction, stateName: keyof ReducerState) => {
    const stateList = define(state)[stateName] as DataType[]
    const payloadList = action.payload as DataType[]
    const newItems = payloadList.filter(payloadItem =>
        !stateList.map(stateItem => stateItem.id).includes(payloadItem.id)
    )

    return stateList
        .map(stateItem => {
            const payloadItem = payloadList.find(payloadItem => payloadItem.id === stateItem.id)
            return stateItem.id === payloadItem?.id ? payloadItem : stateItem
        })
        .concat(newItems)
}

export const remove = (state: ReducerState, action: RemoveAction, stateName: keyof ReducerState) => {
    const stateList = define(state)[stateName] as DataType[]
    const payloadList = action.payload as string[]
    return stateList.filter(stateItem => !payloadList.includes(stateItem.id))
}
