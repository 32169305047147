import { Form, Data, FormField, ModalId, useUIActions, FieldType, option, FieldValue, Option, StaticValue } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { Tax, TaxBase } from '../../../state/types'
import { TableName } from "../../../../../tables"
import { useAdministrationState } from "../../../hooks/administrationHooks"
import { useState } from "react"
import { firstItem } from "../../../../../utils/listUtils"
import { usePricesHooks } from "../../../../operations"

type Props = {
    taxItem?: Data
    baseAmount?: number
    vatAmount?: number
    editableAmount?: boolean
    filterTaxes?: (tax: Tax) => boolean
    onSubmit: (data: Data) => void
}

export const TaxItemForm = (props: Props) => {
    const { taxItem, baseAmount = 0, vatAmount = 0, editableAmount, filterTaxes, onSubmit } = props
    const createMode = !taxItem
    
    const { taxes } = useAdministrationState()
    const taxList = filterTaxes ? taxes.filter(filterTaxes) : taxes

    const pricesHooks = usePricesHooks()
    
    const findTax = (taxCode?: string) => taxList.find(tax => tax.code === taxCode)
    const calculateTaxAmount = (tax: Tax) => {
        const taxBaseAmount = tax.taxBase === TaxBase.PRICE_WITH_VAT ? baseAmount :
            (tax.taxBase === TaxBase.VAT ? vatAmount : baseAmount - vatAmount)
        return pricesHooks.adjustment.calculate(taxBaseAmount, tax.yield)
    }
    
    const defaultTaxCode = (taxItem?.taxCode || firstItem(taxList)?.code) as string | undefined
    const defaultTax = findTax(defaultTaxCode)
    const defaultTaxYield = defaultTax?.yield
    const defaultAmount = (taxItem?.amount || defaultTax && calculateTaxAmount(defaultTax)) as number | undefined
    const [taxYield, setTaxYield] = useState<number | undefined>(defaultTaxYield)
    const [amount, setAmount] = useState<number | undefined>(defaultAmount)
    
    const { toggleModal } = useUIActions()

    const taxOptions = taxList.map(tax => option(tax.code, tax.name))
    
    const renderOption = (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>) => void) => {
        const tax = define(findTax(option.value))
        return (
            <div onClick={onClick}>
                {tax.name} ({tax.yield}%)
            </div>
        )
    }

    const onChangeTaxCode = (value?: FieldValue) => {
        const tax = findTax(value as string | undefined)
        if (tax) {
            setTaxYield(tax?.yield)
            setAmount(calculateTaxAmount(tax))
        }
    }

    const onChangeAmount = (value?: FieldValue) => {
        setAmount(value as number | undefined)
    }

    const renderAmount = !editableAmount ? (() => <StaticValue type={FieldType.PRICE} value={amount} />) : undefined

    const fields: FormField[] = [
        {
            name: 'taxCode',
            type: FieldType.TABLE,
            table: TableName.TAXES,
            label: 'Impuesto',
            defaultValue: defaultTaxCode,
            options: taxOptions,
            placeholder: 'Seleccionar impuesto',
            renderOption,
            onChange: onChangeTaxCode
        },
        {
            name: 'taxYield',
            type: FieldType.PERCENTAGE,
            value: taxYield,
            label: '%',
            disabled: true
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto',
            value: amount,
            onChange: onChangeAmount,
            render: renderAmount
        }
    ]
    
    const handleSubmit = (data: Data) => {
        onSubmit({
            ...data,
            taxYield: define(taxYield),
            amount
        })
        toggleModal(ModalId.TAX_ITEM_FORM)
    }

    const handleCancel = () => toggleModal(ModalId.TAX_ITEM_FORM)

    return (
        <Form
            formId="tax-item-form"
            fields={fields}
            createMode={createMode}
            createLabel="Agregar"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    )
}
