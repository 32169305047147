import { Action, TableActions } from "../../../features/roles";
import { TableName } from '../../../tables'

export const ConstrufyAdminPolicyName = 'construfy.admin'
export const ConstrufyAdminRoleName = 'construfy.admin.role'

const allActions = [Action.GET_ALL, Action.GET_BY_PARAMS, Action.GET_BY_IDS, Action.SAVE, Action.REMOVE]

export const construfyAdminPermissions = (): TableActions[] => [
    { tableName: TableName.ADMIN, actions: allActions },
    { tableName: TableName.BRANCHES, actions: allActions },
    { tableName: TableName.BUDGETS, actions: allActions },
    { tableName: TableName.BUDGET_ITEMS, actions: allActions },
    { tableName: TableName.CASH_FUNDS, actions: allActions },
    { tableName: TableName.CATEGORIES, actions: allActions },
    { tableName: TableName.CHECKS, actions: allActions },
    { tableName: TableName.COMPANIES, actions: allActions },
    { tableName: TableName.CREDIT_CARDS, actions: allActions },
    { tableName: TableName.CUSTOMER_TYPES, actions: allActions },
    { tableName: TableName.CUSTOMERS, actions: allActions },
    { tableName: TableName.EMPLOYEES, actions: allActions },
    { tableName: TableName.EXPENSE_TYPES, actions: allActions },
    { tableName: TableName.EXPENSES, actions: allActions },
    { tableName: TableName.HOARD_ITEMS, actions: allActions },
    { tableName: TableName.HOARD_ORDERS, actions: allActions },
    { tableName: TableName.LOTS, actions: allActions },
    { tableName: TableName.MEASURES, actions: allActions },
    { tableName: TableName.ORDER_DELIVERIES, actions: allActions },
    { tableName: TableName.PAYMENT_ORDERS, actions: allActions },
    { tableName: TableName.PAYMENT_TYPES, actions: allActions },
    { tableName: TableName.PRODUCTS, actions: allActions },
    { tableName: TableName.PRODUCT_PRICES, actions: allActions },
    { tableName: TableName.PURCHASE_ITEMS, actions: allActions },
    { tableName: TableName.PURCHASE_ORDERS, actions: allActions },
    { tableName: TableName.PURCHASE_TICKETS, actions: allActions },
    { tableName: TableName.REQUESTS, actions: allActions },
    { tableName: TableName.SALE_ITEMS, actions: allActions },
    { tableName: TableName.SALE_ORDERS, actions: allActions },
    { tableName: TableName.SETTINGS, actions: allActions },
    { tableName: TableName.STOCK, actions: allActions },
    { tableName: TableName.SUPPLIERS, actions: allActions },
    { tableName: TableName.TAXES, actions: allActions },
    { tableName: TableName.TRANSACTIONS, actions: allActions },
    { tableName: TableName.UNIT_TYPES, actions: allActions },
    { tableName: TableName.USERS, actions: allActions }
]
