import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { FieldType, FieldValue, option, FilterField, Filters } from '../../../../../features/ui'
import { useManagerState } from '../../../../manager'
import { define } from '../../../../../utils/typeUtils'
import { SaleOrder } from '../../../state/types'
import { useSalesActions } from '../../../actions/salesActions'
import { useSalesHooks, useSalesState } from '../../../hooks/salesHooks'
import { useAdministrationState } from '../../../../administration'
import { DeliveryStatus } from '../../../../operations'
import { Labels } from '../../../../../features/data-types'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const SaleFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const salesState = useSalesState()
    const stateSales = salesState.sales
    const customers = salesState.customers
    const { employees } = useAdministrationState()
    const { currentBranch } = useManagerState()
    const currentBranchId = define(currentBranch).id

    const [sales, setSales] = useState<SaleOrder[]>()
    const [searchText, setSearchText] = useState<string>()
    const [deliveryStatus, setDeliveryStatus] = useState<DeliveryStatus>()
    const [sellerId, setSellerId] = useState<string>()
    const [customerId, setCustomerId] = useState<string>()
    const [dateFrom, setDateFrom] = useState<Date>()
    const [dateTo, setDateTo] = useState<Date>()
    const [stateSalesLength, setStateSalesLength] = useState<number>(stateSales.length)

    const { searchSales } = useSalesHooks()

    const salesActions = useSalesActions()

    const fetchAndSearch = async () => {
        onSearchStart()
        const branchSales = await salesActions().fetchAllSales(currentBranchId) as SaleOrder[]
        setSales(branchSales)
        search(branchSales, searchText, deliveryStatus, sellerId, customerId, dateFrom, dateTo)
    }

    useEffect(() => {
        if (stateSales.length < stateSalesLength) {
            fetchAndSearch()
        }
        setStateSalesLength(stateSales.length)
    }, [stateSales])

    useEffect(() => {
        fetchAndSearch()
    }, [currentBranchId])

    const search = (
        baseSaleList: SaleOrder[] = [],
        searchTextParam?: string,
        deliveryStatusParam?: string,
        sellerIdParam?: string,
        customerIdParam?: string,
        dateFromParam?: Date,
        dateToParam?: Date
    ) => {
        onSearchStart()
        let saleList = baseSaleList.filter(sale => sale.branchId === currentBranchId)
        saleList = saleList.filter(sale => !deliveryStatusParam || sale.deliveryStatus === deliveryStatusParam)
        saleList = saleList.filter(sale => !sellerIdParam || sale.sellerId === sellerIdParam)
        saleList = saleList.filter(sale => !customerIdParam || sale.customerId === customerIdParam)
        saleList = dateFromParam ? saleList.filter(sale => new Date(sale.deliveryDate) >= dateFromParam) : saleList
        saleList = dateToParam ? saleList.filter(sale => new Date(sale.deliveryDate) <= dateToParam) : saleList
        saleList = searchSales(saleList, searchTextParam)
        setTimeout(() => onSearchDone(saleList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(sales, newSearchText, deliveryStatus, sellerId, customerId, dateFrom, dateTo)
    }

    const onChangeDeliveryStatus = (value?: FieldValue) => {
        const newDeliveryStatus = value as DeliveryStatus | undefined
        if (newDeliveryStatus !== deliveryStatus) {
            setDeliveryStatus(newDeliveryStatus)
            search(sales, searchText, newDeliveryStatus, sellerId, customerId, dateFrom, dateTo)
        }
    }

    const onChangeSeller = (value?: FieldValue) => {
        const newSellerId = value as string | undefined
        if (newSellerId !== sellerId) {
            setSellerId(newSellerId)
            search(sales, searchText, deliveryStatus, newSellerId, customerId, dateFrom, dateTo)
        }
    }

    const onChangeCustomer = (value?: FieldValue) => {
        const newCustomerId = value as string | undefined
        if (newCustomerId !== customerId) {
            setCustomerId(newCustomerId)
            search(sales, searchText, deliveryStatus, sellerId, newCustomerId, dateFrom, dateTo)
        }
    }

    const onChangeDateFrom = (value?: FieldValue) => {
        const newDateFrom = value as Date | undefined
        newDateFrom?.setHours(11, 59, 59)
        if (newDateFrom !== dateFrom) {
            setDateFrom(newDateFrom)
            search(sales, searchText, deliveryStatus, sellerId, customerId, newDateFrom, dateTo)
        }
    }
    
    const onChangeDateTo = (value?: FieldValue) => {
        const newDateTo = value as Date | undefined
        newDateTo?.setHours(11, 59, 59)
        if (newDateTo !== dateTo) {
            setDateTo(newDateTo)
            search(sales, searchText, deliveryStatus, sellerId, customerId, dateFrom, newDateTo)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por código, vendedor o cliente',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'deliveryStatus',
            type: FieldType.SELECT,
            options: Object.values(DeliveryStatus).map(status => option(status, Labels.deliveryStatusLabel(status))),
            label: 'Estado',
            space: 2,
            onChange: onChangeDeliveryStatus
        },
        {
            name: 'seller',
            type: FieldType.SELECT,
            options: employees.map(employee => option(employee.id, employee.name)),
            label: 'Vendedor',
            space: 3,
            onChange: onChangeSeller
        },
        {
            name: 'customer',
            type: FieldType.SELECT,
            options: customers.map(customer => option(customer.id, customer.name)),
            label: 'Cliente',
            space: 3,
            onChange: onChangeCustomer
        },
        {
            name: 'dateFrom',
            type: FieldType.DATE,
            label: 'Entrega: Desde',
            space: 2,
            onChange: onChangeDateFrom
        },
        {
            name: 'dateTo',
            type: FieldType.DATE,
            label: 'Entrega: Hasta',
            space: 2,
            onChange: onChangeDateTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
