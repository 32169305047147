import { Data } from "../../../../features/data-types"
import { showToast } from "../../../../features/ui"
import { define } from "../../../../utils/typeUtils"
import { CategorySaleYield } from "../../../administration"
import { usePurchasesActions } from "../../../purchases/actions/purchasesActions"
import { usePurchasesHooks } from "../../../purchases/hooks/purchasesHooks"

export const useActions = () => {
    const { getSettings } = usePurchasesHooks()
    const purchasesActions = usePurchasesActions()
    
    const onUpdateSettings = () => showToast('La configuración fue actualizada.')

    const updateGeneralSaleYield = async (saleYield: number) => {
        await purchasesActions().savePurchasesSetting('generalSaleYield', saleYield)
        onUpdateSettings()
    }

    const submitAddCategorySaleYield = async (data: Data) => {
        const categorySaleYields = (getSettings()?.categorySaleYields || []) as CategorySaleYield[]
        const categorySaleYield: CategorySaleYield = {
            categoryId: define(data.categoryId) as string,
            saleYield: define(data.saleYield) as number
        }
        const map = new Map(categorySaleYields.map(categorySaleYield => [categorySaleYield.categoryId, categorySaleYield]))
        map.set(categorySaleYield.categoryId, categorySaleYield)
        const newCategorySaleYields = Array.from(map.values())
        await purchasesActions().savePurchasesSetting('categorySaleYields', newCategorySaleYields)
        onUpdateSettings()
    }

    const submitRemoveCategorySaleYield = async (categoryId: string) => {
        const categorySaleYields = (getSettings()?.categorySaleYields || []) as CategorySaleYield[]
        const newCategorySaleYields = categorySaleYields.filter(categorySaleYield => categorySaleYield.categoryId !== categoryId)
        await purchasesActions().savePurchasesSetting('categorySaleYields', newCategorySaleYields)
        onUpdateSettings()
    }

    return {
        updateGeneralSaleYield,
        submitAddCategorySaleYield,
        submitRemoveCategorySaleYield
    }
}
