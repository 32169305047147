import { AttributeMap } from 'aws-sdk/clients/dynamodb'
import { createBatches, getList } from './utils'
import { KeyParams, RangeParams } from './types'
import { DynamoApi } from './dynamo'
import { define } from '../../utils/typeUtils'

export const useDynamoActions = () => {
    return (dynamoApi: DynamoApi, tKeyValue?: string) => {
        const getAll = (
            dbTableName: string,
            limit?: number,
            startKey?: KeyParams,
            propName?: string,
        ) => {
            return dynamoApi.getAll(dbTableName, tKeyValue, limit, startKey, propName)
        }

        const getByParams = (
            dbTableName: string,
            params: KeyParams
        ) => {
            return dynamoApi.getByParams(dbTableName, params, tKeyValue)
        }

        const getMultiple = (
            dbTableName: string,
            uKeyValues: string[]
        ) => {
            return dynamoApi.getMultiple(dbTableName, uKeyValues, tKeyValue)
        }

        const getFiltered = (
            dbTableName: string,
            propFilters?: KeyParams,
            rangeFilters?: RangeParams
        ) => {
            return dynamoApi.getFiltered(dbTableName, define(tKeyValue), propFilters, rangeFilters)
        }

        const count = (
            dbTableName: string,
            params: KeyParams
        ) => {
            return dynamoApi.count(dbTableName, params, tKeyValue)
        }

        const createOrUpdate = async (
            dbTableName: string,
            list: AttributeMap[]
        ) => {
            const batches = createBatches<AttributeMap>(list)
            if (batches.length === 1) {
                return dynamoApi.createOrUpdate(dbTableName, list)
                    .then(unprocessedItems => getList(dbTableName, unprocessedItems))
            } else {
                const promises = batches.map(batch => dynamoApi.createOrUpdate(dbTableName, batch))
                return Promise.all(promises).then(unprocessedItemsList => {
                    const unprocessedItems = unprocessedItemsList.reduce((items, unprocessedItemsItem) => 
                        ({
                            ...items,
                            ...unprocessedItemsItem
                        })
                    , {})
                    return getList(dbTableName, unprocessedItems)
                })
            }
        }

        const remove = async (
            dbTableName: string,
            uKeyValues: string[]
        ) => {
            const batches = createBatches<string>(uKeyValues)
            if (batches.length === 1) {
                return dynamoApi.remove(dbTableName, uKeyValues, tKeyValue)
                    .then(unprocessedItems => getList(dbTableName, unprocessedItems))
            } else {
                const promises = batches.map(batch => dynamoApi.remove(dbTableName, batch, tKeyValue))
                return Promise.all(promises).then(unprocessedItemsList => {
                    const unprocessedItems = unprocessedItemsList.reduce((items, unprocessedItemsItem) => 
                        ({
                            ...items,
                            ...unprocessedItemsItem
                        })
                    , {})
                    return getList(dbTableName, unprocessedItems)
                })
            }
        }

        return {
            getAll,
            getByParams,
            getMultiple,
            getFiltered,
            count,
            createOrUpdate,
            remove
        }
    }
}
