import styles from './spinner.module.css'

type Props = {
    size?: 's' | 'm' | 'l'
    label?: string
}

export const Spinner = (props: Props) => {
    const { size, label } = props
    const sizeClass = size === 'l' ? styles.large : (size === 'm' ? styles.medium : styles.small)

    return (
        <div className={`${styles.container} ${sizeClass}`}>
            <div className={`spinner-grow text-primary ${styles.spinner}`} role="status" />
            <span className={styles.label}>{label || 'Cargando...'}</span>
        </div>
    )
}
