import { useDataTypesHooks } from '../../../../../features/data-types'
import { FieldType } from '../../../../../features/ui'
import { define } from '../../../../../utils/typeUtils'
import { usePricesHooks } from '../../../../operations'
import { HoardItem, HoardOrder } from '../../../state/types'
import styles from './hoardValues.module.css'

type Props = {
    hoard: HoardOrder
    hoardItems: HoardItem[]
}

export const HoardValues = (props: Props) => {
    const { hoard, hoardItems } = props
    const hoardValue = define(hoard.value)

    const pricesHooks = usePricesHooks()
    const { formatValue } = useDataTypesHooks()
    
    return (
        <div className={styles.container}>
            <div>Acopio Total: {formatValue(hoardValue, FieldType.PRICE)}</div>
            <div>Acopio Entregado: {formatValue(pricesHooks.hoard.deliveredCredit(hoardItems), FieldType.PRICE)}</div>
            <div>Acopio Restante: {formatValue(pricesHooks.hoard.remainingCredit(hoardValue, hoardItems), FieldType.PRICE)}</div>
        </div>
    )
}
