import { Modal, ModalId, Validatable } from "../.."

type Props = {
    title: string
    validations: string[]
}

export const ValidationModal = (props: Props) => {
    const { title, validations } = props

    return (
        <Modal
            modalId={ModalId.VALIDATION}
            title={title}
            cancelLabel="Cerrar"
        >
            <Validatable validations={validations} expanded />
        </Modal>
    )
}
