import { Labels, useDataTypesHooks, useDescriptorHooks } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { DetailFieldProps, DetailPanel, FieldType, FieldValue, FormatType, UIPermission, useGetCrumbs } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { useSystemStateHooks } from "../../../../system"
import { Check, CheckStatus, CheckType } from "../../../state/types"

export const CheckDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const check = define(dataType) as Check

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_CHECKS)
  const { formatCustomValue } = useDataTypesHooks()
  const descriptorHooks = useDescriptorHooks()

  const crumbs = useGetCrumbs('Cheques', RoutePath.CHECKS, descriptorHooks.check.info(check))

  const fields: DetailFieldProps[] = [
    {
      name: 'type',
      type: FieldType.TEXT,
      label: 'Tipo',
      render: (value?: FieldValue) => <>{Labels.checkTypeLabel(define(value) as CheckType)}</>
    },
    { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
    { name: 'amount', type: FieldType.NUMBER, label: 'Monto' },
    { name: 'issueDate', type: FieldType.DATE, label: 'Fecha de Emisión' },
    { name: 'paymentDate', type: FieldType.DATE, label: 'Fecha de Pago' },
    {
      name: 'taxId',
      type: FieldType.NUMBER,
      label: 'CUIT / CUIL',
      render: (value?: FieldValue) => <>{formatCustomValue(value, FormatType.CUIT_CUIL)}</>
    },
    { name: 'endorsable', type: FieldType.BOOLEAN, label: 'A la Orden' },
    {
      name: 'status',
      type: FieldType.TEXT,
      label: 'Estado',
      render: (value?: FieldValue) => <>{Labels.checkStatusLabel(define(value) as CheckStatus)}</>
    },
  ]

  return (<>
    <DetailPanel
      tableName={TableName.CHECKS}
      crumbs={crumbs}
      fields={fields}
      data={check}
      icon='card-text'
      readOnly={readOnly}
    />
  </>)
}
