import { Labels, useDataTypesHooks } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { Badge, DetailFieldProps, DetailPanel, FieldType, FieldValue, FormatType, UIPermission, useGetCrumbs } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { useSystemStateHooks } from "../../../../system"
import { Area, Employee } from "../../../state/types"

export const EmployeeDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const employee = define(dataType) as Employee

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_EMPLOYEES)
  const { formatCustomValue } = useDataTypesHooks()

  const title = `Empleado: ${employee.name}`

  const crumbs = useGetCrumbs('Empleados', RoutePath.EMPLOYEES, employee.name)

  const fields: DetailFieldProps[] = [
    { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
    {
      name: 'identifier',
      type: FieldType.NUMBER,
      label: 'DNI',
      render: (value?: FieldValue) => value ? <>{formatCustomValue(value, FormatType.DNI)}</> : <Badge label="Vacío" />
    },
    {
      name: 'area',
      type: FieldType.TEXT,
      label: 'Area',
      render: (value?: FieldValue) => <>{Labels.areaLabel(define(value) as Area)}</>
    },
    { name: 'branchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal' }
  ]
  if (employee.email) {
    const emailField: DetailFieldProps = {
      name: 'email',
      type: FieldType.TEXT,
      label: 'Email'
    }
    fields.push(emailField)
  }
  if (employee.phone) {
    const phoneField: DetailFieldProps = {
      name: 'phone',
      type: FieldType.TEXT,
      label: 'Teléfono',
      render: (value?: FieldValue) => <>{formatCustomValue(value, FormatType.PHONE)}</>
    }
    fields.push(phoneField)
  }
  if (employee.address) {
    const addressField: DetailFieldProps = {
      name: 'address',
      type: FieldType.TEXT,
      label: 'Dirección'
    }
    fields.push(addressField)
  }

  return (<>
    <DetailPanel
      title={title}
      tableName={TableName.EMPLOYEES}
      crumbs={crumbs}
      fields={fields}
      data={employee}
      icon='person-badge'
      readOnly={readOnly}
    />
  </>)
}
