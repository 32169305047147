import { ReducerState } from "../../../state/reducers/types"
import { addOrUpdate, remove } from "../../../state/reducers/utils"
import { ActionType, SalesAction } from './actionTypes'
import { Budget, BudgetItem, Customer, CustomerType, HoardItem, HoardOrder, SaleItem, SaleOrder } from "./types"

export type SalesState = {
    sales: SaleOrder[],
    saleItems: SaleItem[],
    budgets: Budget[],
    budgetItems: BudgetItem[],
    hoards: HoardOrder[],
    hoardItems: HoardItem[],
    customers: Customer[],
    customerTypes: CustomerType[]
}

const initialState: SalesState = {
    sales: [],
    saleItems: [],
    budgets: [],
    budgetItems: [],
    hoards: [],
    hoardItems: [],
    customers: [],
    customerTypes: []
}

const reducer = (state = initialState, action: SalesAction) => {
    switch(action.type) {
        case ActionType.UPDATE_SALES:
            return { 
                ...state,
                sales: addOrUpdate(state, action, 'sales' as keyof ReducerState)
            }
        case ActionType.REMOVE_SALES:
            return { 
                ...state,
                sales: remove(state, action, 'sales' as keyof ReducerState)
            }
        case ActionType.UPDATE_SALE_ITEMS:
            return { 
                ...state,
                saleItems: addOrUpdate(state, action, 'saleItems' as keyof ReducerState)
            }
        case ActionType.REMOVE_SALE_ITEMS:
            return { 
                ...state,
                saleItems: remove(state, action, 'saleItems' as keyof ReducerState)
            }
        case ActionType.UPDATE_BUDGETS:
            return { 
                ...state,
                budgets: addOrUpdate(state, action, 'budgets' as keyof ReducerState)
            }
        case ActionType.REMOVE_BUDGETS:
            return { 
                ...state,
                budgets: remove(state, action, 'budgets' as keyof ReducerState)
            }
        case ActionType.UPDATE_BUDGET_ITEMS:
            return { 
                ...state,
                budgetItems: addOrUpdate(state, action, 'budgetItems' as keyof ReducerState)
            }
        case ActionType.REMOVE_BUDGET_ITEMS:
            return { 
                ...state,
                budgetItems: remove(state, action, 'budgetItems' as keyof ReducerState)
            }
        case ActionType.UPDATE_HOARDS:
            return { 
                ...state,
                hoards: addOrUpdate(state, action, 'hoards' as keyof ReducerState)
            }
        case ActionType.REMOVE_HOARDS:
            return { 
                ...state,
                hoards: remove(state, action, 'hoards' as keyof ReducerState)
            }
        case ActionType.UPDATE_HOARD_ITEMS:
            return { 
                ...state,
                hoardItems: addOrUpdate(state, action, 'hoardItems' as keyof ReducerState)
            }
        case ActionType.REMOVE_HOARD_ITEMS:
            return { 
                ...state,
                hoardItems: remove(state, action, 'hoardItems' as keyof ReducerState)
            }
        case ActionType.UPDATE_CUSTOMERS:
            return { 
                ...state,
                customers: addOrUpdate(state, action, 'customers' as keyof ReducerState)
            }
        case ActionType.REMOVE_CUSTOMERS:
            return { 
                ...state,
                customers: remove(state, action, 'customers' as keyof ReducerState)
            }
        case ActionType.UPDATE_CUSTOMER_TYPES:
            return {
                ...state,
                customerTypes: addOrUpdate(state, action, 'customerTypes' as keyof ReducerState)
            }
        case ActionType.REMOVE_CUSTOMER_TYPES:
            return {
                ...state,
                customerTypes: remove(state, action, 'customerTypes' as keyof ReducerState)
            }
        default:
            return state
    }
}

export default reducer
