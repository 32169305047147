import { Modal, ModalId } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"

type Props = {
    productId?: string
    onSubmit: (productId: string) => void
}

export const UnignoreProductModal = (props: Props) => {
    const { productId, onSubmit } = props

    const title = 'Dejar de ignorar producto?'
    const body = 'Al eliminar este producto de la lista, se habilitará para ser acopiado.'

    const handleSubmit = () => onSubmit(define(productId))
    
    return (
        <Modal
            modalId={ModalId.UNIGNORE_PRODUCT}
            title={title}
            submitLabel="Confirmar"
            submitKind="secondary"
            onSubmit={handleSubmit}
        >
            <>{body}</>
        </Modal>
    )
}
