import { FieldType, FieldValue, FilterField, Filters, option } from "../../../../../features/ui"
import { useEffect, useState } from "react"
import { DataType } from '../../../../../tables'
import { useAdministrationHooks, useAdministrationState } from "../../../hooks/administrationHooks"
import { Area } from "../../../state/types"
import { Labels } from "../../../../../features/data-types"

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const EmployeeFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { employees } = useAdministrationState()

    const [searchText, setSearchText] = useState<string>()
    const [area, setArea] = useState<Area>()

    const { searchEmployees } = useAdministrationHooks()

    useEffect(() => search(searchText, area), [employees])

    const search = (searchTextParam?: string, areaParam?: Area) => {
        onSearchStart()
        let employeeList = employees.filter(employee => !areaParam || employee.area === areaParam)
        employeeList = searchEmployees(employeeList, searchTextParam)
        setTimeout(() => onSearchDone(employeeList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(newSearchText, area)
    }

    const onChangeArea = (value?: FieldValue) => {
        const newArea = value as Area | undefined
        if (newArea !== area) {
            setArea(newArea)
            search(searchText, newArea)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por nombre, DNI o email',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'area',
            type: FieldType.SELECT,
            options: Object.values(Area).map(area => option(area, Labels.areaLabel(area))),
            label: 'Area',
            onChange: onChangeArea
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
