import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data } from "../../../../../features/ui"
import { Currency } from "../../../../../tables/types/types"
import { define } from "../../../../../utils/typeUtils"
import { useAdministrationState } from "../../../../administration"
import { DeliveryStatus, PaymentStatus } from "../../../../operations"
import { usePurchasesActions } from "../../../actions/purchasesActions"
import { PurchaseOrder } from "../../../state/types"

export const useActions = () => {
    const { currentEmployee } = useAdministrationState()

    const purchasesActions = usePurchasesActions()
    
    const submitPurchase = async (
        purchaseData: Data,
        code: number,
        purchaseItemsData: Data[],
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const deliveryDate = define(purchaseData.deliveryDate) as Date
        const purchase: PurchaseOrder = {
            companyId: purchaseData.companyId as string,
            id: purchaseData.id as string,
            code,
            creationDate: new Date().toISOString(),
            supplierId: define(purchaseData.supplierId) as string,
            currency: Currency.ARS,
            employeeId: define(currentEmployee).id,
            deliveryDate: deliveryDate.toISOString(),
            deliveryBranchId: define(purchaseData.deliveryBranchId) as string,
            deliveryStatus: purchaseData.deliveryStatus as DeliveryStatus || DeliveryStatus.PENDING,
            paymentStatus: purchaseData.paymentStatus as PaymentStatus || PaymentStatus.PENDING
        }

        await purchasesActions().savePurchaseWithItems(purchase, purchaseItemsData)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitPurchase
    }
}
