import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data } from "../../../../../features/ui"
import { useSalesActions } from "../../../actions/salesActions"
import { CustomerType } from "../../../state/types"

export const useActions = () => {
    const salesActions = useSalesActions()

    const submitCustomerType = async (
        customerTypeData: Data,
        customerTypeYield: number,
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, name } = customerTypeData
        const customerType: CustomerType = {
            companyId: companyId as string,
            id: id as string,
            name: name as string,
            yield: customerTypeYield
        }

        await salesActions().saveCustomerType(customerType)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitCustomerType
    }
}
