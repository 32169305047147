import { useEffect, useState } from "react"
import { useLoad } from "./load"
import { ElementLoader } from "../element-loader/elementLoader"

export const IndexElement = () => {
    const [ready, setReady] = useState(false)

    const load = useLoad()

    useEffect(() => {
        load().then(() => setReady(true))
    }, [])
    
    return (
        <ElementLoader ready={ready}>
            <></>
        </ElementLoader>
    )
}
