import { useEffect, useState } from "react"
import { EditableFormField, EditablePanel, FieldType, TabsPanel, UIPermission } from "../../../../features/ui"
import { useActions } from "./actions"
import { useSalesHooks } from "../../hooks/salesHooks"
import { Product } from "../../../products"
import { ModalId, useUIActions } from "../../../../features/ui"
import { NonHoardableProductsTable } from "../hoard/non-hoardable-products-table/nonHoardableProductsTable"
import { TableName } from "../../../../tables"
import { NonHoardableProductFormModal } from "../hoard/non-hoardable-product-form-modal/nonHoardableProductFormModal"
import { UnignoreProductModal } from "../hoard/unignore-product-modal/unignoreProductModal"
import { useSystemStateHooks } from "../../../system"

export const SalesSettingsPanel = () => {
    const { getSettings } = useSalesHooks()
    const settings = getSettings()
    const hoardIgnoredProductIds = (settings?.hoardIgnoredProductIds || []) as string[]
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_SALES_SETTINGS)

    const [hoardIgnoredProductIdsState, setHoardIgnoredProductIdsState] = useState<string[]>(hoardIgnoredProductIds)
    const [currentProductId, setCurrentProductId] = useState<string>()

    const { submitIgnoreProduct, submitUnignoreProduct } = useActions()
    const { toggleModal } = useUIActions()

    useEffect(() => {
        setHoardIgnoredProductIdsState(hoardIgnoredProductIds)
    }, [hoardIgnoredProductIds])

    const ignoredProductsData = hoardIgnoredProductIdsState.map(productId => ({ productId }))
    const filterProducts = (product: Product) => currentProductId === product.id || !hoardIgnoredProductIdsState.includes(product.id)

    const onAddProduct = () => {
        toggleModal(ModalId.NON_HOARDABLE_PRODUCT_FORM)
        setCurrentProductId(undefined)
    }

    const onRemoveProduct = (productId: string) => {
        setCurrentProductId(productId)
        toggleModal(ModalId.UNIGNORE_PRODUCT)
    }

    const fields: EditableFormField[] = [
        {
            name: 'nonHoardableProducts',
            type: FieldType.TABLE,
            table: TableName.PRODUCTS,
            label: 'Productos No Acopiables',
            hint: 'Agregar a la lista los productos que no deben ser acopiados.',
            disabled: true,
            render: () => (
                <NonHoardableProductsTable
                    rows={ignoredProductsData}
                    readOnly={readOnly}
                    onAdd={onAddProduct}
                    onRemoveRow={onRemoveProduct}
                />
            )
        }
    ]

    const onUnignoreProduct = (productId: string) => {
        submitUnignoreProduct(productId)
        setCurrentProductId(undefined)
    }

    const tabs = [
        {
            id: 'hoard',
            title: 'Acopio',
            content: <EditablePanel
                fields={fields}
                readOnly={readOnly}
            />
        }
    ]

    return (
        <>
            <TabsPanel
                title="Configuración"
                tabs={tabs}
                wide={false}
            />
            <NonHoardableProductFormModal
                filterProducts={filterProducts}
                onSubmit={submitIgnoreProduct}
            />
            <UnignoreProductModal
                productId={currentProductId}
                onSubmit={onUnignoreProduct}
            />
        </>
    )
}
