import * as Administration from '../../modules/administration'
import * as Manager from '../../modules/manager'
import * as Operations from '../../modules/operations'
import * as Products from '../../modules/products'
import * as Purchases from '../../modules/purchases'
import * as Sales from '../../modules/sales'
import * as Stock from '../../modules/stock'
import * as System from '../../modules/system'
import * as UI from '../../features/ui'
import { AdministrationState, AdministrationUpdateAction, AdministrationRemoveAction } from '../../modules/administration'
import { ManagerRemoveAction, ManagerState, ManagerUpdateAction } from '../../modules/manager'
import { OperationsState, OperationsUpdateAction, OperationsRemoveAction } from '../../modules/operations'
import { ProductsState, ProductsUpdateAction, ProductsRemoveAction } from '../../modules/products'
import { PurchasesState, PurchasesUpdateAction, PurchasesRemoveAction } from '../../modules/purchases'
import { SalesRemoveAction, SalesState, SalesUpdateAction } from '../../modules/sales'
import { StockState, StockUpdateAction, StockRemoveAction } from '../../modules/stock'
import { SystemState, SystemUpdateAction } from '../../modules/system'
import { UIState } from '../../features/ui'

export enum ReducerName {
    ADMINISTRATION_REDUCER = 'administrationReducer',
    MANAGER_REDUCER = 'managerReducer',
    OPERATIONS_REDUCER = 'operationsReducer',
    PRODUCTS_REDUCER = 'productsReducer',
    PURCHASES_REDUCER = 'purchasesReducer',
    SALES_REDUCER = 'salesReducer',
    STOCK_REDUCER = 'stockReducer',
    SYSTEM_REDUCER = 'systemReducer',
    UI_REDUCER = 'uiReducer',
    WIZARD_REDUCER = 'wizardReducer'
}

export type ReducerStateActions = 
    typeof Administration.actionCreators |
    typeof Manager.actionCreators |
    typeof Operations.actionCreators |
    typeof Products.actionCreators |    
    typeof Purchases.actionCreators |
    typeof Sales.actionCreators |
    typeof Stock.actionCreators |
    typeof System.actionCreators |
    typeof UI.actionCreators |
    null

export type ReducerState =
    AdministrationState |
    ManagerState |
    OperationsState |
    ProductsState |
    PurchasesState |
    SalesState |
    StockState |
    SystemState |
    UIState |
    undefined

export type ReducerStates = {
    administrationReducer: ReducerState,
    managerReducer: ReducerState,
    operationsReducer: ReducerState,
    productsReducer: ReducerState,
    purchasesReducer: ReducerState,
    salesReducer: ReducerState,
    stockReducer: ReducerState,
    systemReducer: ReducerState,
    uiReducer: ReducerState
}

export enum QueryOperator {
    AND,
    OR
}

export type TextParams = {
    [key: string]: string
}

export type RangeParams = {
    [key: string]: {
        start: string
        end: string
    }
}

export type Query = {
    operator?: QueryOperator,
    params: TextParams
}

export type UpdateAction = 
    AdministrationUpdateAction |
    ManagerUpdateAction |
    OperationsUpdateAction |
    ProductsUpdateAction |
    PurchasesUpdateAction |
    SalesUpdateAction |
    StockUpdateAction |
    SystemUpdateAction

export type RemoveAction = 
    AdministrationRemoveAction |
    ManagerRemoveAction |
    OperationsRemoveAction |
    ProductsRemoveAction |    
    PurchasesRemoveAction |
    SalesRemoveAction |
    StockRemoveAction
