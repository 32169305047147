import { DataTypeList, Labels } from "../../../../../features/data-types"
import { PanelWrapper, Action, Column, Data, FieldType, FieldValue, ModalId, SpinnerDiv, UIPermission, useUIActions, Badge } from "../../../../../features/ui"
import { useSystemStateHooks } from "../../../../system"
import { define } from "../../../../../utils/typeUtils"
import { CashFund, CashFundType } from "../../../state/types"
import { DataType, TableName } from "../../../../../tables"
import { CashFundFilters } from "./cashFundFilters"
import { useState } from "react"
import { useFind } from "../../../../../state/reducers/hooks"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"

export const CashFundList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentCashFund, setCurrentCashFund] = useState<CashFund>()

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_CASH_FUNDS)
    const navigate = useNavigate()
    const find = useFind()

    const { toggleModal } = useUIActions()

    const columns: Column[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => <Badge label={Labels.cashFundTypeLabel(define(value) as CashFundType)} />
        },
        {
            name: 'availableAmount',
            type: FieldType.PRICE,
            label: 'Disponible'
        },
        {
            name: 'reservedAmount',
            type: FieldType.PRICE,
            label: 'Reservado'
        }
    ]

    const onTransfer = (id?: string) => {
        if (id) {
            navigate(`${id}/${RoutePath.TRANSFER}`)
        }
    }

    const onEdit = (id?: string) => {
        if (id) {
            navigate(`${id}/${RoutePath.EDIT}`)
        }
    }

    const onRemove = (id?: string) => {
        setCurrentCashFund(find(TableName.CASH_FUNDS, id) as CashFund | undefined)
        toggleModal(ModalId.REMOVE)
    }

    const actions = (): Action[] => {
        const actionList: Action[] = []
        if (!readOnly) {
            actionList.push(
                { icon: 'arrow-left-right', kind: 'tertiary', tooltip: 'Transferir Dinero', onClick: onTransfer },
                { icon: 'pencil-fill', tooltip: 'Edit', onClick: onEdit },
                { icon: 'trash-fill', kind: 'secondary', tooltip: 'Eliminar', onClick: onRemove }
            )
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as CashFund[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Cajas">
            <CashFundFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    currentDataTypeParam={currentCashFund}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
        </PanelWrapper>
    )
}
