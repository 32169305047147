import styles from './stockForm.module.css'
import { useEffect, useState } from "react"
import { PanelWrapper, Spinner, FieldType, FieldValue, Form, FormField, StockAction, option, useUIStateActions, useGetCrumbs, Badge, NumberField } from "../../../../features/ui"
import { define } from "../../../../utils/typeUtils"
import { Stock } from "../../state/types"
import { Lot, Product, ProductName, useProductsActions } from "../../../products"
import { TableName } from "../../../../tables"
import { useStockActions } from "../../actions/stockActions"
import { useActions } from './actions'
import { useDescriptorHooks } from '../../../../features/data-types'
import { useFind } from '../../../../state/reducers/hooks'
import { Branch } from '../../../manager'
import { useNavigate } from 'react-router-dom'
import { RoutePath, useRoutesHooks } from '../../../../features/routes'
import { firstItem } from '../../../../utils/listUtils'

type Props = {
    stockAction?: StockAction
}

export const StockForm = (props: Props) => {
    const { stockAction = StockAction.ADD } = props
    const addMode = stockAction !== StockAction.SUBSTRACT
    const { dataType } = useRoutesHooks().parseUrl()
    const stock = define(dataType) as Stock

    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [product, setProduct] = useState<Product>()
    const [productLots, setProductLots] = useState<Lot[]>([])
    const [productStockList, setProductStockList] = useState<Stock[]>([])
    const [lot, setLot] = useState<Lot>()
    const [amount, setAmount] = useState<number>()

    const navigate = useNavigate()
    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const { findLotStock, updateStock } = useActions()
    const productsActions = useProductsActions()
    const stockActions = useStockActions()
    const { setDisableBranchSelector } = useUIStateActions()
    
    const init = async () => {
        setDisableBranchSelector(true)
        const { fetchProduct, fetchLots } = productsActions()
        const stateProduct = define(await fetchProduct(stock.productId)) as Product
        setProduct(stateProduct)
        const stateLots = await fetchLots(stock.productId) as Lot[]
        setProductLots(stateLots)
        setLot(firstItem(stateLots))
        const stockList = await stockActions().fetchProductBranchStock(stock.productId, stock.branchId) as Stock[]
        setProductStockList(stockList)
        setAsyncDataLoaded(true)
    }

    useEffect(() => {
        init()
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    const title = `${addMode ? 'Alta' : 'Baja'} de Stock: ${product?.name}`

    const crumbs = useGetCrumbs('Stock', RoutePath.STOCK, product?.name, stock.id, addMode ? 'Alta' : 'Baja')

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

    const onChangeLot = (value?: FieldValue) => {
        setLot(productLots.find(productLot => productLot.id === value))
    }

    const onChangeAmount = (value?: FieldValue) => {
        setAmount(value as number | undefined)
    }

    const fields: FormField[] = [
        {
            name: 'product',
            type: FieldType.TEXT,
            label: 'Producto',
            render: () => renderAsync(
                <ProductName product={product} showCode />
            )
        },
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal',
            render: () => {
                const branch = define(find(TableName.BRANCHES, stock.branchId))as Branch
                return <Badge label={branch.name} />
            }
        },
        {
            name: 'availableStock',
            type: FieldType.NUMBER,
            label: 'Stock Disponible',
            render: () => <>
                {product && lot ? descriptorHooks.product.lot.availableStock(product, stock.branchId, lot) : 0}
            </>
        },
        {
            name: 'amount',
            type: FieldType.NUMBER,
            label: 'Cantidad',
            render: () => {
                const max = addMode ? undefined : findLotStock(productStockList, product, lot)?.availableUnits
                return <>
                    <NumberField
                        id="amount"
                        min={1}
                        max={max}
                        allowDecimals={false}
                        className={styles.amount}
                        onChange={onChangeAmount}
                    />
                    unidades
                </>
            }
        }
    ]
    if (productLots.length > 0) {
        const lotOptions = productLots.map(lot => option(lot.id, lot.code))
        const lotField = {
            name: 'lotId',
            type: FieldType.TABLE,
            table: TableName.LOTS,
            label: 'Partida',
            options: lotOptions,
            defaultValue: lot?.id,
            onChange: onChangeLot
        }
        fields.splice(1, 0, lotField)
    }

    const onSubmit = () => {
        updateStock(define(product).id, define(amount), stock.branchId, define(lot).id, addMode)
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <Form
                formId="stock-form"
                fields={fields}
                createMode={false}
                disableSubmit={!asyncDataLoaded}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
