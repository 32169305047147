import styles from './fileTile.module.css'
import { FileType } from './types'

type Props = {
    fileType: FileType
    fileName?: string
    className?: string
}

const EmptyFileImg = '/assets/img/empty-file.png'
const CsvFileImg = '/assets/img/csv-file.png'
const PdfFileImg = '/assets/img/pdf-file.png'

export const FileTile = (props: Props) => {
    const { fileType, fileName, className } = props

    const fileImg = fileType === FileType.PDF ? PdfFileImg : CsvFileImg
    const imgSrc = fileName ? fileImg : EmptyFileImg
    const alt = `${fileType.toString()} File`

    return (
        <div className={`${styles.container} ${className || ''}`}>
            <div className={styles.tile}>
                <img src={imgSrc} alt={alt} className={styles.img} />
                <span className={styles.name}>
                    {fileName || 'No hay archivo seleccionado'}
                </span>
            </div>
        </div>
    )
}
