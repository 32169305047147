import { Dispatch } from 'redux'
import { ActionType, SalesAction } from './actionTypes'
import { Budget, BudgetItem, Customer, CustomerType, HoardItem, HoardOrder, SaleItem, SaleOrder } from './types'

export const updateSales = (sales: SaleOrder[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.UPDATE_SALES,
            payload: sales
        })
    }
}

export const removeSales = (ids: string[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.REMOVE_SALES,
            payload: ids
        })
    }
}

export const updateSaleItems = (saleItems: SaleItem[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.UPDATE_SALE_ITEMS,
            payload: saleItems
        })
    }
}

export const removeSaleItems = (ids: string[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.REMOVE_SALE_ITEMS,
            payload: ids
        })
    }
}

export const updateBudgets = (budgets: Budget[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.UPDATE_BUDGETS,
            payload: budgets
        })
    }
}

export const removeBudgets = (ids: string[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.REMOVE_BUDGETS,
            payload: ids
        })
    }
}

export const updateBudgetItems = (budgetItems: BudgetItem[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.UPDATE_BUDGET_ITEMS,
            payload: budgetItems
        })
    }
}

export const removeBudgetItems = (ids: string[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.REMOVE_BUDGET_ITEMS,
            payload: ids
        })
    }
}
export const updateHoards = (hoards: HoardOrder[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.UPDATE_HOARDS,
            payload: hoards
        })
    }
}

export const removeHoards = (ids: string[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.REMOVE_HOARDS,
            payload: ids
        })
    }
}

export const updateHoardItems = (hoardItems: HoardItem[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.UPDATE_HOARD_ITEMS,
            payload: hoardItems
        })
    }
}

export const removeHoardItems = (ids: string[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.REMOVE_HOARD_ITEMS,
            payload: ids
        })
    }
}

export const updateCustomers = (customers: Customer[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.UPDATE_CUSTOMERS,
            payload: customers
        })
    }
}

export const removeCustomers = (ids: string[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.REMOVE_CUSTOMERS,
            payload: ids
        })
    }
}

export const updateCustomerTypes = (customerTypes: CustomerType[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.UPDATE_CUSTOMER_TYPES,
            payload: customerTypes
        })
    }
}

export const removeCustomerTypes = (ids: string[]) => {
    return (dispatch: Dispatch<SalesAction>) => {
        dispatch({
            type: ActionType.REMOVE_CUSTOMER_TYPES,
            payload: ids
        })
    }
}
