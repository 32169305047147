import styles from './detail.module.css'
import { Button } from '../button/button'
import { EmptyImgImg } from '../../constants'
import { Icon } from '../icon/icon'
import { DataType, TableName } from '../../../../tables'
import { useUIActions } from '../../actions/uiActions'
import { FieldRow } from '../field-row/fieldRow'
import { StaticValue } from '../static-value/staticValue'
import { Action, Data, DetailFieldProps, FieldType, Tab } from '../types'
import { CannotRemoveModal, RemoveModal } from '../../../data-types'
import { ModalId } from '../modal/types'
import { ActionButton } from '../action-button/actionButton'
import { useNavigate } from 'react-router-dom'
import { RoutePath, useRoutesHooks } from '../../../routes'
import { liveUrl } from '../../utils/uiUtils'
import { getRouteFromTableName } from '../../../routes/utils/routesUtils'

type Props = {
  tableName: TableName
  fields: DetailFieldProps[]
  data: Data
  icon?: string
  tabs?: Tab[]
  imgSpace?: number
  readOnly?: boolean,
  actions?: Action[]
}

export const Detail = (props: Props) => {
  const { tableName, fields, data, icon, tabs = [], imgSpace = 4, readOnly, actions } = props
  const imgSrc = data.imageUrl ? liveUrl(data.imageUrl as string) : undefined

  const navigate = useNavigate()
  const { getRoutePath } = useRoutesHooks()

  const { toggleModal, onRemoveDataType } = useUIActions()

  const onEdit = () => {
    navigate(RoutePath.EDIT)
  }

  const onRemove = () => toggleModal(ModalId.REMOVE)

  const afterRemove = () => {
    const tableRoutePath = getRouteFromTableName(tableName)
    tableRoutePath && navigate(getRoutePath(tableRoutePath))
    onRemoveDataType()
  }

  const defaultRender = (field: DetailFieldProps) => {
    const rawValue = data[field.name]
    const value = field.type === FieldType.DATE ? new Date(rawValue as string) : rawValue
    return (
      <StaticValue type={field.type} table={field.table} value={value} />
    )
  }

  const renderActionBtn = (action: Action, index: number) => {
    return <ActionButton
        key={index}
        action={action}
        className={styles.actionBtn}
        onClick={action.onClick}
    />
  }

  const renderEditBtn = () => {
    return <Button
      label='Editar'
      icon="pencil-fill"
      className={styles.actionBtn}
      onClick={onEdit}
    />
  }

  const renderRemoveBtn = () => {
    return <Button
      label='Eliminar'
      kind="secondary"
      icon="trash-fill"
      className={styles.actionBtn}
      onClick={onRemove}
    />
  }

  return (
    <>
      <section className="section profile">
        <div className="row">
          <div className={`col-xl-${imgSpace}`}>
            <div className={`card-body pt-4 ${styles.imgContainer}`}>
              {imgSrc ? (
                <img src={imgSrc || EmptyImgImg} className={styles.img} alt="Imagen" />
              ) : (
                <Icon
                  icon={icon || 'card-list'}
                  color='tertiary'
                  className={styles.icon}
                />
              )}
            </div>
            <div className={styles.actions}>
              {actions ? actions.map(renderActionBtn) : (
                !readOnly && (
                  <>
                    {renderEditBtn()}
                    {renderRemoveBtn()}
                  </>
                )
              )}
            </div>
          </div>

          <div className={`col-xl-${imgSpace ? 12 - imgSpace : 12}`}>
            <div className="card-body">
              <ul className="nav nav-tabs nav-tabs-bordered">
                <li className="nav-item">
                  <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#overview">Información General</button>
                </li>
                {tabs.map(tab => (
                  <li key={tab.id} className="nav-item">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target={`#${tab.id}`}>{tab.title}</button>
                  </li>
                ))}
              </ul>

              <div className="tab-content pt-4">
                <div className="tab-pane fade show active profile-overview" id="overview">
                  {fields.map(field => {
                    const value = data[field.name]
                    return (
                      <FieldRow
                        key={field.name}
                        label={field.label}
                        labelSpace={4}
                      >
                        {field.render ?
                          field.render(value) : defaultRender(field)
                        }
                      </FieldRow>
                    )
                  })}
                </div>

                {tabs.map(tab => (
                  <div key={tab.id} className={`tab-pane fade profile-overview ${styles.tabBody}`} id={tab.id}>
                    {tab.content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <RemoveModal
        tableName={tableName}
        dataType={data as DataType}
        afterRemove={afterRemove}
      />
      <CannotRemoveModal />
    </>
  )
}
