import { useState } from "react"
import { PanelWrapper, FieldType, UIPermission, Column, FieldValue, useUIActions, ModalId, Action, SpinnerDiv, Data, ButtonKind } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { useFind } from "../../../../../state/reducers/hooks"
import { define, toNumber } from "../../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../../features/data-types"
import { Expense, ExpenseStatus } from "../../../state/types"
import { ExpenseReceiptModal } from "../../expense/expense-receipt-modal/expenseReceiptModal"
import { SalaryFilters } from "./salaryFilters"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"

export const SalaryList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentExpense, setCurrentExpense] = useState<Expense>()

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_EXPENSES)
    const navigate = useNavigate()
    const find = useFind()

    const { toggleModal } = useUIActions()

    const setRowsState = (expenses: Expense[]) => {
        setRows(expenses)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
        { name: 'date', type: FieldType.DATE, label: 'Fecha' },
        { name: 'amount', type: FieldType.PRICE, label: 'Monto' },        
        { name: 'status', type: FieldType.TEXT, label: 'Estado de Pago', render: (value?: FieldValue) => Labels.expenseStatusLabel(define(value) as ExpenseStatus) }
    ]

    const findExpense = (code?: string) => find(TableName.EXPENSES, toNumber(code), 'code') as Expense | undefined

    const onView = (code?: string) => {
        const expense = findExpense(code)
        if (expense) {
            navigate(expense.id)
        }
    }

    const onDownloadReceipt = (code?: string) => {
        setCurrentExpense(findExpense(code))
        toggleModal(ModalId.EXPENSE_RECEIPT)
    }

    const onEdit = (code?: string) => {
        const expense = findExpense(code)
        if (expense) {
            navigate(`${expense.id}/${RoutePath.EDIT}`)
        }
    }

    const onRemove = (code?: string) => {
        setCurrentExpense(findExpense(code))
        toggleModal(ModalId.REMOVE)
    }

    const actions = (code?: string): Action[] => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary' as ButtonKind, tooltip: 'Ver Detalle', onClick: onView }
        ]

        const expense = findExpense(code)
        if (expense?.receiptUrl) {
            actionList.push(
                { icon: 'download', kind: 'tertiary' as ButtonKind, tooltip: 'Descargar Recibo', narrowTooltip: true, onClick: onDownloadReceipt }
            )
        }
        if (!readOnly && expense?.status === ExpenseStatus.PENDING) {
            const editAction = {
                icon: 'pencil-fill',
                tooltip: 'Editar',
                onClick: onEdit
            }
            const removeAction: Action = {
                icon: 'trash-fill',
                kind: 'secondary',
                tooltip: 'Eliminar',
                onClick: onRemove
            }
            actionList.push(editAction, removeAction)
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Expense[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Sueldos Liquidados" wide>
            <SalaryFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentExpense}
                    createLabel="Crear Nuevo"
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
            <ExpenseReceiptModal expense={currentExpense} />
        </PanelWrapper>
    )
}
