import { DateField } from '../date-field/dateField'
import { NumberField } from '../number-field/numberField'
import { PriceField } from '../price-field/priceField'
import { TextField } from '../text-field/textField'
import { FormField, FormFieldProps, FieldValue, FieldType } from '../../types'
import { useFindListState } from '../../../../../state/reducers/hooks'
import { define } from '../../../../../utils/typeUtils'
import { option } from '../../../utils/uiUtils'
import { NamedDataType } from '../../../../../tables'
import { PercentField } from '../percent-field/percentField'
import { SelectField } from '../select-field/selectField'
import { BooleanField } from '../boolean-field/booleanField'
import { ImageField } from '../image-field/imageField'
import { TextArea } from '../text-area/textArea'

type Props = {
    field: FormField
    onChange: (value?: FieldValue) => void
    className?: string
}

export const Field = (props: Props) => {
    const { field, onChange, className } = props
    const findListState = useFindListState()

    if (field.type === FieldType.DATE) {
        const dateField = field as FormFieldProps<Date>
        
        return <DateField
            id={dateField.name}
            value={dateField.value}
            defaultValue={dateField.defaultValue}
            placeholder={dateField.placeholder}
            minDate={dateField.min as Date}
            maxDate={dateField.max as Date}
            optional={dateField.optional}
            disabled={dateField.disabled}
            onChange={onChange}
            className={className}
        />
    } else if (field.type === FieldType.SELECT) {
        const selectField = field as FormFieldProps<string>
        
        return <SelectField
            id={selectField.name}
            value={selectField.value}
            defaultValue={selectField.defaultValue}
            options={selectField.options || []}
            placeholder={selectField.placeholder}
            optional={selectField.optional}
            disabled={selectField.disabled}
            renderOption={selectField.renderOption}
            focus={selectField.focus}
            onChange={onChange}
            aria-label={`${selectField.name} select field.`}
            className={className}
        />
    } else if (field.type === FieldType.NUMBER) {
        const numberField = field as FormFieldProps<number>
        
        return <NumberField
            id={numberField.name}
            value={numberField.value}
            defaultValue={numberField.defaultValue}
            placeholder={numberField.placeholder}
            min={numberField.min as number}
            max={numberField.max as number}
            allowDecimals={numberField.allowDecimals}
            optional={numberField.optional}
            disabled={numberField.disabled}
            focus={numberField.focus}
            onChange={onChange}
            aria-label={`${numberField.name} number field.`}
            className={className}
        />
    } else if (field.type === FieldType.PRICE) {
        const priceField = field as FormFieldProps<number>
        
        return <PriceField
            id={priceField.name}
            value={priceField.value}
            defaultValue={priceField.defaultValue}
            min={priceField.min as number}
            max={priceField.max as number}
            optional={priceField.optional}
            disabled={priceField.disabled}
            onChange={onChange}
        />
    } else if (field.type === FieldType.PERCENTAGE) {
        const percentField = field as FormFieldProps<number>
        
        return <PercentField
            id={percentField.name}
            value={percentField.value}
            defaultValue={percentField.defaultValue}
            optional={percentField.optional}
            disabled={percentField.disabled}
            onChange={onChange}
        />
    } else if (field.type === FieldType.BOOLEAN) {
        const booleanField = field as FormFieldProps<boolean>
        
        return <BooleanField
            id={booleanField.name}
            label={booleanField.label}
            value={!!booleanField.value}
            defaultValue={!!booleanField.defaultValue}
            optional={true}
            disabled={booleanField.disabled}
            onChange={onChange}
        />
    } else if (field.type === FieldType.IMAGE) {
        const imageField = field as FormFieldProps<File>
        
        return <ImageField
            id={imageField.name}
            value={imageField.value}
            onChange={onChange}
        />
    } else if (field.type === FieldType.TABLE) {
        const tableField = field as FormFieldProps<string>
        let options = tableField.options
        if (!options) {
            const list = findListState(define(field.table)) as NamedDataType[]
            options = list.map(item => option(item.id, item.name || item.id))
        }

        return <SelectField
            id={tableField.name}
            value={tableField.value}
            defaultValue={tableField.defaultValue}
            options={options}
            placeholder={tableField.placeholder}
            optional={tableField.optional}
            disabled={tableField.disabled}
            renderOption={tableField.renderOption}
            focus={tableField.focus}
            onChange={onChange}
            onCreateNew={tableField.onCreateNew}
            aria-label={`${tableField.name} select field.`}
            className={className}
        />
    } else if (field.type === FieldType.LONG_TEXT) {
        const textAreaField = field as FormFieldProps<string>
        
        return <TextArea
            id={textAreaField.name}
            value={textAreaField.value}
            defaultValue={textAreaField.defaultValue}
            optional={textAreaField.optional}
            disabled={textAreaField.disabled}
            onChange={onChange}
            className={className}
        />
    }
    
    const textField = field as FormFieldProps<string>

    return <TextField
        id={textField.name}
        value={textField.value}
        defaultValue={textField.defaultValue}
        placeholder={textField.placeholder}
        freeValue={true}
        icon={textField.icon}
        optional={textField.optional}
        disabled={textField.disabled}
        focus={textField.focus}
        onChange={onChange}
        aria-label={`${textField.name} text field.`}
        className={className}
    />
}
