import styles from './deliveryList.module.css'
import { TableName } from "../../../../tables"
import { useDataTypesHooks } from "../../../../features/data-types"
import { Column, Data, FieldType, FieldValue, Section, Table } from "../../../../features/ui"
import { useFind, useFindMultipleByParams } from "../../../../state/reducers/hooks"
import { define } from "../../../../utils/typeUtils"
import { Product, ProductName } from "../../../products"
import { Operation, OrderDelivery } from '../../state/types'

type Props = {
  orderId: string
  operation: Operation
}

export const DeliveryList = (props: Props) => {
  const { orderId, operation } = props

  const { formatValue } = useDataTypesHooks()
  const find = useFind()
  const findMultipleByParams = useFindMultipleByParams()

  const deliveries = findMultipleByParams(TableName.ORDER_DELIVERIES, { orderId }) as OrderDelivery[]

  const tableName = () => {
    switch (operation) {
      case Operation.PURCHASE:
        return TableName.PURCHASE_ITEMS
      case Operation.HOARD:
        return TableName.HOARD_ITEMS
      case Operation.SALE:
      default:
        return TableName.SALE_ITEMS
    }
  }

  const columns: Column[] = [
    {
      name: 'productId',
      type: FieldType.TEXT,
      label: 'Producto',
      render: (value?: FieldValue) => {
        const product = define(find(TableName.PRODUCTS, value as string | undefined)) as Product
        return <ProductName product={product} showSupplierCode />
      }
    },
    {
      name: 'quantity',
      type: FieldType.TEXT,
      label: 'Cantidad Entregada'
    }
  ]
  
  return (<>
    {deliveries
      .sort((delivery_A, delivery_B) => delivery_B.date.localeCompare(delivery_A.date))
      .map((delivery, index) => {
        const date = formatValue(new Date(delivery.date), FieldType.DATE)
        const branchName = formatValue(delivery.branchId, FieldType.TABLE, TableName.BRANCHES)

        const items = delivery.deliveredItems.map(deliveredItem => {
          const item = define(find(tableName(), deliveredItem.itemId)) as Data
          return {
            productId: item.productId,
            quantity: deliveredItem.delivered
          }
        })

        return (
          <Section
            key={index}
            id="delivery"
            title={`${date} - ${branchName}`}
            collapsable
            className={styles.deliverySection}
          >
            <Table
              columns={columns}
              rows={items}
              rowId='productId'
              readOnly
              small
            />
          </Section>
        )
      }
    )}
  </>)
}
