import { useNavigate } from "react-router-dom"
import { ModalId, useUIActions } from "../../../ui"
import { useWizardActions } from "../../actions/wizardActions"

export const useActions = () => {
    const navigate = useNavigate()

    const { clearData } = useWizardActions()
    const { toggleModal } = useUIActions()

    const resumeWizard = (path: string) => {
        toggleModal(ModalId.OPEN_WIZARD)
        navigate(path)
    }

    const restartWizard = (wizardKey: string, path: string) => {
        clearData(wizardKey)
        toggleModal(ModalId.OPEN_WIZARD)
        navigate(path)
    }
    
    return {
        resumeWizard,
        restartWizard
    }
}
