import styles from './editableField.module.css'
import { Button } from '../button/button'
import { useState } from 'react'
import { FieldRow } from '../field-row/fieldRow'
import { Field } from '../fields/field/field'
import { FormField, FieldValue, FieldType, EditableFormField } from '../types'

type Props = {
    id: string
    field: EditableFormField
    readOnly?: boolean
    onSubmit?: (value?: FieldValue) => void
}

export const EditableField = (props: Props) => {
    const { id, field, readOnly, onSubmit } = props
    
    const [value, setValue] = useState<FieldValue | undefined>(field.value || field.defaultValue)
    const [disabled, setDisabled] = useState<boolean>(true)
    const [focus, setFocus] = useState<boolean>(false)

    const onEdit = () => {
        !readOnly && setDisabled(false)
        setFocus(true)
    }

    const onChange = (field: FormField) => (value?: FieldValue) => {
        setValue(value)
        field.onChange && field.onChange(value)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setDisabled(true)
        setFocus(false)
        onSubmit && onSubmit(value)
    }

    const onCancel = () => {
        setDisabled(true)
        setFocus(false)
        setValue(field.defaultValue)
    }

    const editableField: EditableFormField = {
        ...field,
        value,
        disabled,
        focus
    }

    return (
        <form id={id} onSubmit={handleSubmit} className={styles.form}>
            <FieldRow
                id={editableField.name}
                label={editableField.label}
                hint={editableField.hint}
                labelSpace={4}
                hideLabel={editableField.type === FieldType.BOOLEAN}
                narrow={editableField.narrow}
                className={styles.fieldRow}
            >
                {editableField.render ? editableField.render() : (
                    <Field
                        key={editableField.key}
                        field={editableField}
                        onChange={onChange(editableField)}
                    />
                )}
            </FieldRow>
            {!readOnly && !field.disabled && (
                <div className={styles.btnsContainer}>
                    <Button
                        icon='pencil'
                        tooltip='Editar'
                        kind="tertiary"
                        disabled={!disabled}
                        onClick={onEdit}
                        className={`${styles.editBtn} ${!disabled ? styles.hidden : ''}`}
                    />
                    <Button
                        type='submit'
                        icon='check-lg'
                        tooltip='Guardar'
                        rightMargin
                        disabled={disabled}
                        className={disabled ? styles.hidden : ''}
                    />
                    <Button
                        icon='x-lg'
                        tooltip='Cancelar'
                        kind="secondary"
                        type="button"
                        disabled={disabled}
                        onClick={onCancel}
                        className={disabled ? styles.hidden : ''}
                    />
                </div>
            )}
        </form>
    )
}
