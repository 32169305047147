import "react-toastify/dist/ReactToastify.css"
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import { Header, Footer } from './features/ui';
import { ToastContainer } from 'react-toastify';
import { initTablesData } from './tables';
import { initIamSystemApi, initSystemApis } from './services';
import { awsSystemKey, awsSystemSecret } from './services/constants';
import { TenantKey, UniqueKey } from './tables/constants';

initTablesData()
initSystemApis(awsSystemKey, awsSystemSecret, TenantKey, UniqueKey)
initIamSystemApi(awsSystemKey, awsSystemSecret)

function App() {
  return (
    <>
      <Header />
      <main id="main" className="main">
        <Outlet />
      </main>
      <Footer />
      <ToastContainer />
    </>
  )
}

export default withAuthenticationRequired(App)
