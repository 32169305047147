import { FormField } from "../ui"

export enum WizardId {
    PURCHASE_DELIVERY = 'purchase-delivery',
    SALE_DELIVERY = 'sale-delivery',
    HOARD_DELIVERY = 'hoard-delivery'
}

export type Step = {
    fields: FormField[]
}
