import { useDefaultModuleActions } from "../../../../modules/common"
import { DynamoUserApi } from "../../../../services"
import { useRoutesHooks } from "../../hooks/routesHooks"

export const useLoad = () => {
    const { tableName, dataTypeId } = useRoutesHooks().parseUrl()

    const defaultModuleActions = useDefaultModuleActions()
    
    return async () => {
        if (tableName && dataTypeId) {
            return await defaultModuleActions(DynamoUserApi).fetchByParams(tableName, { id: dataTypeId })
        }
    }
}
