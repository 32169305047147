import { useEffect, useState } from "react"
import { Data, Modal, ModalId, useUIState } from "../../../../features/ui"
import { MeasureForm } from "./measureForm"
import { MeasureType } from "../../state/types"

type Props = {
    measureData?: Data
    filterMeasureTypes: (measureType: MeasureType) => boolean
    onSubmit: (data: Data) => void
}

export const MeasureFormModal = (props: Props) => {
    const { measureData, filterMeasureTypes, onSubmit } = props
    const [key, setKey] = useState(0)
    const { openedModal } = useUIState()

    useEffect(() => setKey(key + 1), [openedModal])
    
    return (
        <Modal
            modalId={ModalId.MEASURE_FORM}
            title="Agregar Medida"
            actions={[]}
            widthSize="m"
        >
            <MeasureForm
                key={key}
                measureData={measureData}
                filterMeasureTypes={filterMeasureTypes}
                onSubmit={onSubmit}
            />
        </Modal>
    )
}
