import { Transaction, TransactionDestinationType, TransactionSourceType } from "../../../state/types"
import { Data, showToast } from "../../../../../features/ui"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { Currency } from "../../../../../tables/types/types"
import { useSystemStateHooks } from "../../../../system"
import { define } from "../../../../../utils/typeUtils"
import { useNavigate } from "react-router-dom"
import { newId } from "../../../../../features/data-types"

export const useActions = () => {
    const { companyId } = useSystemStateHooks()
    const navigate = useNavigate()

    const administrationActions = useAdministrationActions()

    const submitTransfer = async (
        transferData: Data,
        concept: string
    ) => {
        const { sourceId, destinationId, amount } = transferData
        const transaction: Transaction = {
            companyId: define(companyId),
            id: newId(),
            date: new Date().toISOString(),
            sourceType: TransactionSourceType.CASH_FUND,
            sourceId: define(sourceId) as string,
            amount: define(amount) as number,
            destinationType: TransactionDestinationType.CASH_FUND,
            destinationId: define(destinationId) as string,
            currency: Currency.ARS,
            concept
        }
        
        await administrationActions().transferFromCashFund(transaction)
        
        showToast('La transferencia fue completada.')
        navigate(-1)
    }
    
    return {
        submitTransfer
    }
}
