import { ReducerState } from "../../../state/reducers/types"
import { addOrUpdate, remove } from "../../../state/reducers/utils"
import { ActionType, StockAction } from './actionTypes'
import { Stock, UnitType } from "./types"

export type StockState = {
    stock: Stock[]
    unitTypes: UnitType[]
}

const initialState: StockState = {
    stock: [],
    unitTypes: []
}

const reducer = (state = initialState, action: StockAction) => {
    switch(action.type) {
        case ActionType.UPDATE_STOCK:
            return { 
                ...state,
                stock: addOrUpdate(state, action, 'stock' as keyof ReducerState)
            }
        case ActionType.REMOVE_STOCK:
            return { 
                ...state,
                stock: remove(state, action, 'stock' as keyof ReducerState)
            }
        case ActionType.UPDATE_UNIT_TYPES:
            return { 
                ...state,
                unitTypes: addOrUpdate(state, action, 'unitTypes' as keyof ReducerState)
            }
        default:
            return state
    }
}

export default reducer
