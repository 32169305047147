import { useNavigate } from "react-router-dom"
import { isDefined } from "../../../utils/typeUtils"
import { FieldValue, ModalId, useUIActions } from "../../ui"
import { useWizardHooks } from "../hooks/wizardHooks"

export const useWizardActions = () => {
    const { getData, getStepData, containsData } = useWizardHooks()
    const navigate = useNavigate()
    const { toggleModal } = useUIActions()

    const updatedData = (
        wizardKey: string,
        stepIndex: number,
        fieldName: string,
        value?: FieldValue
    ) => {
        const wizardData = getData(wizardKey)
        const stepData = getStepData(wizardKey, stepIndex)
        const newStepData = {
            ...stepData,
            [fieldName]: value
        }
        if (!isDefined(value)) {
            delete newStepData[fieldName]
        }
        return {
            ...wizardData,
            [stepIndex]: newStepData
        }
    }

    const saveData = (
        wizardKey: string,
        newWizardData: object
    ) => {
        localStorage.setItem(wizardKey, JSON.stringify(newWizardData))
    }

    const clearData = (wizardKey: string) => {
        localStorage.removeItem(wizardKey)
    }

    const goToWizard = (
        wizardKey: string,
        path: string
    ) => {
        if (containsData(wizardKey)) {
            toggleModal(ModalId.OPEN_WIZARD)
        } else {
            navigate(path)
        }
    }

    return {
        updatedData,
        saveData,
        clearData,
        goToWizard
    }
}
