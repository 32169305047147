import { define } from "../../../utils/typeUtils"
import { usePurchasesActions } from "../../purchases"
import { useSalesActions } from "../../sales"
import { useStockActions } from "../../stock"
import { useSystemStateHooks } from "../../system"

export const useWarehouseActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const purchasesActions = usePurchasesActions()
    const salesActions = useSalesActions()
    const stockActions = useStockActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const { savePurchaseDelivery } = purchasesActions(companyId)
        const { saveSaleDelivery } = salesActions(companyId)
        const { increaseStock, decreaseStock } = stockActions(companyId)

        return {
            savePurchaseDelivery,
            saveSaleDelivery,
            increaseStock,
            decreaseStock
        }
    }
}
