import { DataTypeList } from "../../../../features/data-types"
import { PanelWrapper, Column, FieldType, UIPermission } from "../../../../features/ui"
import { useSystemStateHooks } from "../../../system"

export const CategoryList = () => {
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_CATEGORIES)

    const columns: Column[] = [
        {
            name: 'imageUrl',
            type: FieldType.IMAGE,
            label: ' '
        },
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        }
    ]

    return (
        <PanelWrapper title="Categorías">
            <DataTypeList
                columns={columns}
                readOnly={readOnly}
                showDetail={false}
            />
        </PanelWrapper>
    )
}
