import 'jquery-ui-dist/jquery-ui'
import $ from 'jquery'
import jsPDF from "jspdf"

export const usePDFActions = () => {
    const generatePDF = (
        pdfId: string,
        fileName: string,
    ) => {
        const doc = new jsPDF('portrait', 'px',[595, 842])
        const elementSelector = $(`#${pdfId}`).clone()
        elementSelector.css('display', 'block')
        const element = elementSelector.get(0)
        if (element) {
            doc.html(element, {
                callback: () => {
                    doc.save(fileName)
                }
            })
        }
    }

    return {
        generatePDF
    }
}