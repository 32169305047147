import { useDataTypesHooks } from "../../../../../features/data-types"
import { Table, Column, Data, FieldType, FieldValue, Section } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"

type Props = {
    rows: Data[]
    readOnly?: boolean
    onAdd?: () => void
    onEditRow?: (catgoryId: string) => void
    onRemoveRow?: (catgoryId: string) => void
}

export const CategorySaleYieldsTable = (props: Props) => {
    const { rows, readOnly, onAdd, onEditRow, onRemoveRow } = props

    const { formatValue } = useDataTypesHooks()

    const columns: Column[] = [
        {
            name: 'categoryId',
            type: FieldType.TABLE,
            table: TableName.CATEGORIES,
            label: 'Categoría'
        },
        {
            name: 'saleYield',
            type: FieldType.PERCENTAGE,
            label: 'Rentabilidad',
            render: (value?: FieldValue) => <>{formatValue(value, FieldType.NUMBER)}%</>
        }
    ]

    return (
        <Section
            id='categorySaleYields'
            space={12}
        >
            <Table
                columns={columns}
                rows={rows}
                rowId='categoryId'
                readOnly={readOnly}
                createIcon="plus"
                small
                onCreate={onAdd}
                onEdit={onEditRow}
                onRemove={onRemoveRow}
            />
        </Section>
    )
}
