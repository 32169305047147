import { Product, Category, ProductPrices, Measure, Lot } from "./types"

export enum ActionType {
    UPDATE_PRODUCTS = 'updateProducts',
    REMOVE_PRODUCTS = 'removeProducts',
    UPDATE_CATEGORIES = 'updateCategories',
    REMOVE_CATEGORIES = 'removeCategories',
    UPDATE_PRODUCT_PRICES = 'updateProductPrices',
    REMOVE_PRODUCT_PRICES = 'removeProductPrices',
    UPDATE_LOTS = 'updateLots',
    REMOVE_LOTS = 'removeLots',
    UPDATE_MEASURES = 'updateMeasures',
    REMOVE_MEASURES = 'removeMeasures'
}

interface UpdateProducts {
    type: ActionType.UPDATE_PRODUCTS
    payload: Product[]
}

interface RemoveProducts {
    type: ActionType.REMOVE_PRODUCTS
    payload: string[]
}

interface UpdateCategories {
    type: ActionType.UPDATE_CATEGORIES
    payload: Category[]
}

interface RemoveCategories {
    type: ActionType.REMOVE_CATEGORIES
    payload: string[]
}

interface UpdateProductPrices {
    type: ActionType.UPDATE_PRODUCT_PRICES
    payload: ProductPrices[]
}

interface RemoveProductPrices {
    type: ActionType.REMOVE_PRODUCT_PRICES
    payload: string[]
}

interface UpdateLots {
    type: ActionType.UPDATE_LOTS
    payload: Lot[]
}

interface RemoveLots {
    type: ActionType.REMOVE_LOTS
    payload: string[]
}

interface UpdateMeasures {
    type: ActionType.UPDATE_MEASURES
    payload: Measure[]
}

interface RemoveMeasures {
    type: ActionType.REMOVE_MEASURES
    payload: string[]
}

export type ProductsAction =
    ProductsUpdateAction |
    ProductsRemoveAction

export type ProductsUpdateAction =
    UpdateProducts |
    UpdateCategories |
    UpdateProductPrices |
    UpdateLots |
    UpdateMeasures

export type ProductsRemoveAction =
    RemoveProducts |
    RemoveCategories |
    RemoveProductPrices |
    RemoveLots |
    RemoveMeasures
