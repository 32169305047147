import { useEffect, useState } from "react"
import { PanelWrapper, FormField, Data, useUIStateActions, FieldType, FieldValue, Spinner, FileField, useGetCrumbs, Badge, TextArea } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { useActions } from "./actions"
import { DataTypeForm } from "../../../../../features/data-types"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Branch, useManagerState } from "../../../../manager"
import { define } from "../../../../../utils/typeUtils"
import { useFind } from "../../../../../state/reducers/hooks"
import { Expense } from "../../../state/types"
import { fileName, useFileActions } from "../../../../../features/files"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const SalaryForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const expense = dataType as Expense | undefined
    const createMode = !expense
    const { currentBranch } = useManagerState()
    const branchId = currentBranch?.id
    
    const [code, setCode] = useState(expense?.code || 1)
    
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(!expense?.receiptUrl)
    const [detail, setDetail] = useState<string | undefined>(expense?.detail)
    const [receiptFile, setReceiptFile] = useState<File>()
    
    const navigate = useNavigate()
    const generateNextValue = useGenerateNextValue()
    const find = useFind()

    const { submitSalaryExpense } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { fetchFile } = useFileActions()

    const init = async () => {
        setDisableBranchSelector(true)
        if (createMode) {
            setCode(await generateNextValue(TableName.EXPENSES, 'code'))
        }
        if (!createMode && expense && expense?.receiptUrl) {
            const file = await fetchFile(expense.receiptUrl, fileName(TableName.EXPENSES, expense, 'Sueldo_Liquidado'))
            setReceiptFile(file)
        }
        setAsyncDataLoaded(true)
    }

    useEffect(() => {
        init()
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    const expenseLabel = expense ? `: ${expense.name}` : ''
    const title = `${createMode ? 'Registrar' : 'Editar'} Sueldo Liquidado${expenseLabel}`

    const crumbs = useGetCrumbs('Sueldos Liquidados', RoutePath.SALARIES, expense?.name, undefined, createMode ? 'Registrar' : 'Editar')

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

    const onDetailChange = (value?: FieldValue) => {
        setDetail(value as string | undefined)
    }

    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal',
            render: () => {
                const branch = define(find(TableName.BRANCHES, branchId))as Branch
                return <Badge label={branch.name} />
            }
        },
        {
            name: 'date',
            type: FieldType.DATE,
            label: 'Fecha',
            defaultValue: new Date(),
            max: new Date()
        },
        {
            name: 'detail',
            type: FieldType.TEXT,
            label: 'Detalle',
            render: () => <TextArea
                id="detail"
                defaultValue={detail}
                optional
                onChange={onDetailChange}
            />
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        },
        {
            name: 'receiptUrl',
            type: FieldType.FILE,
            label: 'Recibo',
            hint: 'Formato: PDF',
            render: () => renderAsync(<FileField
                id="receiptUrl"
                value={receiptFile}
                onChange={setReceiptFile}
            />)
        }
    ]
    
    const onSubmit = (expenseData: Data) => {
        submitSalaryExpense(
            expenseData,
            createMode,
            code,
            define(branchId),
            detail,
            receiptFile
        )
    }

    const onCancel = () => navigate(-1)
    
    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="salary-expense-form"
               fields={fields}
               createMode={createMode}
               disableSubmit={!asyncDataLoaded}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
