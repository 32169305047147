import { ReactElement, useState } from "react"
import { ModalId, useUIActions } from "../../ui"

export const useWizard = (steps: ReactElement[]) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const { toggleModal } = useUIActions()

    const goTo = (index: number) => setCurrentStepIndex(index)
    
    const next = () => {
        if (isLastStep) {
            toggleModal(ModalId.FINISH_WIZARD)
        } else {
            setCurrentStepIndex(prevIndex => prevIndex + 1)
        }
    }
    
    const back = () => setCurrentStepIndex(prevIndex => prevIndex <= 0 ? prevIndex : prevIndex - 1)

    const isFirstStep = currentStepIndex === 0
    const isLastStep = currentStepIndex === steps.length - 1

    return {
        currentStepIndex,
        step: steps[currentStepIndex],
        steps,
        goTo,
        next,
        back,
        isFirstStep,
        isLastStep
    }
}
