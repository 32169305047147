import styles from './icon.module.css'

export type IconColor = 'primary' | 'secondary' | 'tertiary' | 'quaternary'

type Props = {
    icon: string
    color?: IconColor
    rightMargin?: boolean
    small?: boolean
    className?: string
    onClick?: () => void
}

export const Icon = (props: Props) => {
    const { icon, color = 'primary', rightMargin, small, className, onClick } = props
    const getColorClass = () => {
        switch(color) {
            case 'primary':
            default:
                return styles.iconPrimary
            case 'secondary':
                return styles.iconSecondary
            case 'tertiary':
                return styles.iconTertiary
            case 'quaternary':
                return styles.iconQuaternary
        }
    }
    const sizeClass = small ? styles.small : ''
    const marginClass = rightMargin ? styles.rightMargin : ''
    const propClass = className || ''

    return <i
        className={`bi bi-${icon} ${styles.icon} ${getColorClass()} ${sizeClass} ${marginClass} ${propClass}`}
        onClick={onClick}
    />
}
