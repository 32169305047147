import { RoutePath, useRoutesHooks } from "../../routes"
import { Crumb } from "../components/types"

export const useGetCrumbs = (
    listLabel: string,
    listPath: RoutePath,
    detailLabel?: string,
    id?: string,
    actionLabel?: string
): Crumb[] => {
    const { getRoutePath } = useRoutesHooks()

    const crumbs: Crumb[] = [
        {
            label: listLabel,
            path: getRoutePath(listPath)
        }
    ]
    
    if (detailLabel) {
        crumbs.push({
            label: detailLabel,
            ...(id && {
                path: getRoutePath(listPath, id)
            })
        })
    }
    
    if (actionLabel) {
        crumbs.push({
            label: actionLabel
        })
    }

    return crumbs
}
