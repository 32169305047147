import { useImportProductPrices } from "../../../../features/importer"

export const useActions = () => {
    const importProductPrices = useImportProductPrices()

    const updatePrices = (file: File) => {
        importProductPrices(file)
    }

    return { updatePrices }
}
