import { useState } from "react"
import { FileType } from "../.."
import { Button } from "../button/button"

type Props = {
    id: string
    fileTypes: FileType[]
    onChange: (value?: File) => void
}

export const UploadButton = (props: Props) => {
    const { id, fileTypes, onChange } = props

    const [uploadRef, setUploadRef] = useState<HTMLLabelElement | null>()

    const toAccept = (types: FileType[]) => {
        const map = new Map<FileType, string>()
            .set(FileType.PDF, 'application/pdf')
            .set(FileType.JPEG, 'image/jpeg')
            .set(FileType.JPG, 'image/jpg')
            .set(FileType.PNG, 'image/png')
        return types.length > 0 ? types.map(type => map.get(type)).join(',') : '*'
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files) {
            const file = files[0]
            onChange(file)
        }
    }

    const handleClickUpload = () => uploadRef?.click()

    return (<>
        <input
            type="file"
            id={id}
            accept={toAccept(fileTypes)}
            hidden
            onChange={handleFileChange}
        />
        <label htmlFor={id} ref={ref => setUploadRef(ref)}>
            <Button
                icon='upload'
                tooltip="Seleccionar Archivo"
                rightMargin
                onClick={handleClickUpload}
            />
        </label>
    </>)
}
