import { OrderDelivery } from "./types"

export enum ActionType {
    UPDATE_DELIVERIES = 'updateDeliveries',
    REMOVE_DELIVERIES = 'removeDeliveries',
}

interface UpdateDeliveries {
    type: ActionType.UPDATE_DELIVERIES
    payload: OrderDelivery[]
}

interface RemoveDeliveries {
    type: ActionType.REMOVE_DELIVERIES
    payload: string[]
}

export type OperationsAction =
    OperationsUpdateAction |
    OperationsRemoveAction

export type OperationsUpdateAction =
    UpdateDeliveries

export type OperationsRemoveAction =
    RemoveDeliveries
