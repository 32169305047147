import { PanelWrapper } from "../panel-wrapper/panelWrapper"
import { SpinnerDiv } from "../spinner/spinnerDiv"
import { Action, Crumb } from "../types"

type Props = {
  title?: string
  crumbs?: Crumb[]
  ready: boolean
  wide?: boolean
  actions?: Action[]
  children?: JSX.Element
}

export const PanelLoader = (props: Props) => {
  const { title, crumbs, ready, wide, actions, children } = props

  return (
    <PanelWrapper
      title={title}
      crumbs={crumbs}
      wide={wide}
      actions={actions}
    >
      {ready ? children : <SpinnerDiv />}
    </PanelWrapper>
  )
}
